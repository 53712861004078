import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en';
import de from './translations/de';

const resources = {
  en,
  de,
};

let lng = 'de';

const LOCAL_STORAGE_KEY = '__i18n_locale__';
try {
  const l = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!l) {
    console.log(`No locale stored in localStorage. Fallback to ${lng}`);
  } else if (Object.keys(resources).includes(l)) {
    console.log(`Load and setting default locale from localStorage to ${l}`);
    lng = l;
  } else {
    console.warn(`Unknown locale loaded from localStorage ${l}. Fallback to lng`);
  }
} catch (error) {
  console.warn('Error retrieving current locale from localStorage', error);
}

i18n.use(initReactI18next).init({
  resources,
  lng,
  // keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

i18n.on('languageChanged', (l) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, l);
  } catch (error) {
    console.warn('Error saving current locale to localStorage', error);
  }
});

export default i18n;
