import PageContainer from 'components/layout/PageContainer';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { resetTheme } from 'graphql/methods';
import { useTheme } from 'graphql/hooks';
import toast from 'utils/toast';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import TableTheme from './TableTheme';
import TableOfContent from './TableOfContent';
import CorporatePrimaryColour from './CorporatePrimaryColour';
import TextStylesForDocuments from './TextStylesForDocuments';
import BodyPrimary from './BodyPrimary';
import classes from './CorporateIdentitySetting.module.less';

const skeletons = [...new Array(3)].map((_, i) => i + 1);
const onResetTheme = async () => {
  const onConfirm = () =>
    resetTheme()
      .then(() => toast.successDefault())
      .catch((e) => console.error(e) || toast.error(i18n.t('admin.Setting.CorporateIdentity.errors.reset')));

  confirmModal({
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.cancel'),
    okText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.ok'),
    okType: 'danger',
    onOk: onConfirm,
    title: i18n.t('admin.Setting.CorporateIdentity.resetConfirmModalMessage'),
  });
};

function CorporateIdentitySetting() {
  const { t } = useTranslation();
  const { loading, theme } = useTheme();

  return (
    <PageContainer
      title={t('admin.Setting.CorporateIdentity.title')}
      right={
        <Button icon={<UndoOutlined />} onClick={onResetTheme}>
          {t('admin.Setting.CorporateIdentity.reset')}
        </Button>
      }
    >
      {loading ? (
        skeletons.map((k) => <Skeleton title loading={!theme && loading} active key={k} />)
      ) : (
        <div className={classes.cards}>
          <CorporatePrimaryColour
            primaryColor={theme.main.primaryColor}
            color={theme.main.color}
            fontFamily={theme.main.fontFamily}
          />
          <TextStylesForDocuments
            title={theme.title}
            h1={theme.h1}
            h2={theme.h2}
            h3={theme.h3}
            textBlock={theme.textBlock}
            testimonial={theme.testimonial}
            testimonialSmallText={theme.testimonialSmallText}
          />
          <TableTheme
            table={theme.table}
            tableContent={theme.tableContent}
            tableFooter={theme.tableFooter}
            tableHeaderPrimary={theme.tableHeaderPrimary}
            tableHeaderSecondary={theme.tableHeaderSecondary}
            tableLeftColumn={theme.tableLeftColumn}
          />
          <TableOfContent tableOfContent={theme.tableOfContent} />
          <BodyPrimary bodyPrimary={theme.bodyPrimary} bodyPrimaryFooter={theme.bodyPrimaryFooter} />
        </div>
      )}
    </PageContainer>
  );
}
export default CorporateIdentitySetting;
