/* eslint-disable react/no-unescaped-entities */
import PageContainer from 'components/layout/PageContainer';
import { useQuery } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';
import { Alert, Skeleton, Button } from 'antd';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import { adminCategoryListQuery, libraryCategories } from 'graphql/queries';
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { createCategory as _createCategory, createCommonCategory } from 'graphql/methods';
import { useMemo, useState, memo } from 'react';
import { CustomDragLayer } from 'components/admin/drag/CustomDragLayer';
import { useLibraryContext } from 'contexts/LibraryContext';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import CatalogueConfigurationDraggable from './CatalogueConfigurationDraggable';
import classes from './CatalogueConfiguration.module.less';
import ImportCategoryPresets from './ImportCategoryPresetsModal';
import ItemEditModal from './itemModal/ItemEditModal';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

export default memo(CatalogueConfigurationPage);
function CatalogueConfigurationPage() {
  const { isLibrary } = useLibraryContext();
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const createCategory = isLibrary ? createCommonCategory : _createCategory;

  const showModal = () => setIsModalVisible(true);

  return (
    <PageContainer
      title={isLibrary ? t('admin.CatalogueConfiguration.titleCommonLibrary') : t('admin.CatalogueConfiguration.title')}
      rightProps={{ style: { flex: 0.5 } }}
      right={
        <div className="ant-modal-confirm-btns">
          {!isLibrary ? (
            <Button icon={<ImportOutlined />} onClick={showModal}>
              {t('admin.CatalogueConfiguration.importCategoryPresets')}
            </Button>
          ) : null}
          <Button icon={<PlusOutlined />} onClick={createCategory} type="primary">
            {t('admin.CatalogueConfiguration.addCategory')}
          </Button>
        </div>
      }
    >
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          <ItemEditModal />
          <CatalogueConfigurationPageContent />
          <ImportCategoryPresets visible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
}

function CatalogueConfigurationPageContent() {
  const { isLibrary } = useLibraryContext();
  const me = useCurrentUser();
  const { data, loading, error } = useQuery(isLibrary ? libraryCategories : adminCategoryListQuery, {
    fetchPolicy: me?.[0]?.role?.includes('ADMIN') ? 'network-only' : 'cache-first', // force refresh if admin changed something to force load changes
  });
  const categories = useMemo(() => grabFirstGQLDataResult(data) || [], [data]);
  const { t } = useTranslation();

  return (
    <>
      {skeletons.map((k) => (
        <Skeleton title loading={!data && loading} active key={k} />
      ))}
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      <div className={classes.categoryList}>
        <CatalogueConfigurationDraggable categories={categories} />
      </div>
      {isEmpty(categories) && !loading && !error ? (
        <p>{t('admin.CatalogueConfiguration.emptyCategoryListMessage')}</p>
      ) : null}

      <CustomDragLayer />
    </>
  );
}
