import apollo from 'graphql/apollo';
import { createCompanyMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userCompanyListQuery } from 'graphql/queries';

const createCompany = async ({ type, name, identifer, street, houseNumber, cityCode, city, country }) =>
  apollo.mutate({
    mutation: createCompanyMutation,
    variables: {
      company: {
        type,
        name,
        identifer,
        address: {
          street,
          houseNumber,
          cityCode,
          city,
          country,
        },
      },
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: userCompanyListQuery });
      cache.writeQuery({
        query: userCompanyListQuery,
        data: {
          companies: [result, ...cachedData.companies],
        },
      });
    },
  });

export default createCompany;
