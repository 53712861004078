import { Form, Input } from 'formik-antd';
import * as Yup from 'yup';
import { memo, useRef } from 'react';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import { updateCategory } from 'graphql/methods';
import FormItem from 'components/common/FormItem';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { useLibraryContext } from 'contexts/LibraryContext';
import I18nFormik from 'components/common/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { RichEditorField } from 'components/common/RichEditor';
import { textsSchema } from './itemModal/schema';
import { SubTitleFormikInput } from './itemModal/components';

const AddCategorySchema = () =>
  Yup.object().shape({
    ...textsSchema({}),
  });
export const IntroForDocumentFormikInput = ({ props }) => (
  <RichEditorField name="introForDocument" label="admin.categoryTexts.introForDocument.label" {...props} />
);

const TextsTab = ({ _id, name, subTitle, libraryDescription, introForDocument }) => {
  const initialValues = {
    name,
    subTitle,
    libraryDescription,
    introForDocument,
  };
  const formikRef = useRef();
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  return (
    <I18nFormik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={(values, formik) => updateCategory({ _id, modifier: values }).catch(formik.setErrors)}
      validationSchema={AddCategorySchema}
      enableReinitialize
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItem name="name" label={t('admin.categoryTexts.name.label')}>
          <Input name="name" autoFocus placeholder={t('admin.categoryTexts.name.label')} />
        </FormItem>
        <SubTitleFormikInput />
        <IntroForDocumentFormikInput />
        {isLibrary ? (
          <RichEditorField name="libraryDescription" label={t('admin.categoryTexts.libraryDescription.label')} />
        ) : null}
        <SaveChangesButton initialValues={initialValues} />
      </Form>
    </I18nFormik>
  );
};
export default memo(TextsTab, equal);
