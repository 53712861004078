import gql from 'graphql-tag';
import { adminCompanyFragment } from 'graphql/fragments';

const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany($company: CompanyInput!) {
    createCompany(company: $company) {
      ...adminCompanyFragment
    }
  }
  ${adminCompanyFragment}
`;

export default CREATE_COMPANY_MUTATION;
