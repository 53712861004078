import gql from 'graphql-tag';

const adminPdfTableColorsFragment = gql`
  fragment adminPdfTableColorsFragment on PdfTableColors {
    color
    background
  }
`;

export default adminPdfTableColorsFragment;
