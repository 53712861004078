import { Route, Switch } from 'react-router-dom';

export const DisplayByRoute = ({ path, children, component: Component, ...props }) => {
  return (
    <Switch>
      <Route key={path} path={path} exact render={(routeProps) => <Component {...routeProps} {...props} />} />
    </Switch>
  );
};

export default DisplayByRoute;
