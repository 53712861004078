import gql from 'graphql-tag';

const userDiscountFragment = gql`
  fragment userDiscountFragment on Discount {
    _id
    name
    value
    type
  }
`;

export default userDiscountFragment;
