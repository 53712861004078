import gql from 'graphql-tag';
import apollo from 'graphql/apollo';
import { adminItemFragment } from 'graphql/fragments';

export const SET_CATEGOTY_ITEMS_WRITE_FRAGMENT = gql`
  fragment setCategoryItems on Category {
    items {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;
export const GET_TYPENAME_CATEGORY = gql`
  query getCategories {
    categories {
      __typename
    }
  }
`;
export default function moveCategoryItem({ _id, items }) {
  const { categories } = apollo.readQuery({
    query: GET_TYPENAME_CATEGORY,
  });
  if (!categories.length) return;

  const { __typename } = categories[0];

  apollo.writeFragment({
    id: `${__typename}___${_id}`,
    fragment: gql`
      fragment setCategoryItems on Category {
        items {
          ...adminItemFragment
        }
      }
      ${adminItemFragment}
    `,
    fragmentName: 'setCategoryItems',
    data: { items },
  });
}
