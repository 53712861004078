import apollo from 'graphql/apollo';
import { updateItemMutation } from 'graphql/mutations';

const updateCategoryItem = async ({
  _id,
  benefits,
  companyTypeIds,
  infoText,
  libraryDescription,
  minPrice,
  name,
  notesToEmployee,
  paymentInterval,
  pleaseNote,
  pricingFormula,
  scalePricingFormulaTitle,
  scaleTitle,
  scales,
  scalesEnabled,
  subTitle,
}) =>
  apollo.mutate({
    mutation: updateItemMutation,
    variables: {
      _id,
      modifier: {
        benefits,
        companyTypeIds,
        infoText,
        libraryDescription,
        minPrice,
        name,
        notesToEmployee,
        paymentInterval,
        pleaseNote,
        pricingFormula,
        scalePricingFormulaTitle,
        scaleTitle,
        scales,
        scalesEnabled,
        subTitle,
      },
    },
  });
export default updateCategoryItem;
