import { useQuery } from '@apollo/react-hooks';
import { Skeleton, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import PageContainer from 'components/layout/PageContainer';
import { pick } from 'lodash';
import { moveItemInputField, updateCategoryItem } from 'graphql/methods';
import { useCallback } from 'react';
import InputFieldsTab from 'components/admin/InputFieldsTab';
import { getItemCategoryQuery } from 'graphql/queries';
import { clientShift } from 'utils/helpers';
import moveCategoryInputFieldCache from 'graphql/cache/moveItemInputField';
import ConditionalVisibilityTab from 'components/admin/ConditionalVisibilityTab';
import TextsTab from './TextsTab';
import PriceTab from './PriceTab';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const { TabPane } = Tabs;

const useItem = ({ _id }) => {
  const { loading, data } = useQuery(getItemCategoryQuery, { variables: { _id }, fetchPolicy: 'cache-and-network' });
  const item = data?.categoryItem;
  const itemMapped = item && { ...item, scales: item.scales && item.scales.map(({ __typename, ...rest }) => rest) };
  return { item: itemMapped, loading };
};
const ItemCatalogueConfiguration = () => {
  const {
    params: { id: _id },
  } = useRouteMatch();
  const { loading, item } = useItem({ _id });
  const categoryId = item?.categoryId;
  const inputFields = item?.inputFields;
  const { t } = useTranslation();
  const allInitialValues = pick(item, [
    'name',
    'infoText',
    'paymentInterval',
    'pricingFormula',
    'minPrice',
    'scales',
    'scaleTitle',
    'scalePricingFormulaTitle',
    'scalesEnabled',
  ]);
  const update = useCallback((modifier) => updateCategoryItem({ _id, ...allInitialValues, ...modifier }), [
    _id,
    allInitialValues,
  ]);
  function moveInputField(dragIndex, hoverIndex, idInputField) {
    const newInputFields = clientShift({ array: inputFields, _id: idInputField, shift: hoverIndex - dragIndex });
    moveCategoryInputFieldCache({ _id, inputFields: newInputFields });
  }
  if (loading && !item) return skeletons.map((k) => <Skeleton title loading={!item && loading} active key={k} />);

  if (!item) return null;
  return (
    <PageContainer
      topComponent={
        <span className="ant-page-header-heading-title">
          {t('admin.itemModal.editSubTitleForItem', { itemName: item.name })}
        </span>
      }
    >
      <div className="xs-mt-20 white-background">
        <Tabs destroyInactiveTabPane type="card">
          <TabPane tab={t('admin.itemModal.tabs.price')} key="1">
            <PriceTab
              parentId={_id}
              parentType="categoryItem"
              onSubmit={update}
              initialValues={pick(item, [
                'paymentInterval',
                'pricingFormula',
                'minPrice',
                'scales',
                'scaleTitle',
                'scalePricingFormulaTitle',
                'scalesEnabled',
              ])}
              categoryId={categoryId}
            />
          </TabPane>
          <TabPane tab={t('admin.itemModal.tabs.inputs')} key="2">
            <div className="margin-16 margin-top-0">
              <InputFieldsTab
                inputFields={inputFields}
                parentId={_id}
                categoryId={categoryId}
                parentType="categoryItem"
                moveInCache={moveInputField}
                moveMutation={moveItemInputField}
              />
            </div>
          </TabPane>
          <TabPane tab={t('admin.itemModal.tabs.texts')} key="3">
            <TextsTab
              initialValues={{
                name: item.name,
                subTitle: item.subTitle,
                infoText: item.infoText,
                benefits: item.benefits,
                notesToEmployee: item.notesToEmployee,
                pleaseNote: item.pleaseNote,
              }}
              onSubmit={update}
            />
          </TabPane>
          <TabPane tab={t('admin.itemModal.tabs.conditionalVisibility')} key="4">
            <ConditionalVisibilityTab parentId={_id} parentType="categoryItem" companyTypeIds={item.companyTypeIds} />
          </TabPane>
        </Tabs>
      </div>
    </PageContainer>
  );
};

export default ItemCatalogueConfiguration;
