import { useCallback, useRef, useState } from 'react';
import { Modal, Collapse } from 'antd';
import classnames from 'classnames';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { ErrorComponent } from 'components/common/ErrorComponent';
import { useDocumentTemplateList } from 'graphql/hooks';
import { CheckOutlined } from '@ant-design/icons';
import intersectionBy from 'lodash/intersectionBy';
import { importDocumentTemplatesFromLibrary } from 'graphql/methods';
import classes from './ImportDocumentFromLibrary.module.less';

const { Panel } = Collapse;

const ImportDocumentFromLibrary = ({ setIsModalVisible, ...props }) => {
  const { t } = useTranslation();
  const { data: documentTemplates } = useDocumentTemplateList(false);
  const selectedDocumentsRef = useRef([]);
  const [duplicatedDocuments, setDuplicatedDocuments] = useState([]);

  const handleSelectDocument = useCallback(
    (document, select) => {
      console.log({ document, select });
      if (select) {
        selectedDocumentsRef.current.push(document);
      } else {
        selectedDocumentsRef.current = selectedDocumentsRef.current.filter(
          ({ documentId }) => documentId !== document.documentId,
        );
      }
      const intersection = intersectionBy(selectedDocumentsRef.current, documentTemplates, 'name');
      setDuplicatedDocuments(intersection.reduce((acc, c) => [...acc, c.name], []));
    },
    [documentTemplates],
  );

  const handleOk = useCallback(async () => {
    if (!duplicatedDocuments.length) {
      await importDocumentTemplatesFromLibrary(selectedDocumentsRef.current);
      setIsModalVisible(false);
      setDuplicatedDocuments([]);
      selectedDocumentsRef.current = [];
    } else {
      const errorEl = document.querySelector('.ant-modal');
      if (errorEl) {
        errorEl.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }
    }
  }, [duplicatedDocuments.length, setIsModalVisible]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setDuplicatedDocuments([]);
    selectedDocumentsRef.current = [];
  }, [setIsModalVisible]);

  const { loading, data: commonLibraryDocumentTemplates } = useDocumentTemplateList(true);

  if (loading) return null;

  return (
    <Modal
      title={t('admin.DocumentTemplateListPage.importFromLibraryModalTitle')}
      {...props}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
    >
      {duplicatedDocuments.length !== 0 ? (
        <ErrorComponent
          description={t('admin.DocumentTemplateListPage.errorMessageInModal', {
            categoryNames: duplicatedDocuments.map((c) => `"${c}"`).join(', '),
          })}
        />
      ) : null}
      {commonLibraryDocumentTemplates.map((document) => {
        const isSelected = !!find(selectedDocumentsRef.current, { documentId: document._id });
        const isDuplicatedCategory = duplicatedDocuments.includes(document.name);
        return (
          <div
            className={classnames(classes.document, isDuplicatedCategory ? classes.duplicated : null)}
            key={document._id}
            id={document._id}
          >
            <Collapse className="site-collapse-custom-collapse">
              <Panel forceRender header={document.name} key={document._id} className={classes.siteCollapseCustomPanel}>
                {document.description}
              </Panel>
            </Collapse>
            <button
              className={classes.addDocumentBtn}
              type="button"
              onClick={() => handleSelectDocument({ documentId: document._id, name: document.name }, !isSelected)}
            >
              {isSelected ? <CheckOutlined /> : null}
            </button>
          </div>
        );
      })}
    </Modal>
  );
};

export default ImportDocumentFromLibrary;
