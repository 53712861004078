import gql from 'graphql-tag';

const adminFontFragment = gql`
  fragment adminFontFragment on Font {
    name
    link
  }
`;

export default adminFontFragment;
