import { Divider, Drawer, Layout, Menu, Tooltip } from 'antd';
import { RiMenuUnfoldFill, RiMenuFoldFill } from 'react-icons/ri';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routePaths, { useGetRoutePathsNavBar } from 'router/route-paths';
import ROLES from 'constants/roles';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { useTranslation } from 'react-i18next';
import {
  DropboxOutlined,
  FileDoneOutlined,
  SettingOutlined,
  BankOutlined,
  HighlightOutlined,
  AuditOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  EuroCircleOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import DisplayByRoute from 'components/common/DisplayByRoute';
import s from './NavBar.module.less';
import Logo from './Logo';

const { Sider } = Layout;

function NavBarRouter(props) {
  const routePathsNavBar = useGetRoutePathsNavBar();
  return <DisplayByRoute {...props} path={routePathsNavBar} component={NavBar} />;
}

export const CustomTriggerMemo = memo(CustomTrigger, equal);
function CustomTrigger({ visible, sizeIcon, className, onClick }) {
  return (
    <div className={cn(s.trigger, className)} onClick={onClick} aria-hidden="true">
      {visible ? <RiMenuFoldFill size={sizeIcon} /> : <RiMenuUnfoldFill size={sizeIcon} />}
    </div>
  );
}

function NavBar({ breakpoint, visible, setVisible }) {
  const changeVisible = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);
  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);
  return breakpoint ? (
    <CustomNavBarForSmallWidthDevice changeVisible={changeVisible} onClose={onClose} visible={visible} />
  ) : (
    <CustomNavBar changeVisible={changeVisible} onClose={onClose} visible={visible} />
  );
}
function CustomNavBar({ visible, changeVisible, onClose }) {
  return (
    <>
      <Sider theme="light" width={80} />
      <Sider
        width={250}
        theme="light"
        collapsible
        collapsed={!visible}
        onCollapse={changeVisible}
        className={s.sider}
        trigger={null}
      >
        <div aria-hidden="true" className={cn(s.siderTrigger, visible ? s.siderOpen : '')} onClick={changeVisible}>
          <CustomTriggerMemo visible={visible} sizeIcon={25} />
        </div>
        <MenuNavigation
          visible={visible}
          onClickLogo={changeVisible}
          onClickMenu={() => {
            onClose(!false);
          }}
        />
      </Sider>
    </>
  );
}
function CustomNavBarForSmallWidthDevice({ visible, onClose, changeVisible }) {
  const onClickMenu = useCallback(() => {
    onClose(!false);
  }, [onClose]);
  return (
    <Drawer className={s.drawer} visible={visible} placement="left" closable={false} onClose={onClose} width={250}>
      <MenuNavigation onClickLogo={changeVisible} visible={visible} onClickMenu={onClickMenu} />
    </Drawer>
  );
}

function MenuNavigation({ onClickMenu, visible, onClickLogo }) {
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  const role = me?.role;
  const isAdmin = role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN;
  const isSuperAdmin = role === ROLES.SUPER_ADMIN;

  const { pathname } = useLocation();
  return (
    <>
      <div className={s.hideLogoText} />
      <Tooltip placement="right" title={!visible && t('NavBar.logo.tooltip')}>
        <div>
          <Logo placeholder="" onClick={onClickLogo} />
        </div>
      </Tooltip>
      <Divider style={{ margin: 0 }} />

      <Menu onClick={onClickMenu} className={s.navigationMenu} defaultSelectedKeys={[pathname]} mode="inline">
        <Menu.Item key={routePaths.shoppingCartEntries} title={!visible && t('NavBar.shoppingCartEntries.tooltip')}>
          <Link to={routePaths.shoppingCartEntries}>
            <UnorderedListOutlined />
            {visible && t('NavBar.shoppingCartEntries.title')}
          </Link>
        </Menu.Item>
        <Menu.Item key={routePaths.shoppingCart} title={!visible && t('NavBar.createShoppingCart.tooltip')}>
          <Link to={routePaths.shoppingCart}>
            <PlusOutlined />
            {visible && t('NavBar.createShoppingCart.title')}
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          className={s.subMenu}
          popupClassName={s.itemSubMenu}
          key="setting"
          icon={<SettingOutlined />}
          title="Setting"
        >
          {isAdmin ? (
            <>
              <Menu.Item key={routePaths.catalogueConfiguration} title="">
                <Link to={routePaths.catalogueConfiguration}>
                  <EuroCircleOutlined />
                  {t('NavBar.catalogueConfiguration.title')}
                </Link>
              </Menu.Item>
              <Menu.Item key={routePaths.documentTemplateList} title="">
                <Link to={routePaths.documentTemplateList}>
                  <FileDoneOutlined />
                  {t('NavBar.documentTemplateListPage.title')}
                </Link>
              </Menu.Item>
            </>
          ) : null}
          <Menu.Item key={routePaths.companySetting} title="">
            <Link to={routePaths.companySetting}>
              <BankOutlined />
              {t('NavBar.companyTenant.title')}
            </Link>
          </Menu.Item>
          <Menu.Item key={routePaths.corporateIdentitySetting} title="">
            <Link to={routePaths.corporateIdentitySetting}>
              <HighlightOutlined />
              {t('NavBar.corporateIdentity.title')}
            </Link>
          </Menu.Item>
          <Menu.Item key={routePaths.accountSetting} title="">
            <Link to={routePaths.accountSetting}>
              <AuditOutlined />
              {t('NavBar.accountSetting.title')}
            </Link>
          </Menu.Item>{' '}
        </Menu.SubMenu>
        {isSuperAdmin ? (
          <>
            <Menu.SubMenu
              className={s.subMenu}
              popupClassName={s.itemSubMenu}
              icon={<DropboxOutlined />}
              title={t('NavBar.commonLibrary.title')}
            >
              <Menu.Item key={routePaths.commonLibraryConfiguration} title="">
                <Link to={routePaths.commonLibraryConfiguration}>
                  <EuroCircleOutlined />
                  {visible
                    ? t('NavBar.commonLibrary.catalogueCommonLibrary.titleSort')
                    : t('NavBar.commonLibrary.catalogueCommonLibrary.title')}
                </Link>
              </Menu.Item>
              <Menu.Item key={routePaths.commonLibraryDocumentTemplateList} title="">
                <Link to={routePaths.commonLibraryDocumentTemplateList}>
                  <FileDoneOutlined />
                  {visible
                    ? t('NavBar.commonLibrary.documentCommonLibrary.titleSort')
                    : t('NavBar.commonLibrary.documentCommonLibrary.title')}
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : null}
      </Menu>
    </>
  );
}

export default memo(NavBarRouter, equal);
