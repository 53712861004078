// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3davGTZN77qwBoWP_ugwkx {\n  padding: 5px 20px !important;\n  height: 50px !important;\n}\n._3davGTZN77qwBoWP_ugwkx:not(:last-child) {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/ShoppingCart/Buttons.module.less"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,uBAAA;AACF;AAAE;EACE,kBAAA;AAEJ","sourcesContent":[".btn {\n  padding: 5px 20px !important;\n  height: 50px !important;\n  &:not(:last-child) {\n    margin-right: 10px;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "_3davGTZN77qwBoWP_ugwkx"
};
export default ___CSS_LOADER_EXPORT___;
