import adminCategoryListQuery from './adminCategoryList';
import libraryCategories from './libraryCategories';

const getItemCategoryQuery = (isLibrary) => {
  if (isLibrary) {
    return libraryCategories;
  }
  return adminCategoryListQuery;
};

export default getItemCategoryQuery;
