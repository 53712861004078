export default {
  translation: {
    BackendErrors: {
      category: {
        categoryNotFound: 'Kategorie ist nicht vorhanden',
      },
      changesWereNotSaved: {
        unknownError: 'Die Änderungen wurden NICHT gespeichert (Serverfehler). Bitte versuche es erneut',
      },
      documentTemplate: {
        documentTemplateIsUsed: 'Es gibt Menükarten, die diesen Dokumententyp verwenden',
      },
      permissionDenied: 'Zugriff verweigert',
      usedInFormula: 'Fehler: wird in der Preisformel verwendet',
      user: {
        noValidToken: 'Ungültiger Token (Serverfehler)',
        passwordIsNotValidOrExpired: 'Falsches Passwort. Bitte versuche es nochmal',
        userHasNoPasswordSet: 'Der Benutzer hat KEIN Passwort',
        userNotFound: 'Benutzer wurde NICHT gefunden',
        userNotFoundForEmail: 'Benutzer mit der E-Mail {{email}} existiert NICHT',
      },
    },
    CurrentUserDropdown: {
      locale: 'Sprache: <1>$t(locale)</1>',
      loggedInAs: 'Eingelogged als <1>{{name}}</1>',
      logout: 'Ausloggen',
      noUserName: 'Unbekannter Name',
      role: 'Rolle: <1>{{role}}</1>',
    },
    FooterComponent: {
      copyright: '© KanzleiPilot {{year}}',
    },
    InputErrors: {
      phoneNumber: {
        noCode: 'Ländercode ist ein Pflichtfeld',
        noPhone: 'Telefonnummer ist ein Pflichtfeld',
      },
    },
    Login: {
      inputs: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
      },
      resetPassword: 'Ich habe mein Passwort vergessen',
      submit: 'Einloggen',
      title: 'Logge dich bei KanzleiPilot ein',
    },
    NavBar: {
      accountSetting: {
        title: '$t(user.Setting.AccountSetting.title)',
      },
      catalogueConfiguration: {
        title: '$t(admin.CatalogueConfiguration.title) ',
      },
      commonLibrary: {
        catalogueCommonLibrary: {
          title: 'Kategorien-Vorlagen',
          titleSort: 'Kategorievorlage',
        },
        documentCommonLibrary: {
          title: 'Dokumenten-Vorlage',
          titleSort: 'Dokument-Vorlage',
        },
        title: 'Kategorie-Vorlagen',
      },
      companyTenant: {
        title: '$t(admin.Setting.CompanyTenant.title)',
      },
      corporateIdentity: {
        title: '$t(admin.Setting.CorporateIdentity.title)',
      },
      createShoppingCart: {
        title: '$t(ShoppingCartEntriesPage.createShoppingCart)',
        tooltip: '$t(ShoppingCartEntriesPage.createShoppingCart)',
      },
      documentTemplateListPage: {
        title: '$t(admin.DocumentTemplateListPage.title)',
      },
      logo: {
        tooltip: 'Menü aufklappen',
      },
      shoppingCartEntries: {
        title: '$t(ShoppingCartEntriesPage.title)',
        tooltip: '$t(ShoppingCartEntriesPage.title)',
      },
    },
    ResetPassword: {
      buttons: {
        changePassword: 'Passwort ändern',
        goToLogin: 'Zurück',
        verify: 'Verifizieren',
      },
      enterEmail: 'Bitte trage die E-Mail ein, für die du das Passwort zurück setzen möchtest',
      errors: {
        noMatch: 'Passwörter stimmen leider NICHT überein',
      },
      fields: {
        email: 'E-Mail',
        newPassword: 'Neues Passwort',
        otp: 'Einmaliges Passwort',
        passwordConfirmation: 'Neues Passwort (bitte wiederholen)',
      },
      steps: {
        email: 'Passwort eintragen',
        setNewPassword: 'Neues Passwort eintragen',
        verification: 'Gib das einmalige Passwort ein',
      },
      title: 'Passwort neu setzen',
      verificationDescription:
        'Wir haben an die E-Mail {{email}} einen Bestätigungscode gesendet. Bitte trage deinen Bestätigungscode ein.',
    },
    ShoppingCartEntriesPage: {
      catalogue_configuration_button: 'Menükarte konfigurieren',
      createShoppingCart: 'Neue Menükarte anlegen',
      entriesTable: {
        columnTitles: {
          actions: 'Aktionen',
          companyIdentifier: 'Mandantennummer',
          companyName: 'Mandant',
          contact: 'Kontakt',
          createdAt: 'Erstellt am',
          relatedContacts: 'Kontaktpersonen',
          valueMonthly: 'Wert monatlich',
          valueOneOff: 'Wert einmalig',
          valueYearly: 'Wert jährlich',
        },
      },
      search: 'Suche nach einer Mandantennummer, dem Namen eines Mandanten oder Kontaktes...',
      searchTitle: 'Suche',
      title: 'Menükarten verwalten',
    },
    admin: {
      CatalogueConfiguration: {
        ConfigureCategoryProperties: {
          properties: {
            conditionalVisibility: {
              bodyTitle: 'Zeige diese Kategorie bei folgenden Mandantentypen:',
              title: 'Sichtbarkeit nach Mandantentyp',
            },
            discounts: {
              title: 'Rabatte',
            },
            inputFields: {
              title: 'Eingabefelder',
            },
            items: {
              title: 'Positionen',
            },
            staticItems: {
              title: 'Statischer Leistungskatalog',
            },
            texts: {
              title: 'Texte',
            },
          },
          title: 'Kategorie bearbeiten',
        },
        addCategory: 'Neuen Bereich anlegen',
        collapseOptions: {
          remove: 'Bereich löschen',
        },
        discounts: {
          title: 'Rabatte',
        },
        emptyCategoryListMessage: 'Es gibt noch KEINE Bereiche. Bitte klicke auf "Neuen Bereich anlegen"',
        emptyDiscountListMessage: 'Es gibt noch KEINE Rabatte. Bitte klicke auf "Neuen Rabatt anlegen"',
        emptyInputFieldListMessage: 'Es gibt noch KEINE Eingabefelder. Bitte klicke auf "Neues Eingabefeld anlegen"',
        emptyInputFieldOptionListMessage:
          'Es gibt noch KEINE Auswahlfeld Optionen. Bitte klicke auf "Neue Option anlegen"',
        emptyItemListMessage: 'Es gibt noch KEINE Positionen. Bitte klicke auf "Neue Position anlegen"',
        importCategoryPresets: 'Aus Bibliothek importieren',
        inputFields: {
          title: 'Eingabefelder',
        },
        items: {
          title: 'Positionen',
        },
        staticItems: {
          title: 'Statischer Leistungskatalog',
        },
        texts: {
          title: 'Texte',
        },
        title: 'Bereiche und Positionen konfigurieren',
        titleCommonLibrary: 'Gemeinsame Bibliothek',
      },
      DocumentTemplateConfigurationPage: {
        addBlockModal: {
          addToBottomButton: 'füge unten hinzu',
          addToTopButton: 'füge oben hinzu',
          availableBlocksTitle: 'Verfügbare Blöcke',
          blockActionsColumnTitle: 'Aktionen',
          blockNameColumnTitle: 'Name',
          blockPreviewTitle: 'Vorschau',
          title: 'Block hinzufügen',
        },
        backTitle: 'Zurück zu den Dokumententypen',
        deleteBlockConfirmation: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title:
            'Möchtest du den Block wirklich löschen? Warnung: deine Block-Einstellungen gehen verloren (Texte, Einstellungen, etc.). Fortfahren?',
        },
        generalInformationSection: {
          descriptionInputLabel: 'Beschreibung',
          lastUsedDocumentNr: 'Aktuelle Dokumenten-Nummer: {{n}}',
          nameInputLabel: 'Name',
          nrOfDocumentsCreated: '{{count}} Dokument erzeugt',
          nrOfDocumentsCreated_plural: '{{count}} Dokumente erzeugt',
          title: 'Allgemeine Information',
        },
        layoutSection: {
          addBlockButton: 'Block hinzufügen',
          emptyBlockList: 'Füge deine Dokumentenvorlage einen Block hinzu und drücke auf "Block hinzufügen"',
          infoSectionTitle: 'Info',
          title: 'Layout',
        },
        title: 'Dokumentenvorlagen bearbeiten',
      },
      DocumentTemplateListPage: {
        addDocumentTemplateButton: 'Dokumentenvorlagen hinzufügen',
        createdAt: 'Erstellt am {{date}}',
        emptyListMessage: 'Du hast noch keine Dokumentenvorlagen angelegt',
        errorMessageInModal: 'Kategorien mit den Namen {{categoryNames}} existieren bereits',
        importFromLibraryButton: 'Aus gemeinsamer Bibliothek importieren',
        importFromLibraryModalTitle: 'Dokumentenvorlagen importieren',
        lastUsedDocumentNr: 'Aktuelle #:{{n}}',
        nrOfDocumentsCreated: '{{count}} Dokument erstellt',
        nrOfDocumentsCreated_plural: '{{count}} Dokumente erstellt',
        title: 'Dokumentenvorlagen',
        titleCommonLibrary: 'Gemeinsame Dokumentenvorlagen',
      },
      Placeholders: {
        client: {
          label: 'Client',
        },
        contact: {
          label: 'Mandant',
        },
        contactCity: {
          label: 'Sitz des Mandanten (Ort)',
        },
        contactName: {
          label: 'Mandantenname',
        },
        currentDate: {
          label: 'Datum (tagesaktuell bei Generierung)',
        },
        currentUserEmail: {
          label: 'E-Mail des Bearbeiters',
        },
        document: {
          label: 'Dokument',
        },
        documentCreatedAt: {
          label: 'Document creation date',
        },
        documentNumber: {
          label: 'Document number',
        },
        environment: {
          label: 'Sonstiges',
        },
        shoppingCartCompanyCity: {
          label: 'Sitz des Mandanten (Ort)',
        },
        shoppingCartCompanyOrFullname: {
          label: 'Mandantenname',
        },
        tenantCompany: {
          label: 'Kanzleiname',
        },
        tenantCompanyCity: {
          label: 'Sitz unserer Kanzlei',
        },
        tenantCompanyCityCode: {
          label: 'PLZ unserer Kanzlei',
        },
        tenantCompanyCountry: {
          label: 'Land unserer Kanzlei',
        },
        tenantCompanyFullAddress: {
          label: 'Our company full address',
        },
        tenantCompanyHouseNumber: {
          label: 'Hausnummer unserer Kanzlei',
        },
        tenantCompanyName: {
          label: 'Kanzleiname',
        },
        tenantCompanyPhoneNumber: {
          label: 'Telefonnummer unserer Kanzlei',
        },
        tenantCompanyStreet: {
          label: 'Straße unserer Kanzlei',
        },
        tenantCompanyWebsite: {
          label: 'Webseite unserer Kanzlei',
        },
      },
      RichEditor: {
        toolbar: {
          link: {
            createModalName: 'Link name',
            createModalTitle: 'Link hinzufügen',
            createModalValue: 'Link URL',
            modalValueInvalidError: 'Link muss eine validide URL sein (z.B. https://www.kanzleipilot.de)',
            modalValueTooltipHTML:
              '<div><div>Beispiel:</div><div>https://www.kanzleipilot.de - Webadresse verlinken</div><div>mailto:name@gmail.com - E-Mail verlinken</div><div>tel:+49123456789 - Telefonnummer verlinken</div></div>',
          },
        },
      },
      Setting: {
        CompanySetting: {
          inputs: {
            city: {
              title: 'Stadt',
            },
            cityCode: {
              title: 'PLZ',
            },
            country: {
              title: 'Land',
            },
            houseNumber: {
              title: 'Hausnummer',
            },
            name: {
              title: 'Name',
            },
            phoneNumber: {
              title: 'Telefon',
            },
            street: {
              title: 'Straße',
            },
            website: {
              title: 'Webseite',
            },
          },
          title: 'Unsere Kanzlei',
        },
        CompanyTenant: {
          inputs: {
            city: {
              title: 'Stadt',
            },
            cityCode: {
              title: 'PLZ',
            },
            country: {
              title: 'Land',
            },
            houseNumber: {
              title: 'Hausnummer',
            },
            name: {
              title: 'Name',
            },
            phoneNumber: {
              title: 'Telefon',
            },
            street: {
              title: 'Straße',
            },
            website: {
              title: 'Webseite',
            },
          },
          title: 'Unsere Kanzlei',
        },
        CorporateIdentity: {
          BodyPrimary: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Farbe',
              fontFamily: 'Schriftart',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            inputs: {
              bodyPrimary: 'Flächen',
              bodyPrimaryFooter: 'Flächen (Fußzeile)',
            },
            title: 'Flächen',
          },
          ColoursForDocuments: {
            field: {
              background: 'Hintergrund-Farbe',
              backgroundColour: 'Vordergrund-Farbe',
              color: 'Text-Farbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              textColour: 'Textfarbe',
            },
            inputs: {
              tableContent: 'Tabelleninhalt',
              tableFooter: 'Fußzeile',
              tableHeaderPrimary: 'Kopfzeile Primär',
              tableHeaderSecondary: 'Kopfzeile Sekundär',
              tableLeftColumn: 'Linke Spalte',
              tableOfContent: 'Inhaltsverzeichnis',
            },
            title: 'Text-Style für Dokumente',
          },
          CorporatePrimaryColor: {
            inputs: {
              color: 'Primäre Kontrastfarbe',
              fontFamily: 'Schriftart',
              primaryColor: 'Primärfarbe',
              primaryContrastColor: 'Primäre Kontrastfarbe',
            },
            preview: 'Vorschau eines Buttons in Ihrer Farbgebung (Primärfarbe und Kontrastfarbe)',
            title: 'Konfiguration der Corporate Identify',
          },
          TableOfContent: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            title: 'Table of content',
          },
          TableTheme: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              table: 'Table',
              tableContent: 'Table content',
              tableFooter: 'Table footer',
              tableHeaderPrimary: 'Table header primary',
              tableHeaderSecondary: 'Table header secondary',
              tableLeftColumn: 'Table left column',
            },
            title: 'Styles for Table',
          },
          TextStylesForDocuments: {
            field: {
              color: 'Farbe',
              colour: 'Farbe',
              font: 'Schriftart',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              lineHeight: 'Zeilenabstand',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            inputs: {
              h1: 'h1',
              h2: 'h2',
              h3: 'H3',
              testimonial: 'Testimonial',
              testimonialSmallText: 'Testimonial kleiner Text',
              textBlock: 'Standard-Text',
              title: 'Titel',
            },
            title: 'Text-Stile für Dokumente',
          },
          applyStyles: 'Apply Styles',
          errors: {
            reset: 'Oops. There was a problem resetting the theme',
          },
          inputs: {
            font: {
              placeholder: 'Wähle eine Schriftart',
            },
          },
          reset: 'Reset',
          resetConfirmModalMessage:
            'Danger: Are you sure you want to reset CI to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          title: 'Corporate Identity',
        },
      },
      addCategoryModal: {
        addModalTitle: 'Neuen Bereich anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Eine Kategorie mit diesem Namen existiert bereits. Bitte ändere den Namen',
        nameFieldDescription: 'Name des Bereichs',
        nameFieldLabel: 'Name',
        ok: '$t(common.yes)',
      },
      addDiscountButton: 'Neuen Rabatt anlegen',
      addDocumentTemplateModal: {
        addModalTitle: 'Neuen Dokumententyp anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Ein Dokumententyp mit diesem Namen existiert bereits. Bitte ändere den Namen',
        nameFieldDescription: 'Name des Dokumententyp',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addInputButton: 'Neues Eingabefelder anlegen',
      addItemButton: 'Neue Position anlegen',
      addStaticItemButton: 'Neue Leistung anlegen',
      categoryNameInput: {
        label: 'Name',
      },
      categoryTexts: {
        introForDocument: {
          label: 'Einleitung für Dokumente',
        },
        libraryDescription: {
          label: 'Beschreibung für Bibliothek (nur für SuperAdmins)',
        },
        name: {
          label: 'Name',
        },
      },
      deleteCategory: 'Bereich löschen',
      deleteCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du den Bereich löschen möchtest?',
      },
      deleteDiscountConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du den Rabatt löschen möchtest?',
      },
      deleteInputConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du das Eingabefelder löschen möchtest?',
      },
      deleteItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Position löschen möchtest?',
      },
      deleteStaticItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Leistung löschen möchtest?',
      },
      discountFieldModal: {
        addModalTitle: 'Neuen Rabatt anlegen',
        duplicatedErrorMessage: 'Ein Rabatt mit diesem Namen existiert bereits!',
        editModalTitle: 'Rabatt bearbeiten',
        nameFieldDescription: 'Name des Rabatts',
        nameFieldLabel: 'Name',
        typeFieldAbsolute: '$t(common.Discount.absolute)',
        typeFieldLabel: 'Typ',
        typeFieldPercent: '$t(common.Discount.percent)',
        valueFieldDescription: 'Wert des Rabatts (in %)',
        valueFieldLabel: 'Wert',
      },
      discountTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
        value: 'Wert',
      },
      discountUsedInShoppingCartConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        save: 'Speichern',
        titleDelete: 'Rabatt wird in einer bestehenden Menükarte verwendet. Möchtest du ihn löschen?',
        titleUpdate: 'Der Rabatt wird in einer existierenden Menükarte verwendet. Möchtest du ihn ändern?',
      },
      discountsTabTitle: 'Rabatte',
      discountsTitle: 'Discounts',
      importCategoryPresetsModal: {
        errorMessage:
          'Eine Kategorie mit diesem Namen existiert bereits. Bitte nenne die existierende Kategorie zunächst um',
        title: 'Bereiche und Positionen aus Bibliothek importieren',
      },
      inputFieldModal: {
        addModalTitle: 'Neues Eingabefelder anlegen',
        cancel: '$t(common.cancel)',
        comboOptions: {
          addOption: 'Neue Option anlegen',
          boxLabel: 'Auswahlfeld Optionen',
          modal: {
            addTitle: 'Neue Option anlegen',
            cancel: '$t(common.cancel)',
            duplicatedErrorMessage: 'Eine Option mit diesem Namen existiert bereits!',
            editTitle: 'Option bearbeiten',
            nameFieldDescription: 'Option Name',
            nameFieldLabel: 'Name',
            ok: '$t(common.yes)',
            valueFieldDescription: 'Option Wert',
            valueFieldLabel: 'Wert',
          },
          table: {
            actionsColumnLabel: '',
            deleteOptionConfirmation: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Bist du sicher, dass du die Option löschen möchtest?',
            },
            nameColumnLabel: 'Name',
            valueColumnLabel: 'Wert',
          },
        },
        duplicatedErrorMessage: 'Ein Eingabefelder mit diesem Namen existiert bereits!',
        editModalTitle: 'Eingabefelder bearbeiten',
        nameFieldDescription: 'Eingabefelder Name',
        nameFieldLabel: 'Name',
        ok: '$t(common.yes)',
        typeFieldLabel: 'Type',
      },
      inputFieldTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Typ',
      },
      inputsTitle: 'Eingabefelder',
      itemModal: {
        addTitle: 'Neue Position anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Position mit diesem Namen existiert bereits!',
        editSubTitleForItem: 'Position {{itemName}} bearbeiten',
        editTitle: 'Position bearbeiten',
        inputs: {
          benefits: {
            label: 'Nutzen (für Dokumente)',
          },
          infoText: {
            label: 'Info-Button Text',
            placeholder: '',
          },
          minPrice: {
            label: 'Mindestpreis',
          },
          name: {
            label: 'Name',
            placeholder: '',
          },
          notesToEmployee: {
            label: 'Interner Hinweis (für Kanzlei-Team)',
          },
          paymentInterval: {
            label: 'Zahlungsintervall',
            monthlyLabel: '$t(common.Item.paymentIntervalValue.monthly)',
            oneOffLabel: '$t(common.Item.paymentIntervalValue.oneOff)',
            yearlyLabel: '$t(common.Item.paymentIntervalValue.yearly)',
          },
          pleaseNote: {
            label: 'Bitte beachten (für Dokumente)',
          },
          pricingFormula: {
            errors: {
              invalid: 'Bitte trage eine gültige Preisformel ein',
            },
            insertVariableMenu: {
              subMenu: {
                category: 'Eingabefelder aus Kategorie',
                categoryMonthlyAdvance: 'Categories total sum',
                categoryTotal: 'Wert einer Kategorie',
                function: 'Funktionen',
                item: 'Eingabefelder aus Position',
                itemTotal: 'Wert einer Position',
                scaleFunctionParameters: 'Scale function parameters',
                variable: 'Variablen',
              },
              title: 'Platzhalter einfügen',
            },
            label: 'Preisformel',
          },
          scales: {
            addModalLabel: 'Neue Staffel anlegen',
            atLeastTwoError: 'Bitte lege mindestens 2 Staffeln an',
            cancel: '$t(common.cancel)',
            deleteModal: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Bist du sicher, dass du die Staffel löschen möchtest?',
            },
            duplicatedError: 'Staffeln müssen einzigartig sein',
            duplicatedErrorMessage: 'Staffel mit diesem Namen existiert bereits',
            editModalLabel: 'Staffel bearbeiten',
            emptyScaleList:
              'Es existieren noch keine Staffeln. Bitte klicke auf "$t(admin.itemModal.inputs.scales.addModalLabel)" um eine Staffel anzulegen',
            emptyTable:
              'Es existieren noch keine Staffeln. Bitte klicke auf "$t(admin.itemModal.inputs.scales.addModalLabel)" um eine Staffel anzulegen',
            inputs: {
              formula: {
                label: 'Wert (Preisformel)',
              },
              pricingFormulaTitle: {
                label: 'Überschrift für “Wert“-Spalte',
                placeholder: '',
              },
              scaleTitle: {
                label: 'Überschrift für “Staffel“-Spalte',
                placeholder: '',
              },
              value: {
                label: 'Staffel',
              },
            },
            label: 'Staffeln konfigurieren?',
            ok: '$t(common.yes)',
            pdfConfigurationLabel: 'Überschriften für Staffel-Auflistung in Dokumenten',
            table: {
              columns: {
                pricingFormula: 'Wert (Preisformel)',
                value: 'Staffel',
              },
            },
            wrapperLabel: 'Staffeln konfigurieren',
          },
          scalesList: {
            label: 'Staffel-Liste',
          },
          subTitle: {
            label: 'Subtitle',
            placeholder: '',
          },
        },
        ok: '$t(common.yes)',
        tabs: {
          conditionalVisibility: 'Sichtbarkeit nach Mandantentyp',
          inputs: 'Eingabefelder',
          price: 'Preis',
          texts: 'Texte',
        },
      },
      itemTable: {
        columns: {
          actions: '',
          name: 'Name',
          paymentInterval: 'Zahlungsintervall',
          pricingFormula: 'Preisformel',
        },
        title: 'Positionen',
      },
      pricingFormulaInput: {
        functions: {
          StBVV_TabelleA: 'StBVV_TabelleA() Beispiel: "StBVV_TabeleA(140000)" gibt 1773 zurück',
          StBVV_TabelleB: 'StBVV_TabelleB() Beispiel: "StBVV_TabeleB(5000)" gibt 81 zurück',
          StBVV_TabelleC: 'StBVV_TabelleC() Beispiel: "StBVV_TabeleC(45000)" gibt 122 zurück',
          round: 'round() Beispiel: "round(7.8)" gibt 8 zurück',
          scale: 'scale(Zahl) nutzt die unten definierbare Preisstaffel-Konfiguration',
        },
        functionsListText: 'Folgende Funktionen sind verfügbar:',
        howUseButton: 'Hilfe? Lerne die Preisformel kennen',
        inputsListText: 'Folgende Eingabefelder sind verfügbar:',
        modalInfo: {
          helpText: '',
          title: 'Lerne die Preisformel kennen',
          videoCaption: '',
          videoUrl: '',
        },
        syntaxInstructions:
          'Drücke "Platzhalter einfügen" um einen Platzhalter einzufügen\nDrücke "@" um eine Liste der Eingabefelder anzuzeigen\nDrücke "#" um eine Liste der Funktionen anzuzeigen',
      },
      staticItemsFieldModal: {
        addModalTitle: 'Neue Leistung anlegen',
        duplicatedErrorMessage: 'Leistung mit diesem Namen existiert bereits. Bitte wähle einen anderen Namen',
        editModalTitle: 'Leistung bearbeiten',
        nameFieldDescription: 'Leistung',
        nameFieldLabel: 'Leistung',
        valueFieldDescription: 'Wert der Leistung (z.B. "90 €")',
        valueFieldLabel: 'Wert',
      },
      staticItemsTabTitle: 'Statischer Leistungskatalog',
      staticItemsTableColumns: {
        actions: '',
        name: 'Leistung',
        value: 'Wert',
      },
      staticItemsTitle: 'Statischer Leistungskatalog',
      textsTabSubmitButton: 'Änderungen Speichern',
      textsTabTitle: 'Texte',
      textsTitle: 'Texte',
    },
    common: {
      ConditionalVisibility: {
        balanceOfAccountsGmbH: 'Bilanz - GmbH',
        balanceOfAccountsGmbHAndCoKg: 'Bilanz - GmbH & Co. Kg',
        balanceOfAccountsGmbHAndCoKgSelfBooker: 'Bilanz - GmbH & Co. Kg (Selbstbucher)',
        balanceOfAccountsGmbHSelfBooker: 'Bilanz - GmbH (Selbstbucher)',
        balanceOfAccountsOther: 'Bilanz - Personengesellschaft',
        balanceOfAccountsOtherSelfBooker: 'Bilanz - Personengesellschaft (Selbstbucher)',
        balanceOfAccountsSingle: 'Bilanz - Einzelunternehmer',
        balanceOfAccountsSingleSelfBooker: 'Bilanz - Einzelunternehmer (Selbstbucher)',
        cashBasisAccountingCapital: 'Einnahmen-Überschuss-Rechnung - Personengesellschaft',
        cashBasisAccountingCapitalSelfBooker: 'Einnahmen-Überschuss-Rechnung - Personengesellschaft (Selbstbucher)',
        cashBasisAccountingSingle: 'Einnahmen-Überschuss-Rechnung - Einzelunternehmer',
        cashBasisAccountingSingleSelfBooker: 'Einnahmen-Überschuss-Rechnung - Einzelunternehmer (Selbstbucher)',
        checkAll: 'Alle auswählen',
        privatePerson: 'Privatperson - nicht selbständig',
        uncheckAll: 'Keine auswählen',
      },
      Discount: {
        absolute: 'absolut',
        percent: 'prozentual',
      },
      Formula: {
        categoryMonthlyAdvance: 'VonKategorie.{{categoryName}}.MonatlichePauschale',
        categoryType: 'EingabeKategorie',
        itemTotal: 'WertVonPosition',
        itemType: 'EingabePosition',
      },
      InputField: {
        combo: 'Auswahlfeld',
        input: 'Eingabefeld',
        name: 'Eingabefeld',
      },
      Item: {
        paymentIntervalValue: {
          monthly: 'monatlich',
          monthlyFixPrice: 'Monatlicher Fixpreis',
          oneOff: 'einmalig',
          yearly: 'jährlich',
        },
        recursive:
          'Du kannst dich NICHT auf diese Position beziehen, da sie sich selber in ihrer Preisformel auf diese Position bezieht (zirkuläre Abhängigkeit)',
        scaleError:
          'Bitte konfiguriere zunächst Staffeln, um die “scale”-Funktion in der Preisformel verwenden zu können',
      },
      buttons: {
        resendVerificationEmail: 'Bestätigungsmail erneut senden',
      },
      cancel: 'Abbrechen',
      company: {
        addCompany: 'Mandat hinzufügen',
        changeCompany: 'Mandat ändern',
        editCompany: 'Edit Company',
      },
      confirm: 'Ja',
      contact: {
        addContact: 'Kontakt hinzufügen',
        deleteContact: 'Delete contact',
        editContact: 'Kontakt bearbeiten',
      },
      error: 'Fehler',
      inputs: {
        otp: {
          title: 'Einmaliges Passwort',
        },
      },
      off: 'Aus',
      ok: 'Ok',
      on: 'An',
      success: 'Aktualisierung erfolgreich durchgeführt!',
      successToastText: '$t(common.success)',
      toast: {
        success: {
          changePassword: 'Passwort wurde erfolgreich geändert',
          clipboard: 'Farbcode wurde in die Zwischenablage kopiert',
          sendChangeEmailVerificationEmail:
            'Bitte überprüfe deine Email {{email}}. Wir haben dir eine E-Mail gesendet, mit der du dein Passwort ändern kannst.',
          sendChangePasswordVerificationEmail:
            'Bitte überprüfe deine Email {{email}}. Wir haben dir eine E-Mail gesendet, mit der du dein Passwort ändern kannst.',
        },
      },
      yes: '$t(common.yes)',
    },
    decimalSeparator: ',',
    deleteDocumentTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed:
        'Es existieren Menükarten, die diese Dokumentenvorlage verwenden. Möchtest du sie wirklich löschen? (Wichtig: Bereits generierte PDF-Dateien bleiben natürlich erhalten)',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die Dokumentenvorlage löschen möchtest?',
    },
    deleteShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die Menükarte löschen möchtest?',
    },
    locale: 'Deutsch',
    localeCode: 'de',
    sharedPackage: {
      discounted_no_value:
        '{{finalPrice}} {{paymentInterval}}<br/><i>(rabattiert, anstelle von {{priceBeforeDiscount}})</i>',
      discounted_with_value:
        '{{finalPrice}} {{paymentInterval}}<br/>reduziert durch {{discount}} <i>(anstelle von <del>{{priceBeforeDiscount}}<del>)</i>',
      empty_pricing_formula: 'Bitte fülle die Preisformel aus',
      free_service: 'gratis {{paymentInterval}} Position',
      not_discounted: '{{finalPrice}} {{paymentInterval}}',
      variable_is_empty: 'Bitte trage einen Wert für {{variable}} ein',
    },
    user: {
      Setting: {
        AccountSetting: {
          tabs: {
            main: {
              changeEmail: 'Ändere deine E-Mail',
              fields: {
                email: 'E-Mail',
                firstName: 'Vorname',
                lastName: 'Nachname',
                newEmail: 'Neue E-Mail',
                otp: 'Einmaliges Passwort',
                phone: 'Telefon',
                position: 'Position',
              },
              saveEmail: 'E-Mail speichern',
              title: 'Dein Account',
            },
            password: {
              errors: {
                noMatch: 'Passwörter müssen übereinstimmmen',
              },
              fields: {
                currentPassword: 'Aktuelles Passwort',
                newPassword: 'Neues Passwort',
                otp: 'Einmaliges Passwort',
                passwordConfirmation: 'Neues Passwort (bitte wiederholen)',
              },
              savePassword: 'Passwort speichern',
              title: 'Passwort',
            },
            security: {
              fields: {
                email: 'E-Mail',
                firstName: 'Vorname',
                lastName: 'Nachname',
                position: 'Position',
              },
              title: 'Sicherheit',
            },
          },
          title: 'Dein Account',
        },
      },
      ShoppingCart: {
        Buttons: {
          email: 'E-Mail senden',
          errors: {
            formHasErrors: 'Es existieren Eingabefehler in dem Formular. Bitte behebe sie',
            noCompanyError: 'Bitte wähle ein Mandat aus, um diesen Button zu aktivieren',
            noContactsError: 'Bitte wähle einen Kontakt aus, um diesen Button zu aktivieren',
            noItemsSelectedError: 'Bitte wähle mindestens eine Position aus, um diese Aktionen zu aktivieren',
          },
          html: 'Vorschau öffnen',
          pdf: 'PDF Vorschau',
          store: 'Speichern',
          submitRevisionModal: {
            newButton: 'als neue Menükarte speichern',
            revisionButton: 'als Revision speichern',
            title:
              'Möchtest du die Menükarte als neue Menükarte für den Mandanten oder als Revision der Existierenden speichern?',
          },
          title: 'Aktionen',
        },
        Category: {
          Discount: {
            label: 'Wähle einen Rabatt',
          },
          InputField: {
            mustBeANumberMessage: '{{name}} muss eine Zahl sein',
            requiredMessage: 'Bitte trage eine Werte für {{name}} ein',
          },
          Item: {
            fixedMonthlyFeeHint: 'Position ist im Unternehmer-Honorar beinhaltet',
            minPrice: 'Mindestpreis: {{minPrice}}',
            paymentInterval: 'Zahlungsintervall: $t(common.Item.paymentIntervalValue.{{paymentInterval}})',
            pricingFormula: 'Preisformel: {{formula}}',
            referenceError: {
              referenceIsSelectedButInvalid:
                'Bitte schaue dir die Position {{itemName}} an (dort fehlen deine Eingaben and die Preisberechnung bezieht sich auf den Positionswert)',
              referenceNotSelectedAndInvalid:
                'Bitte schaue dir die Position {{itemName}} an und wähle sie aus (dort fehlen deine Eingaben and die Preisberechnung bezieht sich auf den Positionswert)',
              referenceNotSelectedButValid:
                'Bitte wähle die Position {{itemName}} aus (die Preisberechnung bezieht sich darauf und {{itemName}} muss ausgewählt sein)',
            },
            requiredFieldsAlertText: 'Diese Eingabefelder sind Pflichtfelder',
            zeroNotAllowedError: 'Keine 0 Werte erlaubt',
          },
          Total: {
            label: 'Preisvorschau des Bereiches',
          },
          startOfService: 'Leistungsbeginn (ab Monat / Jahr)',
          startOfServiceRequired: 'Leistungsbeginn erforderlich',
        },
        CompanyType: {
          confirmChange:
            'Bist du sicher, dass du den Mandantentyp ändern möchtest? Die bestehende Positionsauswahl geht verloren. Möchtest du den Mandantentyp wechseln?',
          inputs: {
            companyType: {
              label: 'Welche Rechtsform haben Sie?',
              placeholder: 'Mandantentyp auswählen',
            },
            selfBooker: 'Erstellen Sie Ihre Buchhaltung selber?',
          },
          noTypes: 'Keine Mandantentyp',
          selfBooker: 'Erstellen Sie Ihre Buchhaltung selber?',
          title: 'Mandantentyp auswählen',
        },
        ContactData: {
          buttons: {
            addCompany: '$t(common.company.addCompany)',
            addContact: '$t(common.contact.addContact)',
            changeCompany: '$t(common.company.changeCompany)',
          },
          company: {
            addExistingCompanyTab: 'Existierendes Mandat hinzufügen',
            card: {
              actions: {
                change: '$t(common.company.changeCompany)',
                edit: '$t(common.company.editCompany)',
              },
              address: 'Addresse',
              identifer: 'Mandatsnummer',
            },
            createNewCompanyTab: 'Neues Mandat anlegen',
            duplicatedErrorMessage: 'Mandat mit diesem Namen existiert bereits',
            inputs: {
              city: {
                label: 'Stadt',
                placeholder: 'Stadt',
              },
              cityCode: {
                label: 'PLZ',
                placeholder: 'PLZ',
              },
              company: {
                label: 'Mandatname (Unternehmensname)',
                placeholder: 'Suchen nach Mandat (z.B. Unternehmensname)',
              },
              country: {
                label: 'Land',
                placeholder: 'Land',
              },
              houseNumber: {
                label: 'Nr.',
                placeholder: 'Nr.',
              },
              identifer: {
                label: 'Mandatsnummer',
                placeholder: 'Mandatsnummer',
              },
              name: {
                company: {
                  label: 'Firmierung',
                  placeholder: 'Firmierung',
                },
                individual: {
                  label: 'Mandatsname',
                  placeholder: 'Mandatsname',
                },
                label: 'Unternehmensname',
                placeholder: 'Unternehmensname',
              },
              street: {
                label: 'Straße',
                placeholder: 'Straße',
              },
              type: {
                company: 'Unternehmen',
                individual: 'Einzelperson',
              },
            },
          },
          contact: {
            addExistingContactTab: 'Existierenden Kontakt hinzufügen',
            createNewContactTab: 'Neuen Kontakt anlegen',
            duplicatedErrorMessage: {
              contact: 'Kontakt existiert bereits',
              email: 'Kontakt mit dieser E-Mail existiert bereits',
            },
            empty: 'Es existieren noch keine Kontakte',
            inputs: {
              contact: {
                label: 'Kontakt',
                placeholder: 'Suche Kontakt',
              },
              email: {
                label: 'E-Mail Addresse',
                placeholder: 'E-Mail Addresse',
              },
              firstName: {
                label: 'Vorname',
                placeholder: 'Vorname',
              },
              lastName: {
                label: 'Nachname',
                placeholder: 'Nachname',
              },
              phoneNumber: {
                label: 'Telefon (geschäftlich)',
                placeholder: 'Telefon (geschäftlich)',
              },
              phoneNumberAlternative: {
                label: 'Telefon (alternativ)',
                placeholder: 'Telefon (alternativ)',
              },
              position: {
                label: 'Position',
                placeholder: 'Position',
              },
              salutation: {
                label: 'Anrede',
                options: {
                  Mr: 'Herr',
                  Mrs: '',
                  Ms: 'Frau',
                },
                placeholder: 'Herr',
              },
            },
            table: {
              email: 'Email',
              name: 'Name',
              phones: {
                phoneNumber: 'Telefon (geschäftlich)',
                phoneNumberAlternative: 'Telefon (alternativ)',
                title: 'Telefonnummern',
              },
              position: 'Position',
            },
          },
          contactDetailsLabel: 'E-Mail',
          label: 'Kontakt-Daten',
          modal: {
            cancel: '$t(common.cancel)',
            ok: '$t(common.ok)',
            titles: {
              addCompany: '$t(common.company.addCompany)',
              addContact: '$t(common.contact.addContact)',
              changeCompany: '$t(common.company.changeCompany)',
              deleteContact: '$t(common.contact.deleteContact)',
              editCompany: '$t(common.company.editCompany)',
              editContact: '$t(common.contact.editContact)',
            },
          },
        },
        Discounts: {
          activeDiscountsLabel: 'Liste der aktiven Rabatte',
          activeDiscountsListLabel: 'Folgende Rabatte sind aktiv:',
        },
        DocumentTypes: {
          label: 'Dokumente',
          typesSelectLabel: 'Welche Dokumente sollen generiert werden?',
        },
        DocumentTypesTable: {
          actionsHeader: '',
          nameHeader: 'Dokumentenvorlage',
        },
        TotalPricing: {
          TotalTable: {
            headers: {
              interval: 'Intervall',
              price: 'Preis',
            },
          },
          error: 'Bitte behebe die Fehler im Formular, um den Preis zu sehen',
          fixedMonthlyFeeLine_monthly:
            '{{nrOfSelectedItems}} monatliche Positionen ausgewählt und im Unternehmer-Honorar beinhaltet',
          fixedMonthlyFeeLine_yearly:
            '{{nrOfSelectedItems}} jährliche Positionen ausgewählt und im Unternehmer-Honorar beinhaltet',
          fixedMonthlyFeeTypeHint: 'Kosten sind im Unternehmer-Honorar beinhaltet',
          label: 'Kalkulation',
        },
        buttonLabel: 'Optionen',
        categoriesCardTitle: 'Wunsch-Leistungen auswählen',
        emptyCategoryListMessage: 'Es existieren bisher KEINE Kategorien',
        options: {
          cancel: '$t(common.cancel)',
          debugMode: 'Konfiguration anzeigen',
          fixedMonthlyFee: 'Unternehmer-Honorar Modus',
          hiddenNote: {
            placeholder: 'Versteckte Notizen',
            title: 'Versteckte Notizen',
          },
          hideHiddenNote: 'Notiz verbergen',
          label: 'Optionen',
          ok: '$t(common.yes)',
          showDiscounts: 'Rabatte anzeigen',
          showHiddenNote: 'Notiz anzeigen',
          showPrices: 'Preise anzeigen',
          standardFee: 'Standard Modus',
          switch: {
            off: '$t(common.off)',
            on: '$t(common.on)',
          },
          title: 'Optionen',
        },
        somethingChangedWhenOpenedEdit:
          'Die Stamm-Konfiguration, auf der diese Menükarte zum Zeitpunkt ihrer letzten Bearbeitung beruht hat, hat sich geändert. Es werden aktuelle Werte dargestellt. Bitte überprüfe diese Menükarte genau',
        title: 'Neue Menükarte anlegen',
      },
    },
  },
};
