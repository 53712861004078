// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kdFrM7S9AyP7twEKXjUAr > :nth-child(4) {\n  padding-top: 20px;\n}\n.kdFrM7S9AyP7twEKXjUAr label {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/ShoppingCart/ContactData/company/AddCompanyModal.module.less"],"names":[],"mappings":"AAAA;EAEI,iBAAA;AAAJ;AAFA;EAKI,mBAAA;AAAJ","sourcesContent":[".createForm {\n  > :nth-child(4) {\n    padding-top: 20px;\n  }\n  label {\n    white-space: nowrap;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createForm": "kdFrM7S9AyP7twEKXjUAr"
};
export default ___CSS_LOADER_EXPORT___;
