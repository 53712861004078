import apollo from 'graphql/apollo';
import { deleteCategoryMutation } from 'graphql/mutations';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { clientDelete } from 'utils/helpers';
import toast from 'utils/toast';

export const deleteCategory = async ({ _id, categoryListQuery }) =>
  apollo.mutate({
    mutation: deleteCategoryMutation,
    variables: { _id },
    update: (cache) => {
      const cachedData = cache.readQuery({ query: categoryListQuery });

      const newCategories = clientDelete({ array: cachedData.categories, _id });

      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: newCategories,
        },
      });
    },
  });
export default deleteCategory;

export const deleteCategoryWithConfirmation = ({ _id, categoryListQuery }) =>
  confirmModal({
    cancelText: i18n.t('admin.deleteCategoryConfirmation.cancel'),
    okText: i18n.t('admin.deleteCategoryConfirmation.ok'),
    okType: 'danger',
    onOk: () => deleteCategory({ _id, categoryListQuery }).then(toast.successDefault),
    title: i18n.t('admin.deleteCategoryConfirmation.title'),
  });
