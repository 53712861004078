import gql from 'graphql-tag';
import { adminItemFragment } from 'graphql/fragments';

const CREATE_ITEM_MUTATION = gql`
  mutation createCategoryItem($categoryId: ID!, $item: CategoryItemInput!) {
    createCategoryItem(categoryId: $categoryId, item: $item) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default CREATE_ITEM_MUTATION;
