// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QJ__H-u8EzQh1-ioHJCjN {\n  display: flex;\n  flex-direction: column;\n  width: 100% !important;\n}\n.QJ__H-u8EzQh1-ioHJCjN > button {\n  overflow: hidden;\n  height: 50px !important;\n}\n@media (min-width: 320px) {\n  .QJ__H-u8EzQh1-ioHJCjN {\n    flex-direction: row;\n    flex-wrap: nowrap;\n  }\n  .QJ__H-u8EzQh1-ioHJCjN > :first-child {\n    border-right: none !important;\n    border-top-right-radius: 0 !important;\n    border-bottom-right-radius: 0 !important;\n  }\n  .QJ__H-u8EzQh1-ioHJCjN > :last-child {\n    border-left: none !important;\n    border-top-left-radius: 0 !important;\n    border-bottom-left-radius: 0 !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/ShoppingCart/ContactData/components/Switcher.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,sBAAA;AACF;AAJA;EAKI,gBAAA;EACA,uBAAA;AAEJ;AACE;EAAA;IACE,mBAAA;IAEA,iBAAA;EACF;EAJA;IAKI,6BAAA;IACA,qCAAA;IACA,wCAAA;EAEJ;EATA;IAUI,4BAAA;IACA,oCAAA;IACA,uCAAA;EAEJ;AACF","sourcesContent":[".tabContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100% !important;\n  > button {\n    overflow: hidden;\n    height: 50px !important;\n  }\n\n  @media (min-width: 320px) {\n    flex-direction: row;\n\n    flex-wrap: nowrap;\n    > :first-child {\n      border-right: none !important;\n      border-top-right-radius: 0 !important;\n      border-bottom-right-radius: 0 !important;\n    }\n    > :last-child {\n      border-left: none !important;\n      border-top-left-radius: 0 !important;\n      border-bottom-left-radius: 0 !important;\n    }\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "QJ__H-u8EzQh1-ioHJCjN"
};
export default ___CSS_LOADER_EXPORT___;
