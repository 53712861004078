import { useMemo } from 'react';
import { Tabs } from 'antd';
import { Form, Input, Switch } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormItem';
import SaveChangesButton from 'components/common/SaveChangesButton';
import I18nFormik from 'components/common/I18nFormik';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import i18n from 'i18n';
import { RichEditorField } from 'components/common/RichEditor';
import BlockController from './BlockController';
import classes from './DocumentLayoutBlockForm.module.less';

const { TabPane } = Tabs;

const FieldRenderer = ({ setPreviewBlockDescriptor, fieldDescriptor }) => {
  const label = fieldDescriptor.displayName || fieldDescriptor.name;
  if (fieldDescriptor.type === 'multi-line-text') {
    return <RichEditorField {...fieldDescriptor} label={label} />;
  }
  if (fieldDescriptor.type === 'boolean') {
    return (
      <FormItem
        className={`hide-form-item-colon row-reverse ${classes.switch}`}
        name={fieldDescriptor.name}
        label={label}
        labelCol={{ span: 21 }}
      >
        <Switch
          name={fieldDescriptor.name}
          checkedChildren={i18n.t('common.on')}
          unCheckedChildren={i18n.t('common.off')}
        />
      </FormItem>
    );
  }
  if (fieldDescriptor.type === 'text') {
    return (
      <FormItem name={fieldDescriptor.name} label={label}>
        <Input name={fieldDescriptor.name} />
      </FormItem>
    );
  }
  if (fieldDescriptor.type === 'tab') {
    return (
      <Tabs
        defaultActiveKey="1"
        destroyInactiveTabPane
        onChange={(key) => {
          const tab = fieldDescriptor.tabs.find((d) => d.name === key);
          setPreviewBlockDescriptor(tab);
        }}
      >
        {fieldDescriptor.tabs.map((tab) => (
          <TabPane tab={tab.displayName} key={tab.name}>
            {tab.props.map((fd) => (
              <FieldRenderer key={fd.name} fieldDescriptor={fd} setPreviewBlockDescriptor={setPreviewBlockDescriptor} />
            ))}
          </TabPane>
        ))}
      </Tabs>
    );
  }
  return null;
};

const flattenFields = (form) => {
  const fieldDescriptors = [];
  const traverse = (f) => {
    f.forEach((fieldDescriptor) => {
      if (fieldDescriptor.type === 'tab') {
        fieldDescriptor?.tabs?.forEach((t) => traverse(t.props));
      } else {
        fieldDescriptors.push(fieldDescriptor);
      }
    });
  };
  traverse(form);
  return fieldDescriptors;
};
function mergeInitialValuesWithDefaultValues({ form, initialValues }) {
  const newInitialValues = { ...initialValues };
  const flatFields = flattenFields(form);
  flatFields.forEach(({ name, defaultValue }) => {
    if (name && !Object.prototype.hasOwnProperty.call(newInitialValues, name) && defaultValue !== undefined)
      newInitialValues[name] = defaultValue;
  });
  return newInitialValues;
}

export default function DocumentLayoutBlockForm({
  form,
  initialValues,
  getDocumentTemplate,
  blockId,
  setPreviewBlockDescriptor,
}) {
  const mergedInitialValues = useMemo(() => mergeInitialValuesWithDefaultValues({ form, initialValues }), [
    form,
    initialValues,
  ]);
  return (
    <I18nFormik
      initialValues={mergedInitialValues}
      onSubmit={(values) => {
        const blocks = BlockController.updateBlockForm({
          blockId,
          blocks: getDocumentTemplate().blocks,
          formValues: values,
        });
        BlockController.persistBlocks({ _id: getDocumentTemplate()._id, blocks })
          .then(toast.successDefault)
          .catch(toastGraphQLError);
      }}
      // TODO: do we need validation?
      // validationSchema={generateValidationSchema(form)}
      enableReinitialize
    >
      <Form layout="vertical">
        <AlertFromFormik />
        {form.map((fieldDescriptor) => (
          <FieldRenderer
            key={fieldDescriptor.name}
            fieldDescriptor={fieldDescriptor}
            setPreviewBlockDescriptor={setPreviewBlockDescriptor}
          />
        ))}

        <SaveChangesButton initialValues={mergedInitialValues} />
      </Form>
    </I18nFormik>
  );
}
