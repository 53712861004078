import gql from 'graphql-tag';
import apollo from 'graphql/apollo';
import { adminInputFieldFragment } from 'graphql/fragments';

export const SET_CATEGORY_INPUT_FIELD_WRITE_FRAGMENT = gql`
  fragment setCategoryInputField on Category {
    inputFields {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;
export const GET_TYPENAME_CATEGORY = gql`
  query getCategories {
    categories {
      __typename
    }
  }
`;
export default function moveCategoryInputField({ _id, inputFields }) {
  const { categories } = apollo.readQuery({
    query: GET_TYPENAME_CATEGORY,
  });
  if (!categories.length) return;
  const { __typename } = categories[0];

  apollo.writeFragment({
    id: `${__typename}___${_id}`,
    fragment: SET_CATEGORY_INPUT_FIELD_WRITE_FRAGMENT,
    fragmentName: 'setCategoryInputField',
    data: { inputFields },
  });
}
