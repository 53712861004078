import { useState, useMemo, memo } from 'react';
import { Modal, Row, Col, Button } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { ReactComponent as AddToTopIcon } from 'assets/icons/add-to-top.svg';
import { ReactComponent as AddToBottomIcon } from 'assets/icons/add-to-bottom.svg';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import { documentTemplateBlockDescriptorsQuery } from 'graphql/queries';
import equal from 'fast-deep-equal/es6/react';
import BlockController from './BlockController';
import BlockPreviewContent from './BlockPreviewContent';

const i18nPrefix = 'admin.DocumentTemplateConfigurationPage.addBlockModal';
const getTableColumns = ({ onInfoClick, onClickAddToTop, onClickAddToBottom }) => [
  {
    title: i18n.t(`${i18nPrefix}.blockNameColumnTitle`),
    dataIndex: 'displayName',
    key: 'name',
  },
  {
    title: i18n.t(`${i18nPrefix}.blockActionsColumnTitle`),
    dataIndex: '',
    key: 'actions',
    width: 400,
    render: (block) => {
      return (
        <>
          <Button ghost type="primary" icon={<InfoOutlined />} onClick={() => onInfoClick(block)} />{' '}
          <Button
            ghost
            type="primary"
            icon={<AddToTopIcon width={18} style={{ marginBottom: -2, marginRight: 2 }} />}
            onClick={() => onClickAddToTop(block)}
          >
            {i18n.t(`${i18nPrefix}.addToTopButton`)}
          </Button>{' '}
          <Button
            ghost
            type="primary"
            icon={<AddToBottomIcon width={18} style={{ marginBottom: -2, marginRight: 2 }} />}
            onClick={() => onClickAddToBottom(block)}
          >
            {i18n.t(`${i18nPrefix}.addToBottomButton`)}
          </Button>
        </>
      );
    },
  },
];

const useDocumentTemplateBlockDescriptors = () => {
  const { data, loading, error, ...rest } = useQuery(documentTemplateBlockDescriptorsQuery);
  const res = grabFirstGQLDataResult(data);

  return { data: Array.isArray(res) ? res : [], loading, error, ...rest };
};

function AddBlockModal({ isVisible, close, getDocumentTemplate, changeActiveKey }) {
  const { t } = useTranslation();
  const { data: blockDescriptors, loading, error } = useDocumentTemplateBlockDescriptors();

  const [activeBlock, setActiveBlock] = useState(null);
  const tableColumns = useMemo(() => {
    return getTableColumns({
      onInfoClick: (newActiveBlock) => {
        setActiveBlock((b) => (b?.name !== newActiveBlock?.name ? newActiveBlock : null));
      },
      onClickAddToTop: (block) => {
        const blocks = BlockController.addBlock({ blocks: getDocumentTemplate().blocks, block, position: 'top' });
        BlockController.persistBlocks({ _id: getDocumentTemplate()._id, blocks })
          .then((e) => {
            changeActiveKey({ blockId: e.data.updateDocumentTemplate.blocks[0]._id, active: true });
          })
          .then(close)
          .catch(toastGraphQLError);
      },
      onClickAddToBottom: (block) => {
        const blocks = BlockController.addBlock({ blocks: getDocumentTemplate().blocks, block, position: 'bottom' });
        BlockController.persistBlocks({ _id: getDocumentTemplate()._id, blocks })
          .then((e) => {
            changeActiveKey({
              blockId: e.data.updateDocumentTemplate.blocks[e.data.updateDocumentTemplate.blocks.length - 1]._id,
              active: true,
            });
          })
          .then(close)
          .catch(toastGraphQLError);
      },
    });
  }, [getDocumentTemplate, close, changeActiveKey]);

  return (
    <Modal
      title={t(`${i18nPrefix}.title`)}
      visible={isVisible}
      onCancel={close}
      onOk={close}
      width={1200}
      style={{ top: 40 }}
      footer={null}
    >
      <Row gutter={[10, 10]}>
        <Col sm={24} md={16}>
          <h4>{t(`${i18nPrefix}.availableBlocksTitle`)}</h4>
          <QueryErrorAlert error={error} />
          <TableMemo
            size="small"
            rowKey={(record) => record.name}
            pagination={false}
            dataSource={blockDescriptors}
            columns={tableColumns}
            loading={loading}
          />
        </Col>
        {activeBlock ? (
          <Col sm={24} md={8}>
            <BlockPreviewContent block={activeBlock} title={t(`${i18nPrefix}.blockPreviewTitle`)} />
          </Col>
        ) : null}
      </Row>
    </Modal>
  );
}

export default memo(AddBlockModal, equal);
