/* eslint-disable import/no-unresolved */
if (!global.Intl) {
  require('intl');
  require('intl/locale-data/jsonp/en.js');
  require('intl/locale-data/jsonp/de.js');
} else if (!Intl.NumberFormat.prototype.formatToParts) {
  Intl.NumberFormat = require('intl').NumberFormat;
  require('intl/locale-data/jsonp/de.js');
  require('intl/locale-data/jsonp/en.js');
}
