import apollo from 'graphql/apollo';
import { deleteShoppingCartMutation } from 'graphql/mutations';
// import { shoppingCartEntriesTableQuery } from 'graphql/queries';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import toast from 'utils/toast';

export const deleteShoppingCart = async ({ _id }) =>
  apollo.mutate({
    mutation: deleteShoppingCartMutation,
    variables: { _id },
    refetchQueries: ['shoppingCartEntries'],
  });
export default deleteShoppingCart;

export const deleteShoppingCartWithConfirmation = ({ _id }) =>
  confirmModal({
    cancelText: i18n.t('deleteShoppingCartConfirmation.cancel'),
    okText: i18n.t('deleteShoppingCartConfirmation.ok'),
    okType: 'danger',
    onOk: () => deleteShoppingCart({ _id }).then(toast.successDefault),
    title: i18n.t('deleteShoppingCartConfirmation.title'),
  });
