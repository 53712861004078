// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L_7Ej2qRXW49hlw2KUcKy > :first-child {\n  background-color: transparent;\n  margin: 0 !important;\n}\n.L_7Ej2qRXW49hlw2KUcKy > :last-child {\n  background-color: white;\n  padding: 24px;\n}\n.L_7Ej2qRXW49hlw2KUcKy .ant-tabs-tab {\n  background-color: white;\n  border: none !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/AccountSetting/AccountSetting.module.less"],"names":[],"mappings":"AAAA;EAEI,6BAAA;EACA,oBAAA;AAAJ;AAHA;EAMI,uBAAA;EACA,aAAA;AAAJ;AAPA;EAUI,uBAAA;EACA,uBAAA;AAAJ","sourcesContent":[".tabs {\n  > :first-child {\n    background-color: transparent;\n    margin: 0 !important;\n  }\n  > :last-child {\n    background-color: white;\n    padding: 24px;\n  }\n  :global(.ant-tabs-tab) {\n    background-color: white;\n    border: none !important;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "L_7Ej2qRXW49hlw2KUcKy"
};
export default ___CSS_LOADER_EXPORT___;
