import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import PageBack from 'components/common/PageBack';
import routePaths from 'router/route-paths';
import { useLibraryContext } from 'contexts/LibraryContext';
import { useDocumentTemplate } from 'graphql/hooks';
import GeneralInfoForm from './GeneralInfoForm';
import DocumentLayout from './DocumentLayout';

export default function DocumentTemplateConfiguration({ match }) {
  const { id: documentTemplateId } = match.params;
  const { data: documentTemplate, error } = useDocumentTemplate(documentTemplateId);
  const { isLibrary } = useLibraryContext();

  const loading = true;
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.DocumentTemplateConfigurationPage.title')}
      topComponent={
        <PageBack
          to={isLibrary ? routePaths.commonLibraryDocumentTemplateList : routePaths.documentTemplateList}
          label={t('admin.DocumentTemplateConfigurationPage.backTitle')}
        />
      }
    >
      <div className="xs-mt-20">
        {error ? (
          <Card>
            <QueryErrorAlert error={error} />
          </Card>
        ) : null}
        {loading && !documentTemplate ? (
          <Card>
            <Skeleton title loading={loading} active />
          </Card>
        ) : null}
        {documentTemplate ? (
          <>
            <GeneralInfoForm documentTemplate={documentTemplate} />
            <DocumentLayout documentTemplate={documentTemplate} />
          </>
        ) : null}
      </div>
    </PageContainer>
  );
}
