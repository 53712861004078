import I18nFormik from 'components/common/I18nFormik';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { Form, Input, InputNumber } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/SaveChangesButton';
import * as Yup from 'yup';
import PhoneNumber, { PhoneInputValidationSchema } from 'components/common/PhoneInputFormik';
import i18n from 'i18n';
import { adminTenantCompany } from 'graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { Skeleton } from 'antd';
import { updateTenantCompany } from 'graphql/methods';
import { CountryFormikDropdownInput } from 'pages/user/ShoppingCart/ContactData/components/Inputs';
import classes from './CompanySetting.module.less';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const schema = () => ({
  name: Yup.string()
    .required()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.name.title')),
  street: Yup.string()
    .required()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.street.title')),
  houseNumber: Yup.string()
    .required()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.houseNumber.title')),
  cityCode: Yup.string()
    .transform((value) => value || '')
    .required()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.cityCode.title')),
  city: Yup.string()
    .required()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.city.title')),
  phoneNumber: PhoneInputValidationSchema({ required: true }).label(
    i18n.t('admin.Setting.CompanyTenant.inputs.phoneNumber.title'),
  ),
  country: Yup.string()
    .required()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.country.title')),
  website: Yup.string()
    .url()
    .label(i18n.t('admin.Setting.CompanyTenant.inputs.website.title')),
});

const updateCompanySettingSchema = () => Yup.object().shape(schema());
function CompanySetting() {
  //  const { data, loading } = useQuery();
  // const company = useMemo(() => grabFirstGQLDataResult(data), [data]);

  // if (loading) return 'loading...';
  // return <CompanySettingForm company={company} />;
  return <CompanySettingForm />;
}
function CompanySettingForm() {
  const { data, loading } = useQuery(adminTenantCompany);
  const company = grabFirstGQLDataResult(data);

  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      name: company?.companyName || '',
      street: company?.street || '',
      houseNumber: company?.streetNr || '',
      cityCode: company?.cityCode || '',
      city: company?.city || '',
      phoneNumber: company?.phoneNr || '49-',
      country: company?.country || 'DE',
      website: company?.websiteUrl || '',
    }),
    [company],
  );

  if (!data && loading) return skeletons.map((k) => <Skeleton title loading active key={k} />);

  return (
    <div style={{ backgroundColor: 'white', padding: '20px' }}>
      <I18nFormik
        initialValues={initialValues}
        onSubmit={(modifier) => {
          updateTenantCompany({
            companyName: modifier.name,
            street: modifier.street,
            streetNr: modifier.houseNumber,
            cityCode: modifier.cityCode,
            city: modifier.city,
            phoneNr: modifier.phoneNumber,
            country: modifier.country,
            websiteUrl: modifier.website,
          });
        }}
        validationSchema={updateCompanySettingSchema}
        enableReinitialize
      >
        <Form layout="vertical">
          <AlertFromFormik />
          <FormItem name="name" label={t('admin.Setting.CompanyTenant.inputs.name.title')}>
            <Input name="name" />
          </FormItem>
          <div className={classes.group}>
            <div className="flex-3">
              <FormItem name="street" label={t('admin.Setting.CompanyTenant.inputs.street.title')}>
                <Input name="street" />
              </FormItem>
            </div>
            <div className="flex-1">
              <FormItem name="houseNumber" label={t('admin.Setting.CompanyTenant.inputs.houseNumber.title')}>
                <Input name="houseNumber" />
              </FormItem>
            </div>
          </div>
          <div className={classes.group}>
            <div className="flex-1">
              <FormItem name="cityCode" label={t('admin.Setting.CompanyTenant.inputs.cityCode.title')}>
                <InputNumber min={0} maxLength={9} name="cityCode" />
              </FormItem>
            </div>
            <div className="flex-3">
              <FormItem name="city" label={t('admin.Setting.CompanyTenant.inputs.city.title')}>
                <Input name="city" />
              </FormItem>
            </div>
          </div>
          <CountryFormikDropdownInput />
          <FormItem name="phoneNumber" label={t('admin.Setting.CompanyTenant.inputs.phoneNumber.title')}>
            <PhoneNumber name="phoneNumber" maxLength={60} />
          </FormItem>
          <FormItem name="website" label={t('admin.Setting.CompanyTenant.inputs.website.title')}>
            <Input name="website" />
          </FormItem>

          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </div>
  );
}

function CompanySettingWrapper() {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('admin.Setting.CompanyTenant.title')}>
      <CompanySetting />
    </PageContainer>
  );
}

export default memo(CompanySettingWrapper, equal);
