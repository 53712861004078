import gql from 'graphql-tag';
import adminPdfMainThemeFragment from './adminPdfMainThemeFragment';
import adminPdfComponentThemeFragment from './adminPdfComponentThemeFragment';
import adminPdfTableThemeFragment from './adminPdfTableThemeFragment';
import adminPdfTableColorsFragment from './adminPdfTableColorsFragment';
import adminPdfBodyPrimaryFragment from './adminPdfBodyPrimaryFragment';
import adminPdfBodyPrimaryFooterFragment from './adminPdfBodyPrimaryFooterFragment';
import adminPdfTableOfContentFragment from './adminPdfTableOfContentFragment';

const adminPdfThemeFragment = gql`
  fragment adminPdfThemeFragment on PdfTheme {
    main {
      ...adminPdfMainThemeFragment
    }
    title {
      ...adminPdfComponentThemeFragment
    }
    h1 {
      ...adminPdfComponentThemeFragment
    }
    h2 {
      ...adminPdfComponentThemeFragment
    }
    h3 {
      ...adminPdfComponentThemeFragment
    }
    textBlock {
      ...adminPdfComponentThemeFragment
    }
    testimonial {
      ...adminPdfComponentThemeFragment
    }
    testimonialSmallText {
      ...adminPdfComponentThemeFragment
    }
    table {
      ...adminPdfTableThemeFragment
    }
    tableHeaderPrimary {
      ...adminPdfTableColorsFragment
    }
    tableHeaderSecondary {
      ...adminPdfTableColorsFragment
    }
    tableContent {
      ...adminPdfTableColorsFragment
    }
    tableFooter {
      ...adminPdfTableColorsFragment
    }
    tableLeftColumn {
      ...adminPdfTableColorsFragment
    }
    bodyPrimary {
      ...adminPdfBodyPrimaryFragment
    }
    bodyPrimaryFooter {
      ...adminPdfBodyPrimaryFooterFragment
    }
    tableOfContent {
      ...adminPdfTableOfContentFragment
    }
  }
  ${adminPdfComponentThemeFragment}
  ${adminPdfMainThemeFragment}
  ${adminPdfBodyPrimaryFragment}
  ${adminPdfBodyPrimaryFooterFragment}
  ${adminPdfTableOfContentFragment}
  ${adminPdfTableThemeFragment}
  ${adminPdfTableColorsFragment}
`;

export default adminPdfThemeFragment;
