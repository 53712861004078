/* eslint-disable react/no-unescaped-entities */
import { useLibraryContext } from 'contexts/LibraryContext';
import { moveCategoryCache } from 'graphql/cache';
import { deleteCategoryWithConfirmation, moveCategory } from 'graphql/methods';
import { getCategoryListQuery } from 'graphql/queries';
import { useCallback, useMemo, useState } from 'react';
import { clientShift } from 'utils/helpers';
import DraggableCollapse from 'components/admin/drag/Collapse/Collapse';
import { find, filter } from 'lodash';
import ItemTypes from 'constants/ItemTypes';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CollapsibleCategoryContent from './CollapsibleCategoryContent';
import CustomExpandIcon from './components/CustomExpandIcon/CustomExpandIcon';
import DraggableDecoration from './drag/DraggableDecoration';
import classes from './CatalogueConfiguration.module.less';

export default function CatalogueConfigurationDraggable({ categories }) {
  const { isLibrary } = useLibraryContext();
  const categoryListQuery = getCategoryListQuery(isLibrary);

  const newCategoriesIds = useMemo(() => filter(categories, { isNew: true }).map((e) => e._id), [categories]);

  const moveCategoryMutation = useCallback(
    (...props) => {
      moveCategory(...props, categoryListQuery);
    },
    [categoryListQuery],
  );
  const mutation = useMemo(
    () => ({
      move: moveCategoryMutation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const move = useCallback(
    (dragIndex, hoverIndex, _id) => {
      const newCategories = clientShift({ array: categories, _id, shift: dragIndex - hoverIndex, orderBy: 'desc' });
      moveCategoryCache({ categories: newCategories, categoryListQuery });
    },
    [categories, categoryListQuery],
  );

  return (
    <DraggableCollapse
      newKeys={newCategoriesIds}
      component={CollapsibleCategoryContent}
      nameElementData="category"
      data={categories}
      moveInCache={move}
      moveMutation={mutation}
      itemTypes={ItemTypes.CATEGORY}
      draggableProps={{ isConnectedPreview: false }}
      collapseProps={{
        expandIcon: (props) => <CustomExpandIcon {...props} />,
      }}
      panelProps={{
        forceRender: true, // required for shopping cart form validation auto scroll
        header: (id) => find(categories, { _id: id })?.name,
        extra: Extra,
      }}
      decorate={<DraggableDecoration height="49px" />}
    />
  );
}
function Extra({ _id }) {
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      visible={visible}
      onVisibleChange={setVisible}
      trigger={['click']}
      overlay={
        <CollapseCategoryMenu
          _id={_id}
          onClose={(e) => {
            e.stopPropagation();
            setVisible(false);
          }}
        />
      }
    >
      <MoreOutlined
        onClick={(e) => e.stopPropagation()}
        className={`${classes.categoryOptions} hover-background-light-primary`}
      />
    </Dropdown>
  );
}

function CollapseCategoryMenu({ _id, onClose }) {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const categoryListQuery = getCategoryListQuery(isLibrary);
  return (
    <div aria-hidden="true" onClick={onClose} className="border-gray">
      <Menu
        openKeys={null}
        selectedKeys={null}
        onSelect={() => {
          deleteCategoryWithConfirmation({ _id, categoryListQuery });
        }}
      >
        <Menu.Item active={false} className="hover-background-light-primary" icon={<DeleteOutlined />}>
          {t('admin.CatalogueConfiguration.collapseOptions.remove')}
        </Menu.Item>
      </Menu>
    </div>
  );
}
