import { Checkbox, Input, Radio } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { InputNumber } from 'components/common/InputNumber';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { RichEditorField } from 'components/common/RichEditor';
import './components.css';
import PricingFormulaInput from './PricingFormulaInput';

export const MinimalPriceFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="minPrice" label={t('admin.itemModal.inputs.minPrice.label')}>
      <InputNumber min={0} name="minPrice" />
    </FormItem>
  );
};

export const PricingFormulaFormikInput = ({ categoryId, parentId, parentType }) => {
  return (
    <PricingFormulaInput
      name="pricingFormula"
      hookProps={{
        parentId,
        parentType,
        categoryId,
      }}
    />
  );
};
export const PaymentIntervalFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="paymentInterval" label={t('admin.itemModal.inputs.paymentInterval.label')}>
      <Radio.Group name="paymentInterval">
        <Radio.Button value="oneOff">{t('admin.itemModal.inputs.paymentInterval.oneOffLabel')}</Radio.Button>
        <Radio.Button value="monthly">{t('admin.itemModal.inputs.paymentInterval.monthlyLabel')}</Radio.Button>
        <Radio.Button value="yearly">{t('admin.itemModal.inputs.paymentInterval.yearlyLabel')}</Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};
export const ConfigurePricingScalesCheckbox = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="scalesEnabled">
      <Checkbox name="scalesEnabled">{t('admin.itemModal.inputs.scales.label')}</Checkbox>
    </FormItem>
  );
};
export const NameFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="name" label={t('admin.itemModal.inputs.name.label')}>
      <Input autoFocus name="name" placeholder={t('admin.itemModal.inputs.name.placeholder')} />
    </FormItem>
  );
};

export const InfoTextFormikInput = ({ props }) => (
  <RichEditorField name="infoText" label="admin.itemModal.inputs.infoText.label" hidePlaceholdersButton {...props} />
);
export const PleaseNoteFormikInput = ({ props }) => (
  <RichEditorField name="pleaseNote" label="admin.itemModal.inputs.pleaseNote.label" {...props} />
);
export const NotesToEmployeeFormikInput = ({ props }) => (
  <RichEditorField
    name="notesToEmployee"
    label="admin.itemModal.inputs.notesToEmployee.label"
    hidePlaceholdersButton
    {...props}
  />
);
export const BenefitsFormikInput = ({ props }) => (
  <RichEditorField name="benefits" label="admin.itemModal.inputs.benefits.label" {...props} />
);
export const SubTitleFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="subTitle" label={t('admin.itemModal.inputs.subTitle.label')}>
      <Input name="subTitle" placeholder={t('admin.itemModal.inputs.subTitle.placeholder')} />
    </FormItem>
  );
};

export const PricingFormulaHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('admin.pricingFormulaInput.howUseButton')}
      title={t('admin.pricingFormulaInput.modalInfo.title')}
      helpText={t('admin.pricingFormulaInput.modalInfo.helpText')}
      videoCaption={t('admin.pricingFormulaInput.modalInfo.videoCaption')}
      videoUrl={t('admin.pricingFormulaInput.modalInfo.videoUrl')}
    />
  );
};
