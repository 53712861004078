/* eslint-disable no-param-reassign */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import i18n from 'i18n';
import { forwardRef } from 'react';
import { MentionContextProvider } from './MentionContext';
import { BaseRichEditorField } from './RichEditor';
import { RichEditorPlaceholders } from './RichEditorPlaceholders';

const makeRichEditorPlaceholdersSchema = (schema, { i18nKey, mentionType, forcedKeys }) => {
  const newSchema = schema.map((schemaElement) => ({
    ...schemaElement,
    fields: schemaElement.fields.map((field) => ({ ...field })),
  })); // fast cloneDeep

  newSchema.forEach((row) => {
    row.fields = row.fields.filter((f) => (f.hiddenByDefault ? forcedKeys?.includes?.(f.name) : true));
  });

  newSchema.forEach((row) => {
    const rowName = row.name;
    const rowI18nPath = `${i18nKey}.${rowName}`;
    row.title = i18n.t(`${rowI18nPath}.label`);
    row.fields.forEach((field) => {
      const fieldName = field.name;
      const fieldI18nPath = `${i18nKey}.${fieldName}`;
      field.title = i18n.t(`${fieldI18nPath}.label`);
      field.onClick = ({ insertMention }) => {
        insertMention({ name: field.name, mentionType });
      };
    });
  });
  return newSchema;
};

const defaultMentionContextValue = () => {
  const renderMention = ({ element }) => {
    const { mentionType, name } = element;
    if (mentionType === 'common') {
      return i18n.t(`admin.Placeholders.${name}.label`);
    }
    return '?';
  };
  return { renderMention };
};
const richEditorSchemaQuery = gql`
  query getPlaceholdersSchema {
    getPlaceholdersSchema {
      name
      fields {
        name
        hiddenByDefault
      }
    }
  }
`;
const useRichEditorSchema = ({ placeholders = [] } = {}) => {
  const { data } = useQuery(richEditorSchemaQuery);
  if (!data?.getPlaceholdersSchema) return null;
  return makeRichEditorPlaceholdersSchema(data?.getPlaceholdersSchema, {
    i18nKey: 'admin.Placeholders',
    mentionType: 'common',
    forcedKeys: placeholders,
  });
};
const RichEditorField_ = (props, ref) => {
  const placeholdersSchema = useRichEditorSchema(props);

  const toolbarChildren = () => {
    if (props.hidePlaceholdersButton) return null;
    return [<RichEditorPlaceholders schema={placeholdersSchema} />];
  };
  const { rootElement = 'div' } = props;
  if (!placeholdersSchema?.length) return null;
  return (
    <MentionContextProvider value={defaultMentionContextValue()}>
      <BaseRichEditorField ref={ref} {...props} toolbarChildren={toolbarChildren} rootElement={rootElement} />
    </MentionContextProvider>
  );
};
export const RichEditorField = forwardRef(RichEditorField_);
