// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3bQU7a4rXbdOyH2ociXR9I {\n  display: flex;\n  width: 50%;\n  height: 56px;\n}\n._33QBFHWExYHSDHVBr6SuiO {\n  display: flex;\n  flex-direction: row;\n}\n._2t6JTcdcnPz-_11AwTZekX {\n  margin-left: 8px;\n}\n.J6NmowBg60VbAIPkptXJ2 path {\n  stroke: #999;\n}\n._2fI-yGfZSSnMke4JolTjMQ {\n  width: 100%;\n  padding: 20px;\n  margin: -20px;\n}\n@media (min-width: 480px) {\n  ._2fI-yGfZSSnMke4JolTjMQ {\n    max-width: 480px;\n  }\n}\n@media (min-width: 768px) {\n  ._2fI-yGfZSSnMke4JolTjMQ {\n    max-width: 768px;\n  }\n}\n@media (min-width: 992px) {\n  ._2fI-yGfZSSnMke4JolTjMQ {\n    max-width: 992px;\n  }\n}\n@media (min-width: 1600px) {\n  ._2fI-yGfZSSnMke4JolTjMQ {\n    max-width: 1600px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/drag/CategoryDrag/CategoryDrag.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,UAAA;EACA,YAAA;AACF;AACA;EACE,aAAA;EACA,mBAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EAEI,YAAA;AAAJ;AAGA;EACE,WAAA;EACA,aAAA;EACA,aAAA;AADF;AAEE;EAAA;IACE,gBAAA;EACF;AACF;AAAE;EAAA;IACE,gBAAA;EAGF;AACF;AAFE;EAAA;IACE,gBAAA;EAKF;AACF;AAJE;EAAA;IACE,iBAAA;EAOF;AACF","sourcesContent":[".container {\n  display: flex;\n  width: 50%;\n  height: 56px;\n}\n.expandIcon {\n  display: flex;\n  flex-direction: row;\n}\n.arrow {\n  margin-left: 8px;\n}\n.drag {\n  path {\n    stroke: #999;\n  }\n}\n.layer {\n  width: 100%;\n  padding: 20px;\n  margin: -20px;\n  @media (min-width: 480px) {\n    max-width: 480px;\n  }\n  @media (min-width: 768px) {\n    max-width: 768px;\n  }\n  @media (min-width: 992px) {\n    max-width: 992px;\n  }\n  @media (min-width: 1600px) {\n    max-width: 1600px;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3bQU7a4rXbdOyH2ociXR9I",
	"expandIcon": "_33QBFHWExYHSDHVBr6SuiO",
	"arrow": "_2t6JTcdcnPz-_11AwTZekX",
	"drag": "J6NmowBg60VbAIPkptXJ2",
	"layer": "_2fI-yGfZSSnMke4JolTjMQ"
};
export default ___CSS_LOADER_EXPORT___;
