/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line import/extensions
import CatalogueConfiguration from 'components/admin/CatalogueConfiguration.js';
import { LibraryProvider } from 'contexts/LibraryContext';

export default function CatalogueConfigurationPage() {
  return (
    <LibraryProvider value={{ isLibrary: true }}>
      <CatalogueConfiguration />
    </LibraryProvider>
  );
}
