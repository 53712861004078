// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3QvocJz67Zl_T9ZflbPpb_ {\n  cursor: move;\n  margin: -10px -15px;\n  padding: 10px 15px;\n  z-index: 1;\n}\n._1FyMefiEteZCqix9d0eMDi path {\n  stroke: #999;\n}\n.fzbX45tf7a5flB2sS5iUb {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/drag/MoveButton.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;AACF;AACA;EAEI,YAAA;AAAJ;AAGA;EACE,kBAAA;AADF","sourcesContent":[".container {\n  cursor: move;\n  margin: -10px -15px;\n  padding: 10px 15px;\n  z-index: 1;\n}\n.drag {\n  path {\n    stroke: #999;\n  }\n}\n.borderDrag {\n  position: relative;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3QvocJz67Zl_T9ZflbPpb_",
	"drag": "_1FyMefiEteZCqix9d0eMDi",
	"borderDrag": "fzbX45tf7a5flB2sS5iUb"
};
export default ___CSS_LOADER_EXPORT___;
