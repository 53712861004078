import gql from 'graphql-tag';
import { adminStaticItemFragment } from 'graphql/fragments';

const m = gql`
  mutation createCategoryStaticItem($categoryId: ID!, $modifier: StaticItemInput!) {
    createCategoryStaticItem(categoryId: $categoryId, modifier: $modifier) {
      ...adminStaticItemFragment
    }
  }
  ${adminStaticItemFragment}
`;

export default m;
