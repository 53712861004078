import i18n from 'i18n';
import { escapeRegExp } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getDecimalSeparator = (t = i18n.t) => {
  const ifComma = t('decimalSeparator') === ',';
  return ifComma
    ? { decimalSeparator: ',', thousandSeparator: '.' }
    : { decimalSeparator: '.', thousandSeparator: ',' };
};

export const useDecimalSeparator = () => {
  const { t } = useTranslation();
  return getDecimalSeparator(t);
};

export const getNumberFormat = ({ decimalSeparator, thousandSeparator }) => {
  // 23456789.345 => 23${thousandSeparator}456${thousandSeparator}789${decimalSeparator}345
  const escapedDecimalSeparator = escapeRegExp(decimalSeparator);
  const escapedThousandSeparator = escapeRegExp(thousandSeparator);
  const jsDot = '.'; // string value of dot in js Number
  const escapedJsDot = escapeRegExp(jsDot);
  const thousandSeparatorRegExp = new RegExp(escapedThousandSeparator, 'g'); // match 'thousandSeparator'
  const dotRegExp = new RegExp(`${escapedJsDot}(?=\\d)`, 'g'); // match '.' followed by a number but not include number
  const decimalRegExp = new RegExp(`${escapedDecimalSeparator}(?=\\d)`, 'g'); // match 'decimalSeparator' followed by a number but not include number
  const eachThreeNumberRegExp = new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g'); // match thousands separator place (regex from https://ant.design/components/input-number/)
  const eachThreeNumberRegExpIfExistComma = new RegExp(`\\B(?=(\\d{3})+(?!\\d)${escapedDecimalSeparator})`, 'g'); // match thousands separator place until decimalSeparator appears (modified version of eachThreeNumberRegExp)
  const formatter = (value) => {
    // js number to string
    const v = `${value}`; // to string
    const valueWithDecimalUserWants = `${value}`.replace(dotRegExp, decimalSeparator); // replace jsDot to custom decimal separator
    const valueSplittedByThousands = valueWithDecimalUserWants.replace(
      v.includes(jsDot) ? eachThreeNumberRegExpIfExistComma : eachThreeNumberRegExp,
      thousandSeparator,
    ); // split value by thousands
    return valueSplittedByThousands;
  };
  const parser = (value) => {
    // parse formatted user's number string to `${number}`
    const removedThousandsSeparator = value.replace(thousandSeparatorRegExp, ''); // remove thousands separator
    const valueWithJSDot = removedThousandsSeparator.replace(decimalRegExp, jsDot); // use js Number dot
    return valueWithJSDot;
  };
  return { formatter, parser };
};
export const useNumberFormat = ({ decimalSeparator, thousandSeparator }) => {
  return useMemo(() => getNumberFormat({ decimalSeparator, thousandSeparator }), [decimalSeparator, thousandSeparator]);
};

export default useNumberFormat;
