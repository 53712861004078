import gql from 'graphql-tag';
import { adminDiscountFragment } from 'graphql/fragments';

const CREATE_DISCOUNT_MUTATION = gql`
  mutation createCategoryDiscount($categoryId: ID!, $discount: DiscountInput!) {
    createCategoryDiscount(categoryId: $categoryId, discount: $discount) {
      ...adminDiscountFragment
    }
  }
  ${adminDiscountFragment}
`;

export default CREATE_DISCOUNT_MUTATION;
