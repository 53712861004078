import gql from 'graphql-tag';

const userShoppingCartEntriesTableFragment = gql`
  fragment userShoppingCartEntriesTableFragment on ShoppingCart {
    _id
    createdAt
    # contact
    company {
      _id
      name
      identifer
    }
    contacts {
      _id
      firstName
      lastName
    }
    documentTemplates {
      _id
      name
    }
    valueOneOff
    valueMonthly
    valueYearly
  }
`;

export default userShoppingCartEntriesTableFragment;
