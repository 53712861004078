import apollo from 'graphql/apollo';
import { moveInputFieldMutation } from 'graphql/mutations';
import { getItemCategoryQuery } from 'graphql/queries';

const moveItemInputField = async ({ _id, itemId, offset, order }) =>
  apollo.mutate({
    mutation: moveInputFieldMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: getItemCategoryQuery, variables: { _id: itemId }, fetchPolicy: 'network-only' }],
  });
export default moveItemInputField;
