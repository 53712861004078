import gql from 'graphql-tag';
import { adminCategoryListFragment } from '../fragments';

const LIBRARY_CATEGORIES = gql`
  query libraryCategories {
    categories(isLibrary: true) {
      libraryDescription
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default LIBRARY_CATEGORIES;
