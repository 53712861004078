import gql from 'graphql-tag';
import { adminThemeFragment } from 'graphql/fragments';

const RESET_THEME_MUTATION = gql`
  mutation resetTheme {
    resetTheme {
      ...adminThemeFragment
    }
  }
  ${adminThemeFragment}
`;

export default RESET_THEME_MUTATION;
