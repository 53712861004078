import { Calc, Cart } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useFormikContext } from 'formik';
import { find } from 'lodash';
import { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TableMemo } from 'memo';
import cn from 'classnames';
import { useCategoriesContext, useCategoryContext } from './context';
import { getDiscountForCategory, formikToResponse } from './utils';
import classes from './CategoryInCart.module.less';

const isSelectedTypeItemInCategory = ({ items, cartCategory, type }) => {
  // const itemsObject = items.reduce((acc, item) => ({ ...acc, [item._id]: item }), {});
  // return cartCategory.itemIds?.find((id) => itemsObject[id]?.paymentInterval === type);
  return cartCategory.itemIds?.filter((_id) => find(items, { _id, paymentInterval: type })).length;
};
const isSelectedTypeItemInCart = ({ items, cart, type }) =>
  find(cart, (cartCategory) => isSelectedTypeItemInCategory({ items, cartCategory, type }));

const totalPreviewColumns = ({ t }) => [
  {
    title: t('user.ShoppingCart.TotalPricing.TotalTable.headers.interval'),
    dataIndex: 'interval',
    key: 'interval',
  },
  {
    title: t('user.ShoppingCart.TotalPricing.TotalTable.headers.price'),
    key: 'price',
    dataIndex: 'price',
  },
];

const TotalTableComponent = ({
  oneOffDiscounted,
  oneOff,
  discount,
  monthlyDiscounted,
  monthly,
  monthlySelected,
  oneOffSelected,
  yearly,
  yearlyDiscounted,
  yearlySelected,
  isFixedMonthlyFeeType,
  isCategoryLevel,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(() => totalPreviewColumns({ t }), [t]);
  const dataSource = useMemo(
    () =>
      [
        oneOffSelected && {
          paymentInterval: 'oneOff',
          priceBeforeDiscount: oneOff,
          finalPrice: oneOffDiscounted,
          isOneOff: true,
        },
        monthlySelected && {
          paymentInterval: 'monthly',
          priceBeforeDiscount: monthly,
          finalPrice: monthlyDiscounted,
          i18nName: isFixedMonthlyFeeType && !isCategoryLevel ? 'monthlyFixPrice' : 'monthly', // only in total show "fixed monthly price instead of monthly"
          numberOfItems: monthlySelected,
        },
        yearlySelected && {
          paymentInterval: 'yearly',
          priceBeforeDiscount: yearly,
          finalPrice: yearlyDiscounted,
          numberOfItems: yearlySelected,
        },
      ]
        .filter(Boolean)
        .map(
          ({
            paymentInterval,
            priceBeforeDiscount,
            finalPrice,
            i18nName = paymentInterval,
            isOneOff,
            numberOfItems,
          }) => {
            const priceTranslation = Calc.priceInfoAsText({
              finalPrice,
              priceBeforeDiscount,
              paymentInterval,
              discount,
              hideInterval: true,
            });
            let price =
              isCategoryLevel && isFixedMonthlyFeeType && !isOneOff ? (
                t(`user.ShoppingCart.TotalPricing.fixedMonthlyFeeLine_${paymentInterval}`, {
                  nrOfSelectedItems: numberOfItems,
                })
              ) : (
                <Trans
                  i18nKey={`sharedPackage.${priceTranslation.code}`}
                  components={{ del: <del /> }}
                  values={{
                    ...priceTranslation.variables,
                    newlineOrWhitespace: '',
                    paymentInterval: '',
                  }}
                />
              );
            if (finalPrice?.includes?.('error') || priceBeforeDiscount?.includes?.('error'))
              price = t(`user.ShoppingCart.TotalPricing.error`);
            return {
              interval: t(`common.Item.paymentIntervalValue.${i18nName}`),
              price,
            };
          },
        ),
    [
      oneOffSelected,
      oneOff,
      oneOffDiscounted,
      monthlySelected,
      monthly,
      monthlyDiscounted,
      isFixedMonthlyFeeType,
      yearlySelected,
      yearly,
      yearlyDiscounted,
      discount,
      t,
      isCategoryLevel,
    ],
  );

  return (
    <>
      <TableMemo
        className={cn('table-first-colum-min-width', classes.totalTable)}
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
};
export const useCalcCategoryTotalById = () => {
  const categories = useCategoriesContext();
  const { values } = useFormikContext();
  if (!values.showPrices) return null;
  const { cart, items, discounts, inputFields } = formikToResponse({ categories, values });
  const categoryTotalFn = (_id) => {
    try {
      const cartCategory = cart.find((c) => c._id === _id);
      const { oneOffDiscounted, oneOff, monthlyDiscounted, monthly, yearlyDiscounted, yearly } = Calc.calcCategoryTotal(
        {
          cartCategory,
          categories,
          items,
          discount: cartCategory.discountId && find(discounts, { _id: cartCategory.discountId }),
          inputFields,
          throwErrors: true,
          calcCategoryTotalById: categoryTotalFn,
          allCategoryIds: categories.map((c) => c._id),
        },
      );
      return { oneOffDiscounted, oneOff, monthlyDiscounted, monthly, yearlyDiscounted, yearly };
    } catch (e) {
      console.log(e);
      return 'eee';
    }
  };
  return categoryTotalFn;
};
const CategoryTotalTableNotMemo = () => {
  const category = useCategoryContext();
  const categories = useCategoriesContext();
  const { values } = useFormikContext();
  const calcCategoryTotalById = useCalcCategoryTotalById();
  const { oneOffDiscounted, oneOff, monthlyDiscounted, monthly, yearlyDiscounted, yearly } = calcCategoryTotalById(
    category._id,
  );
  if (!values.showPrices) return null;
  try {
    const discount = getDiscountForCategory({ category, values });
    const { cart, items } = formikToResponse({ categories, values });
    const cartCategory = cart.find((c) => c._id === category._id);
    const isFixedMonthlyFeeType = Cart.isCurrentFeeTypeMonthly(values.feeType);
    const oneOffSelected = isSelectedTypeItemInCategory({ items, cartCategory, type: 'oneOff' });
    const monthlySelected = isSelectedTypeItemInCategory({ items, cartCategory, type: 'monthly' });
    const yearlySelected = isSelectedTypeItemInCategory({ items, cartCategory, type: 'yearly' });

    return (
      <TotalTableComponent
        {...{
          oneOffDiscounted,
          oneOff,
          discount,
          monthlyDiscounted,
          monthly,
          yearlyDiscounted,
          yearly,
          yearlySelected,
          monthlySelected,
          oneOffSelected,
          isFixedMonthlyFeeType,
          isCategoryLevel: true,
        }}
      />
    );
  } catch (e) {
    console.error(e);
    return 'Please fix validation errors above to see price';
  }
};
export const CategoryTotalTable = memo(CategoryTotalTableNotMemo);

const OverallTotalTableNotMemo = () => {
  const categories = useCategoriesContext();
  const { values } = useFormikContext();
  if (!values.showPrices) return null;
  try {
    const { cart, items, discounts, inputFields } = formikToResponse({ categories, values });
    const { oneOffDiscounted, oneOff, monthlyDiscounted, monthly, yearly, yearlyDiscounted } = Calc.calcCartTotal({
      cart,
      categories,
      items,
      discounts,
      inputFields,
      throwErrors: true,
    });
    const isFixedMonthlyFeeType = Cart.isCurrentFeeTypeMonthly(values.feeType);

    const oneOffSelected = isSelectedTypeItemInCart({ items, cart, type: 'oneOff' });
    let yearlySelected = isSelectedTypeItemInCart({ items, cart, type: 'yearly' });
    const monthlySelected =
      (yearlySelected && isFixedMonthlyFeeType) || isSelectedTypeItemInCart({ items, cart, type: 'monthly' });

    let monthlyFinal = monthly;
    let monthlyDiscountedFinal = monthlyDiscounted;
    if (isFixedMonthlyFeeType) {
      monthlyFinal = (monthlyFinal || 0) + (yearly || 0) / 12;
      monthlyDiscountedFinal = (monthlyDiscountedFinal || 0) + (yearlyDiscounted || 0) / 12;
      yearlySelected = false;
    }
    return (
      <TotalTableComponent
        {...{
          oneOffDiscounted,
          oneOff,
          monthlyDiscounted: monthlyDiscountedFinal,
          monthly: monthlyFinal,
          yearly,
          yearlyDiscounted,
          monthlySelected,
          yearlySelected,
          oneOffSelected,
          isFixedMonthlyFeeType,
        }}
      />
    );
  } catch (e) {
    console.error(e);
    return 'Please fix validation errors above to see price';
  }
};
export const OverallTotalTable = memo(OverallTotalTableNotMemo);
