import gql from 'graphql-tag';
import { adminInputFieldFragment } from '../admin';

const userItemFragment = gql`
  fragment userItemFragment on CategoryItem {
    _id
    benefits
    infoText
    minPrice
    name
    notesToEmployee
    paymentInterval
    pleaseNote
    pricingFormula
    subTitle
    companyTypeIds
    recursiveFormulaInfo {
      _id
      usedItemIdsInFormula
      usedIdsInFormula
      moreInfo
    }
    scales {
      _id
      value
      pricingFormula
    }
    scaleTitle
    scalePricingFormulaTitle
    scalesEnabled
    inputFields {
      ...adminInputFieldFragment
    }
    pricingFormulaExtended {
      _id
      raw
      formatted
      inputFields {
        _id
        name
      }
    }
  }
  ${adminInputFieldFragment}
`;

export default userItemFragment;
