// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uRtFLeW7quvHboMHBZ0Pa {\n  word-break: break-all;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/table/CellTable.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".cell {\n  word-break: break-all;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "uRtFLeW7quvHboMHBZ0Pa"
};
export default ___CSS_LOADER_EXPORT___;
