import gql from 'graphql-tag';

const userInputFieldFragment = gql`
  fragment userInputFieldFragment on CategoryInputField {
    _id
    name
    type
    parentType
    parentId
    categoryId
    options {
      _id
      order
      value
      name
    }
  }
`;

export default userInputFieldFragment;
