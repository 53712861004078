import apollo from 'graphql/apollo';
import { isDiscountUsedInCartQuery } from 'graphql/queries';

const isDiscountUsedInCart = async ({ _id }) =>
  (
    await apollo.query({
      query: isDiscountUsedInCartQuery,
      variables: { _id },
      fetchPolicy: 'network-only',
    })
  ).data?.discountById?.usedInShoppingCart;
export default isDiscountUsedInCart;
