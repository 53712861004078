import gql from 'graphql-tag';

const GET_MY_EMAIL = gql`
  query getMyEmail {
    me {
      _id
      email
    }
  }
`;

export default GET_MY_EMAIL;
