import gql from 'graphql-tag';
import { adminDocumentTemplateListFragment } from 'graphql/fragments';

const CREATE_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation createDocumentTemplate($documentTemplate: CreateDocumentTemplateInput!, $isLibrary: Boolean) {
    createDocumentTemplate(documentTemplate: $documentTemplate, isLibrary: $isLibrary) {
      ...adminDocumentTemplateListFragment
    }
  }
  ${adminDocumentTemplateListFragment}
`;

export default CREATE_DOCUMENT_TEMPLATE_MUTATION;
