import gql from 'graphql-tag';
import { adminThemeFragment } from 'graphql/fragments';

const ADMIN_MT_THEME_QUERY = gql`
  query adminMyTheme {
    myTheme {
      ...adminThemeFragment
    }
  }
  ${adminThemeFragment}
`;

export default ADMIN_MT_THEME_QUERY;
