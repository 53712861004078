import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export const useDragNDrop = ({ record, rowIndex, moveMutation, itemTypes, moveMutationCache, extraDropProps = {} }) => {
  const ref = useRef(null);
  const [{ isDraggingInContainer }, drop] = useDrop({
    accept: itemTypes,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = rowIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveMutationCache(dragIndex, hoverIndex, item.id);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isDraggingInContainer: monitor.getItemType(),
    }),
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: itemTypes, id: record?._id, index: rowIndex, data: record },
    end: () => {
      const { order } = record;
      moveMutation({ _id: record._id, order, ...extraDropProps });
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return { drag, drop, preview, ref, isDragging, isDraggingInContainer };
};
