import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import s from './Footer.module.css';

const { Footer } = Layout;

export default function DefaultFooter() {
  const { t } = useTranslation();

  return <Footer className={s.footer}>{t('FooterComponent.copyright', { year: new Date().getFullYear() })}</Footer>;
}
