import gql from 'graphql-tag';
import { documentTemplateBlockDescriptorFragment } from 'graphql/fragments';

const documentTemplateBlockDescriptorsQuery = gql`
  query documentTemplateBlockDescriptors {
    documentTemplateBlockDescriptors {
      ...documentTemplateBlockDescriptorFragment
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default documentTemplateBlockDescriptorsQuery;
