import gql from 'graphql-tag';
import { adminTenantCompanyFragment } from 'graphql/fragments';

const ADMIN_TENANT_COMPANY = gql`
  query adminTenantCompany {
    getTenantCompany {
      ...adminTenantCompanyFragment
    }
  }
  ${adminTenantCompanyFragment}
`;

export default ADMIN_TENANT_COMPANY;
