// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3--1cv-ViwXGXq8L8DhQ03 {\n  display: inline-block;\n  position: relative;\n}\n._3--1cv-ViwXGXq8L8DhQ03 ._2byT12Hm2QRDy6LQTyApio {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n  cursor: not-allowed;\n}\n", "",{"version":3,"sources":["webpack://src/components/common/ButtonSupportOnClickWhenDisabled.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;AACF;AAHA;EAII,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;AAEJ","sourcesContent":[".button-container {\n  display: inline-block;\n  position: relative;\n  .above-button {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n    cursor: not-allowed;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-container": "_3--1cv-ViwXGXq8L8DhQ03",
	"above-button": "_2byT12Hm2QRDy6LQTyApio"
};
export default ___CSS_LOADER_EXPORT___;
