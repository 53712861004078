// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3AJy8CDFZ8SMz3nlbOcAmf {\n  display: flex;\n  flex-direction: column;\n}\n._27Qf874mArE7wum3-drm6Y {\n  padding: 10px;\n  margin: -10px;\n  user-select: none;\n  border-radius: 4px;\n  font-size: 20px;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/CatalogueConfiguration.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACA;EACE,aAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AACF","sourcesContent":[".categoryList {\n  display: flex;\n  flex-direction: column;\n}\n.categoryOptions {\n  padding: 10px;\n  margin: -10px;\n  user-select: none;\n  border-radius: 4px;\n  font-size: 20px;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryList": "_3AJy8CDFZ8SMz3nlbOcAmf",
	"categoryOptions": "_27Qf874mArE7wum3-drm6Y"
};
export default ___CSS_LOADER_EXPORT___;
