import gql from 'graphql-tag';

const adminPdfComponentThemeFragment = gql`
  fragment adminPdfComponentThemeFragment on PdfComponentTheme {
    fontFamily
    color
    fontSize
    lineHeight
    marginBottom
    marginTop
  }
`;

export default adminPdfComponentThemeFragment;
