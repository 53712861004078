import { memo, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import BlockPreviewContent from './BlockPreviewContent';
import DocumentLayoutBlockForm from './DocumentLayoutBlockForm';

const i18nPrefix = 'admin.DocumentTemplateConfigurationPage.layoutSection';

function DocumentLayoutBlockContent({ block, getDocumentTemplate }) {
  const { t } = useTranslation();
  const { descriptor: blockDescriptor } = block;

  // in case of tabs we need to change the preview image
  const [previewBlockDescriptor, setPreviewBlockDescriptor] = useState(blockDescriptor);
  return (
    <div>
      {blockDescriptor ? (
        <Row gutter={[10, 10]}>
          <Col sm={24} md={16}>
            <DocumentLayoutBlockForm
              blockId={block._id}
              form={blockDescriptor.form}
              initialValues={block.props}
              getDocumentTemplate={getDocumentTemplate}
              setPreviewBlockDescriptor={setPreviewBlockDescriptor}
            />
          </Col>
          <Col sm={24} md={8}>
            <BlockPreviewContent
              hideBlockName
              title={t(`${i18nPrefix}.infoSectionTitle`)}
              block={previewBlockDescriptor}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}
export default memo(DocumentLayoutBlockContent);
