import React from 'react';
import { SubmitButton, Input, Form } from 'formik-antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import useLogin from 'hooks/auth/useLogin';
import * as Yup from 'yup';
import routePaths from 'router/route-paths';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import I18nFormik from 'components/common/I18nFormik';

const ForgotPasswordButton = () => {
  const { t } = useTranslation();
  return (
    <Link className="grey-link" to={routePaths.resetPassword}>
      {t('Login.resetPassword')}
    </Link>
  );
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .label('Email')
    .email()
    .required(),
  password: Yup.string()
    .label('Password')
    .min(6)
    .max(64)
    .required(),
});

const initialValues = {
  email: '',
  password: '',
};

export default function LoginForm() {
  const login = useLogin();
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async ({ email, password }, formik) => {
    try {
      formik.setSubmitting(true);
      await login({ email, password });
      formik.resetForm();
      history.replace(routePaths.home);
    } catch (error) {
      console.error(error);
      formik.setErrors(
        setGraphqlErrors({
          error,
          errorResolver: (e) => {
            if (e.includes('User not found')) return ['email', e];
            if (e.includes('IncorrectPasswordError')) return ['password', 'Password is incorrect'];
            return undefined;
          },
        }),
      );
    } finally {
      formik.setSubmitting(false);
    }
  };
  return (
    <I18nFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginSchema}>
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItem name="email" label={t('Login.inputs.email.label')}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            name="email"
            placeholder={t('Login.inputs.email.placeholder')}
            autoComplete="username"
          />
        </FormItem>
        <FormItem name="password" label={t('Login.inputs.password.label')}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            placeholder={t('Login.inputs.password.placeholder')}
            autoComplete="current-password"
          />
        </FormItem>
        <SubmitButton className="margin-right-16">{t('Login.submit')}</SubmitButton>
        <ForgotPasswordButton />
        {/* <pre style={{ flex: 1 }}>
            <FormikDebug />
          </pre> */}
      </Form>
    </I18nFormik>
  );
}
