export default {
  translation: {
    BackendErrors: {
      category: {
        categoryNotFound: 'Category not found',
      },
      changesWereNotSaved: {
        unknownError: 'The changes were not saved due to unknown error. Please try again.',
      },
      documentTemplate: {
        documentTemplateIsUsed: 'Document template is used in existing shopping carts',
      },
      permissionDenied: 'Permission denied',
      usedInFormula: 'Used in formula',
      user: {
        noValidToken: 'Not valid token',
        passwordIsNotValidOrExpired: 'One-time password is not valid or expired. Please try again',
        userHasNoPasswordSet: 'User has no password set',
        userNotFound: 'User not found',
        userNotFoundForEmail: 'User not found for email {{email}}',
      },
    },
    CurrentUserDropdown: {
      locale: 'Locale: <1>$t(locale)</1>',
      loggedInAs: 'Logged in as <1>{{name}}</1>',
      logout: 'Logout',
      noUserName: 'No name',
      role: 'Role: <1>{{role}}</1>',
    },
    FooterComponent: {
      copyright: '© KanzleiPilot {{year}}',
    },
    InputErrors: {
      phoneNumber: {
        noCode: 'Code is a required field',
        noPhone: 'Phone is a required field',
      },
    },
    Login: {
      inputs: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
      },
      resetPassword: 'I forgot my password',
      submit: 'Submit',
      title: 'Login',
    },
    NavBar: {
      accountSetting: {
        title: '$t(user.Setting.AccountSetting.title)',
      },
      catalogueConfiguration: {
        title: '$t(admin.CatalogueConfiguration.title) ',
      },
      commonLibrary: {
        catalogueCommonLibrary: {
          title: 'Common library catalogue configuration',
          titleSort: 'Catalogue configuration',
        },
        documentCommonLibrary: {
          title: 'Common library document templates',
          titleSort: 'Document template',
        },
        title: 'Common library',
      },
      companyTenant: {
        title: '$t(admin.Setting.CompanyTenant.title)',
      },
      corporateIdentity: {
        title: '$t(admin.Setting.CorporateIdentity.title)',
      },
      createShoppingCart: {
        title: '$t(ShoppingCartEntriesPage.createShoppingCart)',
        tooltip: '$t(ShoppingCartEntriesPage.createShoppingCart)',
      },
      documentTemplateListPage: {
        title: '$t(admin.DocumentTemplateListPage.title)',
      },
      logo: {
        tooltip: 'Logo',
      },
      shoppingCartEntries: {
        title: '$t(ShoppingCartEntriesPage.title)',
        tooltip: '$t(ShoppingCartEntriesPage.title)',
      },
    },
    ResetPassword: {
      buttons: {
        changePassword: 'Change password',
        goToLogin: 'Go back to login',
        verify: 'Verify',
      },
      enterEmail: "Please enter the email address you'd like your password reset information sent to.",
      errors: {
        noMatch: 'Passwords must match',
      },
      fields: {
        email: 'Email',
        newPassword: 'New password',
        otp: 'One Time Password',
        passwordConfirmation: 'Password confirmation',
      },
      steps: {
        email: 'Enter email',
        setNewPassword: 'Set new password',
        verification: 'Enter one time password',
      },
      title: 'Reset Password',
      verificationDescription:
        'A verification message has been sent to your email address {{email}}. Enter the verification code for the otp input. If the message does not arrive within 5 minutes, you can resend it.',
    },
    ShoppingCartEntriesPage: {
      catalogue_configuration_button: 'Catalogue configuration',
      createShoppingCart: 'Create a shopping cart',
      entriesTable: {
        columnTitles: {
          actions: 'Actions',
          companyIdentifier: 'Company Identifer',
          companyName: 'Company name',
          contact: 'Contact',
          createdAt: 'Created at',
          relatedContacts: 'Related contact(s)',
          valueMonthly: 'Value Monthly',
          valueOneOff: 'Value One-Off',
          valueYearly: 'Value Yearly',
        },
      },
      search: 'Filter shopping carts by typing in your company identifier, company name, or related contacts',
      searchTitle: 'Search shopping carts',
      title: 'Shopping cart entries',
    },
    admin: {
      CatalogueConfiguration: {
        ConfigureCategoryProperties: {
          properties: {
            conditionalVisibility: {
              bodyTitle: 'Show this category (in shopping cart) if companyBookingType equals:',
              title: 'Conditional Visibility',
            },
            discounts: {
              title: 'Discounts',
            },
            inputFields: {
              title: 'Input Fields',
            },
            items: {
              title: 'Items',
            },
            staticItems: {
              title: 'Static Items',
            },
            texts: {
              title: 'Texts',
            },
          },
          title: 'Configure category properties',
        },
        addCategory: 'Add category',
        collapseOptions: {
          remove: 'Remove category',
        },
        discounts: {
          title: 'Discounts',
        },
        emptyCategoryListMessage: 'There are no categories yet. Create one by clicking the "Add category" button',
        emptyDiscountListMessage: 'There are no discounts yet. Create one by clicking the "Add discount" button',
        emptyInputFieldListMessage:
          'There are no input fields yet. Create one by clicking the "Add input field" button',
        emptyInputFieldOptionListMessage:
          'There are no input field options yet. Create one by clicking the "Add option" button',
        emptyItemListMessage: 'There are no items yet. Create one by clicking the "Add item" button',
        importCategoryPresets: 'Import category-presets',
        title: 'Catalogue configuration',
        titleCommonLibrary: 'Common library',
      },
      DocumentTemplateConfigurationPage: {
        addBlockModal: {
          addToBottomButton: 'add to bottom',
          addToTopButton: 'add to top',
          availableBlocksTitle: 'Available Blocks',
          blockActionsColumnTitle: 'Actions',
          blockNameColumnTitle: 'Name',
          blockPreviewTitle: 'Block Preview',
          title: 'Add a block to document template',
        },
        backTitle: 'Back to Document Templates',
        deleteBlockConfirmation: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title:
            'Do you want to remove this block? Warning: your custom configuration for the block (texts, settings, etc.) will be lost.',
        },
        generalInformationSection: {
          descriptionInputLabel: 'Description',
          lastUsedDocumentNr: 'Last used number: {{n}}',
          nameInputLabel: 'Name',
          nrOfDocumentsCreated: '{{count}} document created',
          nrOfDocumentsCreated_plural: '{{count}} documents created',
          title: 'General information',
        },
        layoutSection: {
          addBlockButton: 'Add Block',
          emptyBlockList:
            'There are no blocks in your document template. Please use "Add Block" button above to add blocks',
          infoSectionTitle: 'Info',
          title: 'Layout',
        },
        title: 'Edit Document Template',
      },
      DocumentTemplateListPage: {
        addDocumentTemplateButton: 'Add Document Template',
        createdAt: 'Created {{date}}',
        emptyListMessage: 'There are no document templates yet.',
        errorMessageInModal: 'Categories with such names {{categoryNames}} have already been added to the list',
        importFromLibraryButton: 'Import from library',
        importFromLibraryModalTitle: 'Import Document Templates from Common Library',
        lastUsedDocumentNr: 'Current #:{{n}}',
        nrOfDocumentsCreated: '{{count}} document created',
        nrOfDocumentsCreated_plural: '{{count}} documents created',
        title: 'Document Templates',
        titleCommonLibrary: 'Common Library Document Templates',
      },
      Placeholders: {
        client: {
          label: 'Client',
        },
        contact: {
          label: 'Contact',
        },
        contactCity: {
          label: 'Contact city',
        },
        contactName: {
          label: 'Shopping cart contact full name',
        },
        currentDate: {
          label: 'Current date',
        },
        currentUserEmail: {
          label: 'Current user email',
        },
        document: {
          label: 'Document',
        },
        documentCreatedAt: {
          label: 'Document creation date',
        },
        documentNumber: {
          label: 'Document number',
        },
        environment: {
          label: 'Environment',
        },
        shoppingCartCompanyCity: {
          label: 'Shopping cart company city',
        },
        shoppingCartCompanyOrFullname: {
          label: 'Shopping cart company name or full name',
        },
        tenantCompany: {
          label: 'Our own company',
        },
        tenantCompanyCity: {
          label: 'Our company city',
        },
        tenantCompanyCityCode: {
          label: 'Our company city code',
        },
        tenantCompanyCountry: {
          label: 'Our company country',
        },
        tenantCompanyFullAddress: {
          label: 'Our company full address',
        },
        tenantCompanyHouseNumber: {
          label: 'Our company house number',
        },
        tenantCompanyName: {
          label: 'Our company name',
        },
        tenantCompanyPhoneNumber: {
          label: 'Our company phone number',
        },
        tenantCompanyStreet: {
          label: 'Our company street',
        },
        tenantCompanyWebsite: {
          label: 'Our company website url',
        },
      },
      RichEditor: {
        toolbar: {
          link: {
            createModalName: 'Link name',
            createModalTitle: 'Add a link',
            createModalValue: 'Link URL',
            modalValueInvalidError: 'Link URL must be a valid URL',
            modalValueTooltipHTML:
              '<div><div>Example:</div><div>https://google.com - insert a web site link</div><div>mailto:someone@gmail.com - insert a create email link</div><div>tel:+49123456789 - insert a phone number</div></div>',
          },
        },
      },
      Setting: {
        CompanyTenant: {
          inputs: {
            city: {
              title: 'City',
            },
            cityCode: {
              title: 'City code',
            },
            country: {
              title: 'Country',
            },
            houseNumber: {
              title: 'House number',
            },
            name: {
              title: 'Name',
            },
            phoneNumber: {
              title: 'Phone number',
            },
            street: {
              title: 'Street',
            },
            website: {
              title: 'Website',
            },
          },
          title: 'Your Company Data',
        },
        CorporateIdentity: {
          BodyPrimary: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              bodyPrimary: 'Body primary',
              bodyPrimaryFooter: 'Body primary footer',
            },
            title: 'Body Primary',
          },
          CorporatePrimaryColor: {
            inputs: {
              color: 'Primary Contrast Color',
              fontFamily: 'Font Family',
              primaryColor: 'Primary Color',
            },
            preview: 'Preview of button (Primary+Contrast)',
            title: 'Corporate Primary Color',
          },
          TableOfContent: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            title: 'Table of content',
          },
          TableTheme: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              table: 'Table',
              tableContent: 'Table content',
              tableFooter: 'Table footer',
              tableHeaderPrimary: 'Table header primary',
              tableHeaderSecondary: 'Table header secondary',
              tableLeftColumn: 'Table left column',
            },
            title: 'Styles for Table',
          },
          TextStylesForDocuments: {
            field: {
              color: 'Color',
              fontFamily: 'Font',
              fontSize: 'Font Size',
              lineHeight: 'Line Height',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              h1: 'H1',
              h2: 'H2',
              h3: 'H3',
              testimonial: 'Testimonial',
              testimonialSmallText: 'Testimonial small text',
              textBlock: 'Text-block',
              title: 'Title',
            },
            title: 'Text Styles for Documents',
          },
          applyStyles: 'Apply Styles',
          errors: {
            reset: 'Oops. There was a problem resetting the theme',
          },
          inputs: {
            font: {
              placeholder: 'Select existing font',
            },
          },
          reset: 'Reset',
          resetConfirmModalMessage:
            'Danger: Are you sure you want to reset CI to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          title: 'Corporate Identity',
        },
      },
      addCategoryModal: {
        addModalTitle: 'Create a new category',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Category with the same name already exists',
        nameFieldDescription: 'Category name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addDiscountButton: 'Add Discount',
      addDocumentTemplateModal: {
        addModalTitle: 'Create a new document template',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Document template with the same name already exists',
        nameFieldDescription: 'Document name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addInputButton: 'Add input field',
      addItemButton: 'Add Item',
      addStaticItemButton: 'Add static item',
      categoryNameInput: {
        label: 'Name',
      },
      categoryTexts: {
        introForDocument: {
          label: 'Intro for document',
        },
        libraryDescription: {
          label: 'Description',
        },
        name: {
          label: 'Name',
        },
      },
      deleteCategory: 'Delete Category',
      deleteCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the category?',
      },
      deleteDiscountConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the discount?',
      },
      deleteInputConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the input field?',
      },
      deleteItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the item?',
      },
      deleteStaticItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the static item?',
      },
      discountFieldModal: {
        addModalTitle: 'Create a new Discount',
        duplicatedErrorMessage: 'Discount with the same name already exists',
        editModalTitle: 'Edit Discount',
        nameFieldDescription: 'Discount name',
        nameFieldLabel: 'Name',
        typeFieldAbsolute: '$t(common.Discount.absolute)',
        typeFieldLabel: 'Type',
        typeFieldPercent: '$t(common.Discount.percent)',
        valueFieldDescription: 'Discount value',
        valueFieldLabel: 'Value',
      },
      discountTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
        value: 'Value',
      },
      discountUsedInShoppingCartConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        titleDelete: 'Discount is used in existing shopping carts. Remove?',
        titleUpdate: 'Discount is used in existing shopping carts. Update?',
      },
      discountsTitle: 'Discounts',
      importCategoryPresetsModal: {
        errorMessage: 'Categories with such names {{categoryNames}} have already been added to the list',
        title: 'Import Category-Presets from Common Library',
      },
      inputFieldModal: {
        addModalTitle: 'Create a new Input Field',
        cancel: '$t(common.cancel)',
        comboOptions: {
          addOption: 'add Option',
          boxLabel: 'Combobox options',
          modal: {
            addTitle: 'Create new option',
            cancel: '$t(common.cancel)',
            duplicatedErrorMessage: 'Input field option with the same name already exists',
            editTitle: 'Edit option',
            nameFieldDescription: 'Input Field option name',
            nameFieldLabel: 'Name',
            ok: 'Ok',
            valueFieldDescription: 'Input Field option value',
            valueFieldLabel: 'Value',
          },
          table: {
            actionsColumnLabel: '',
            deleteOptionConfirmation: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Are you sure you want to remove the option?',
            },
            nameColumnLabel: 'Name',
            valueColumnLabel: 'Value',
          },
        },
        duplicatedErrorMessage: 'Input Field with the same name already exists',
        editModalTitle: 'Edit Input Field',
        nameFieldDescription: 'Input Field name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
        typeFieldLabel: 'Type',
      },
      inputFieldTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
      },
      inputsTitle: 'Input Fields',
      itemModal: {
        addTitle: 'New item',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Item with the same name already exists',
        editSubTitleForItem: 'Edit item {{itemName}}',
        editTitle: 'Edit item',
        inputs: {
          benefits: {
            label: 'Benefits',
          },
          infoText: {
            label: 'Info',
          },
          minPrice: {
            label: 'Minimal Price',
          },
          name: {
            label: 'Name',
            placeholder: '',
          },
          notesToEmployee: {
            label: 'Notes to employee',
          },
          paymentInterval: {
            label: 'Payment Interval',
            monthlyLabel: '$t(common.Item.paymentIntervalValue.monthly)',
            oneOffLabel: '$t(common.Item.paymentIntervalValue.oneOff)',
            yearlyLabel: '$t(common.Item.paymentIntervalValue.yearly)',
          },
          pleaseNote: {
            label: 'Please note',
          },
          pricingFormula: {
            errors: {
              invalid: 'Pricing Formula is invalid',
            },
            insertVariableMenu: {
              subMenu: {
                category: 'Inputs from category',
                categoryMonthlyAdvance: 'Categories total sum',
                categoryTotal: 'Total value from category',
                function: 'Functions',
                item: 'Inputs from position',
                itemTotal: 'Total value from item',
                scaleFunctionParameters: 'Scale function parameters',
                variable: 'Variables',
              },
              title: 'Insert Placeholder',
            },
            label: 'Pricing Formula',
          },
          scales: {
            addModalLabel: 'Create a new Scale',
            atLeastTwoError: 'There must be at least two scales',
            cancel: '$t(common.cancel)',
            deleteModal: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Are you sure you want to remove the Scale?',
            },
            duplicatedError: 'Each scale must be unique',
            duplicatedErrorMessage: 'Scale with the same name already exists',
            editModalLabel: 'Edit Scale',
            emptyScaleList:
              'There are no scales yet. Create one by clicking the "$t(admin.itemModal.inputs.scales.addModalLabel)" button',
            emptyTable:
              'There are no scales yet. Create one by clicking the "$t(admin.itemModal.inputs.scales.addModalLabel)" button',
            inputs: {
              formula: {
                label: 'scale formula',
              },
              pricingFormulaTitle: {
                label: 'Pricing formula title',
                placeholder: 'Pricing formula title (for PDF display)',
              },
              scaleTitle: {
                label: 'Scale title',
                placeholder: 'Scale title (for PDF display)',
              },
              value: {
                label: 'scale value',
              },
            },
            label: 'Configure "Pricing Scales" function?',
            ok: 'Ok',
            pdfConfigurationLabel: 'PDF configuration',
            table: {
              columns: {
                pricingFormula: 'PricingFormula for Scale',
                value: 'Scale',
              },
            },
            wrapperLabel: '"Pricing Scales" function',
          },
          scalesList: {
            label: 'Scales list',
          },
          subTitle: {
            label: 'Subtitle',
            placeholder: '',
          },
        },
        ok: 'Ok',
        tabs: {
          conditionalVisibility: 'Conditional Visibility',
          inputs: 'Input Fields',
          price: 'Price',
          texts: 'Texts',
        },
      },
      itemTable: {
        columns: {
          actions: '',
          name: 'Name',
          paymentInterval: 'Payment Interval',
          pricingFormula: 'Pricing Formula',
        },
        title: 'Items',
      },
      pricingFormulaInput: {
        functions: {
          StBVV_TabelleA: 'StBVV_TabelleA(nr or formula) - p.e. "StBVV_TabeleA(140000)" will be 1773',
          StBVV_TabelleB: 'StBVV_TabelleB(nr or formula) - p.e. "StBVV_TabeleB(5000)" will be 81',
          StBVV_TabelleC: 'StBVV_TabelleC(nr or formula) - p.e. "StBVV_TabeleC(45000)" will be 122',
          round: 'round(nr or formula) - p.e. "round(7.8)" will round up to 8',
          scale:
            'scale(nr or formula) - p.e. "scale(342, 23)" will pass parameters 342 and 23 as inputValue1 and inputValue2 into Pricing Scales function defined in this page below',
        },
        functionsListText: 'The following functions are available:',
        howUseButton: 'Learn how to use',
        inputsListText: 'The following Input Fields are available:',
        modalInfo: {
          helpText: '',
          title: 'How to use pricing formula input',
          videoCaption: '',
          videoUrl: '',
        },
        syntaxInstructions:
          'Operators: +, -, /, *, %, ^\nType a "@" to insert an input field\nType a "#" to insert a function',
      },
      staticItemsFieldModal: {
        addModalTitle: 'Create a new static item',
        duplicatedErrorMessage: 'Static item with the same name already exists',
        editModalTitle: 'Edit Static item',
        nameFieldDescription: 'Static item name',
        nameFieldLabel: 'Name',
        valueFieldDescription: 'Static item value',
        valueFieldLabel: 'Value',
      },
      staticItemsTableColumns: {
        actions: '',
        name: 'Name',
        value: 'Value',
      },
      staticItemsTitle: 'Static Items',
      textsTabSubmitButton: 'Save changes',
      textsTabTitle: 'Texts',
      textsTitle: 'Texts',
    },
    common: {
      ConditionalVisibility: {
        balanceOfAccountsGmbH: 'Balance of accounts - GmbH',
        balanceOfAccountsGmbHAndCoKg: 'Balance of accounts - GmbH & Co. Kg',
        balanceOfAccountsGmbHAndCoKgSelfBooker: 'Balance of accounts - GmbH & Co. Kg (self-booker)',
        balanceOfAccountsGmbHSelfBooker: 'Balance of accounts - GmbH (self-booker)',
        balanceOfAccountsOther: 'Balance of accounts - other',
        balanceOfAccountsOtherSelfBooker: 'Balance of accounts - other (self-booker)',
        balanceOfAccountsSingle: 'Balance of accounts - single',
        balanceOfAccountsSingleSelfBooker: 'Balance of accounts - single (self-booker)',
        cashBasisAccountingCapital: 'Cash basis accounting - capital',
        cashBasisAccountingCapitalSelfBooker: 'Cash basis accounting - capital (self-booker)',
        cashBasisAccountingSingle: 'Cash basis accounting - single',
        cashBasisAccountingSingleSelfBooker: 'Cash basis accounting - single (self-booker)',
        checkAll: 'Check all',
        privatePerson: 'Private person',
        uncheckAll: 'Uncheck all',
      },
      Discount: {
        absolute: 'absolute',
        percent: 'percent',
      },
      Formula: {
        categoryMonthlyAdvance: 'categoryTotalMonthlyAdvance.{{categoryName}}.monthlyAdvancePrice',
        categoryType: 'category',
        itemTotal: 'itemTotal',
        itemType: 'item',
      },
      InputField: {
        combo: 'combobox',
        input: 'input',
        name: 'Input field',
      },
      Item: {
        paymentIntervalValue: {
          monthly: 'monthly',
          monthlyFixPrice: 'fixed monthly price',
          oneOff: 'one-off',
          yearly: 'yearly',
        },
        recursive: "This item's formula includes items that reference this item (recursive reference)",
        scaleError: 'The formula references scale function which needs to be defined below',
      },
      buttons: {
        resendVerificationEmail: 'Resend verification email',
      },
      cancel: 'Cancel',
      company: {
        addCompany: 'Add company',
        changeCompany: 'Change Company',
        editCompany: 'Edit Company',
      },
      confirm: '$t(common.yes)',
      contact: {
        addContact: 'Add contact',
        deleteContact: 'Delete contact',
        editContact: 'Edit contact',
      },
      error: 'Error',
      inputs: {
        otp: {
          title: 'One Time Password',
        },
      },
      off: 'Off',
      ok: 'Ok',
      on: 'On',
      success: 'Success!',
      successToastText: '$t(common.success)',
      toast: {
        success: {
          changePassword: 'Password changed successfully',
          clipboard: 'Copied to clipboard',
          sendChangeEmailVerificationEmail:
            'Please check your email, {{email}}, for further instructions on changing your email.',
          sendChangePasswordVerificationEmail:
            'Please check your email, {{email}}, for further instructions on resetting your password.',
        },
      },
      yes: 'Yes',
    },
    decimalSeparator: '.',
    deleteDocumentTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed: 'Documents exist using this document. Are you sure that you want to remove it?',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the document template?',
    },
    deleteShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the shopping cart?',
    },
    locale: 'English',
    localeCode: 'en',
    sharedPackage: {
      discounted_no_value:
        '{{finalPrice}} {{paymentInterval}}{{newlineOrWhitespace}}<i>(instead of {{priceBeforeDiscount}} due to discounts)</i>',
      discounted_with_value:
        '{{finalPrice}} {{paymentInterval}}{{newlineOrWhitespace}}discounted by {{discount}} <i>(original price <del>{{priceBeforeDiscount}}</del>)</i>',
      empty_pricing_formula: 'empty pricing formula NOT allowed',
      free_service: 'free {{paymentInterval}} service',
      not_discounted: '{{finalPrice}} {{paymentInterval}}',
      variable_is_empty: '{{variable}} is empty',
    },
    user: {
      Setting: {
        AccountSetting: {
          tabs: {
            main: {
              changeEmail: 'Change Email',
              fields: {
                email: 'Email',
                firstName: 'First name',
                lastName: 'Last name',
                newEmail: 'New email',
                otp: 'One Time Password',
                phone: 'Phone',
                position: 'Position',
              },
              saveEmail: 'Save Email',
              title: 'Main',
            },
            password: {
              errors: {
                noMatch: 'Passwords must match',
              },
              fields: {
                currentPassword: 'Current password',
                newPassword: 'New password',
                otp: 'One Time Password',
                passwordConfirmation: 'Password confirmation',
              },
              savePassword: 'Save password',
              title: 'Password',
            },
          },
          title: 'Edit Account',
        },
      },
      ShoppingCart: {
        Buttons: {
          email: 'Send Email',
          errors: {
            formHasErrors: 'There are validation-errors in the form. Please correct',
            noCompanyError: 'Please add company to enable those buttons',
            noContactsError: 'Please add contact to enable those buttons',
            noItemsSelectedError: 'Please select items to enable those buttons',
          },
          html: 'Preview of delivery slip HTML',
          pdf: 'Preview of delivery slip PDF',
          store: 'Store to db',
          submitRevisionModal: {
            newButton: 'New shopping cart',
            revisionButton: 'Revision',
            title: 'Create a new shopping cart or revision of this cart',
          },
          title: "Let's do it!",
        },
        Category: {
          Discount: {
            label: 'Select Discount',
          },
          InputField: {
            mustBeANumberMessage: '{{name}} must be a number',
            requiredMessage: 'Please enter value in {{name}}',
          },
          Item: {
            fixedMonthlyFeeHint:
              'Price is included in "fixed monthly price" listed below in "Final Price Preview" section',
            minPrice: 'Minimal Price: {{minPrice}}',
            paymentInterval: 'Payment interval: $t(common.Item.paymentIntervalValue.{{paymentInterval}})',
            pricingFormula: 'Pricing formula: {{formula}}',
            referenceError: {
              referenceIsSelectedButInvalid:
                'Please have a look at {{itemName}}. It is invalid and the price calculation depends on it. Please solve.',
              referenceNotSelectedAndInvalid:
                'Please have a look at {{itemName}}. Select it and fill in the input-values.',
              referenceNotSelectedButValid:
                'Please have a look at {{itemName}}. The price-calculation of this item depends on it and it needs to be selected',
            },
            requiredFieldsAlertText: 'These fields are required',
            zeroNotAllowedError: 'No 0 values allowed',
          },
          Total: {
            label: 'Category total price preview',
          },
          startOfService: 'start of service',
          startOfServiceRequired: 'Start of service is required',
        },
        CompanyType: {
          confirmChange:
            'Are you sure to change selecting? If you change then existing selections will be lost. Change selection?',
          inputs: {
            companyType: {
              label: 'What is your company type?',
              placeholder: 'Select company type',
            },
            selfBooker: 'Are you a self-booker?',
          },
          noTypes: 'No types',
          title: 'Company Type',
        },
        ContactData: {
          buttons: {
            addCompany: '$t(common.company.addCompany)',
            addContact: '$t(common.contact.addContact)',
            changeCompany: '$t(common.company.changeCompany)',
          },
          company: {
            addExistingCompanyTab: 'Add existing company',
            card: {
              actions: {
                change: '$t(common.company.changeCompany)',
                edit: '$t(common.company.editCompany)',
              },
              address: 'Address',
              identifer: 'Identifer',
            },
            createNewCompanyTab: 'Create new company',
            duplicatedErrorMessage: 'Company with the same name already exists',
            inputs: {
              city: {
                label: 'City',
                placeholder: 'City',
              },
              cityCode: {
                label: 'Citycode',
                placeholder: 'Citycode',
              },
              company: {
                label: 'Company',
                placeholder: 'Search existing company-name',
              },
              country: {
                label: 'Country',
                placeholder: 'Country',
              },
              houseNumber: {
                label: 'House number',
                placeholder: 'Housenr',
              },
              identifer: {
                label: 'Company identifer',
                placeholder: 'Company identifer',
              },
              name: {
                company: {
                  label: 'Company name',
                  placeholder: 'Company name',
                },
                individual: {
                  label: 'Account name',
                  placeholder: 'Account name',
                },
              },
              street: {
                label: 'Street',
                placeholder: 'Street',
              },
              type: {
                company: 'Company',
                individual: 'Single Person',
              },
            },
          },
          contact: {
            addExistingContactTab: 'Add existing contact',
            createNewContactTab: 'Create new contact',
            duplicatedErrorMessage: {
              contact: 'Contact is already imported',
              email: 'Contact with the same email already exists',
            },
            empty: 'no contacts',
            inputs: {
              contact: {
                label: 'Contact',
                placeholder: 'Search existing contact',
              },
              email: {
                label: 'E-mail Address',
                placeholder: 'E-mail Address',
              },
              firstName: {
                label: 'First name',
                placeholder: 'First name',
              },
              lastName: {
                label: 'Last name',
                placeholder: 'Last name',
              },
              phoneNumber: {
                label: 'Phone-Number business',
                placeholder: 'Phone-Number business',
              },
              phoneNumberAlternative: {
                label: 'Phone-Number alternative',
                placeholder: 'Phone-Number alternative',
              },
              position: {
                label: 'Position',
                placeholder: 'Position',
              },
              salutation: {
                label: 'Salutation',
                options: {
                  Mr: 'Mr',
                  Mrs: 'Mrs',
                  Ms: 'Ms',
                },
                placeholder: 'Mr',
              },
            },
            table: {
              email: 'Email',
              name: 'Name',
              phones: {
                phoneNumber: 'Phone 1',
                phoneNumberAlternative: 'Phone 2',
                title: 'Phones',
              },
              position: 'Position',
            },
          },
          contactDetailsLabel: 'E-Mail-Address',
          label: 'Contact Data',
          modal: {
            cancel: '$t(common.cancel)',
            ok: '$t(common.ok)',
            titles: {
              addCompany: '$t(common.company.addCompany)',
              addContact: '$t(common.contact.addContact)',
              changeCompany: '$t(common.company.changeCompany)',
              deleteContact: '$t(common.contact.deleteContact)',
              editCompany: '$t(common.company.editCompany)',
              editContact: '$t(common.contact.editContact)',
            },
          },
        },
        Discounts: {
          activeDiscountsLabel: 'Active Discounts list',
          activeDiscountsListLabel: 'The following discounts are active:',
        },
        DocumentTypes: {
          label: 'Select Document-types to generate',
          typesSelectLabel: 'Which documents should be generated?',
        },
        DocumentTypesTable: {
          actionsHeader: 'Actions',
          nameHeader: 'Document-type',
        },
        TotalPricing: {
          TotalTable: {
            headers: {
              interval: 'interval',
              price: 'price',
            },
          },
          error: 'Please fix validation errors above to see price',
          fixedMonthlyFeeLine_monthly:
            '{{nrOfSelectedItems}} monthly items are selected and are included in the monthly fixed price',
          fixedMonthlyFeeLine_yearly:
            '{{nrOfSelectedItems}} yearly items are selected and are included in the monthly fixed price',
          fixedMonthlyFeeTypeHint:
            'Prices for selected Monthly and Yearly items are not shown here. They are included in the "fixed monthly price" listed below in "Final Price Preview" section',
          label: 'Total Pricing',
        },
        buttonLabel: 'Shopping Cart Options',
        categoriesCardTitle: 'Select your categories and items',
        emptyCategoryListMessage: 'There are no categories yet.',
        options: {
          cancel: '$t(common.cancel)',
          debugMode: 'debug mode',
          fixedMonthlyFee: 'Fixed monthly fee',
          hiddenNote: {
            placeholder: 'Note area',
            title: 'Hidden note area',
          },
          hideHiddenNote: 'Hide hidden note',
          ok: 'Ok',
          showDiscounts: 'show discounts',
          showHiddenNote: 'Show hidden note',
          showPrices: 'show prices (of category and items, including discounted prices)',
          standardFee: 'Standard fee',
          switch: {
            off: '$t(common.off)',
            on: '$t(common.on)',
          },
          title: 'Options',
        },
        somethingChangedWhenOpenedEdit: 'Something has changed in the cart, displaying new data',
        title: 'Shopping Cart',
      },
    },
  },
};
