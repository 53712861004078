import apollo from 'graphql/apollo';
import { updateCompanyMutation } from 'graphql/mutations';

const updateCompany = async ({ _id, type, name, identifer, street, houseNumber, cityCode, city, country }) =>
  apollo.mutate({
    mutation: updateCompanyMutation,
    variables: {
      _id,
      modifier: {
        type,
        name,
        identifer,
        address: {
          street,
          houseNumber,
          cityCode,
          city,
          country,
        },
      },
    },
  });

export default updateCompany;
