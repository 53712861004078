import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ADMIN_DOCUMENT_TEMPLATE_LIST_QUERY from 'graphql/queries/adminDocumentTemplateList';
import { grabFirstGQLDataResult } from 'utils/helpers';

const useDocumentTemplateList = (isLibrary) => {
  const { data, ...rest } = useQuery(ADMIN_DOCUMENT_TEMPLATE_LIST_QUERY, {
    variables: { isLibrary },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    const documentTemplates = grabFirstGQLDataResult(data) || [];
    return {
      data: Array.isArray(documentTemplates)
        ? documentTemplates.map((row) => ({
            ...row,
            createdAt: new Date(row.createdAt),
          }))
        : [],
      ...rest,
    };
  }, [data, rest]);
};

export default useDocumentTemplateList;
