import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormItem';
import I18nFormik from 'components/common/I18nFormik';
import { Form, Input, SubmitButton } from 'formik-antd';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import toast from 'utils/toast';
import * as Yup from 'yup';
import useResetPassword from 'hooks/auth/useChangePassword';
import { useCallback, useState } from 'react';
import { Button } from 'antd';
import { forgotPassword } from 'graphql/methods';
import { LockOutlined } from '@ant-design/icons';
import { useResetContext } from './context';
import LoginButton from './LoginButton';

const setNewPasswordSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required()
      .min(8)
      .label(i18n.t('ResetPassword.fields.newPassword')),
    passwordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('newPassword'), null], i18n.t('ResetPassword.errors.noMatch'))
      .min(8)
      .label(i18n.t('ResetPassword.fields.passwordConfirmation')),
    otp: Yup.string()
      .required()
      .label(i18n.t('ResetPassword.fields.otp')),
  });

const ResendVerificationEmail = () => {
  const resetStore = useResetContext();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const onClick = useCallback(async () => {
    setDisabled(true);
    await forgotPassword({ email: resetStore.email });
    toast.success(t('common.toast.success.sendChangePasswordVerificationEmail', { email: resetStore.email }));

    window.setTimeout(() => setDisabled(false), 5000);
  }, [resetStore.email, t]);

  return (
    <Button disabled={disabled} onClick={onClick}>
      {t('common.buttons.resendVerificationEmail')}
    </Button>
  );
};

function SetNewPasswordStep() {
  const resetStore = useResetContext();
  const resetPassword = useResetPassword();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <I18nFormik
      initialValues={{
        newPassword: '',
        passwordConfirmation: '',
        otp: '',
      }}
      validationSchema={setNewPasswordSchema}
      onSubmit={async ({ newPassword, otp }, formik) => {
        try {
          formik.setSubmitting(true);
          await resetPassword({ email: resetStore.email, otp, newPassword });
          toast.success(t('common.toast.success.changePassword'));
          history.replace(routePaths.login);
        } catch (error) {
          console.error(error);
          formik.setErrors(
            setGraphqlErrors({
              error,
              errorResolver: (e) => {
                if (e.includes('One-time')) return ['otp', e];
                return undefined;
              },
            }),
          );
        } finally {
          formik.setSubmitting(false);
        }
      }}
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItem name="newPassword" label={t('ResetPassword.fields.newPassword')}>
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} name="newPassword" />
        </FormItem>
        <FormItem name="passwordConfirmation" label={t('ResetPassword.fields.passwordConfirmation')}>
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} name="passwordConfirmation" />
        </FormItem>
        <FormItem name="otp" label={t('ResetPassword.fields.otp')}>
          <div className="otp-input">
            <Input name="otp" />
            <ResendVerificationEmail />
          </div>
        </FormItem>
        <SubmitButton style={{ marginTop: '16px' }} className="margin-right-16">
          {t('ResetPassword.buttons.changePassword')}
        </SubmitButton>
        <LoginButton />
      </Form>
    </I18nFormik>
  );
}

export default SetNewPasswordStep;
