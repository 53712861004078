/* eslint-disable no-param-reassign */
import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Editable, withReact, useSlate, Slate, useSelected, useFocused } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement, Path, Text } from 'slate';
import { withHistory } from 'slate-history';
import {
  BoldOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { usePrevious } from 'utils/helpers';
import { Field } from 'formik';
import FormItem from 'components/common/FormItem';
import { useTranslation } from 'react-i18next';
import { MdLooks3, MdLooksOne, MdLooksTwo } from 'react-icons/md';
import { H1, H2, H3, P, A, UL, OL } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/styledComponents';
import equal from 'fast-deep-equal/es6/react';
import { Toolbar } from './components';
import classes from './components/classes.module.less';
import { MentionElement, withMentions } from './plugins/withMentions';
import { LinkBlockButton, withLinks } from './plugins/withLinks';
import { BlockButton } from './BlockButton';

// dev purposes
window.SLATE_P = {
  Editor,
  Transforms,
  createEditor,
  SlateElement,
  Path,
  Text,
};
// dev purposes
window.SLATE_P_R = {
  Editable,
  withReact,
  useSlate,
  Slate,
  useSelected,
  useFocused,
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'bulleted-list':
      return <UL {...attributes}>{children}</UL>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <OL {...attributes}>{children}</OL>;
    case 'heading-one':
      return <H1 {...attributes}>{children}</H1>;
    case 'heading-two':
      return <H2 {...attributes}>{children}</H2>;
    case 'heading-three':
      return <H3 {...attributes}>{children}</H3>;
    case 'mention':
      return <MentionElement {...props} />;
    case 'link':
      return (
        <A {...attributes} href={element.url} target="_blank" rel="noopener noreferrer">
          {children}
        </A>
      );
    default:
      return <P {...attributes}>{children}</P>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;
  return <span {...attributes}>{children}</span>;
};

const preventDefault = (e) => e.preventDefault();

const useHideToolbar = () => {
  const focused = useFocused();
  const { focused: prevFocused } = usePrevious({ focused }, { focused });
  const [isToolbarHidden, setIsToolbarHidden] = useState(true);
  const timeoutRef = useRef();
  const timeoutFocusRef = useRef();
  const onFocus = () => {
    clearTimeout(timeoutRef.current);
    clearTimeout(timeoutFocusRef.current);
    timeoutFocusRef.current = window.setTimeout(() => setIsToolbarHidden(false), 100);
  };
  const onBlur = () => {
    clearTimeout(timeoutRef.current);
    clearTimeout(timeoutFocusRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setIsToolbarHidden(true);
    }, 300);
  };
  if (prevFocused && !focused) onBlur();
  if (!prevFocused && focused) onFocus();
  return { isToolbarHidden };
};
const formattingDisabledIn = ['heading-one', 'heading-two', 'heading-three'];
const SlateToolbar = ({ toolbarChildren, allowedModifiers, rootElement }) => {
  const { isToolbarHidden } = useHideToolbar();
  if (isToolbarHidden) return null;
  const renderButton = (format, component) =>
    (allowedModifiers ? allowedModifiers?.includes?.(format) : true) ? component({ format, rootElement }) : null;
  return (
    <Toolbar onMouseDown={preventDefault}>
      {renderButton('bold', (props) => (<BlockButton {...props} icon={<BoldOutlined />} disableIn={formattingDisabledIn} />)) /* prettier-ignore */}
      {renderButton('italic', (props) => (<BlockButton {...props} icon={<ItalicOutlined />} disableIn={formattingDisabledIn} />)) /* prettier-ignore */}
      {renderButton('underline', (props) => (<BlockButton {...props} icon={<UnderlineOutlined />} disableIn={formattingDisabledIn} />)) /* prettier-ignore */}
      {renderButton('numbered-list', (props) => (<BlockButton {...props} icon={<OrderedListOutlined />} />)) /* prettier-ignore */}
      {renderButton('bulleted-list', (props) => (<BlockButton {...props} icon={<UnorderedListOutlined />} />)) /* prettier-ignore */}
      {renderButton('heading-one', (props) => (<BlockButton {...props} removeMarks icon={<MdLooksOne />} />)) /* prettier-ignore */}
      {renderButton('heading-two', (props) => (<BlockButton {...props} removeMarks icon={<MdLooksTwo />} />)) /* prettier-ignore */}
      {renderButton('heading-three', (props) => (<BlockButton {...props} removeMarks icon={<MdLooks3 />} />)) /* prettier-ignore */}
      {renderButton('link', LinkBlockButton)}
      {toolbarChildren && (typeof toolbarChildren === 'function' ? toolbarChildren() : toolbarChildren)}
    </Toolbar>
  );
};
const SlateComponent = memo(
  forwardRef(function SlateComponent(
    { toolbarChildren, renderElement, renderLeaf, allowedModifiers, rootElement },
    ref,
  ) {
    return (
      <>
        <SlateToolbar toolbarChildren={toolbarChildren} allowedModifiers={allowedModifiers} rootElement={rootElement} />
        <div className={classes.editorValue}>
          <Editable
            ref={ref}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            // placeholder="Enter some rich text..."
            spellCheck
            autoFocus
          />
        </div>
      </>
    );
  }),
  equal,
);
const tryGetInitialValue = (initialValueJSON, { rootElement = 'div' } = {}) => {
  let value = [{ type: rootElement, children: [{ text: '' }] }];
  if (!initialValueJSON) return value;
  try {
    value = JSON.parse(initialValueJSON);
    // console.log(value);
    // eslint-disable-next-line no-empty
  } catch (e) {
    // console.log(e);
  }
  return value;
};

const defaultWithPlugins = (e) => e;
const RichTextExample = (
  { toolbarChildren, withPlugins = defaultWithPlugins, initialValue, onChange, allowedModifiers, rootElement = 'div' },
  ref,
) => {
  // console.log(initialValue, 11);
  const [value, setValue] = useState(() => tryGetInitialValue(initialValue, { rootElement }));
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withPlugins(withLinks(withMentions(withHistory(withReact(createEditor()))))), [
    withPlugins,
  ]);
  const slateRef = useRef();
  const slateComponentRef = useRef();
  useImperativeHandle(ref, () => ({ editor }));
  if (!window.showRichTextRawLogged)
    window.showRichTextRawLogged =
      console.log('use window.showRichTextRaw=true to show rich text editor value') || true;
  return (
    <div className="slate">
      <Slate
        ref={slateRef}
        editor={editor}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          onChange && onChange(JSON.stringify(newValue));
        }}
      >
        <SlateComponent
          ref={slateComponentRef}
          allowedModifiers={allowedModifiers}
          toolbarChildren={toolbarChildren}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          rootElement={rootElement}
        />
        {window.showRichTextRaw ? <div>{JSON.stringify(value)}</div> : null}
        {/* {window.showRichTextSerialized ? <div>{serialize(value)}</div> : null} */}
        {/* {window.showRichTextHTML ? <div dangerouslySetInnerHTML={{ __html: serialize(value) }} /> : null} */}
      </Slate>
    </div>
  );
};
export const RichTextWithRef = forwardRef(RichTextExample);

const RichEditorFormik_ = ({ name = 'wysiwyg', label, returnNullIf, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <FormItem name={name} label={t(label)}>
      <Field name={name}>
        {({ field, form }) => {
          if (returnNullIf?.(field.value)) return null;
          return (
            <RichTextWithRef
              ref={ref}
              onChange={(contentState) => {
                form.setFieldValue(name, contentState);
                form.setFieldTouched(name, true);
              }}
              initialValue={field.value}
              {...props}
            />
          );
        }}
      </Field>
    </FormItem>
  );
};
export const BaseRichEditorField = forwardRef(RichEditorFormik_);
