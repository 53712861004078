// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1qld0vcEA9zHDv8Q0WNiwU {\n  margin-top: 6px;\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/ItemCatalogueConfiguration/Scales.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF","sourcesContent":[".addButton {\n  margin-top: 6px;\n  margin-bottom: 16px;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "_1qld0vcEA9zHDv8Q0WNiwU"
};
export default ___CSS_LOADER_EXPORT___;
