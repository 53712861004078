import gql from 'graphql-tag';

const MOVE_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation moveDocumentTemplate($_id: ID!, $offset: Int, $order: Int) {
    moveDocumentTemplate(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default MOVE_DOCUMENT_TEMPLATE_MUTATION;
