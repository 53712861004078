import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

export default function PageBack({ to, label }) {
  return (
    <h3>
      <Link to={to}>
        <LeftOutlined /> {label}
      </Link>
    </h3>
  );
}
