// import { formatDateTime } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'formik-antd';
import { Row, Col, Card, Typography } from 'antd';
import * as Yup from 'yup';
import { updateDocumentTemplate } from 'graphql/methods';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormItem';
import SaveChangesButton from 'components/common/SaveChangesButton';
import I18nFormik from 'components/common/I18nFormik';
import i18n from 'i18n';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';

const getFormInitialValues = (documentTemplate) => {
  return {
    name: documentTemplate.name || '',
    description: documentTemplate.description || '',
  };
};

const generalInfoSchema = Yup.object().shape({
  name: Yup.string()
    .label(i18n.t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nameInputLabel'))
    .required(),
  description: Yup.string().label(
    i18n.t('admin.DocumentTemplateConfigurationPage.generalInformationSection.descriptionInputLabel'),
  ),
});
const GeneralInfoFormComponent = ({ templateId, initialValues, lastUsedDocumentNr, nrOfDocumentsCreated }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.title')} className="xs-mb-20">
      <I18nFormik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          const { name, description } = values;
          await updateDocumentTemplate({ _id: templateId, modifier: { name, description } }).catch(formik.setErrors);
        }}
        validationSchema={generalInfoSchema}
        enableReinitialize
      >
        <Form layout="vertical">
          <Row gutter={[10, 10]}>
            <Col xs={24} md={24}>
              <AlertFromFormik />
              <FormItem
                name="name"
                label={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nameInputLabel')}
              >
                <Input name="name" />
              </FormItem>
              <FormItem
                name="description"
                label={t('admin.DocumentTemplateConfigurationPage.generalInformationSection.descriptionInputLabel')}
              >
                <Input.TextArea name="description" autoSize />
              </FormItem>
            </Col>
            <Col xs={24} md={24}>
              <Typography.Text type="secondary">
                {lastUsedDocumentNr ? (
                  <>
                    {t('admin.DocumentTemplateConfigurationPage.generalInformationSection.lastUsedDocumentNr', {
                      n: lastUsedDocumentNr,
                    })}
                    <br />
                  </>
                ) : null}
                {t('admin.DocumentTemplateConfigurationPage.generalInformationSection.nrOfDocumentsCreated', {
                  count: nrOfDocumentsCreated,
                })}
                <br />
                {/* {formatDateTime(new Date(documentTemplate.createdAt))} */}
              </Typography.Text>
            </Col>
          </Row>
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </Card>
  );
};
const MemoGeneralInfoFormComponent = memo(GeneralInfoFormComponent, equal);
export default function GeneralInfoForm({ documentTemplate }) {
  const initialValues = getFormInitialValues(documentTemplate);

  return (
    <MemoGeneralInfoFormComponent
      templateId={documentTemplate._id}
      initialValues={initialValues}
      lastUsedDocumentNr={documentTemplate.lastUsedDocumentNr}
      nrOfDocumentsCreated={documentTemplate.nrOfDocumentsCreated}
    />
  );
}
