import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import I18nFormik from 'components/common/I18nFormik';
import InputConfirmForm from './InputConfirmForm';

// const NotFormik = (props) => {
//   const { children, fc } = props;
//   if (fc) return <FormikContext.Provider value={fc}>{children(fc)}</FormikContext.Provider>;
//   return children(fc);
// };
const InputConfirmModal = (props, formikRef) => {
  const {
    bodyText,
    closeModal,
    fields,
    forceMultiField,
    handleClose,
    headerText,
    isClosing,
    isSubmitting,
    cancelText,
    okText,
    // notRenderFormik,
    onSubmit,
    validationSchema,
    value,
    width,
    touchedFields = [],
    labelCol,
    onChange,
    formContent,
    // formikContext,
  } = props;
  const FormikComponent = /* notRenderFormik ? NotFormik : */ I18nFormik;
  const { t } = useTranslation();
  return (
    <FormikComponent
      closeForm={closeModal}
      initialValues={fields.length === 1 && !forceMultiField ? { [fields[0].name]: value } : value}
      innerRef={formikRef}
      onReset={closeModal}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      // fc={formikContext}
    >
      {({ handleSubmit, setTouched, touched }) => {
        const shouldTouched = touchedFields.reduce((accum, field) => {
          if (!touched[field]) return accum.push(field);
          return accum;
        }, []);

        if (shouldTouched.length) setTouched(shouldTouched);

        return (
          <Modal
            afterClose={closeModal}
            confirmLoading={isSubmitting}
            onCancel={handleClose}
            onOk={handleSubmit}
            title={headerText}
            visible={!isClosing}
            width={width}
            okText={okText || t('common.ok')}
            cancelText={cancelText || t('common.cancel')}
            className="input-confirm-modal"
          >
            <InputConfirmForm
              bodyText={bodyText}
              fields={fields}
              formContent={formContent}
              touchedFields={touchedFields}
              handleSubmit={handleSubmit}
              labelCol={labelCol}
              onChange={onChange}
            />
          </Modal>
        );
      }}
    </FormikComponent>
  );
};

export default React.forwardRef(InputConfirmModal);
