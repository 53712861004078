import React from 'react';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';
import { Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  return (
    <Row justify="center" align="middle" style={{ flex: 1 }}>
      <Col xs={24} md={18} lg={14} style={{ padding: 20 }}>
        <Card title={t('ResetPassword.title')}>
          <ResetPasswordForm />
        </Card>
      </Col>
    </Row>
  );
}
