import cn from 'classnames';
import { memo } from 'react';
import classes from './CellTable.module.less';

export default memo(CellTable);

function CellTable({ children, className, ...props }) {
  return (
    <td {...props} className={cn(className, classes.cell)}>
      {children}
    </td>
  );
}
