import gql from 'graphql-tag';
import { adminInputFieldFragment } from 'graphql/fragments';

const UPDATE_INPUT_FIELD_MUTATION = gql`
  mutation updateCategoryInput($_id: ID!, $modifier: CategoryInputFieldInput!) {
    updateCategoryInput(_id: $_id, modifier: $modifier) {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

export default UPDATE_INPUT_FIELD_MUTATION;
