import { PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { Button, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import FormItem from 'components/common/FormItem';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useQuery } from '@apollo/react-hooks';
import { userContactListQuery } from 'graphql/queries';
import { createContact } from 'graphql/methods';
import apollo from 'graphql/apollo';
import { userContactFragment } from 'graphql/fragments';
import { findIndex } from 'lodash';
import salutations from 'constants/salutations';
import classesTabs from '../ContactData.module.less';
import {
  ContactExistingFormikInput,
  ContactForm,
  NumberPhoneAlternativeFormikInput,
  NumberPhoneBusinessFormikInput,
} from '../components/Inputs';
import { contactFormTabSchema } from '../components/schema';
import ContactTable from './ContactTable';

const { TabPane } = Tabs;

const AddExistingContactTab = ({ changeTab }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(userContactListQuery, { fetchPolicy: 'cache-first' });
  const contacts = grabFirstGQLDataResult(data);

  return (
    <div>
      <ContactExistingFormikInput
        contacts={contacts}
        loading={loading}
        changeTab={changeTab}
        placeholder={t('user.ShoppingCart.ContactData.contact.inputs.existingContact.placeholder')}
      />
    </div>
  );
};

const AddContactContent = ({ contacts, loading }) => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState('1');

  const { setFieldValue } = useFormikContext();

  const changeTab = useCallback(
    (key) => {
      setActiveKey(key);
      setFieldValue('tab', key);
    },
    [setFieldValue],
  );
  return (
    <Tabs type="card" activeKey={activeKey} className={classesTabs.tabs} onChange={changeTab}>
      <TabPane tab={t('user.ShoppingCart.ContactData.contact.addExistingContactTab')} key="1">
        <AddExistingContactTab contacts={contacts} loading={loading} changeTab={changeTab} />
      </TabPane>
      <TabPane tab={t('user.ShoppingCart.ContactData.contact.createNewContactTab')} key="2">
        <ContactForm />
        <NumberPhoneBusinessFormikInput typeContactData="contact" />
        <NumberPhoneAlternativeFormikInput typeContactData="contact" />
      </TabPane>
    </Tabs>
  );
};

const addNewContact = ({ contacts, onAdd }) =>
  inputConfirmModal({
    formContent: () => <AddContactContent />,
    fields: [],
    onSubmit: async ({ contact, tab, ...newContact }) => {
      if (tab === '1') {
        if (contacts.find((e) => contact === e._id)) throw new Error('DuplicatedContact');
        const currentContact = apollo.readFragment({ id: `Contact___${contact}`, fragment: userContactFragment });
        onAdd(currentContact);
      } else {
        const { data } = await createContact(newContact);
        onAdd(grabFirstGQLDataResult(data));
      }
    },
    value: {
      tab: '1',
      contact: null,
      salutation: salutations.Mr,
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '49-',
      phoneNumberAlternative: '49-',
    },
    headerText: i18n.t('user.ShoppingCart.ContactData.modal.titles.addContact'),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: contactFormTabSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['email', i18n.t('user.ShoppingCart.ContactData.contact.duplicatedErrorMessage.email')],
      DuplicatedContact: ['contact', i18n.t('user.ShoppingCart.ContactData.contact.duplicatedErrorMessage.contact')],
    },
  });

function ContactsField() {
  const name = 'contacts';
  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const contacts = values[name];
  const onAdd = useCallback(
    (newValue) => {
      setFieldValue(name, [...contacts, newValue]);
      setFieldTouched(name, true, false);
    },
    [contacts, setFieldTouched, setFieldValue],
  );
  const onDelete = useCallback(
    (_id) => {
      const filterContacts = contacts.filter((e) => e._id !== _id);
      setFieldValue(name, filterContacts);
      setFieldTouched(name, true, false);
    },
    [contacts, setFieldTouched, setFieldValue],
  );
  const onChange = useCallback(
    (contact) => {
      const newContacts = [...contacts];
      const indexContact = findIndex(newContacts, { _id: contact._id });
      if (indexContact !== -1) newContacts[indexContact] = contact;

      setFieldValue(name, newContacts);
      setFieldTouched(name, true, false);
    },
    [contacts, setFieldTouched, setFieldValue],
  );
  const { t } = useTranslation();
  return (
    <FormItem name="contacts">
      {contacts && contacts.length ? (
        <ContactTable contacts={contacts} onDelete={onDelete} onChange={onChange} />
      ) : null}
      <div>
        <Button
          style={{ marginTop: '16px' }}
          icon={<PlusOutlined />}
          onClick={() => addNewContact({ contacts, onAdd })}
          type={contacts?.length ? 'default' : 'primary'}
        >
          {t('user.ShoppingCart.ContactData.buttons.addContact')}
        </Button>
      </div>
    </FormItem>
  );
}
export default ContactsField;
