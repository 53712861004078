import gql from 'graphql-tag';

const MOVE_DISCOUNT_MUTATION = gql`
  mutation moveCategoryDiscount($_id: ID!, $offset: Int, $order: Int) {
    moveDiscount(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default MOVE_DISCOUNT_MUTATION;
