import gql from 'graphql-tag';
import { adminInputFieldFragment } from 'graphql/fragments';

const CREATE_INPUT_FIELD_MUTATION = gql`
  mutation createCategoryInput(
    $categoryId: ID!
    $inputField: CategoryInputFieldInput!
    $parentId: ID!
    $parentType: String!
  ) {
    createCategoryInput(
      categoryId: $categoryId
      inputField: $inputField
      parentId: $parentId
      parentType: $parentType
    ) {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

export default CREATE_INPUT_FIELD_MUTATION;
