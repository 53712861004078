// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LUMpEChHgeAL3NiBxqxXr > :first-child {\n  margin: 0 !important;\n}\n.LUMpEChHgeAL3NiBxqxXr > :first-child > div {\n  width: 100%;\n  height: 300px;\n}\n.LUMpEChHgeAL3NiBxqxXr > :last-child {\n  margin-top: 16px;\n}\n._8pO0IZix7x7Rn0ZDABIQP {\n  display: inline;\n  padding: 0 !important;\n  color: #1890ff !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/common/MoreInfoWidget.module.less"],"names":[],"mappings":"AAAA;EAMI,oBAAA;AAJJ;AAFA;EAGM,WAAA;EACA,aAAA;AAEN;AANA;EASI,gBAAA;AAAJ;AAGA;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;AADF","sourcesContent":[".modalHelper {\n  > :first-child {\n    > div {\n      width: 100%;\n      height: 300px;\n    }\n    margin: 0 !important;\n  }\n  > :last-child {\n    margin-top: 16px;\n  }\n}\n.button {\n  display: inline;\n  padding: 0 !important;\n  color: #1890ff !important;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHelper": "LUMpEChHgeAL3NiBxqxXr",
	"button": "_8pO0IZix7x7Rn0ZDABIQP"
};
export default ___CSS_LOADER_EXPORT___;
