/* eslint-disable no-shadow */
import { Button } from 'antd';
import { Form, Input, Radio, Switch } from 'formik-antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import inputConfirmModal from 'utils/inputConfirmModal';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { pick, isEqual } from 'lodash';
import i18n from 'i18n';
import cn from 'classnames';
import BorderedBox from 'components/common/BorderedBox';
import s from './ShoppingCart.module.less';

const Options = () => {
  const [showHiddenNote, setShowHiddenNote] = useState(false);
  const toggleHiddenNote = useCallback(() => setShowHiddenNote((prev) => !prev), []);
  const { t } = useTranslation();

  return (
    <div>
      <Form layout="horizontal">
        <FormItem name="feeType" className={s.feeType} label="">
          <Radio.Group name="feeType">
            <Radio.Button value="standard">{t('user.ShoppingCart.options.standardFee')}</Radio.Button>
            <Radio.Button value="fixedMonthly">{t('user.ShoppingCart.options.fixedMonthlyFee')}</Radio.Button>
          </Radio.Group>
        </FormItem>
        <div className={s.groupOptions}>
          <div className={s.switchesGroup}>
            <FormItem
              className={cn(s.showPricesFormItem, 'hide-form-item-colon row-reverse')}
              name="showPrices"
              label={t('user.ShoppingCart.options.showPrices')}
              labelCol={{ span: 21 }}
            >
              <Switch
                name="showPrices"
                checkedChildren={t('user.ShoppingCart.options.switch.on')}
                unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
              />
            </FormItem>
            <FormItem
              className={cn('hide-form-item-colon row-reverse')}
              name="showDiscounts"
              label={t('user.ShoppingCart.options.showDiscounts')}
              labelCol={{ span: 21 }}
            >
              <Switch
                name="showDiscounts"
                checkedChildren={t('user.ShoppingCart.options.switch.on')}
                unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
              />
            </FormItem>
            <FormItem
              className={cn(s.showPricesFormItemLastSwitch, 'hide-form-item-colon row-reverse')}
              name="debugMode"
              label={t('user.ShoppingCart.options.debugMode')}
              labelCol={{ span: 21 }}
            >
              <Switch
                name="debugMode"
                checkedChildren={t('user.ShoppingCart.options.switch.on')}
                unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
              />
            </FormItem>
          </div>
          <div className={s.optionNote}>
            <Button onClick={toggleHiddenNote}>
              {t(`user.ShoppingCart.options.${showHiddenNote ? 'hideHiddenNote' : 'showHiddenNote'}`)}
            </Button>
            {showHiddenNote ? (
              <BorderedBox type="transparent" label={t('user.ShoppingCart.options.hiddenNote.title')}>
                <div>
                  <FormItem className="hide-form-item-colon margin-bottom-0" name="hiddenNote" label="">
                    <Input.TextArea
                      placeholder={t('user.ShoppingCart.options.hiddenNote.placeholder')}
                      name="hiddenNote"
                    />
                  </FormItem>
                </div>
              </BorderedBox>
            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
};
const F = [{ component: Options, label: '' }];
const editOptionsModal = ({ value, setFieldValue }) =>
  inputConfirmModal({
    headerText: i18n.t('user.ShoppingCart.options.title'),
    okText: i18n.t('user.ShoppingCart.options.ok'),
    cancelText: i18n.t('user.ShoppingCart.options.cancel'),
    onSubmit: (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
    },
    onChange: (current, previous) => {
      const autoUpdatingFields = ['feeType', 'showPrices', 'showDiscounts', 'debugMode'];
      const currentPicked = pick(current, autoUpdatingFields);
      const previousPicked = pick(previous, autoUpdatingFields);
      if (!isEqual(currentPicked, previousPicked)) {
        Object.entries(currentPicked).forEach(([key, value]) => {
          setFieldValue(key, value);
        });
      }
    },
    fields: F,
    forceMultiField: true,
    validationSchema: () => Yup.object(),
    value,
    width: '575px',
  });

const ShoppingCartOptions = ({ className }) => {
  const { values, setFieldValue } = useFormikContext();
  // console.log({
  //   value: pick(values, ['feeType', 'showPrices', 'showDiscounts', 'hiddenNote', 'debugMode']),
  //   setFieldValue,
  // });
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      onClick={() =>
        editOptionsModal({
          value: pick(values, ['feeType', 'showPrices', 'showDiscounts', 'hiddenNote', 'debugMode']),
          setFieldValue,
        })
      }
    >
      {t('user.ShoppingCart.buttonLabel')}
    </Button>
  );
};

export default ShoppingCartOptions;
