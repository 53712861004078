import gql from 'graphql-tag';
import { adminCompanyTypeFragment } from '../fragments';

const ADMIN_COMPANY_TYPES_QUERY = gql`
  query adminCategoryList {
    companyTypes {
      ...adminCompanyTypeFragment
    }
  }
  ${adminCompanyTypeFragment}
`;

export default ADMIN_COMPANY_TYPES_QUERY;
