import gql from 'graphql-tag';
import { adminStaticItemFragment } from 'graphql/fragments';

const m = gql`
  mutation updateCategoryStaticItem($_id: ID!, $modifier: StaticItemInput!) {
    updateCategoryStaticItem(_id: $_id, modifier: $modifier) {
      ...adminStaticItemFragment
    }
  }
  ${adminStaticItemFragment}
`;

export default m;
