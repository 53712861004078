import { setGraphqlErrors } from 'components/common/ErrorComponent';
import apollo from 'graphql/apollo';
import { updateDocumentTemplateMutation } from 'graphql/mutations';
import i18n from 'i18n';

const updateDocumentTemplate = ({ _id, modifier }) =>
  apollo.mutate({ mutation: updateDocumentTemplateMutation, variables: { _id, modifier } }).catch((error) => {
    throw setGraphqlErrors({
      error,
      errorResolver: { Duplicated: ['name', i18n.t('admin.addDocumentTemplateModal.duplicatedErrorMessage')] },
    });
  });
export default updateDocumentTemplate;
