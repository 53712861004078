import apollo from 'graphql/apollo';
import { updateContactMutation } from 'graphql/mutations';

const updateContact = async ({
  _id,
  salutation,
  firstName,
  position,
  lastName,
  email,
  phoneNumber,
  phoneNumberAlternative,
}) =>
  apollo.mutate({
    mutation: updateContactMutation,
    variables: {
      _id,
      modifier: { salutation, firstName, position, lastName, email, phoneNumber, phoneNumberAlternative },
    },
  });
export default updateContact;
