// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3u7pdzAnO6c5FxQ9dOKjpj {\n  margin-top: 16px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/ShoppingCart/ContactData/contacts/ContactTable..module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF","sourcesContent":[".table {\n  margin-top: 16px;\n  width: 100%;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "_3u7pdzAnO6c5FxQ9dOKjpj"
};
export default ___CSS_LOADER_EXPORT___;
