import { useRowContext } from 'contexts/RowContext';
import { memo } from 'react';
import { colors } from 'styles/colors';

export default memo(DraggableDecoration);
function DraggableDecoration({ height = '100%' }) {
  const { isDragging } = useRowContext();
  if (isDragging)
    return (
      <div
        style={{
          position: 'absolute',
          left: '1px',
          top: '1px',
          width: '5px',
          height,
          backgroundColor: colors.primary,
          borderTopLeftRadius: '1px',
          borderBottomLeftRadius: '1px',
        }}
      />
    );
  return null;
}
