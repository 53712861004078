import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  createStaticItem,
  deleteStaticItemWithConfirmation,
  moveCategoryStaticItem,
  updateStaticItem,
} from 'graphql/methods';
import { TableMemo } from 'memo';
import ItemTypes from 'constants/ItemTypes';
import { memo } from 'react';
import { clientShift } from 'utils/helpers';
import EmptyBox from 'components/common/EmptyBox';
import { useLibraryContext } from 'contexts/LibraryContext';
import { getCategoryListQuery } from 'graphql/queries';
import { updateCategoryStaticItemCache } from 'graphql/cache';
import DragButton from './drag/DragButton';
import DraggableBodyRow from './drag/DraggableBodyRow';
import classes from './CollapsibleCategoryConfiguration.module.less';

const getStaticItemFields = () => [
  {
    label: i18n.t('admin.staticItemsFieldModal.nameFieldLabel'),
    name: 'name',
    description: i18n.t('admin.staticItemsFieldModal.nameFieldDescription'),
  },
  {
    label: i18n.t('admin.staticItemsFieldModal.valueFieldLabel'),
    name: 'value',
    description: i18n.t('admin.staticItemsFieldModal.nameFieldDescription'),
  },
];

const addStaticItem = (categoryId, categoryListQuery) =>
  inputConfirmModal({
    headerText: i18n.t('admin.staticItemsFieldModal.addModalTitle'),
    fields: getStaticItemFields(),
    value: { name: '', value: '' },
    onSubmit: ({ name, value }) => createStaticItem({ categoryId, name, value, categoryListQuery }),
    errorResolver: { Duplicated: ['name', i18n.t('admin.staticItemsFieldModal.duplicatedErrorMessage')] },
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
  });
const editStaticItem = ({ _id, currentName, currentValue }) =>
  inputConfirmModal({
    headerText: i18n.t('admin.staticItemsFieldModal.editModalTitle'),
    onSubmit: async ({ name, value }) => updateStaticItem({ _id, name, value }),
    fields: getStaticItemFields(),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    value: { name: currentName, value: currentValue },
    errorResolver: { Duplicated: ['name', i18n.t('admin.staticItemsFieldModal.duplicatedErrorMessage')] },
  });
const staticItemsTableColumns = ({ t, categoryListQuery }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragButton />,
    key: 'sort',
  },
  {
    title: t('admin.staticItemsTableColumns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('admin.staticItemsTableColumns.value'),
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: t('admin.staticItemsTableColumns.actions'),
    dataIndex: '',
    key: 'actions',
    width: 102,
    render: ({ _id, name, value }) => {
      return (
        <>
          <Button
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editStaticItem({ _id, currentName: name, currentValue: value })}
          />{' '}
          <Button
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteStaticItemWithConfirmation({ _id, categoryListQuery })}
          />
        </>
      );
    },
  },
];

const StaticItemsTab = ({ _id, staticItems }) => {
  const { t } = useTranslation();

  const move = (dragIndex, hoverIndex, currentId) => {
    const newStaticItems = clientShift({ array: staticItems, _id: currentId, shift: hoverIndex - dragIndex });
    updateCategoryStaticItemCache({ _id, staticItems: newStaticItems });
  };

  const { isLibrary } = useLibraryContext();
  const categoryListQuery = getCategoryListQuery(isLibrary);

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => addStaticItem(_id, categoryListQuery)} type="primary">
        {t('admin.addStaticItemButton')}
      </Button>
      <br />
      <br />
      <div className={classes.wrapperTable}>
        <TableMemo
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyDiscountListMessage')} /> }}
          rowKey={(record) => record?._id}
          components={{
            body: {
              row: DraggableBodyRow,
            },
          }}
          onRow={(record, index) => ({
            index,
            record,
            move,
            itemTypes: _id + ItemTypes.CATEGORY_STATIC_FIELD,
            mutation: {
              move: moveCategoryStaticItem(categoryListQuery),
            },
          })}
          dataSource={staticItems}
          pagination={false}
          columns={staticItemsTableColumns({ t, categoryListQuery })}
        />
      </div>
    </>
  );
};
export default memo(StaticItemsTab);
