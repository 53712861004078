import { useState } from 'react';
import ImageZoom from 'components/common/ImageZoom';

export default function BlockPreviewContent({ block, hideBlockName, title }) {
  const [zoomed, setZoomed] = useState(false);
  if ((hideBlockName || !block.displayName) && !block.infoText && !block.previewImageSrc) return null;
  return (
    <>
      {title ? <h4>{title}</h4> : null}
      {!hideBlockName ? <h5>{block.displayName}</h5> : null}
      {block.infoText ? <p>{block.infoText}</p> : null}
      {block.previewImageSrc ? (
        <ImageZoom
          initialWidth={80}
          zoomed={zoomed}
          openZoom={() => setZoomed(true)}
          closeZoom={() => setZoomed(false)}
          src={block.previewImageThumbnailSrc || block.previewImageSrc}
          alt={block.name}
        />
      ) : null}
    </>
  );
}
