import { LibraryProvider } from 'contexts/LibraryContext';
import DocumentTemplateConfiguration from './DocumentTemplateConfiguration';

export default function DocumentTemplate(props) {
  return (
    <LibraryProvider value={{ isLibrary: false }}>
      <DocumentTemplateConfiguration {...props} />
    </LibraryProvider>
  );
}
