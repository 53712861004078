import { responseToFormik } from 'components/user/utils';
import useUserShoppingCart from 'graphql/hooks/useUserShoppingCart';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import confirmModal from 'utils/confirmModal';
import ShoppingCart from '../ShoppingCart/ShoppingCart';

const ShoppingCartRevision = () => {
  const {
    params: { id: _id },
  } = useRouteMatch();
  const [shoppingCart, loading] = useUserShoppingCart({ _id });
  const isShoppingCartChanged = shoppingCart?.isSomethingChanged;
  const modalDisplayedRef = useRef();
  const { t } = useTranslation();
  if (!loading && isShoppingCartChanged && !modalDisplayedRef.current) {
    modalDisplayedRef.current = true;
    confirmModal({
      title: t('user.ShoppingCart.somethingChangedWhenOpenedEdit'),
      maskClosable: true,
      disableCloseButton: true,
      width: 800,
    });
  }

  return (
    <ShoppingCart dbId={_id} dbLoading={loading} dbValues={!loading && responseToFormik({ cart: shoppingCart })} />
  );
};

export default ShoppingCartRevision;
