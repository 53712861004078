import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { adminDocumentTemplateByIdQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const useDocumentTemplate = (documentTemplateId) => {
  const { data, ...rest } = useQuery(adminDocumentTemplateByIdQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { documentTemplateId },
  });
  return useMemo(() => {
    const documentTemplate = grabFirstGQLDataResult(data);
    return {
      data: documentTemplate,
      ...rest,
    };
  }, [data, rest]);
};

export default useDocumentTemplate;
