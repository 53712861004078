/* eslint-disable react/no-unescaped-entities */
import BorderedBox from 'components/common/BorderedBox';
import { useCategoriesContext } from 'components/user/context';
import { OverallTotalTable } from 'components/user/TotalTable';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import s from './ShoppingCart.module.less';

const Discounts = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const categories = useCategoriesContext();
  const activeDiscounts = categories
    .map((category) => [
      category,
      category.discounts.find((discount) => values[`${category._id}_discount`] === discount._id),
    ])
    .filter(([, discount]) => discount);
  if (!activeDiscounts.length) return null;
  return (
    <div className="xs-mb-20">
      <BorderedBox className={s.discounts} type="success" label={t('user.ShoppingCart.Discounts.activeDiscountsLabel')}>
        <div>{t('user.ShoppingCart.Discounts.activeDiscountsListLabel')}</div>
        <ul>
          {activeDiscounts.map(([category, discount]) => (
            <li key={discount._id}>
              "{discount.name}" is active for "{category.name}" ({discount.value}{' '}
              {discount.type === 'percent' ? '%' : '€'} discount)
            </li>
          ))}
        </ul>
      </BorderedBox>
    </div>
  );
};

const TotalPricing = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  if (!values.showPrices) return null;

  return (
    <>
      <Card title={t('user.ShoppingCart.TotalPricing.label')}>
        <Discounts />
        <OverallTotalTable />
      </Card>
      <br />
    </>
  );
};

export default TotalPricing;
