/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button as AntdButton } from 'antd';
import { memo } from 'react';
import s from './ButtonSupportOnClickWhenDisabled.module.less';

const ButtonSupportOnClickWhenDisabled = (props) => {
  const { disabled, onClick } = props;
  if (disabled) {
    return (
      <div className={s['button-container']}>
        <div className={s['above-button']} onClick={onClick} />
        <AntdButton {...props} />
      </div>
    );
  }
  return <AntdButton {...props} />;
};
export default memo(ButtonSupportOnClickWhenDisabled);
