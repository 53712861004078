import PhoneNumberOriginal from '@uplab/antd-country-phone-input';
import { useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import i18n from 'i18n';
import * as Yup from 'yup';
import classes from './PhoneNumber.module.less';

class PhoneControllerClass {
  parse = (string) => {
    const tmpDefaultValue = { code: '', phone: '' };

    if (!string) return tmpDefaultValue;

    const indexHyphen = string.indexOf('-');

    if (indexHyphen === -1) return { ...tmpDefaultValue, phone: string };
    return {
      code: +string.substr(0, indexHyphen),
      phone: string.substr(indexHyphen + 1),
    };
  };

  stringify = ({ code, phone }) => {
    return `${code}-${phone}`;
  };
}
export const PhoneInputValidationSchema = (props) => {
  let schema = Yup.string();
  if (props?.required)
    schema = schema
      .required()
      .test('code-no-empty', i18n.t(`InputErrors.phoneNumber.noCode`), (value) => {
        return !!getInitialPhoneValues(value)?.code;
      })
      .test('phone-no-empty', i18n.t(`InputErrors.phoneNumber.noPhone`), (value) => {
        return !!getInitialPhoneValues(value)?.phone;
      });
  return schema;
};

export const PhoneController = new PhoneControllerClass();

export const getInitialPhoneValues = (value) => {
  const tmpDefaultValue = { code: '', phone: '' };
  if (!value) return tmpDefaultValue;
  const parseValue = PhoneController.parse(value);

  if (typeof parseValue === 'string') return { ...tmpDefaultValue, phone: parseValue };
  if (typeof parseValue === 'object') {
    Object.entries(parseValue).forEach(([k, v]) => {
      tmpDefaultValue[k] = v;
    });
  }

  return tmpDefaultValue;
};

function PhoneNumber({ name, inputProps = {}, maxLength = 60, regEx = /^[0-9/-\s]*$/ }) {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const value = useMemo(() => values[name], [name, values]);
  const [phoneValue, setPhoneValue] = useState(getInitialPhoneValues(value));

  const onChange = useCallback(
    ({ code, phone, short }) => {
      if (phone?.length >= maxLength || !phone.match(regEx)) return;
      setPhoneValue({ code, phone, short });
      setFieldValue(name, PhoneController.stringify({ code, phone }));
      setFieldTouched(name, true, false);
    },
    [maxLength, name, regEx, setFieldTouched, setFieldValue],
  );
  return (
    <PhoneNumberOriginal
      value={phoneValue}
      lang={i18n.language}
      inputProps={{
        onBlur: () => {
          setFieldTouched(name, true, true);
        },
        ...inputProps,
      }}
      containerProps={{ className: classes.phoneInput }}
      onChange={onChange}
    />
  );
}

export default PhoneNumber;
