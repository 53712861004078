// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1a5wDczMD0ixeoEqFrVzEk,\n.hGWji2OOW2V7DembgJJCJ {\n  height: auto;\n  word-break: break-all;\n  padding-left: 25px;\n  flex: 1;\n  max-width: 100%;\n}\n.hGWji2OOW2V7DembgJJCJ {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/components/HeaderCollapse/HeaderCollapse.module.less"],"names":[],"mappings":"AAAA;;EAEE,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,OAAA;EACA,eAAA;AACF;AACA;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".headerActiveName,\n.headerInactiveName {\n  height: auto;\n  word-break: break-all;\n  padding-left: 25px;\n  flex: 1;\n  max-width: 100%;\n}\n.headerInactiveName {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerActiveName": "_1a5wDczMD0ixeoEqFrVzEk",
	"headerInactiveName": "hGWji2OOW2V7DembgJJCJ"
};
export default ___CSS_LOADER_EXPORT___;
