import gql from 'graphql-tag';
import adminInputFieldFragment from './adminInputFieldFragment';

const adminItemFragment = gql`
  fragment adminItemFragment on CategoryItem {
    _id
    benefits
    categoryId
    companyTypeIds
    infoText
    minPrice
    name
    notesToEmployee
    order
    paymentInterval
    pleaseNote
    pricingFormula
    subTitle
    category {
      _id
      name
    }
    recursiveFormulaInfo {
      _id
      usedItemIdsInFormula
      usedIdsInFormula
      moreInfo
    }
    inputFields {
      ...adminInputFieldFragment
    }
    scales {
      _id
      value
      pricingFormula
    }
    scalesEnabled
    scaleTitle
    scalePricingFormulaTitle
    pricingFormulaExtended {
      _id
      raw
      formatted
      inputFields {
        _id
        name
      }
    }
  }
  ${adminInputFieldFragment}
`;

export default adminItemFragment;
