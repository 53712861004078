import { useField, useFormikContext } from 'formik';
import { useFunctionToRefCB } from 'memo';

export const useFormikField = (name) => {
  const field = useField(name);
  const formik = useFormikContext();
  const [{ value }, , { setValue, setTouched }] = field;
  const onChange = useFunctionToRefCB((v) => {
    window.setTimeout(() => {
      setValue(v?.target?.value ?? v, false);
      setTouched(true, false);
      formik.validateForm();
    });
  });
  return { value, onChange };
};
