/* eslint-disable no-empty */
import { notification } from 'antd';
import i18n from 'i18n';

const openNotificationWithIcon = (type, { message }) => {
  notification[type]({
    message,
    onClick: (e) => {
      try {
        e.target.parentElement.parentElement.parentElement.querySelector('a').click();
      } catch {}
    },
    getContainer: () => document.body,
    // description:      'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
  });
};
const toast = {
  info: (message) => openNotificationWithIcon('info', { message }),
  success: (message) => openNotificationWithIcon('success', { message }),
  error: (message) => openNotificationWithIcon('error', { message }),
  successDefault: () => toast.success(i18n.t('common.successToastText')),
};

export default toast;
