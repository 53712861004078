// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ynBfRhqJOH8Sg5ZlfSSz_ {\n  display: inline;\n  margin-left: 6px;\n}\n._1ynBfRhqJOH8Sg5ZlfSSz_:hover {\n  color: #e43f3f;\n}\n._1ynBfRhqJOH8Sg5ZlfSSz_:hover > :last-child {\n  color: #e43f3f !important;\n}\n._1ynBfRhqJOH8Sg5ZlfSSz_ > :nth-child(2) {\n  margin-right: 15px;\n}\n._1ynBfRhqJOH8Sg5ZlfSSz_ > :last-child {\n  transform: rotate(90deg);\n  margin: -4px;\n}\n._3oov8-NmGF3pOBf_O46UGH {\n  max-width: 400px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n._1Jk8wvueFxgYGfSpmSbzyQ {\n  height: 1px;\n  background-color: #eee;\n}\n", "",{"version":3,"sources":["webpack://src/components/common/RichEditor/RichEditorPlaceholders.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;AADF;AAEE;EACE,cAAA;AAAJ;AADE;EAGI,yBAAA;AACN;AAPA;EAUI,kBAAA;AAAJ;AAVA;EAaI,wBAAA;EACA,YAAA;AAAJ;AAGA;EACE,gBAAA;EACA,uBAAA;EACA,gBAAA;AADF;AAGA;EACE,WAAA;EACA,sBAAA;AADF","sourcesContent":["@import 'styles/variables.less';\n\n.placeholder {\n  display: inline;\n  margin-left: 6px;\n  &:hover {\n    color: @primary-color;\n    > :last-child {\n      color: @primary-color !important;\n    }\n  }\n  > :nth-child(2) {\n    margin-right: 15px;\n  }\n  > :last-child {\n    transform: rotate(90deg);\n    margin: -4px;\n  }\n}\n.itemMenu {\n  max-width: 400px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.delimiter {\n  height: 1px;\n  background-color: #eee;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": "_1ynBfRhqJOH8Sg5ZlfSSz_",
	"itemMenu": "_3oov8-NmGF3pOBf_O46UGH",
	"delimiter": "_1Jk8wvueFxgYGfSpmSbzyQ"
};
export default ___CSS_LOADER_EXPORT___;
