import gql from 'graphql-tag';

const MOVE_CATEGORY_MUTATION = gql`
  mutation moveCategory($_id: ID!, $offset: Int, $order: Int) {
    moveCategory(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default MOVE_CATEGORY_MUTATION;
