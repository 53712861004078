import { Form } from 'formik-antd';
import { useFormikContext } from 'formik';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import * as Yup from 'yup';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { useRef, useEffect, useCallback } from 'react';
import { priceSchema } from 'components/admin/itemModal/schema';
import {
  ConfigurePricingScalesCheckbox,
  MinimalPriceFormikInput,
  PaymentIntervalFormikInput,
  PricingFormulaFormikInput,
  PricingFormulaHelperWidget,
} from 'components/admin/itemModal/components';
import { useAdminCategoryInputFields } from 'graphql/hooks';
import I18nFormik from 'components/common/I18nFormik';
import Scales from './Scales';
import classes from './PriceTab.module.less';

export const priceValidationSchema = (props) => Yup.object().shape({ ...priceSchema(props) });
const ValidateFormulaOnMountContainer = (props) => {
  const ref = useRef();
  const setIsDone = useCallback(() => {
    ref.current = true;
  }, []);
  if (ref.current) return null;
  return <ValidateFormulaOnMount {...props} setIsDone={setIsDone} />;
};
const ValidateFormulaOnMount = ({ categoryId, parentId, parentType, doNotValidateOnMount, setIsDone }) => {
  const useValidateFormOncePropIsTrue = (prop) => {
    const form = useFormikContext();
    const isValidatedRef = useRef();
    useEffect(() => {
      if (prop && !isValidatedRef.current) {
        isValidatedRef.current = true;
        setIsDone();
        form.validateForm();
      }
    }, [form, prop]);
  };

  const [inputFields] = useAdminCategoryInputFields({ categoryId, parentId, parentType, fetchPolicy: 'cache-only' });
  useValidateFormOncePropIsTrue(!doNotValidateOnMount && inputFields.length);
  return null;
};
const PriceTab = ({ initialValues, categoryId, parentId, parentType, onSubmit }) => {
  const formikRef = useRef();
  const schema = () => priceValidationSchema({ formikRef, categoryId, parentId, parentType });

  return (
    <I18nFormik
      initialValues={{
        minPrice: 0,
        paymentInterval: undefined,
        pricingFormula: undefined,
        scalesEnabled: false,
        scales: [],
        scaleTitle: '',
        scalePricingFormulaTitle: '',
        ...initialValues,
      }}
      innerRef={formikRef}
      validationSchema={schema}
      onSubmit={(values, formik) => onSubmit(values, formik).catch(formik.setErrors)}
    >
      <Form layout="vertical">
        <div className="margin-16 margin-top-0">
          <AlertFromFormik />
          <PaymentIntervalFormikInput categoryId={categoryId} />
          <PricingFormulaFormikInput categoryId={categoryId} parentId={parentId} parentType={parentType} />
          <PricingFormulaHelperWidget buttonClassName={classes.infoButton} />
          <ValidateFormulaOnMountContainer categoryId={categoryId} parentId={parentId} parentType={parentType} />
          <MinimalPriceFormikInput />
          <ConfigurePricingScalesCheckbox />
          <Scales itemId={parentId} />
          <SaveChangesButton marginTop={0} initialValues={initialValues} />
        </div>
      </Form>
    </I18nFormik>
  );
};

export default PriceTab;
