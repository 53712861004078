import gql from 'graphql-tag';
import { adminContactFragment } from 'graphql/fragments';

const CREATE_CONTACT_MUTATION = gql`
  mutation createCompany($contact: ContactInput!) {
    createContact(contact: $contact) {
      ...adminContactFragment
    }
  }
  ${adminContactFragment}
`;

export default CREATE_CONTACT_MUTATION;
