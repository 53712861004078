// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2KgQDhiXg6XuE8uKqY7BC > :last-child {\n  position: relative !important;\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/DocumentTemplateConfiguration/DocumentLayoutBlock.module.less"],"names":[],"mappings":"AAAA;EAEI,6BAAA;EACA,aAAA;EACA,sBAAA;AAAJ","sourcesContent":[".container {\n  > :last-child {\n    position: relative !important;\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2KgQDhiXg6XuE8uKqY7BC"
};
export default ___CSS_LOADER_EXPORT___;
