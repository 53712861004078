import * as Yup from 'yup';
import { find, forEach, merge } from 'lodash';
import i18n from 'i18n';
import NodeCache from 'node-cache';
import { allInputFieldsInCategories } from 'components/user/utils';

window.i18n = i18n;
const schemaCache = new NodeCache({ stdTTL: 1, checkperiod: 2 });
export const isItemRecursive = (item) => item.recursiveFormulaInfo?.usedItemIdsInFormula?.includes(item._id);
const getSchema = ({
  currentSubmitType,
  allInputFields,
  affectedFieldsMap,
  requiredStartOfServiceCategories,
  recursiveItems,
}) => {
  const email = Yup.string()
    .label('E-Mail-Address')
    .email();
  const shape = {
    showPrices: Yup.boolean().label('Show Prices'),
    company: Yup.object() // TODO: change to ID (string)
      .label('Company')
      .required(),
    contacts: Yup.array()
      .of(Yup.object()) // TODO: change to ID (string)
      .min(1)
      .required()
      .label('Contacts'),
    companyTypeId: Yup.string()
      .required()
      .label('Company type'),
    contactEmail: currentSubmitType === 'email' ? email.required() : email,
    ...merge(
      ...requiredStartOfServiceCategories.map((name) => ({
        [name]: Yup.string()
          .label('Start of service')
          .required(i18n.t('user.ShoppingCart.Category.startOfServiceRequired')),
      })),
    ),
    ...merge(
      ...allInputFields.map(({ _id, name, type }) => {
        const isCombo = type === 'combo';
        let schema = Yup[isCombo ? 'string' : 'number']()
          .typeError(i18n.t('user.ShoppingCart.Category.InputField.mustBeANumberMessage', { name }))
          .label(name)
          .test(
            'zero-not-allowed',
            i18n.t('user.ShoppingCart.Category.Item.zeroNotAllowedError'),
            (value) => value !== 0,
          );
        if (affectedFieldsMap[_id])
          schema = schema.required(i18n.t('user.ShoppingCart.Category.InputField.requiredMessage', { name }));
        return { [_id]: schema };
      }),
    ),
    ...merge(
      ...recursiveItems.map((item) => ({
        [item._id]: Yup.boolean().test('fieldIncludesRecursiveField', i18n.t('common.Item.recursive'), () => false),
      })),
    ),
  };
  // console.log(shape);
  const schema = Yup.object().shape(shape);
  return schema;
};
const cachedSchema = (key) => {
  const cacheKey = JSON.stringify(key);
  const cached = schemaCache.get(cacheKey);
  if (cached) return cached;
  const generated = getSchema(key);
  schemaCache.set(cacheKey, generated);
  return generated;
};
const getValidationSchema = ({ values, categories, currentSubmitType, touched, setFieldTouched }) => {
  const allInputFields = allInputFieldsInCategories(categories);
  const affectedInputFields = categories
    .map((category) => {
      const affectedInputFieldIdsInCategory = category.items
        .filter((item) => values && values[item._id])
        .map((item) => item.recursiveFormulaInfo.usedIdsInFormula)
        .flat();
      return allInputFields.filter((field) => affectedInputFieldIdsInCategory.includes(field._id));
    })
    .flat();

  affectedInputFields.forEach((field) => touched[field._id] || setFieldTouched(field._id));
  const affectedFieldsMap = affectedInputFields.reduce((prev, field) => ({ ...prev, [field._id]: field }), {});
  const requiredStartOfServiceCategories = categories
    .filter((c) =>
      find(c.items, (i) => values[i._id] && (i.paymentInterval === 'monthly' || i.paymentInterval === 'yearly')),
    )
    .map((c) => `${c._id}_startOfService`);
  const allItems = categories.map((category) => category.items).flat();
  const recursiveItems = allItems.filter((item) => values[item._id] && isItemRecursive(item));
  recursiveItems.forEach((field) => touched[field._id] || setFieldTouched(field._id));
  requiredStartOfServiceCategories.forEach((field) => touched[field] || setFieldTouched(field));

  return cachedSchema({
    currentSubmitType,
    allInputFields,
    affectedFieldsMap,
    requiredStartOfServiceCategories,
    recursiveItems,
  });
};

export const validationSchema = ({ formikRef, values, categories, currentSubmitTypeRef }) => {
  if (!formikRef?.current) return Yup.object();
  const { touched, setFieldTouched: SFT } = formikRef.current;
  const currentSubmitType = currentSubmitTypeRef.current;
  const setFieldTouched = (name) => window.setTimeout(() => SFT(name, true), 0);

  return getValidationSchema({ values, categories, currentSubmitType, touched, setFieldTouched });
};
export const startOfServiceFields = ({ categories }) =>
  categories
    .filter((c) => find(c.items, (i) => i.paymentInterval === 'monthly' || i.paymentInterval === 'yearly'))
    .map((c) => `${c._id}_startOfService`);

export const hasCategoryStartOfService = (category) => {
  return find(category.items, (i) => i.paymentInterval === 'monthly' || i.paymentInterval === 'yearly');
};
export const getInitialValues = ({ categories, dbValues, initialCompanyTypeId }) => {
  const values = merge(
    ...categories
      .map((category) =>
        [
          allInputFieldsInCategories([category]).map((field) => ({ [field._id]: undefined })),
          category.items.map((item) => ({ [item._id]: false })),
          { [`${category._id}_discount`]: undefined },
          ...(hasCategoryStartOfService(category) ? [{ [`${category._id}_startOfService`]: undefined }] : []),
        ].flat(),
      )
      .flat(),
    {
      company: null,
      contacts: [],
      contactEmail: undefined,
      showPrices: true,
      feeType: 'standard',
      showDiscounts: true,
      debugMode: false,
      documentTemplates: [],
      companyTypeId: initialCompanyTypeId || 'privatePerson',
    },
  );
  if (dbValues) return { ...values, ...dbValues };
  return values;
};
export const deselectItems = ({ categories, values, companyTypeId: currentId, setFieldValue }) => {
  forEach(categories, (category) => {
    forEach(category?.items, ({ _id, companyTypeIds }) => {
      const isAlreadyDeselected = !values[_id];
      if (isAlreadyDeselected) return;

      const isCategoryVisible = find(category?.companyTypeIds, (id) => currentId === id);
      const isItemVisible = find(companyTypeIds, (id) => currentId === id);
      if (!isCategoryVisible || !isItemVisible) setFieldValue(_id, false);
    });
  });
};
