import gql from 'graphql-tag';
import { userShoppingCartEntriesTableFragment } from '../fragments';

const SHOPPING_CART_ENTRIES_QUERY = gql`
  query shoppingCartEntries($query: String, $limit: Int!, $skip: Int, $sortField: String, $sortOrder: Int) {
    shoppingCartEntries(query: $query, limit: $limit, skip: $skip, sortField: $sortField, sortOrder: $sortOrder) {
      _id
      count
      shoppingCarts {
        ...userShoppingCartEntriesTableFragment
      }
    }
  }
  ${userShoppingCartEntriesTableFragment}
`;

export default SHOPPING_CART_ENTRIES_QUERY;
