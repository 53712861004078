import { PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { Button, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import { useCallback, useState } from 'react';
import FormItem from 'components/common/FormItem';
import { useQuery } from '@apollo/react-hooks';
import { userCompanyListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { createCompany } from 'graphql/methods/admin/company';
import apollo from 'graphql/apollo';
import { userCompanyFragment } from 'graphql/fragments';
import classesTabs from '../ContactData.module.less';
import classes from './AddCompanyModal.module.less';
import { CompanyExistingFormikInput, CompanyForm } from '../components/Inputs';
import { companyFormTabSchema } from '../components/schema';
import CompanyCard from './CompanyCard';

const { TabPane } = Tabs;

const CreateCompanyTab = () => {
  return (
    <Form layout="vertical">
      <div className={classes.createForm}>
        <CompanyForm />
      </div>
    </Form>
  );
};
const AddExistingCompanyTab = ({ changeTab }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(userCompanyListQuery, { fetchPolicy: 'cache-and-network' });
  const companies = grabFirstGQLDataResult(data);
  return (
    <div>
      <CompanyExistingFormikInput
        changeTab={changeTab}
        companies={companies}
        loading={loading}
        placeholder={t('user.ShoppingCart.ContactData.company.inputs.company.placeholder')}
      />
    </div>
  );
};
const AddCompanyContent = () => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('1');
  const changeTab = useCallback(
    (key) => {
      setActiveKey(key);
      setFieldValue('tab', key);
    },
    [setFieldValue],
  );
  return (
    <Tabs type="card" activeKey={activeKey} className={classesTabs.tabs} onChange={changeTab}>
      <TabPane tab={t('user.ShoppingCart.ContactData.company.addExistingCompanyTab')} key="1">
        <AddExistingCompanyTab changeTab={changeTab} />
      </TabPane>
      <TabPane tab={t('user.ShoppingCart.ContactData.company.createNewCompanyTab')} key="2">
        <CreateCompanyTab />
      </TabPane>
    </Tabs>
  );
};

const addNewCompany = ({ onChange, isChange }) =>
  inputConfirmModal({
    formContent: () => <AddCompanyContent />,
    fields: [],
    onSubmit: async ({ company, tab, ...newCompany }) => {
      if (tab === '1') {
        const currentCompany = apollo.readFragment({
          id: `Company___${company}`,
          fragmentName: 'userCompanyFragment',
          fragment: userCompanyFragment,
        });
        onChange(currentCompany);
      } else {
        const { data } = await createCompany(newCompany);
        onChange(grabFirstGQLDataResult(data));
      }
    },
    value: {
      tab: '1',
      company: null,
      type: 'company',
      name: '',
      identifer: '',
      street: '',
      houseNumber: '',
      cityCode: '',
      city: '',
      country: 'DE',
    },
    headerText: i18n.t(`user.ShoppingCart.ContactData.modal.titles.${isChange ? 'changeCompany' : 'addCompany'}`),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: companyFormTabSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['name', i18n.t('user.ShoppingCart.ContactData.company.duplicatedErrorMessage')],
    },
  });

function CompanyField() {
  const { t } = useTranslation();

  const name = 'company';
  const contactsName = 'contacts';

  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const value = values[name];

  const onChange = (newValue) => {
    setFieldValue(name, newValue);
    const hasNewCompanyContacts = !!newValue.contacts?.length;
    const isPrevContactsSelected = !!values[contactsName]?.length;
    if (hasNewCompanyContacts && !isPrevContactsSelected) setFieldValue(contactsName, newValue.contacts);
    setFieldTouched(name, true, false);
  };
  const onOpenModal = () => addNewCompany({ onChange, isChange: value });
  return (
    <FormItem name="company" label="">
      {value ? (
        <CompanyCard company={value} onOpenModal={onOpenModal} onChange={onChange} />
      ) : (
        <div>
          <Button icon={<PlusOutlined />} onClick={onOpenModal} type="primary">
            {t('user.ShoppingCart.ContactData.buttons.addCompany')}
          </Button>
        </div>
      )}
    </FormItem>
  );
}
export default CompanyField;
