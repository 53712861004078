import gql from 'graphql-tag';
import { adminFontFragment } from 'graphql/fragments';

const ADMIN_AVAILABLE_FONTS = gql`
  query adminAvailableFonts {
    availableFonts {
      ...adminFontFragment
    }
  }
  ${adminFontFragment}
`;

export default ADMIN_AVAILABLE_FONTS;
