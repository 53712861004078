import apollo from 'graphql/apollo';
import gql from 'graphql-tag';
import { documentTemplateBlockDescriptorFragment } from 'graphql/fragments';

export const SET_DOCUMENT_BLOCKS_WRITE_FRAGMENT = gql`
  fragment setDocumentBlocks on DocumentTemplate {
    blocks {
      _id
      name
      props
      descriptor {
        ...documentTemplateBlockDescriptorFragment
      }
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default function moveDocumentBlock({ documentTemplateId, blocks }) {
  apollo.writeFragment({
    id: `DocumentTemplate___${documentTemplateId}`,
    data: { blocks },
    fragment: SET_DOCUMENT_BLOCKS_WRITE_FRAGMENT,
    fragmentName: 'setDocumentBlocks',
  });
}
