import { some } from 'lodash';
import { Transforms, Editor, Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';
import { Button } from 'antd';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const definitions = {
  marks: ['bold', 'italic', 'underline'],
  blocks: [
    'bulleted-list',
    'heading-one',
    'heading-three',
    'heading-two',
    'list-item',
    'mention',
    'link',
    'numbered-list',
  ],
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });
  return match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks && marks[format];
};
const toggleBlock = (editor, format, { removeMarks, rootElement = 'div' } = {}) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });
  const newProperties = {
    // eslint-disable-next-line no-nested-ternary
    type: isActive ? rootElement : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);
  if (removeMarks) {
    Transforms.unsetNodes(editor, ['bold', 'underline', 'italic'], {
      match: Text.isText,
      at: {
        anchor: { offset: 0, path: [editor.selection.anchor.path[0], 0] },
        focus: { offset: 9999, path: [editor.selection.focus.path[0], 9999] },
      },
    });
  }
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
export const BlockButton = ({ format, icon, disableIn, removeMarks, rootElement, customFn }) => {
  const editor = useSlate();
  const isBlock = definitions.blocks.find((f) => f === format);

  const isDisabled = some(disableIn, (type) => isBlockActive(editor, type) || isMarkActive(editor, type));
  return (
    <Button
      size="small"
      disabled={isDisabled}
      type={(isBlock ? isBlockActive : isMarkActive)(editor, format) ? 'primary' : 'default'}
      onMouseDown={(event) => {
        event.preventDefault();
        const fn = customFn || (isBlock ? toggleBlock : toggleMark);
        fn(editor, format, { removeMarks, rootElement });
      }}
      icon={icon}
    />
  );
};
