import { Collapse } from 'antd';
import HeaderCollapse from 'components/admin/components/HeaderCollapse/HeaderCollapse';
import { AiOutlineRight } from 'react-icons/ai';
import { GrDrag } from 'react-icons/gr';
import { colors } from 'styles/colors';
import classes from './CategoryDrag.module.less';

const { Panel } = Collapse;
const CategoryDrag = ({ item: category }) => {
  const { name } = category;
  return (
    <div className={classes.layer}>
      <Collapse
        defaultActiveKey={undefined}
        expandIcon={() => (
          <div className={classes.expandIcon}>
            <GrDrag size={14} className={classes.drag} />
            <AiOutlineRight size={14} color={colors.expandArrow} />
          </div>
        )}
        expandIconPosition="left"
      >
        <Panel forceRender header={<HeaderCollapse name={name} isActive={false} />} />
      </Collapse>
    </div>
  );
};

export default CategoryDrag;
