// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e1OAnFzZRUaDJfwy1QlWN {\n  height: auto;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-left: 25px;\n  max-width: 100%;\n}\n._3Ynssiijs7T56NvJThI53V {\n  height: auto;\n  word-break: break-word;\n  padding-left: 25px;\n}\n._3vuRnJ7PK1wM18XbkuCCP3 {\n  border: 1px solid #f0f0f0;\n}\n._20bbNX7jvQCPmruBoYb7o0 {\n  border-bottom: 1px solid #d9d9d9 !important;\n}\n._20bbNX7jvQCPmruBoYb7o0 > :first-child {\n  border-bottom: 0 !important;\n}\n._20bbNX7jvQCPmruBoYb7o0 > :first-child > :first-child > :nth-child(2) {\n  padding: 0 30px 0 0 !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/CollapsibleCommonCategory.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACF;AAEA;EACE,YAAA;EACA,sBAAA;EACA,kBAAA;AAAF;AAEA;EACE,yBAAA;AAAF;AAEA;EACE,2CAAA;AAAF;AADA;EAGI,2BAAA;AACJ;AAJA;EAKM,8BAAA;AAEN","sourcesContent":[".headerInactiveName {\n  height: auto;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-left: 25px;\n  max-width: 100%;\n}\n\n.headerActiveName {\n  height: auto;\n  word-break: break-word;\n  padding-left: 25px;\n}\n.itemsContainer {\n  border: 1px solid #f0f0f0;\n}\n.collapse {\n  border-bottom: 1px solid #d9d9d9 !important;\n  > :first-child {\n    border-bottom: 0 !important;\n    > :first-child > :nth-child(2) {\n      padding: 0 30px 0 0 !important;\n    }\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerInactiveName": "e1OAnFzZRUaDJfwy1QlWN",
	"headerActiveName": "_3Ynssiijs7T56NvJThI53V",
	"itemsContainer": "_3vuRnJ7PK1wM18XbkuCCP3",
	"collapse": "_20bbNX7jvQCPmruBoYb7o0"
};
export default ___CSS_LOADER_EXPORT___;
