import gql from 'graphql-tag';
import { adminCompanyFragment } from 'graphql/fragments';

const UPDATE_COMPANY_MUTATION = gql`
  mutation updateCompany($_id: ID!, $modifier: UpdateCompanyInput) {
    updateCompany(_id: $_id, modifier: $modifier) {
      ...adminCompanyFragment
    }
  }
  ${adminCompanyFragment}
`;

export default UPDATE_COMPANY_MUTATION;
