import gql from 'graphql-tag';
import apollo from 'graphql/apollo';
import { adminDiscountFragment } from 'graphql/fragments';

export const SET_CATEGORY_DISCOUNT_WRITE_FRAGMENT = gql`
  fragment setCategoryDiscount on Category {
    discounts {
      ...adminDiscountFragment
    }
  }
  ${adminDiscountFragment}
`;
export const GET_TYPENAME_CATEGORY = gql`
  query getCategories {
    categories {
      __typename
    }
  }
`;
export default function updateCategoryDiscount({ _id, discounts }) {
  const { categories } = apollo.readQuery({
    query: GET_TYPENAME_CATEGORY,
  });
  if (!categories.length) return;

  const { __typename } = categories[0];

  apollo.writeFragment({
    id: `${__typename}___${_id}`,
    fragment: SET_CATEGORY_DISCOUNT_WRITE_FRAGMENT,
    fragmentName: 'setCategoryDiscount',
    data: { discounts },
  });
}
