import gql from 'graphql-tag';
import { adminItemFragment } from 'graphql/fragments';

const UPDATE_ITEM_MUTATION = gql`
  mutation updateCategoryItem($_id: ID!, $modifier: CategoryItemInput!) {
    updateCategoryItem(_id: $_id, modifier: $modifier) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default UPDATE_ITEM_MUTATION;
