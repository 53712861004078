// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1u2TR7mMf5SWzFGw5LDXMk > :first-child {\n  display: flex;\n  align-items: center;\n  padding: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/DocumentTemplateConfiguration/DocumentLayoutBlockForm.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EAEA,qBAAA;AADJ","sourcesContent":[".switch {\n  > :first-child {\n    display: flex;\n    align-items: center;\n\n    padding: 0 !important;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "_1u2TR7mMf5SWzFGw5LDXMk"
};
export default ___CSS_LOADER_EXPORT___;
