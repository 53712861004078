import { Button, Modal } from 'antd';
import { memo, useCallback } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import { PricingFormulaSuggestionsFormik } from 'components/admin/itemModal/PricingFormulaSuggestions';
import classes from './MoreInfoWidget.module.less';

function MoreInfoWidgetContent({ videoUrl, helpText, videoCaption, hidePricingFormulaSuggestions }) {
  return (
    <div className={classes.modalHelper}>
      {videoUrl ? <ReactPlayer controls url={videoUrl} /> : null}
      {videoCaption ? <p>{videoCaption}</p> : null}
      {helpText ? <p>{helpText}</p> : null}
      {!hidePricingFormulaSuggestions ? <PricingFormulaSuggestionsFormik /> : null}
    </div>
  );
}

function MoreInfoWidget({ buttonClassName, buttonText, title, helpText, videoCaption, videoUrl }) {
  const [modal, contextHolder] = Modal.useModal();
  const onOpenInfoModal = useCallback(() => {
    modal.info({
      maskClosable: true,
      title,
      content: <MoreInfoWidgetContent videoUrl={videoUrl} helpText={helpText} videoCaption={videoCaption} />,
      width: 800,
    });
  }, [modal, title, videoUrl, helpText, videoCaption]);
  return (
    <>
      {contextHolder}
      <Button type="link" className={cn(classes.button, buttonClassName)} onClick={onOpenInfoModal}>
        {buttonText}
      </Button>
    </>
  );
}

export default memo(MoreInfoWidget);
