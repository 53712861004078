// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._21lgjeiZoxrbpCya4rhbD1 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n._21lgjeiZoxrbpCya4rhbD1 ._3zCNMxrKvp49-BdGIBtygJ {\n  color: #e0e0e0;\n}\n", "",{"version":3,"sources":["webpack://src/components/common/EmptyBox.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAHA;EAMI,cAAA;AAAJ","sourcesContent":["@import 'styles/variables.less';\n\n.emptyBox {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  .emptyBoxText {\n    color: @light-gray-color;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyBox": "_21lgjeiZoxrbpCya4rhbD1",
	"emptyBoxText": "_3zCNMxrKvp49-BdGIBtygJ"
};
export default ___CSS_LOADER_EXPORT___;
