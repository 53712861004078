// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2lHuvK6ZlR4fjsCUrdXR6m {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n@media (min-width: 768px) {\n  ._2lHuvK6ZlR4fjsCUrdXR6m {\n    flex-direction: row;\n  }\n  ._2lHuvK6ZlR4fjsCUrdXR6m > :not(:last-child) {\n    margin-right: 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/CompanySetting/CompanySetting.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,sBAAA;AACF;AAAE;EAAA;IACE,mBAAA;EAGF;EAFE;IACE,kBAAA;EAIJ;AACF","sourcesContent":[".group {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  @media (min-width: 768px) {\n    flex-direction: row;\n    & > :not(:last-child) {\n      margin-right: 20px;\n    }\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "_2lHuvK6ZlR4fjsCUrdXR6m"
};
export default ___CSS_LOADER_EXPORT___;
