import gql from 'graphql-tag';

const adminCompanyTypeFragment = gql`
  fragment adminCompanyTypeFragment on CompanyType {
    _id
    isSelfBooker
    label
  }
`;

export default adminCompanyTypeFragment;
