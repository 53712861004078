export default {
  CATEGORY: 'category',
  CATEGORY_DISCOUNT: 'category_discount',
  CATEGORY_INPUT: 'category_input',
  CATEGORY_INPUT_OPTION: 'category_input_option',
  CATEGORY_ITEM: 'category_item',
  CATEGORY_STATIC_FIELD: 'category_static_field',
  EMPTY: 'empty',
  // // // // // // // // // // // //
  DOCUMENT_BLOCK: 'document_block',
};
