import ItemTypes from 'constants/ItemTypes';
import { RowProvider } from 'contexts/RowContext';
import { useDragNDrop } from 'hooks/useDragNDrop';

const DraggableBodyRow = ({
  children,
  record,
  index,
  itemTypes,
  move,
  mutation,
  extraDropProps,
  onBeginDrag,
  onDrop,
  isConnectedPreview = true,
  context,
  ...props
}) => {
  const { ref, drop, drag, preview, isDragging, isDraggingInContainer } = useDragNDrop({
    record,
    onDrop,
    rowIndex: index,
    moveMutation: mutation?.move || mutation,
    moveMutationCache: move,
    onBeginDrag,
    extraDropProps,
    itemTypes: itemTypes || ItemTypes.EMPTY,
  });
  if (isConnectedPreview) preview(drop(ref));
  else drop(ref);

  return (
    <RowProvider value={{ preview, drag, isDragging, isDraggingInContainer, ...context }}>
      <tr {...props} ref={ref}>
        {children}
      </tr>
    </RowProvider>
  );
};

export default DraggableBodyRow;
