import Icon from 'atom/Icon';
import s from './EmptyBox.module.less';
// export default function EmptyBox({ label }) {
//   return (
//     <>
//       <div style={{ position: 'relative', height: '64px', display: 'flex', flex: 1, justifyContent: 'center' }}>
//         <Icon name="inbox" width={64} height={64} />
//       </div>

//       <div>{label}</div>
//     </>
//   );
// }

export default function EmptyBox({ children, label }) {
  return (
    <div className={s.emptyBox}>
      <Icon name="inbox" width={64} height={64} />
      <p className={s.emptyBoxText}>{children || label}</p>
    </div>
  );
}
