import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { InputNumber, Radio } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  createDiscount,
  deleteDiscountWithConfirmation,
  isDiscountUsedInCart,
  updateDiscount,
  moveCategoryDiscount,
} from 'graphql/methods';
import { updateDiscountsCategoryCache } from 'graphql/cache';
import confirmModal from 'utils/confirmModal';
import { TableMemo } from 'memo';
import ItemTypes from 'constants/ItemTypes';
import { memo } from 'react';
import { clientShift } from 'utils/helpers';
import EmptyBox from 'components/common/EmptyBox';
import { useLibraryContext } from 'contexts/LibraryContext';
import { getCategoryListQuery } from 'graphql/queries';
import NumberI18n from 'components/common/NumberI18n';
import DragButton from './drag/DragButton';
import DraggableBodyRow from './drag/DraggableBodyRow';
import classes from './CollapsibleCategoryConfiguration.module.less';

const getDiscountFields = () => [
  {
    label: i18n.t('admin.discountFieldModal.nameFieldLabel'),
    name: 'name',
    description: i18n.t('admin.discountFieldModal.nameFieldDescription'),
  },
  {
    label: i18n.t('admin.discountFieldModal.typeFieldLabel'),
    name: 'type',
    component: (props) => {
      return (
        <Radio.Group {...props}>
          <Radio.Button value="percent">{i18n.t('admin.discountFieldModal.typeFieldPercent')}</Radio.Button>
          <Radio.Button value="absolute">{i18n.t('admin.discountFieldModal.typeFieldAbsolute')}</Radio.Button>
        </Radio.Group>
      );
    },
  },
  {
    label: i18n.t('admin.discountFieldModal.valueFieldLabel'),
    name: 'value',
    description: i18n.t('admin.discountFieldModal.nameFieldDescription'),
    component: InputNumber,
    props: ({ formikContext: { values } }) => {
      const isPercent = values.type === 'percent';
      return {
        min: 0,
        max: isPercent ? 100 : Infinity,
        formatter: (value) => `${value}${isPercent ? '%' : ' €'}`,
        parser: (value) => value.replace(/\s*[%€]/, ''),
      };
    },
  },
];

const addDiscount = (categoryId, categoryListQuery) =>
  inputConfirmModal({
    headerText: i18n.t('admin.discountFieldModal.addModalTitle'),
    fields: getDiscountFields(),
    value: { name: '', value: 10, type: 'percent' },
    onSubmit: ({ name, value, type }) => createDiscount({ categoryId, name, value, type, categoryListQuery }),
    errorResolver: { Duplicated: ['name', i18n.t('admin.discountFieldModal.duplicatedErrorMessage')] },
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
  });
const editDiscount = ({ _id, currentName, currentValue, currentType }) =>
  inputConfirmModal({
    headerText: i18n.t('admin.discountFieldModal.editModalTitle'),
    onSubmit: async ({ name, value, type }) => {
      const used = await isDiscountUsedInCart({ _id });
      const onConfirm = () => updateDiscount({ _id, name, value, type });
      if (!used) return onConfirm();
      return confirmModal({
        cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
        okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
        okType: 'danger',
        onOk: onConfirm,
        title: i18n.t('admin.discountUsedInShoppingCartConfirmation.titleUpdate'),
      });
    },
    fields: getDiscountFields(),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    value: { name: currentName, value: currentValue, type: currentType },
    errorResolver: { Duplicated: ['name', i18n.t('admin.discountFieldModal.duplicatedErrorMessage')] },
  });
const discountTableColumns = ({ t, categoryListQuery }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragButton />,
    key: 'sort',
  },
  {
    title: t('admin.discountTableColumns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('admin.discountTableColumns.type'),
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (v) => t(`common.Discount.${v}`),
  },
  {
    title: t('admin.discountTableColumns.value'),
    dataIndex: 'value',
    key: 'value',
    width: 10,
    render: (v, row) => (
      <span className="white-space-nowrap">{`${NumberI18n(v) || 0}${row.type === 'percent' ? '%' : ' €'}`}</span>
    ),
  },
  {
    title: t('admin.discountTableColumns.actions'),
    dataIndex: '',
    key: 'actions',
    width: 102,
    render: ({ _id, name, value, type }) => {
      return (
        <>
          <Button
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editDiscount({ _id, currentName: name, currentValue: value, currentType: type })}
          />{' '}
          <Button
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteDiscountWithConfirmation({ _id, categoryListQuery })}
          />
        </>
      );
    },
  },
];

const DiscountsTab = ({ _id, discounts }) => {
  const { t } = useTranslation();

  const move = (dragIndex, hoverIndex, discountId) => {
    const newDiscounts = clientShift({ array: discounts, _id: discountId, shift: hoverIndex - dragIndex });
    updateDiscountsCategoryCache({ _id, discounts: newDiscounts });
  };

  const { isLibrary } = useLibraryContext();
  const categoryListQuery = getCategoryListQuery(isLibrary);

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => addDiscount(_id, categoryListQuery)} type="primary">
        {t('admin.addDiscountButton')}
      </Button>
      <br />
      <br />
      <div className={classes.wrapperTable}>
        <TableMemo
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyDiscountListMessage')} /> }}
          rowKey={(record) => record?._id}
          components={{
            body: {
              row: DraggableBodyRow,
            },
          }}
          onRow={(record, index) => ({
            index,
            record,
            move,
            itemTypes: _id + ItemTypes.CATEGORY_DISCOUNT,
            mutation: {
              move: moveCategoryDiscount(categoryListQuery),
            },
          })}
          scroll={{
            x: 388,
          }}
          dataSource={discounts}
          pagination={false}
          columns={discountTableColumns({ t, categoryListQuery })}
        />
      </div>
    </>
  );
};
export default memo(DiscountsTab);
