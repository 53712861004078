import gql from 'graphql-tag';
import { CURRENT_USER_FRAGMENT } from 'hooks/auth/useCurrentUser';

const resetPassword = gql`
  mutation resetPassword($email: String!, $newPassword: String!, $otp: String!) {
    resetPassword(email: $email, newPassword: $newPassword, otp: $otp) {
      token
      refreshToken
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export default resetPassword;
