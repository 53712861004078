import gql from 'graphql-tag';
import { CURRENT_USER_FRAGMENT } from 'hooks/auth/useCurrentUser';

const updateProfile = gql`
  mutation updateProfile($profile: UserProfileInput) {
    updateProfile(profile: $profile) {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export default updateProfile;
