import gql from 'graphql-tag';
import { adminDocumentTemplateByIdFragment } from 'graphql/fragments';

const UPDATE_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation updateDocumentTemplate($modifier: UpdateDocumentTemplateInput!, $_id: ID!) {
    updateDocumentTemplate(_id: $_id, modifier: $modifier) {
      ...adminDocumentTemplateByIdFragment
    }
  }
  ${adminDocumentTemplateByIdFragment}
`;

export default UPDATE_DOCUMENT_TEMPLATE_MUTATION;
