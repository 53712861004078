import gql from 'graphql-tag';
import { adminTenantCompanyFragment } from 'graphql/fragments';

const UPDATE_TENANT_COMPANY_MUTATION = gql`
  mutation updateTenantCompany($company: TenantCompanyInput!) {
    updateTenantCompany(company: $company) {
      ...adminTenantCompanyFragment
    }
  }
  ${adminTenantCompanyFragment}
`;

export default UPDATE_TENANT_COMPANY_MUTATION;
