import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { useFormikContext } from 'formik';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { Input } from 'formik-antd';
import { updateTheme } from 'graphql/methods';
import I18nFormik from 'components/common/I18nFormik';
import { ColorPickerFormikInput, FontFormikInput } from './components/Inputs';
import classes from './CorporateIdentitySetting.module.less';

const bodyPrimaryTableColumns = ({ t }) => [
  {
    key: 'name',
    dataIndex: 'name',
    render: (name) => t(`admin.Setting.CorporateIdentity.BodyPrimary.inputs.${name}`),
    width: 130,
  },
  {
    title: t('admin.Setting.CorporateIdentity.BodyPrimary.field.fontFamily'),
    key: 'font',
    render: ({ name }) => {
      return (
        <div className={classes.fontInputWrapper}>
          <FontFormikInput name={`${name}.fontFamily`} />
        </div>
      );
    },
  },
  {
    title: t('admin.Setting.CorporateIdentity.BodyPrimary.field.color'),
    key: 'colour',
    render: ({ name }) => {
      return <ColorPickerFormikInput className={classes.picker} name={`${name}.color`} />;
    },
    width: 215,
  },
  {
    title: t('admin.Setting.CorporateIdentity.BodyPrimary.field.background'),
    key: 'colour',
    render: ({ name }) => {
      return (
        <ColorPickerFormikInput
          className={classes.picker}
          name={`${name}.background`}
          disabled={name === 'bodyPrimaryFooter'}
        />
      );
    },
    width: 215,
  },
  {
    title: t('admin.Setting.CorporateIdentity.BodyPrimary.field.marginBottom'),
    key: 'font',
    render: ({ name }) => {
      return <Input className={classes.picker} name={`${name}.marginBottom`} disabled={name === 'bodyPrimaryFooter'} />;
    },
  },
  {
    title: t('admin.Setting.CorporateIdentity.BodyPrimary.field.marginTop'),
    key: 'font',
    render: ({ name }) => {
      return <Input className={classes.picker} name={`${name}.marginTop`} disabled={name === 'bodyPrimaryFooter'} />;
    },
  },
];

const BodyPrimaryTable = ({ styles }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableMemo
        bordered={false}
        dataSource={styles}
        pagination={false}
        columns={bodyPrimaryTableColumns({ t })}
        scroll={{ x: 1000 }}
      />
    </>
  );
};
const BodyPrimaryTableMemo = memo(BodyPrimaryTable, equal);

const BodyPrimaryFormContent = () => {
  const { values } = useFormikContext();
  const styles = useMemo(() => {
    return [
      { ...values.bodyPrimary, name: 'bodyPrimary' },
      { ...values.bodyPrimaryFooter, name: 'bodyPrimaryFooter' },
    ];
  }, [values.bodyPrimary, values.bodyPrimaryFooter]);
  return <BodyPrimaryTableMemo styles={styles} />;
};

const BodyPrimaryFormContentMemo = memo(BodyPrimaryFormContent, equal);

function BodyPrimary({ bodyPrimary, bodyPrimaryFooter }) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      bodyPrimary: {
        fontFamily: bodyPrimary?.fontFamily || '',
        color: bodyPrimary?.color || '#000000',
        background: bodyPrimary?.background || '#ffffff',
        marginBottom: bodyPrimary?.marginBottom || '',
        marginTop: bodyPrimary?.marginTop || '',
      },
      bodyPrimaryFooter: {
        fontFamily: bodyPrimaryFooter?.fontFamily || '',
        color: bodyPrimaryFooter?.color || '#000000',
      },
    }),
    [bodyPrimary, bodyPrimaryFooter],
  );
  return (
    <Card title={t('admin.Setting.CorporateIdentity.BodyPrimary.title')}>
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(modifier) => {
          updateTheme({ pdf: modifier });
        }}
      >
        <div>
          <BodyPrimaryFormContentMemo />
          <SaveChangesButton initialValues={initialValues} />
        </div>
      </I18nFormik>
    </Card>
  );
}

export default memo(BodyPrimary, equal);
