import { LibraryProvider } from 'contexts/LibraryContext';
import DocumentTemplateConfiguration from './DocumentTemplateConfiguration';

export default function CommonLibraryDocumentTemplate(props) {
  return (
    <LibraryProvider value={{ isLibrary: true }}>
      <DocumentTemplateConfiguration {...props} />
    </LibraryProvider>
  );
}
