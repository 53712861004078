import gql from 'graphql-tag';

const adminPdfTableThemeFragment = gql`
  fragment adminPdfTableThemeFragment on PdfTableTheme {
    fontSize
    marginBottom
    marginTop
  }
`;

export default adminPdfTableThemeFragment;
