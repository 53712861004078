import gql from 'graphql-tag';

const adminInputFieldFragment = gql`
  fragment adminInputFieldFragment on CategoryInputField {
    _id
    name
    type
    order
    parentType
    parentId
    categoryId
    category {
      _id
      name
    }
    parent {
      ... on Category {
        _id
        name
      }
      ... on CategoryItem {
        _id
        name
      }
    }
    options {
      _id
      order
      value
      name
    }
  }
`;

export default adminInputFieldFragment;
