import gql from 'graphql-tag';
import { userContactFragment } from '../fragments';

const USER_CONTACT_LIST_QUERY = gql`
  query userContactList {
    contacts {
      ...userContactFragment
    }
  }
  ${userContactFragment}
`;

export default USER_CONTACT_LIST_QUERY;
