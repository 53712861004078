import equal from 'fast-deep-equal/es6/react';
import { DatePicker as I } from 'formik-antd';
import { useFunctionToRefCB } from 'memo';
import { memo } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { useTranslation } from 'react-i18next';

const { useFormikContext } = require('formik');

const II = memo(I, equal);
export const InputDate = (props) => {
  const { i18n } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { format = 'MM/YYYY' } = props;
  return (
    <II
      {...props}
      format={format}
      key={i18n.language}
      // https://github.com/jannikbuschke/formik-antd/issues/151
      // format issue dirty hack with moment applied below
      onChange={useFunctionToRefCB((e, string) => {
        setFieldValue(props.name, string ? moment(string, format).format('YYYY-MM') : undefined);
      })}
    />
  );
};

const InputDateMemo = memo(InputDate, equal);
export default InputDateMemo;
