import gql from 'graphql-tag';
import { adminCategoryListFragment, adminInputFieldFragment, adminItemFragment } from '../fragments';

const ADMIN_CATEGORY_INPUT_FIELDS = gql`
  query categoryInputs($categoryId: ID!) {
    categoryInputs(categoryId: $categoryId) {
      ...adminInputFieldFragment
    }
    categoryItems(categoryId: $categoryId) {
      ...adminItemFragment
    }
    categories {
      ...adminCategoryListFragment
    }
  }
  ${adminInputFieldFragment}
  ${adminItemFragment}
  ${adminCategoryListFragment}
`;

export default ADMIN_CATEGORY_INPUT_FIELDS;
