import gql from 'graphql-tag';
import { adminThemeFragment } from 'graphql/fragments';

const UPDATE_THEME_MUTATION = gql`
  mutation updateTheme($theme: ThemeInput) {
    updateTheme(theme: $theme) {
      ...adminThemeFragment
    }
  }
  ${adminThemeFragment}
`;

export default UPDATE_THEME_MUTATION;
