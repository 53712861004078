import { confirmAlert } from '@uplab/react-confirm-alert';
import { setGraphqlErrors } from 'components/common/ErrorComponent';
import { Input } from 'formik-antd';
import { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import InputConfirmModal from './InputConfirmModalComponent';

const InputConfirmModalContainer = ({
  bodyText,
  errorResolver,
  fields,
  formContent,
  forceMultiField,
  headerText,
  okText,
  cancelText,
  // notRenderFormik,
  onClose, // unmount
  onReject,
  onSubmit,
  validationSchema,
  value,
  width,
  onChange,
  ...props
  // formikContext,
}) => {
  const [isSubmitting, setIsSubmitting] = useState();
  const [isClosing, setIsClosing] = useState(false);
  const formikRef = useRef();
  const validationSchema_ = useCallback((field, values) => validationSchema({ formikRef, values }), [validationSchema]);
  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.persist(); // fix for not reusing this SyntheticEvent.
    onReject && event.clickedOnCancelButton && onReject();
    setIsClosing(true);
  };
  const onSubmit_ = async (values, formik) => {
    setIsSubmitting(true);
    if (isSubmitting) return;
    try {
      await onSubmit(fields.length === 1 && !forceMultiField ? Object.values(values)[0] : values);
      setIsClosing(true);
    } catch (e) {
      formik.setErrors(setGraphqlErrors({ error: e, errorResolver }));
    }
    setIsSubmitting(false);
  };
  return (
    <InputConfirmModal
      {...props}
      bodyText={bodyText}
      closeModal={onClose}
      errorResolver={errorResolver}
      fields={fields}
      formContent={formContent}
      forceMultiField={forceMultiField}
      handleClose={handleClose}
      headerText={headerText}
      isClosing={isClosing}
      isSubmitting={isSubmitting}
      okText={okText}
      cancelText={cancelText}
      // notRenderFormik={notRenderFormik}
      onSubmit={onSubmit_}
      ref={formikRef}
      validationSchema={validationSchema_}
      value={value}
      // formikContext={formikContext}
      width={width}
      onChange={onChange}
    />
  );
};
export const defaultSchema = (fields) => ({ formikRef }) =>
  Yup.object().shape({
    ...fields.reduce(
      (prev, field) => ({
        ...prev,
        ...(field.shouldRenderFn && !field.shouldRenderFn(formikRef.current)
          ? {}
          : {
              [field.name]:
                field?.validationSchema?.({ formikRef }) ||
                Yup.string()
                  .label(field.description)
                  .required()
                  .min(1)
                  .nullable(),
            }),
      }),
      {},
    ),
  });

const emptyFunc = () => {};
const getField = (object) => ({
  component: Input,
  description: 'New field',
  label: 'New',
  name: 'input',
  ...object,
});
export const inputConfirmModal = ({
  bodyText = '',
  errorResolver,
  fields,
  formContent,
  forceMultiField,
  headerText = 'New',
  okText,
  cancelText,
  // notRenderFormik,
  onReject = emptyFunc,
  onSubmit = emptyFunc,
  validationSchema = defaultSchema(fields),
  value = fields.length === 1 && !forceMultiField ? '' : {},
  width,
  onChange,
  ...props
  // formikContext,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <InputConfirmModalContainer
        {...props}
        bodyText={bodyText}
        errorResolver={errorResolver}
        fields={fields.map((f) => getField(f))}
        formContent={formContent}
        forceMultiField={forceMultiField}
        headerText={headerText}
        // notRenderFormik={notRenderFormik}
        onClose={onClose}
        okText={okText}
        cancelText={cancelText}
        onReject={onReject}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        value={value}
        width={width}
        onChange={onChange}
        // formikContext={formikContext}
      />
    ),
  });
};
export const singleInputConfirmModal = ({
  bodyText,
  okText,
  cancelText,
  fieldDescription,
  fieldLabel,
  errorResolver,
  headerText,
  onReject,
  onSubmit,
  validationSchema,
  value,
  width,
  ...props
  // notRenderFormik,
}) =>
  inputConfirmModal({
    ...props,
    bodyText,
    okText,
    cancelText,
    errorResolver,
    headerText,
    onReject,
    onSubmit,
    validationSchema,
    value,
    width,
    // notRenderFormik,
    fields: [
      getField({
        label: fieldLabel,
        description: fieldDescription,
        name: 'input',
      }),
    ],
  });

export default inputConfirmModal;
