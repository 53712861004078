import { useTranslation } from 'react-i18next';

export const PricingFormulaSuggestionsFormik = () => {
  const { t } = useTranslation();
  // const [inputFields] = useAdminCategoryInputFields({ categoryId, parentId, parentType, fetchPolicy: 'cache-only' });
  return (
    <div>
      <p className="multiline">{t('admin.pricingFormulaInput.syntaxInstructions')}</p>
      {/* {inputFields?.length ? (
          <>
            {t('admin.pricingFormulaInput.inputsListText')}
            <div>
              {inputFields.map((f) => (
                <Tooltip title={f.disabled ? getCircularTooltipTitle() : undefined}>
                  <Tag key={f.name} icon={f.disabled ? <CircularIcon /> : undefined} color={f.color}>
                    {f.name}
                  </Tag>
                </Tooltip>
              ))}
            </div>
            <p />
          </>
        ) : null} */}
      {t('admin.pricingFormulaInput.functionsListText')}
      <ul>
        <li>{t('admin.pricingFormulaInput.functions.StBVV_TabelleA')}</li>
        <li>{t('admin.pricingFormulaInput.functions.StBVV_TabelleB')}</li>
        <li>{t('admin.pricingFormulaInput.functions.StBVV_TabelleC')}</li>
        <li>{t('admin.pricingFormulaInput.functions.scale')}</li>
        <li>{t('admin.pricingFormulaInput.functions.round')}</li>
      </ul>
      <p />
    </div>
  );
};
