/* eslint-disable no-shadow */
import { Field, Input } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import * as Yup from 'yup';
import { useCallback, useRef } from 'react';
import { Button } from 'antd';
import i18n from 'i18n';
import inputConfirmModal from 'utils/inputConfirmModal';
import { InputNumber } from 'components/common/InputNumber';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/exports';
import { errorWithTouched } from 'components/user/utils';
import { useTranslation } from 'react-i18next';
import { TableMemo } from 'memo';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ObjectId } from 'utils/helpers';
import confirmModal from 'utils/confirmModal';
import toast from 'utils/toast';
import { find, sortBy } from 'lodash';
import { useFormikContext } from 'formik';
import { priceSchema } from 'components/admin/itemModal/schema';
import BorderedBox from 'components/common/BorderedBox';
import EmptyBox from 'components/common/EmptyBox';
import PricingFormulaInput from 'components/admin/itemModal/PricingFormulaInput';
import classes from './Scales.module.less';

export const priceValidationSchema = (props) => Yup.object().shape(priceSchema(props));
const scaleFieldsHook = () => {
  const inputFields = ['inputValue1', 'inputValue2', 'inputValue3', 'inputValue4', 'inputValue5'];
  const fields = inputFields.map((name) => ({
    _id: name,
    originalName: name,
    autoCompleteName: name,
    color: 'geekblue',
    prefix: '',
    type: 'scale',
    name,
    disabled: false,
  }));
  return [fields, false];
};
const scaleValidationSchema = ({ formikRef }) =>
  Yup.string()
    .label('Formula')
    .required()
    .min(1)
    .test('invalid', 'Formula is invalid', function validate(value) {
      try {
        Mentions.isValidFormula(Mentions.richTextToFormula(value));
      } catch (e) {
        return errorWithTouched({ formikRef, error: this.createError({ path: this.path }) });
      }
      return true;
    });
const getScaleFields = ({ noValue } = {}) =>
  [
    !noValue && {
      label: i18n.t('admin.itemModal.inputs.scales.inputs.value.label'),
      name: 'value',
      component: InputNumber,
      description: i18n.t('admin.itemModal.inputs.scales.inputs.value.label'),
    },
    {
      description: i18n.t('admin.itemModal.inputs.scales.inputs.formula.label'),
      name: 'pricingFormula',
      render: () => (
        <PricingFormulaInput
          name="pricingFormula"
          label={i18n.t('admin.itemModal.inputs.scales.inputs.formula.label')}
          fieldsHook={scaleFieldsHook}
          disabledFunctions={['scale']}
        />
      ),
      validationSchema: scaleValidationSchema,
    },
  ].filter(Boolean);

const addScale = ({ onSubmit, language }) =>
  inputConfirmModal({
    headerText: i18n.t('admin.itemModal.inputs.scales.addModalLabel'),
    fields: getScaleFields(),
    okText: i18n.t('admin.itemModal.inputs.scales.ok'),
    cancelText: i18n.t('admin.itemModal.inputs.scales.cancel'),
    value: { value: '', pricingFormula: '' },
    onSubmit: ({ value, pricingFormula }) => onSubmit({ value, pricingFormula }),
    errorResolver: { Duplicated: ['value', i18n.t('admin.itemModal.inputs.scales.duplicatedErrorMessage')] },
    labelCol: language === 'de' ? 8 : 6,
  });
const deleteScaleWithConfirmation = ({ _id, onSubmit }) =>
  confirmModal({
    okText: i18n.t('admin.itemModal.inputs.scales.deleteModal.ok'),
    cancelText: i18n.t('admin.itemModal.inputs.scales.deleteModal.cancel'),
    okType: 'danger',
    onOk: () => Promise.resolve(onSubmit({ _id })).then(toast.successDefault),
    title: i18n.t('admin.itemModal.inputs.scales.deleteModal.title'),
    labelCol: 6,
  });

const editScale = ({ noValue, _id, value, pricingFormula, onSubmit }) =>
  inputConfirmModal({
    headerText: i18n.t('admin.itemModal.inputs.scales.editModalLabel'),
    onSubmit: async ({ value, pricingFormula }) => onSubmit({ _id, value, pricingFormula }),
    fields: getScaleFields({ noValue }),
    okText: i18n.t('admin.itemModal.inputs.scales.ok'),
    cancelText: i18n.t('admin.itemModal.inputs.scales.cancel'),
    value: { value, pricingFormula },
    errorResolver: { Duplicated: ['value', i18n.t('admin.itemModal.inputs.scales.duplicatedErrorMessage')] },
    forceMultiField: true,
  });

const scaleTableColumns = ({ t, editScale, deleteScale, valueRef }) => [
  {
    title: t('admin.itemModal.inputs.scales.table.columns.value'),
    dataIndex: 'value',
    key: 'value',
    render: (v) => (v === null ? `>${valueRef.current[valueRef.current.length - 2].value}` : v),
  },
  {
    title: t('admin.itemModal.inputs.scales.table.columns.pricingFormula'),
    dataIndex: 'pricingFormula',
    key: 'pricingFormula',
    render: (v) => Mentions.removeBrackets(Mentions.richTextToFormula(v)),
  },
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    width: 102,
    render: ({ _id, value, pricingFormula }) => {
      return (
        <>
          <Button
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editScale({ _id, value, pricingFormula })}
          />{' '}
          {value !== null ? (
            <Button type="danger" ghost icon={<DeleteOutlined />} onClick={() => deleteScale({ _id })} />
          ) : null}
        </>
      );
    },
  },
];

const Scales = ({ value: fieldValue, onChange: change }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const createInfinityItem = () => ({
    _id: ObjectId(),
    value: null,
    pricingFormula: '[{"type":"div","children":[{"text":"0"}]}]',
  });
  const onChange = useCallback(
    (arr) => {
      const notInfinityItems = arr.filter((e) => e.value !== null);
      const infinityItem = arr.find((e) => e.value === null);
      const finalArr = arr;
      if (notInfinityItems.length) if (!infinityItem) finalArr.push(createInfinityItem());
      if (!notInfinityItems.length) return change([]);
      return change(finalArr);
    },
    [change],
  );
  const onAddScale = useCallback(
    (added) => {
      if (find(fieldValue, { value: added.value })) throw new Error('Duplicated');
      return onChange([...(fieldValue || []), { ...added, _id: ObjectId() }]);
    },
    [fieldValue, onChange],
  );
  const onDeleteScale = useCallback(({ _id }) => onChange((fieldValue || []).filter((v) => v._id !== _id)), [
    fieldValue,
    onChange,
  ]);
  const onEditScale = useCallback(
    ({ _id, value, pricingFormula }) => {
      if (find(fieldValue, (f) => f.value === value && f._id !== _id)) throw new Error('Duplicated');
      return onChange((fieldValue || []).map((v) => (v._id === _id ? { _id, value, pricingFormula } : v)));
    },
    [fieldValue, onChange],
  );
  const openAddScaleModal = useCallback(() => {
    addScale({ onSubmit: onAddScale, language });
  }, [language, onAddScale]);
  const openDeleteScaleModal = useCallback(
    ({ _id }) => {
      deleteScaleWithConfirmation({ onSubmit: onDeleteScale, _id });
    },
    [onDeleteScale],
  );
  const openEditScaleModal = useCallback(
    ({ _id, value, pricingFormula }) => {
      editScale({ noValue: value === null, onSubmit: onEditScale, _id, value, pricingFormula, language });
    },
    [language, onEditScale],
  );
  const { t } = useTranslation();
  const sortedValue = sortBy(fieldValue, 'value');
  const valueRef = useRef();
  valueRef.current = sortedValue;
  return (
    <div>
      <Button icon={<PlusOutlined />} onClick={openAddScaleModal} type="primary" className={classes.addButton}>
        {t('admin.itemModal.inputs.scales.addModalLabel')}
      </Button>
      <TableMemo
        locale={{ emptyText: <EmptyBox label={t('admin.itemModal.inputs.scales.emptyScaleList')} /> }}
        bordered
        pagination={false}
        dataSource={sortedValue}
        columns={scaleTableColumns({
          t,
          editScale: openEditScaleModal,
          deleteScale: openDeleteScaleModal,
          valueRef,
        })}
      />
    </div>
  );
};

const ScalesInputFormik = ({ name, validate, fast, onChange, ...restProps }) => (
  <Field name={name} validate={validate} fast={fast}>
    {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
      <Scales
        value={value}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
          setFieldTouched(name, true, false);
          onChange && onChange(newValue);
        }}
        {...restProps}
      />
    )}
  </Field>
);
const ScalesInput = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  if (!values.scalesEnabled) return null;
  return (
    <BorderedBox type="transparent" label={t('admin.itemModal.inputs.scales.wrapperLabel')}>
      <FormItem name="scales">
        <ScalesInputFormik name="scales" />
      </FormItem>
      <BorderedBox type="transparent" label={t('admin.itemModal.inputs.scales.pdfConfigurationLabel')}>
        <FormItem name="scaleTitle" label={t('admin.itemModal.inputs.scales.inputs.scaleTitle.label')}>
          <Input name="scaleTitle" placeholder={t('admin.itemModal.inputs.scales.inputs.scaleTitle.placeholder')} />
        </FormItem>
        <FormItem
          name="scalePricingFormulaTitle"
          label={t('admin.itemModal.inputs.scales.inputs.pricingFormulaTitle.label')}
        >
          <Input
            name="scalePricingFormulaTitle"
            placeholder={t('admin.itemModal.inputs.scales.inputs.pricingFormulaTitle.placeholder')}
          />
        </FormItem>
      </BorderedBox>
    </BorderedBox>
  );
};

export default ScalesInput;
