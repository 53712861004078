import { useFunctionToRefCB } from 'memo';
import { useState, useEffect, useMemo } from 'react';
import { usePrevious } from './helpers';

export const usePagination = (props) => {
  const { queryHook, queryParams = {}, defaultPageSize = 10 } = props;
  const [pageSize, setPageSize] = useState(defaultPageSize); // count of rows on page
  const [skip, setSkip] = useState({ skip: 0, limit: defaultPageSize }); // skip. used to paginate
  const [loadedData, setLoadedData] = useState([]); // cached data // this data antd table uses to render.
  const [pages, setPages] = useState(1); // count of pages
  const [query, setQuery] = useState(''); // search query
  const [page, setPage] = useState(1); // number that shows in antd table. 1 === first page.
  const [sort, setSort] = useState({}); // sort field
  const { data, count, loading, error, ...otherQueryData } = queryHook({ ...skip, query, ...sort, ...queryParams }); // graphql query
  const prevValues = usePrevious({ pageSize, data, pages }); // to compare props in useEffect
  useEffect(() => {
    // if user changed 'rows per page' in table
    if (pageSize !== prevValues.pageSize) {
      // if dependencies changed
      setPage(1); // return to first page
      setSkip({ ...skip, skip: 0 }); // update skip to graphql
    }
  }, [skip, pageSize, prevValues]);
  useEffect(() => {
    // set new data to cache, check page out of bounds before render new data
    const runUseEffect = data !== prevValues.data || pages !== prevValues.pages; // check dependencies
    if (data && runUseEffect) {
      // if data is loaded from graphql query
      setPages(Math.ceil(count / pageSize)); // calc and set count of pages
      const currentPage = page; // page in 'from 1' format (1,2,3...)
      if (pages < currentPage) {
        // if we are on out of bounds page
        const newCurrentPage = Math.max(pages, 1); // get nearest accessible page (page cannot be less than 1)
        setSkip({ ...skip, skip: (newCurrentPage - 1) * pageSize }); // pages starts from 1 (1,2,3...) //set skip to graphql query
        setPage(newCurrentPage); // update page in antd table
      }
      setLoadedData(data); // save data in 'loaded data' to cache data.
    }
  }, [count, data, skip, page, pageSize, pages, prevValues]);

  const onFetchData = useFunctionToRefCB(async (pagination, filters, sorter /* { currentDataSource, action } */) => {
    const { current: statePage, pageSize: limit } = pagination;
    const { field: sortField, order: sortOrder } = sorter;
    const newSort = sortOrder ? { sortField, sortOrder: sortOrder === 'ascend' ? 1 : -1 } : {};
    if (newSort.sortOrder !== sort.sortOrder || newSort.sortField !== sort.sortField) setSort(newSort);

    const newSkip = statePage < 1 ? 0 : (statePage - 1) * limit; // calc skip to graphql query
    setSkip({ skip: newSkip, limit }); // set skip to graphql query
    setPageSize(limit); // update count of rows in page
    if (statePage < 1) setPage(1); // change page on UI to first page
  });
  const showPagination = count > pageSize; // hide pagination if there are no more than 'pageSize' rows
  const pagination = useMemo(
    () =>
      showPagination
        ? {
            total: count,
            defaultPageSize,
          }
        : 'none',
    [count, defaultPageSize, showPagination],
  );
  return {
    dataSource: loadedData,
    loading,
    pagination,
    onChange: onFetchData,
    otherQueryData,
    setQuery,
    query,
    error,
  };
};

export default usePagination;
