import MoveButton from 'components/admin/drag/MoveButton';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai';
import { colors } from 'styles/colors';
import classes from './CustomExpandIcon.module.less';

function CustomExpandIcon(props) {
  const { isActive } = props;
  return (
    <div className={classes.expandIcon}>
      <MoveButton size={14} color={colors.dragButton} isPreview={false} />
      <div className={classes.arrow}>
        {isActive ? (
          <AiOutlineDown size={14} color={colors.expandArrow} />
        ) : (
          <AiOutlineRight size={14} color={colors.expandArrow} />
        )}
      </div>
    </div>
  );
}

export default memo(CustomExpandIcon, equal);
