import cn from 'classnames';
import s from './PageContainer.module.less';

export default function PageContainer({ children, className, title, right, rightProps, topComponent, ...rest }) {
  return (
    <div className={cn('container', 'xs-pt-20', className)} {...rest}>
      {topComponent}
      <div className={cn(s.header, 'container-header')}>
        <h1 className="xs-m-0">{title}</h1>
        {right ? <div {...rightProps}>{right}</div> : null}
      </div>
      {children}
    </div>
  );
}
