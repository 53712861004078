import { Button } from 'antd';
import apollo from 'graphql/apollo';
import { updateTheme } from 'graphql/methods';
import { adminMyThemeQuery } from 'graphql/queries';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';

function ApplyStyles({ style }) {
  const { t } = useTranslation();
  const onClick = useCallback(() => {
    const theme = grabFirstGQLDataResult(
      cloneDeep(
        apollo.readQuery({
          query: adminMyThemeQuery,
        }),
      ),
    );

    delete theme.pdf.__typename;

    const applyStyles = {};

    const { color, fontFamily, primaryColor } = theme.pdf.main;
    Object.entries(theme.pdf).forEach(([key, value]) => {
      if ('fontFamily' in value) {
        if (!applyStyles[key]) applyStyles[key] = {};
        applyStyles[key].fontFamily = fontFamily;
      }
    });
    ['title', 'h1', 'h2', 'h3', 'bodyPrimaryFooter'].forEach((e) => {
      if (!applyStyles[e]) applyStyles[e] = {};
      applyStyles[e].color = primaryColor;
    });
    applyStyles.bodyPrimary.color = color;
    applyStyles.bodyPrimary.background = primaryColor;
    applyStyles.tableHeaderPrimary = { background: primaryColor };

    updateTheme({
      pdf: {
        ...applyStyles,
      },
    });
  }, []);
  return (
    <Button onClick={onClick} type="primary" style={style}>
      {t('admin.Setting.CorporateIdentity.applyStyles')}
    </Button>
  );
}

export default ApplyStyles;
