import gql from 'graphql-tag';

const adminPdfMainThemeFragment = gql`
  fragment adminPdfMainThemeFragment on PdfMainTheme {
    primaryColor
    fontFamily
    color
  }
`;

export default adminPdfMainThemeFragment;
