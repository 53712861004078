// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3yb9tv-pT7g96RKI94NO4l {\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin/ItemCatalogueConfiguration/PriceTab.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".infoButton {\n  margin-bottom: 20px;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoButton": "_3yb9tv-pT7g96RKI94NO4l"
};
export default ___CSS_LOADER_EXPORT___;
