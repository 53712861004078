import { Collapse, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { moveCategoryInputField } from 'graphql/methods';
import moveCategoryInputFieldCache from 'graphql/cache/moveCategoryInputField';
import { clientShift } from 'utils/helpers';
import { memo, useCallback, useEffect, useState } from 'react';
import { adminCategoryListQuery } from 'graphql/queries';
import equal from 'fast-deep-equal/es6/react';
import { omit } from 'lodash';
import TextsTab from './TextsTab';
import InputFieldsTab from './InputFieldsTab';
import DiscountsTab from './DiscountsTab';
import classes from './CollapsibleCategoryConfiguration.module.less';
import ItemsContainer from './ItemsContainer';
import StaticItemsTab from './StaticItemsTab';
import ConditionalVisibilityTab from './ConditionalVisibilityTab';

const { TabPane } = Tabs;
const { Panel } = Collapse;

function CollapsibleCategoryContent({ category, isNew }) {
  const {
    _id,
    name,
    subTitle,
    inputFields,
    discounts,
    staticItems,
    items,
    libraryDescription,
    introForDocument,
    companyTypeIds,
  } = category;
  const { t } = useTranslation();
  const moveInputField = useCallback(
    (dragIndex, hoverIndex, idInputField) => {
      const newInputFields = clientShift({ array: inputFields, _id: idInputField, shift: hoverIndex - dragIndex });
      moveCategoryInputFieldCache({ _id, inputFields: newInputFields });
    },
    [_id, inputFields],
  );

  const [activePanel, setActivePanel] = useState([]);
  useEffect(() => {
    if (isNew) {
      setActivePanel(['0']);
    }
  }, [isNew]);

  return (
    <>
      <Collapse activeKey={activePanel} expandIconPosition="left" onChange={setActivePanel} ghost>
        <Panel header={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.title')}>
          <Tabs type="card" className={classes.tabs}>
            <TabPane tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.texts.title')} key="1">
              <TextsTab
                _id={_id}
                name={name}
                subTitle={subTitle}
                libraryDescription={libraryDescription}
                introForDocument={introForDocument}
              />
            </TabPane>
            <TabPane
              tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.inputFields.title')}
              key="2"
            >
              <InputFieldsTab
                categoryId={_id}
                moveInCache={moveInputField}
                moveMutation={moveCategoryInputField(adminCategoryListQuery)}
                parentId={_id}
                parentType="category"
                inputFields={inputFields?.filter((f) => f.parentId === _id)}
              />
            </TabPane>
            <TabPane
              tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.discounts.title')}
              key="3"
            >
              <DiscountsTab discounts={discounts} _id={_id} />
            </TabPane>
            <TabPane
              tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.staticItems.title')}
              key="4"
            >
              <StaticItemsTab staticItems={staticItems} _id={_id} />
            </TabPane>
            <TabPane
              tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.title')}
              key="5"
            >
              <ConditionalVisibilityTab companyTypeIds={companyTypeIds} parentId={_id} />
            </TabPane>
          </Tabs>
        </Panel>
      </Collapse>
      <ItemsContainer _id={_id} items={items} />
    </>
  );
}
const CollapsibleCategoryContentMemo = memo(CollapsibleCategoryContent, equal);
const CollapsibleCategoryContentContainer = ({ category, isNew }) => {
  return <CollapsibleCategoryContentMemo category={omit(category, ['order'])} isNew={isNew} />;
};
export default CollapsibleCategoryContentContainer;
