import apollo from 'graphql/apollo';
import { deleteDocumentTemplateMutation } from 'graphql/mutations';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import toast from 'utils/toast';
import { adminDocumentTemplateListQuery } from 'graphql/queries';
import { toastGraphQLError } from 'utils/helpers';
import isDocumentTemplateUsed from 'graphql/queries/isDocumentTemplateUsed';

export const deleteDocumentTemplate = async ({ _id, isLibrary }) =>
  apollo
    .mutate({
      mutation: deleteDocumentTemplateMutation,
      variables: { _id },
      refetchQueries: [{ query: adminDocumentTemplateListQuery, variables: { isLibrary } }],
    })
    .then(toast.successDefault)
    .catch(toastGraphQLError);

export const deleteDocumentTemplateWithUseCheck = async ({ _id, isLibrary }) => {
  const { data } = await apollo.query({
    query: isDocumentTemplateUsed,
    variables: { _id },
    fetchPolicy: 'network-only',
  });
  if (!data.isDocumentTemplateUsed) {
    await deleteDocumentTemplate({ _id, isLibrary });
  } else {
    confirmModal({
      cancelText: i18n.t('deleteDocumentTemplateConfirmation.cancel'),
      okText: i18n.t('deleteDocumentTemplateConfirmation.ok'),
      okType: 'danger',
      onOk: () => deleteDocumentTemplate({ _id, isLibrary }),
      title: i18n.t('deleteDocumentTemplateConfirmation.documentIsUsed'),
    });
  }
};
export default deleteDocumentTemplateWithUseCheck;

export const deleteDocumentTemplateWithConfirmation = ({ _id, isLibrary }) =>
  confirmModal({
    cancelText: i18n.t('deleteDocumentTemplateConfirmation.cancel'),
    okText: i18n.t('deleteDocumentTemplateConfirmation.ok'),
    okType: 'danger',
    onOk: () => deleteDocumentTemplateWithUseCheck({ _id, isLibrary }),
    title: i18n.t('deleteDocumentTemplateConfirmation.title'),
  });
