import apollo from 'graphql/apollo';
import { moveStaticItemMutation } from 'graphql/mutations';

const moveStaticItem = (categoryListQuery) => async ({ _id, offset, order }) => {
  return apollo.mutate({
    mutation: moveStaticItemMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery }],
  });
};
export default moveStaticItem;
