import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

const SaveChangesButton = ({ style, marginTop = 16, initialValues }) => {
  const { values, handleSubmit } = useFormikContext();
  const { t } = useTranslation();
  // console.log(values, initialValues);
  // console.lou(JSON.stringify(values, null, 2), JSON.stringify(initialValues, null, 2));
  return (
    !isEqual(values, initialValues) && (
      <Button style={{ marginTop, ...style }} onClick={handleSubmit} type="primary">
        {t('admin.textsTabSubmitButton')}
      </Button>
    )
  );
};

export default SaveChangesButton;
