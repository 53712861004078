import gql from 'graphql-tag';
import { userCompanyFragment } from '../fragments';

const USER_COMPANY_LIST_QUERY = gql`
  query userCompanyList {
    companies {
      ...userCompanyFragment
    }
  }
  ${userCompanyFragment}
`;

export default USER_COMPANY_LIST_QUERY;
