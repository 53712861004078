import gql from 'graphql-tag';
import { adminCategoryListFragment } from 'graphql/fragments';

const CREATE_COMMON_CATEGORY_MUTATION = gql`
  mutation createCommonCategory($category: CategoryInput!) {
    createCategory(category: $category, isLibrary: true) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default CREATE_COMMON_CATEGORY_MUTATION;
