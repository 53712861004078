import { Layout } from 'antd';
import AppHeader from 'components/layout/AppHeader';
import Footer from 'components/layout/Footer';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import DisplayByRoute from 'components/common/DisplayByRoute';
import Logo from 'components/layout/Logo';
import { useGetRoutePathsNavBar, useIsUserRole } from 'router/route-paths';
import classNames from 'classnames';
import s from './AppLayout.module.less';
import NavBar, { CustomTriggerMemo } from '../layout/NavBar';

const { Content } = Layout;
function LeftHeaderMenu({ visible, onClick }) {
  return (
    <div className={s.leftHeaderMenu}>
      <CustomTriggerMemo sizeIcon={25} visible={visible} onClick={onClick} />
      <Logo onClick={onClick} />
    </div>
  );
}

export default function AppLayout({ children }) {
  const routePathsNavBar = useGetRoutePathsNavBar();
  const [visible, setVisible] = useState(false);

  const isSmallWidthDevice = useMediaQuery({ maxWidth: 991 });
  useEffect(() => {
    setVisible(false);
  }, [isSmallWidthDevice]);
  const isUser = useIsUserRole();
  return (
    <Layout className={classNames(s.layout, { 'user-role': isUser })}>
      <NavBar breakpoint={isSmallWidthDevice} visible={visible} setVisible={setVisible} />
      <Layout className={s.layout}>
        <DisplayByRoute
          path={routePathsNavBar}
          component={() =>
            isSmallWidthDevice ? <LeftHeaderMenu onClick={() => setVisible(true)} visible={visible} /> : null
          }
        />
        <AppHeader />

        <Content className={s.content}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}
