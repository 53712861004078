import gql from 'graphql-tag';
import { userCategoryListFragment } from '../fragments';

const USER_CATEGORY_LIST_QUERY = gql`
  query userCategoryList {
    categories {
      ...userCategoryListFragment
    }
  }
  ${userCategoryListFragment}
`;

export default USER_CATEGORY_LIST_QUERY;
