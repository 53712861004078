/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useMemo, useRef } from 'react';
import { Modal, Skeleton } from 'antd';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { ErrorComponent } from 'components/common/ErrorComponent';
import intersectionBy from 'lodash/intersectionBy';
import { adminCategoryListQuery, libraryCategories } from 'graphql/queries';
import { importCategoriesFromLibrary } from 'graphql/methods';
import s from './ImportCategoryPresetsModal.module.less';
import CollapsibleCommonCategory from './CollapsibleCommonCategory';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const ImportCategoryPresets = ({ setIsModalVisible, ...rest }) => {
  const selectedCategoriesRef = useRef([]);
  const [duplicatedCategories, setDuplicatedCategories] = useState([]);
  const { data } = useQuery(adminCategoryListQuery);
  const categories = useMemo(() => grabFirstGQLDataResult(data) || [], [data]);

  const { t } = useTranslation();
  const { data: commonData, loading } = useQuery(libraryCategories);
  const commonCategories = useMemo(() => grabFirstGQLDataResult(commonData) || [], [commonData]);

  const handleSelectCategory = useCallback(
    (category) => {
      if (selectedCategoriesRef.current.some(({ categoryId }) => categoryId === category.categoryId)) {
        selectedCategoriesRef.current = selectedCategoriesRef.current.filter(
          ({ categoryId }) => categoryId !== category.categoryId,
        );
      } else {
        selectedCategoriesRef.current.push(category);
      }

      const intersection = intersectionBy(selectedCategoriesRef.current, categories, 'name');
      setDuplicatedCategories(intersection.reduce((acc, c) => [...acc, c.name], []));
    },
    [selectedCategoriesRef, setDuplicatedCategories, categories],
  );
  const handleCancel = () => {
    setIsModalVisible(false);
    setDuplicatedCategories([]);
    selectedCategoriesRef.current = [];
  };
  const handleOk = useCallback(async () => {
    if (!duplicatedCategories.length) {
      await importCategoriesFromLibrary(selectedCategoriesRef.current, adminCategoryListQuery);
      setIsModalVisible(false);
      setDuplicatedCategories([]);
      selectedCategoriesRef.current = [];
    } else {
      const errorEl = document.querySelector('.ant-modal');
      if (errorEl) {
        errorEl.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }
    }
  }, [duplicatedCategories.length, setIsModalVisible]);

  if (loading) {
    return skeletons.map((k) => <Skeleton title loading={loading} active key={k} />);
  }

  return (
    <Modal
      title={t('admin.importCategoryPresetsModal.title')}
      {...rest}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
    >
      {duplicatedCategories.length !== 0 ? (
        <ErrorComponent
          description={t('admin.importCategoryPresetsModal.errorMessage', {
            categoryNames: duplicatedCategories.map((c) => `"${c}"`).join(', '),
          })}
        />
      ) : null}
      {commonCategories.map((category) => {
        const isSelectedCategory = selectedCategoriesRef.current.some(({ categoryId }) => categoryId === category._id);
        const isDuplicatedCategory = duplicatedCategories.includes(category.name);

        return (
          <div
            className={cn(s.category, isDuplicatedCategory ? s.duplicatedCategory : null)}
            id={category._id}
            key={category._id}
          >
            <CollapsibleCommonCategory category={category} />
            <button
              className={s.addCategoryBtn}
              type="button"
              onClick={() => handleSelectCategory({ categoryId: category._id, name: category.name })}
            >
              {isSelectedCategory ? <CheckOutlined /> : null}
            </button>
          </div>
        );
      })}
    </Modal>
  );
};

export default ImportCategoryPresets;
