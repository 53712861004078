import React, { useMemo } from 'react';
import { Form } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import ComponentThatAllowSubmitByPressingEnter from 'components/common/ComponentThatAllowSubmitByPressingEnter';
import FormItem from 'components/common/FormItem';
import { useFormikContext } from 'formik';
import { useFormikSideEffects } from 'use-formik-side-effects';

const InputConfirmForm = (props) => {
  const { bodyText, fields, formContent: FormContent, handleSubmit, labelCol, onChange } = props;
  const formItemLayout = useMemo(
    () => ({
      labelCol: { span: labelCol },
      wrapperCol: { span: 18 },
    }),
    [labelCol],
  );
  const formikContext = useFormikContext();
  useFormikSideEffects(formikContext, onChange);
  return (
    <>
      {bodyText}
      <AlertFromFormik />
      <Form layout="vertical" onSubmitCapture={handleSubmit} {...formItemLayout}>
        {FormContent ? (
          <FormContent />
        ) : (
          fields.map(
            (
              { name, component: Component, label, props: fieldProps, shouldRenderFn, render, tooltip, wideTooltip },
              index,
            ) => {
              if (shouldRenderFn && !shouldRenderFn(formikContext)) return null;
              if (render) return render({ formikContext });
              return (
                <FormItem name={name} label={label} key={name} tooltip={tooltip} wideTooltip={wideTooltip}>
                  <Component
                    autoFocus={!index}
                    name={name}
                    {...(typeof fieldProps === 'function' ? fieldProps({ formikContext }) : fieldProps)}
                  />
                </FormItem>
              );
            },
          )
        )}
        <ComponentThatAllowSubmitByPressingEnter />
      </Form>
    </>
  );
};

export default InputConfirmForm;
