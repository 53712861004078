// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plDQasWiHLQlu_JHkf2Go {\n  position: absolute;\n  left: 20px;\n  display: flex;\n  flex-direction: row;\n}\n._2zz00Z-U9vKRIt_XEfsYSK {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/components/CustomExpandIcon/CustomExpandIcon.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;AACF;AACA;EACE,gBAAA;AACF","sourcesContent":[".expandIcon {\n  position: absolute;\n  left: 20px;\n  display: flex;\n  flex-direction: row;\n}\n.arrow {\n  margin-left: 8px;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandIcon": "plDQasWiHLQlu_JHkf2Go",
	"arrow": "_2zz00Z-U9vKRIt_XEfsYSK"
};
export default ___CSS_LOADER_EXPORT___;
