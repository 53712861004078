import gql from 'graphql-tag';

const adminTenantCompanyFragment = gql`
  fragment adminTenantCompanyFragment on TenantCompany {
    companyName
    street
    streetNr
    cityCode
    city
    phoneNr
    country
    websiteUrl
  }
`;

export default adminTenantCompanyFragment;
