import gql from 'graphql-tag';
import { adminItemFragment } from '../fragments';

export const GET_ITEM_CATEGORY_QUERY = gql`
  query ItemCatalogueConfiguration($_id: ID!) {
    categoryItem(_id: $_id) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default GET_ITEM_CATEGORY_QUERY;
