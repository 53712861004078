import gql from 'graphql-tag';
import adminPdfThemeFragment from './adminPdfThemeFragment';

const adminThemeFragment = gql`
  fragment adminThemeFragment on Theme {
    pdf {
      ...adminPdfThemeFragment
    }
  }
  ${adminPdfThemeFragment}
`;

export default adminThemeFragment;
