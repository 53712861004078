// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1G2CFkzhVg_crmpjfoy6P7 {\n  position: absolute;\n  left: 0;\n  top: 6;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 100;\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/common/LoadingOverlay.module.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AADF","sourcesContent":["@import '../../styles/variables.less';\n\n.fullscreen {\n  position: absolute;\n  left: 0;\n  top: 6;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: @z-index-loading-overlay;\n  width: 100%;\n  height: 100%;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullscreen": "_1G2CFkzhVg_crmpjfoy6P7"
};
export default ___CSS_LOADER_EXPORT___;
