/*
  A hook to fetch current user
  Add extra props to the currentUser fragment when you need it
*/
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { version } from '@JavaScriptSuperstars/kanzleipilot-shared';

export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUser on User {
    _id
    role
    email
    profile {
      firstName
      lastName
      salutation
      position
      phone
    }
    locale
    isAlpha
  }
`;

export const CURRENT_USER_QUERY = gql`
  query me {
    me {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;
export const SharedPackageVersion = gql`
  query sharedPackageVersion {
    sharedPackageVersion
  }
`;

let loggedWarning;

export default function useCurrentUser() {
  const { loading, error, data, refetch } = useQuery(CURRENT_USER_QUERY);
  const sharedPackageVersionQueryData = useQuery(SharedPackageVersion);
  const sharedPackageVersion = sharedPackageVersionQueryData?.data?.sharedPackageVersion;
  if (!loggedWarning && sharedPackageVersion && sharedPackageVersion !== version) {
    loggedWarning = true;
    console.error(
      "%cclient shared package version differs from server's shared package version",
      'color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold',
      sharedPackageVersion,
      version,
    );
  }
  return [data?.me, loading, error, refetch];
}
