import gql from 'graphql-tag';
import apollo from 'graphql/apollo';
import { adminInputFieldFragment } from 'graphql/fragments';

export const SET_ITEM_INPUT_FIElDS_WRITE_FRAGMENT = gql`
  fragment setItemItemInputFields on CategoryItem {
    inputFields {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;
export const GET_TYPENAME_CATEGORY_ITEM = gql`
  query getCategoryItem($_id: ID!) {
    categoryItem(_id: $_id) {
      __typename
    }
  }
`;
export default function moveCategoryItem({ _id, inputFields }) {
  const { categoryItem } = apollo.readQuery({
    query: GET_TYPENAME_CATEGORY_ITEM,
    variables: {
      _id,
    },
  });

  if (!categoryItem) return;

  const { __typename } = categoryItem;

  apollo.writeFragment({
    id: `${__typename}___${_id}`,
    fragment: SET_ITEM_INPUT_FIElDS_WRITE_FRAGMENT,
    fragmentName: 'setItemItemInputFields',
    data: { inputFields },
  });
}
