import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import CompanyField from './company/CompanyField';
import ContactsField from './contacts/ContactsField';

const ContactData = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('user.ShoppingCart.ContactData.label')}>
      <CompanyField />
      <ContactsField />
    </Card>
  );
};

export default ContactData;
