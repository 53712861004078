// https://github.com/formium/formik/issues/284
import equal from 'fast-deep-equal/es6/react';
import { useFormikContext } from 'formik';
import { InputNumber as I } from 'formik-antd';
import useNumberFormat, { useDecimalSeparator } from 'hooks/common/useNumberFormat';
import { useFunctionToRefCB } from 'memo';
import { memo } from 'react';

const II = memo(I, equal);
export const InputNumber = (props) => {
  const { setFieldValue } = useFormikContext();
  const { decimalSeparator, thousandSeparator } = useDecimalSeparator();
  const { formatter, parser } = useNumberFormat({ decimalSeparator, thousandSeparator });
  return (
    <II
      {...props}
      formatter={formatter}
      parser={parser}
      onChange={useFunctionToRefCB((e) => setFieldValue(props.name, e))}
    />
  );
};

const InputNumberMemo = memo(InputNumber, equal);
export default InputNumberMemo;
