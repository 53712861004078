import PageContainer from 'components/layout/PageContainer';
import { useQuery } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';
import { Alert, Skeleton, Card, Button } from 'antd';
import { getMessageFromGraphQLError, grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import CategoryInCart from 'components/user/CategoryInCart';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import EmptyBox from 'components/common/EmptyBox';
import { CategoriesContext } from 'components/user/context';
import { formikToResponse } from 'components/user/utils';
import { userCategoryListQuery } from 'graphql/queries';
import { createHtmlPreview, createShoppingCart, sendEmail, createShoppingCartRevision } from 'graphql/methods';
import { documentModal } from 'components/user/document';
import { useHistory } from 'react-router-dom';
import toast from 'utils/toast';
import routePaths from 'router/route-paths';
import confirmModal from 'utils/confirmModal';
import I18nFormik from 'components/common/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { useFormikField } from 'hooks/common/useFormikField';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import TotalPricing from './TotalPricing';
import Buttons, { useButtonState } from './Buttons';
import { ContactData } from './ContactData';
import { getInitialValues, validationSchema } from './utils';
import ShoppingCartOptions from './ShoppingCartOptions';
import s from './ShoppingCart.module.less';
import ShoppingCartPdfTypesSelect from './ShoppingCartPdfTypesSelect';
import CompanyType, { showElement } from './CompanyType';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const FormComponent = ({ categories, isEmptyList, isSubmitting, currentSubmitTypeRef }) => {
  const { t } = useTranslation();
  const buttonState = useButtonState();
  const { value: companyTypeId } = useFormikField('companyTypeId');
  return (
    <>
      <ShoppingCartOptions className={s.cartOptions} />
      <CompanyType />
      <Card title={t('user.ShoppingCart.categoriesCardTitle')} className={s.shoppingCartCategoriesCard}>
        <AlertFromFormik />
        {categories
          .map((category) => {
            const newItems = category.items.filter((item) => showElement(item.companyTypeIds, companyTypeId));
            return { ...category, items: newItems };
          })
          .filter(
            (category) =>
              category?.items?.length && showElement(category.companyTypeIds, companyTypeId) && category.items.length,
          )
          .map((category) => (
            <CategoryInCart key={category._id} category={{ ...category }} />
          ))}
        {isEmptyList ? <EmptyBox>{t('user.ShoppingCart.emptyCategoryListMessage')}</EmptyBox> : null}
      </Card>
      <br />
      <div>
        <TotalPricing />
        <ContactData />
        <br />
        <ShoppingCartPdfTypesSelect
          buttonState={buttonState}
          isSubmitting={isSubmitting}
          currentSubmitTypeRef={currentSubmitTypeRef}
        />
        <br />
        <Buttons buttonState={buttonState} isSubmitting={isSubmitting} currentSubmitTypeRef={currentSubmitTypeRef} />
        <br />
      </div>
    </>
  );
};
const FormComponentMemo = memo(FormComponent, equal);
export const ShoppingCartComponent = ({ initialCompanyTypeId, dbId, dbValues, categories }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const categoriesWithItems = categories.filter((category) => category?.items?.length);
  const isEmptyList = isEmpty(categoriesWithItems) || isEmpty(categories);
  const currentSubmitTypeRef = useRef({ type: undefined, documentId: undefined }); // save or email or pdf

  const formikRef = useRef();
  window.formikRef = formikRef; // development only
  window.categories = categories; // development only
  const initialValuesRef = useRef(getInitialValues({ categories, dbValues, initialCompanyTypeId }));
  const validationSchemaWithRef = useCallback(
    (field, values) => validationSchema({ values, formikRef, categories, currentSubmitTypeRef }),
    [categories],
  );

  const [isSubmitting, setIsSubmitting] = useState();

  const onSubmit = async (allValues) => {
    const {
      showDiscounts,
      hiddenNote,
      showPrices,
      contactEmail,
      feeType,
      documentTemplates,
      company,
      contacts,
      companyTypeId,
    } = allValues;
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const currentType = currentSubmitTypeRef.current.type;
      const currentDocumentId = currentSubmitTypeRef.current.documentId;
      const { cart } = formikToResponse({ categories, values: allValues });
      const shoppingCart = {
        categories: cart,
        showPrices,
        contact: contactEmail,
        feeType,
        showDiscounts,
        hiddenNote,
        documentTemplates,
        company: company._id,
        contacts: contacts.map((e) => e._id),
        companyTypeId,
      };
      if (currentType === 'save') {
        const createNewShoppingCart = async () =>
          createShoppingCart({ shoppingCart }).then(() => history.push(routePaths.shoppingCartEntries));
        const createNewShoppingCartRevision = async () =>
          createShoppingCartRevision({ shoppingCart, prevCartId: dbId }).then(() =>
            history.push(routePaths.shoppingCartEntries),
          );
        if (dbValues) {
          confirmModal({
            cancelText: t('user.ShoppingCart.Buttons.submitRevisionModal.newButton'),
            okText: t('user.ShoppingCart.Buttons.submitRevisionModal.revisionButton'),
            onOk: createNewShoppingCartRevision,
            onCancel: createNewShoppingCart,
            width: 800,
            title: t('user.ShoppingCart.Buttons.submitRevisionModal.title'),
          });
        } else await createNewShoppingCart();
      }

      if (currentType === 'html') {
        documentModal({ value: createHtmlPreview({ shoppingCart, currentDocumentId }), type: 'html' });
      }

      if (currentType === 'email') await sendEmail({ shoppingCart }).then(toast.successDefault);
    } catch (e) {
      toastGraphQLError(e);
    }
    window.setTimeout(() => setIsSubmitting(false), 0);
  };

  return (
    <CategoriesContext.Provider value={categories}>
      <I18nFormik
        initialValues={initialValuesRef.current}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={validationSchemaWithRef}
      >
        <FormComponentMemo
          categories={categories}
          isEmptyList={isEmptyList}
          isSubmitting={isSubmitting}
          currentSubmitTypeRef={currentSubmitTypeRef}
        />
      </I18nFormik>
    </CategoriesContext.Provider>
  );
};

const ShoppingCartComponentMemo = memo(ShoppingCartComponent, equal);

const ShoppingCartComponentContainer = (props) => {
  const { dbId } = props;
  if (dbId) return <ShoppingCartComponentMemo {...props} />;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initialCompanyTypeId, setInitialCompanyTypeId] = useState();
  if (initialCompanyTypeId) return <ShoppingCartComponentMemo initialCompanyTypeId={initialCompanyTypeId} {...props} />;
  return (
    <div className={s.initialCompanyTypeIdContainer}>
      <I18nFormik
        onSubmit={({ companyTypeId }) => setInitialCompanyTypeId(companyTypeId)}
        initialValues={{ companyTypeId: 'privatePerson' }}
      >
        {({ handleSubmit }) => (
          <>
            <CompanyType />
            <Button type="primary" onClick={handleSubmit}>
              Next
            </Button>
          </>
        )}
      </I18nFormik>
    </div>
  );
};

export const ShoppingCart = ({ dbId, dbValues, dbLoading }) => {
  const me = useCurrentUser();
  const { data, loading, error } = useQuery(userCategoryListQuery, {
    fetchPolicy: me?.[0]?.role?.includes('ADMIN') ? 'network-only' : 'cache-first', // force refresh if admin changed something to force load changes
  });
  const categories = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const { t } = useTranslation();
  return (
    <PageContainer className={s.pageContainer} title={t('user.ShoppingCart.title')}>
      <div className="xs-mt-20">
        {skeletons.map((k) => (
          <Skeleton title loading={loading || dbLoading} active key={k} />
        ))}
        {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
        {!loading && !dbLoading && !error && (
          <ShoppingCartComponentContainer
            dbId={dbId}
            dbValues={dbValues}
            dbLoading={dbLoading}
            categories={categories}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default ShoppingCart;
