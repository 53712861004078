import gql from 'graphql-tag';

const documentTemplateBlockDescriptorFragment = gql`
  fragment documentTemplateBlockDescriptorFragment on DocumentTemplateBlockDescriptor {
    name
    displayName
    form
    infoText
    previewImageThumbnailSrc
    previewImageSrc
  }
`;

export default documentTemplateBlockDescriptorFragment;
