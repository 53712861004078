import { LibraryProvider } from 'contexts/LibraryContext';
import DocumentTemplateList from './DocumentTemplateList';

export default function DocumentTemplateListContainer(props) {
  return (
    <LibraryProvider value={{ isLibrary: false }}>
      <DocumentTemplateList {...props} />
    </LibraryProvider>
  );
}
