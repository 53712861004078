import gql from 'graphql-tag';

const adminDocumentTemplateListFragment = gql`
  fragment adminDocumentTemplateListFragment on DocumentTemplate {
    _id
    name
    order
    description
    lastUsedDocumentNr
    nrOfDocumentsCreated
    createdAt
  }
`;

export default adminDocumentTemplateListFragment;
