import gql from 'graphql-tag';
import { adminCategoryListFragment } from 'graphql/fragments';

const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory($category: CategoryInput!) {
    createCategory(category: $category) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default CREATE_CATEGORY_MUTATION;
