// import React, { useState } from 'react';
// import { confirmAlert } from '@uplab/react-confirm-alert';
// import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './ConfirmModal.less';

const { confirm } = Modal;

// /* eslint-disable no-unused-vars */
// const ConfirmModal = (props) => {
//   const {
//     autoFocus,
//     bodyText,
//     bodyText2,
//     closeModal,
//     confirmButtonText,
//     confirmColor,
//     confirmIcon,
//     confirmLabel,
//     error,
//     formatError,
//     handleClose,
//     headerText,
//     icon,
//     isClosing,
//     isSubmitting,
//     onSubmit,
//     rejectIcon,
//     rejectLabel,
//     singleConfirm,
//   } = props;
//   return (
//     <Modal
//       afterClose={closeModal}
//       confirmLoading={isSubmitting}
//       onCancel={handleClose}
//       onOk={onSubmit}
//       title={headerText}
//       visible={!isClosing}
//     >
//       {bodyText || bodyText2 ? (
//         <p>
//           {bodyText}
//           {/* {bodyText2 ? (
//             <div className="bold">
//               <br />
//               {bodyText2}
//             </div>
//           ) : null} */}
//         </p>
//       ) : null}
//     </Modal>
//   );
// };

// const ConfirmModalContainer = ({
//   autoFocus,
//   bodyText = 'Are you sure?',
//   bodyText2,
//   confirmButtonText,
//   confirmColor,
//   confirmIcon,
//   confirmLabel,
//   formatError,
//   headerText = 'Header',
//   icon,
//   onClose,
//   onReject,
//   onSubmit,
//   rejectIcon,
//   rejectLabel,
//   singleConfirm,
// }) => {
//   const [isSubmitting, setIsSubmitting] = useState();
//   const [isClosing, setIsClosing] = useState(false);
//   const [error, setError] = useState();
//   const handleClose = (event) => {
//     event.stopPropagation();
//     event.preventDefault();
//     event.persist(); // fix for not reusing this SyntheticEvent.
//     onReject && event.clickedOnCancelButton && onReject();
//     setIsClosing(true);
//   };
//   const onSubmit_ = async () => {
//     setIsSubmitting(true);
//     if (isSubmitting) return;
//     try {
//       await onSubmit();
//       setError();
//       setIsClosing(true);
//     } catch (e) {
//       setError(e);
//     }
//     setIsSubmitting(false);
//   };
//   return (
//     <ConfirmModal
//       autoFocus={autoFocus}
//       bodyText2={bodyText2}
//       bodyText={bodyText}
//       closeModal={onClose}
//       confirmButtonText={confirmButtonText}
//       confirmColor={confirmColor}
//       confirmIcon={confirmIcon}
//       confirmLabel={confirmLabel}
//       error={error}
//       formatError={formatError}
//       handleClose={handleClose}
//       headerText={headerText}
//       icon={icon}
//       isClosing={isClosing}
//       isSubmitting={isSubmitting}
//       onSubmit={onSubmit_}
//       rejectIcon={rejectIcon}
//       rejectLabel={rejectLabel}
//       singleConfirm={singleConfirm}
//     />
//   );
// };

// const confirmModal = ({
//   autoFocus = true,
//   bodyText,
//   bodyText2,
//   confirmButtonText,
//   confirmColor,
//   confirmIcon,
//   confirmLabel,
//   headerText,
//   icon,
//   onReject,
//   onSubmit,
//   rejectIcon,
//   rejectLabel,
//   singleConfirm,
// }) =>
//   confirmAlert({
//     customUI: ({ onClose }) => (
//       <ConfirmModalContainer
//         autoFocus={autoFocus}
//         bodyText2={bodyText2}
//         bodyText={bodyText}
//         confirmButtonText={confirmButtonText}
//         confirmColor={confirmColor}
//         confirmIcon={confirmIcon}
//         confirmLabel={confirmLabel}
//         headerText={headerText}
//         icon={icon}
//         onClose={onClose}
//         onReject={onReject}
//         onSubmit={onSubmit}
//         rejectIcon={rejectIcon}
//         rejectLabel={rejectLabel}
//         singleConfirm={singleConfirm}
//       />
//     ),
//   });

// export default confirmModal;

const confirmModal = ({
  width,
  content,
  icon = <ExclamationCircleOutlined />,
  okText,
  cancelText,
  okType,
  onCancel,
  onOk,
  title = 'Do you Want to ...?',
  disableCloseButton,
  maskClosable = false,
}) => {
  confirm({
    width,
    content,
    icon,
    okText,
    okType,
    cancelText,
    onCancel,
    onOk,
    title,
    maskClosable,
    cancelButtonProps: disableCloseButton ? { style: { display: 'none' } } : undefined,
  });
};

export default confirmModal;
