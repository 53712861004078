import ButtonSupportOnClickWhenDisabled from 'components/common/ButtonSupportOnClickWhenDisabled';
import { useCategoriesContext } from 'components/user/context';
import { formikToResponse } from 'components/user/utils';
import { useFormikContext } from 'formik';
import { filter, find, isEmpty, map, uniq } from 'lodash';
import { Fragment, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'utils/toast';
import { useCreateRefCB } from 'memo';
import { Card } from 'antd';
import s from './Buttons.module.less';

const isSelectedItemInCart = ({ cart }) => find(cart, (cartCategory) => cartCategory.itemIds?.length);
export const useButtonState = () => {
  const { values, validateForm } = useFormikContext();
  const categories = useCategoriesContext();
  const { cart } = formikToResponse({ categories, values });
  const isSelectedItem = isSelectedItemInCart({ cart });
  const [isValidating, setIsValidating] = useState(false);
  const isValidatingRef = useRef();
  const disabled = isValidating || !isSelectedItem || !values.company || !values.contacts?.length;
  const onClickRef = useCreateRefCB();
  const { t } = useTranslation();
  onClickRef.current = (submit) => async (e) => {
    if (isValidatingRef.current) return;
    isValidatingRef.current = true;
    setIsValidating(true);
    // do task after end of render (after setState)
    const finish = () => {
      isValidatingRef.current = false;
      setIsValidating(false);
    };

    window.setTimeout(async () => {
      if (!isSelectedItem) return finish(toast.error(t('user.ShoppingCart.Buttons.errors.noItemsSelectedError')));
      if (!values.company) return finish(toast.error(t('user.ShoppingCart.Buttons.errors.noCompanyError')));
      if (!values.contacts?.length) return finish(toast.error(t('user.ShoppingCart.Buttons.errors.noContactsError')));
      const formErrors = await validateForm();
      submit(e);
      if (!isEmpty(formErrors)) {
        toast.error(t('user.ShoppingCart.Buttons.errors.formHasErrors'));
        try {
          const errorFieldNames = Object.keys(formErrors);
          const orderedFormElements = [...(document.querySelectorAll(`[fieldname]`) || [])];
          const elementsWithErrors = filter(orderedFormElements, (el) =>
            errorFieldNames.includes(el.getAttribute('fieldname')),
          );
          if (!elementsWithErrors.length) console.warn('Not found elements with error');
          const tabs = map(elementsWithErrors, (elementWithError) =>
            elementWithError
              .closest('.ant-collapse-item')
              ?.querySelector('.ant-collapse-header[aria-expanded="false"]'),
          );
          const uniqTabs = uniq(tabs.filter(Boolean));
          uniqTabs.forEach((tab) => tab.click());
          const firstElementWithError = elementsWithErrors[0];
          window.setTimeout(() => firstElementWithError?.scrollIntoView({ block: 'center', behavior: 'smooth' }), 0);
        } catch (err) {
          console.error(err);
        }
      }
      return finish();
    }, 0);
  };
  return { onClickRef, disabled };
};
export const SubmitButton = ({ isSubmitting, currentSubmitTypeRef, type, disabled, onClick, label }) => {
  return (
    <ButtonSupportOnClickWhenDisabled
      onClick={onClick}
      loading={isSubmitting && currentSubmitTypeRef.current.type === type}
      disabled={disabled || (isSubmitting && currentSubmitTypeRef.current.type !== type)}
      type="default"
      size="large"
      className={s.btn}
    >
      {label}
    </ButtonSupportOnClickWhenDisabled>
  );
};
const FragmentNoProps = ({ children }) => <>{children}</>;
const ButtonsComponent = ({
  onClickRef,
  t,
  isSubmitting,
  currentSubmitTypeRef,
  handleSubmit,
  disabled,
  schema = [{ label: t('user.ShoppingCart.Buttons.store'), type: 'save' }],
  onlyButtons,
}) => {
  const memoRefs = [useCreateRefCB(), useCreateRefCB(), useCreateRefCB(), useCreateRefCB()];
  const Container = onlyButtons ? FragmentNoProps : Card;
  return (
    <Container title={t('user.ShoppingCart.Buttons.title')}>
      {schema.map(({ label, type, submitProps = {} }, index) => (
        <Fragment key={label}>
          {index !== 0 ? ' ' : null}
          <SubmitButton
            isSubmitting={isSubmitting}
            currentSubmitTypeRef={currentSubmitTypeRef}
            type={type}
            disabled={disabled}
            onClick={memoRefs[index](
              onClickRef.current((e) => {
                if (isSubmitting) return;
                // eslint-disable-next-line no-param-reassign
                Object.assign(currentSubmitTypeRef.current, { type, ...submitProps });
                handleSubmit(e);
              }),
            )}
            label={label}
          />
        </Fragment>
      ))}
    </Container>
  );
};
export const MemoButtonsComponent = memo(ButtonsComponent);

export const Buttons = ({ currentSubmitTypeRef, isSubmitting, buttonState, schema, onlyButtons }) => {
  const { handleSubmit } = useFormikContext();
  const { t } = useTranslation();
  const { onClickRef, disabled } = buttonState;
  return (
    <MemoButtonsComponent
      onClickRef={onClickRef}
      t={t}
      isSubmitting={isSubmitting}
      currentSubmitTypeRef={currentSubmitTypeRef}
      handleSubmit={handleSubmit}
      disabled={disabled}
      schema={schema}
      onlyButtons={onlyButtons}
    />
  );
};

export default Buttons;
