import apollo from 'graphql/apollo';
import { deleteItemMutation } from 'graphql/mutations';
import i18n from 'i18n';
import { find } from 'lodash';
import confirmModal from 'utils/confirmModal';
import { clientDelete } from 'utils/helpers';
import toast from 'utils/toast';

const deleteCategoryItem = async ({ _id, categoryListQuery }) =>
  apollo.mutate({
    mutation: deleteItemMutation,
    variables: { _id },
    update: (cache) => {
      const cachedData = cache.readQuery({ query: categoryListQuery });
      const { categories } = cachedData;
      const category = categories.find(({ items }) => find(items, { _id }));
      if (!category) return;
      const newItems = clientDelete({ array: category.items, _id });
      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: cachedData.categories.map((c) => (find(c.items, { _id }) ? { ...c, items: newItems } : c)),
        },
      });
    },
  });
export const deleteCategoryItemWithConfirmation = ({ _id, categoryListQuery }) =>
  confirmModal({
    okText: i18n.t('admin.deleteItemConfirmation.ok'),
    cancelText: i18n.t('admin.deleteItemConfirmation.cancel'),
    okType: 'danger',
    onOk: () => deleteCategoryItem({ _id, categoryListQuery }).then(toast.successDefault),
    title: i18n.t('admin.deleteItemConfirmation.title'),
  });
export default deleteCategoryItem;
