/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import Avatar from 'react-avatar';
import theme from 'config/theme';
import { Menu, Dropdown } from 'antd';
import useLogout from 'hooks/auth/useLogout';
import { LogoutOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18n';

export const fullNameFromUser = (user) => {
  if (!user) return i18n.t('CurrentUserDropdown.noUserName');
  const { profile } = user;
  if (!profile.firstName && !profile.lastName) return i18n.t('CurrentUserDropdown.noUserName');
  if (profile.firstName && profile.lastName) return `${profile.firstName} ${profile.lastName}`;
  return profile.firstName || profile.lastName;
};
export const UserAvatar = ({ user }) => {
  return (
    <Avatar
      className="cursor-pointer"
      name={fullNameFromUser(user)}
      size={32}
      maxInitials={2}
      round
      color={theme.primaryColor}
      fgColor="white"
    />
  );
};
export default function CurrentUserDropdown({ handleMenuClick = () => {} }) {
  const [me] = useCurrentUser();
  const logout = useLogout();
  const { t } = useTranslation();

  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item>
          <Trans i18nKey="CurrentUserDropdown.loggedInAs" name={fullNameFromUser(me)}>
            Logged in as <b>{{ name: fullNameFromUser(me) }}</b>
          </Trans>
        </Menu.Item>
        <Menu.Item>
          <Trans i18nKey="CurrentUserDropdown.role" role={me.role}>
            Role: <b>{{ role: me.role }}</b>
          </Trans>
        </Menu.Item>
        {!me?.isAlpha ? (
          <Menu.Item
            onClick={() => {
              i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en');
            }}
          >
            <Trans i18nKey="CurrentUserDropdown.locale">
              Locale: <b>{i18n.language}</b>
            </Trans>
          </Menu.Item>
        ) : null}
        <Menu.Divider />
        <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
          {t('CurrentUserDropdown.logout')}
        </Menu.Item>
      </Menu>
    ),
    [me, t, logout, handleMenuClick],
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <div>
        <UserAvatar user={me} />
      </div>
    </Dropdown>
  );
}
