import { Route, Switch } from 'react-router-dom';

export const HideByRoute = ({ path, children }) => {
  return (
    <Switch>
      <Route key={path} path={path} exact component={null} />
      <Route render={() => children} />
    </Switch>
  );
};

export default HideByRoute;
