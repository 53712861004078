import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { Form } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import { useFormikContext } from 'formik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { updateTheme } from 'graphql/methods';
import I18nFormik from 'components/common/I18nFormik';
import { ColorPickerFormikInput, FontFormikInput } from './components/Inputs';
import classes from './CorporateIdentitySetting.module.less';
import './components/fonts.less';
import ApplyStyles from './components/ApplyStyles';

const CorporatePrimaryColorPreview = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { primaryColor } = values;
  const { fontFamily } = values;
  const { color } = values;
  return (
    <div
      style={{ backgroundColor: primaryColor, color, fontFamily, transition: 'none' }}
      className="ant-btn ant-btn-lg"
    >
      {t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.preview')}
    </div>
  );
};

function CorporatePrimaryColour({ primaryColor, color, fontFamily }) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      primaryColor: primaryColor || '#a08008',
      color: color || '#ffffff',
      fontFamily: fontFamily || 'ProximaNova',
    }),
    [color, primaryColor, fontFamily],
  );
  return (
    <Card title={t('Corporate Primary Colour')}>
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(main) => {
          updateTheme({ pdf: { main } });
        }}
      >
        <div>
          <Form layout="vertical" className={classes.corporatePrimaryColorForm}>
            <div>
              <FormItem
                name="primaryColor"
                label={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.primaryColor')}
              >
                <ColorPickerFormikInput name="primaryColor" fast={false} />
              </FormItem>
              <FormItem
                name="fontFamily"
                label={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.fontFamily')}
              >
                <FontFormikInput name="fontFamily" />
              </FormItem>
              <FormItem
                name="primaryColor"
                label={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.color')}
              >
                <ColorPickerFormikInput fast={false} name="color" />
              </FormItem>
            </div>
            <CorporatePrimaryColorPreview />
          </Form>
          <SaveChangesButton style={{ marginRight: '16px', marginTop: 'unset' }} initialValues={initialValues} />
          <ApplyStyles />
        </div>
      </I18nFormik>
    </Card>
  );
}

export default memo(CorporatePrimaryColour, equal);
