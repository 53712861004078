import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { createInputField, deleteInputFieldWithConfirmation, updateInputField } from 'graphql/methods';
import { TableMemo } from 'memo';
import { Radio } from 'formik-antd';
import { useFormikContext } from 'formik';
import BorderedBox from 'components/common/BorderedBox';
import { InputNumber } from 'components/common/InputNumber';
import { memo, useCallback } from 'react';
import { clientShift, ObjectId } from 'utils/helpers';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import ItemTypes from 'constants/ItemTypes';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EmptyBox from 'components/common/EmptyBox';
import * as Yup from 'yup';
import { find } from 'lodash';
import { getCategoryListQuery } from 'graphql/queries';
import { useLibraryContext } from 'contexts/LibraryContext';
import NumberI18n from 'components/common/NumberI18n';
import DragButton from './drag/DragButton';
import DraggableBodyRow from './drag/DraggableBodyRow';
import classes from './CollapsibleCategoryConfiguration.module.less';
import { textsSchema } from './itemModal/schema';
import Cell from './table/Cell';

const inputFieldOptionSchema = () => [
  {
    label: i18n.t('admin.inputFieldModal.comboOptions.modal.nameFieldLabel'),
    name: 'name',
    description: i18n.t('admin.inputFieldModal.comboOptions.modal.nameFieldDescription'),
  },
  {
    label: i18n.t('admin.inputFieldModal.comboOptions.modal.valueFieldLabel'),
    name: 'value',
    description: i18n.t('admin.inputFieldModal.comboOptions.modal.valueFieldDescription'),
    component: InputNumber,
    props: { min: 0, max: Infinity },
  },
];
const optionValidationSchema = () =>
  Yup.object().shape({
    ...textsSchema({}),
    value: Yup.number()
      .typeError('value is a required field')
      .required(),
  });
const addNewOption = ({ onSubmit }) =>
  inputConfirmModal({
    fields: inputFieldOptionSchema(),
    headerText: i18n.t('admin.inputFieldModal.comboOptions.modal.addTitle'),
    onSubmit,
    okText: i18n.t('admin.inputFieldModal.comboOptions.modal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.modal.cancel'),
    value: { name: '', value: undefined },
    validationSchema: optionValidationSchema,
    width: '800px',
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.comboOptions.modal.duplicatedErrorMessage')] },
  });
const editOptionModal = ({ currentValue, currentName, onSubmit }) =>
  inputConfirmModal({
    fields: inputFieldOptionSchema(),
    headerText: i18n.t('admin.inputFieldModal.comboOptions.modal.editTitle'),
    onSubmit,
    okText: i18n.t('admin.inputFieldModal.comboOptions.modal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.modal.cancel'),
    value: { name: currentName, value: currentValue },
    validationSchema: optionValidationSchema,
    width: '800px',
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.comboOptions.modal.duplicatedErrorMessage')] },
  });
/// ///////////////////////////////
const inputFieldOptionsColumns = ({ t, deleteOption, editOption }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragButton />,
    key: 'sort',
  },
  {
    title: t('admin.inputFieldModal.comboOptions.table.nameColumnLabel'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('admin.inputFieldModal.comboOptions.table.valueColumnLabel'),
    dataIndex: 'value',
    key: 'value',
    render: NumberI18n,
  },
  {
    title: t('admin.inputFieldModal.comboOptions.table.actionsColumnLabel'),
    dataIndex: '',
    key: 'actions',
    width: 101,
    render: ({ _id, name, value }) => {
      return (
        <>
          <Button
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() =>
              editOptionModal({
                _id,
                currentName: name,
                currentValue: value,
                onSubmit: (modifier) => {
                  editOption({ _id, modifier });
                },
              })
            }
          />{' '}
          <Button type="danger" ghost icon={<DeleteOutlined />} onClick={() => deleteOption({ _id })} />
        </>
      );
    },
  },
];
const InputFieldOptions = ({ onChange, value: fieldValue }) => {
  const { t } = useTranslation();
  const addOption = useCallback(
    () =>
      addNewOption({
        onSubmit: ({ name, value: optionValue }) => {
          if (find(fieldValue, { name })) throw new Error('Duplicated');
          onChange([
            ...(fieldValue || []),
            {
              name,
              value: optionValue,
              _id: ObjectId(),
              order: (fieldValue?.length ? fieldValue[fieldValue.length - 1].order : 0) + 1,
            },
          ]);
        },
      }),
    [fieldValue, onChange],
  );
  const editOption = useCallback(
    ({ _id, modifier }) => {
      if (find(fieldValue, (v) => v.name === modifier.name && v._id !== _id)) throw new Error('Duplicated');
      onChange((fieldValue || []).map((e) => (e._id === _id ? { _id, ...modifier } : e)));
    },
    [fieldValue, onChange],
  );
  const moveOption = useCallback(
    (dragIndex, hoverIndex) => {
      const _id = fieldValue[dragIndex]?._id;
      const newFieldValue = clientShift({ array: fieldValue, _id, shift: hoverIndex - dragIndex });
      onChange(newFieldValue);
    },
    [fieldValue, onChange],
  );
  const deleteOption = useCallback(
    ({ _id }) => {
      confirmModal({
        cancelText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.cancel'),
        okText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.ok'),
        okType: 'danger',
        onOk: () =>
          Promise.resolve()
            .then(() => {
              const newFieldValue = (fieldValue || [])
                .filter((v) => v._id !== _id)
                .map((v, index) => {
                  return { ...v, order: index + 1 };
                });
              onChange(newFieldValue);
            })

            .then(toast.successDefault),
        title: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.title'),
      });
    },
    [fieldValue, onChange],
  );
  return (
    <BorderedBox type="transparent" label={t('admin.inputFieldModal.comboOptions.boxLabel')}>
      <Button icon={<PlusOutlined />} onClick={addOption} type="primary">
        {t('admin.inputFieldModal.comboOptions.addOption')}
      </Button>
      <br />
      <br />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div className={classes.wrapperTable}>
          <TableMemo
            locale={{
              emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyInputFieldOptionListMessage')} />,
            }}
            rowKey={(record) => {
              return record?._id;
            }}
            components={{
              body: {
                row: DraggableBodyRow,
              },
            }}
            onRow={(record, index) => ({
              index,
              record,
              move: moveOption,
              itemTypes: ItemTypes.CATEGORY_INPUT_OPTION,
              mutation: {
                move: () => {},
              },
            })}
            pagination={false}
            dataSource={fieldValue || []}
            columns={inputFieldOptionsColumns({ t, deleteOption, editOption, moveOption })}
          />
        </div>
      </DndProvider>
    </BorderedBox>
  );
};

const InputFieldOptionsField = ({ parentId, name, validate, fast, onChange, ...restProps }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  return (
    <InputFieldOptions
      parentId={parentId}
      value={values[name]}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
        setFieldTouched(name, true, false);
        onChange && onChange(newValue);
      }}
      {...restProps}
    />
  );
};

const getDiscountFields = ({ parentId }) => [
  {
    label: i18n.t('admin.inputFieldModal.nameFieldLabel'),
    name: 'name',
    description: i18n.t('admin.inputFieldModal.nameFieldDescription'),
  },
  {
    label: i18n.t('admin.inputFieldModal.typeFieldLabel'),
    name: 'type',
    component: (props) => {
      return (
        <Radio.Group {...props}>
          <Radio.Button value="input">{i18n.t('common.InputField.input')}</Radio.Button>
          <Radio.Button value="combo">{i18n.t('common.InputField.combo')}</Radio.Button>
        </Radio.Group>
      );
    },
  },
  {
    name: 'options',
    label: '',
    component: (props) => <InputFieldOptionsField {...props} parentId={parentId} />,
    shouldRenderFn: ({ values }) => values.type === 'combo',
  },
];
const optionsSchema = () => ({
  options: Yup.array().when('type', {
    is: (value) => value === 'combo',
    then: Yup.array()
      .min(1)
      .required(),
  }),
});
const optionsValidationSchema = () =>
  Yup.object().shape({
    ...textsSchema({}),
    ...optionsSchema({}),
  });
const addNewInputField = ({ categoryId, parentId, parentType, categoryListQuery }) =>
  inputConfirmModal({
    okText: i18n.t('admin.inputFieldModal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.cancel'),
    fields: getDiscountFields({ parentId }),
    headerText: i18n.t('admin.inputFieldModal.addModalTitle'),
    onSubmit: (props) => createInputField({ categoryId, parentId, parentType, categoryListQuery, ...props }),
    value: { name: '', type: 'input', options: [] },
    categoryId,
    validationSchema: optionsValidationSchema,
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.duplicatedErrorMessage')] },
    width: '800px',
  });
const editInputField = ({ _id, ...props }) =>
  inputConfirmModal({
    fields: getDiscountFields({ parentId: _id }),
    headerText: i18n.t('admin.inputFieldModal.editModalTitle'),
    onSubmit: (submitProps) => updateInputField({ _id, ...submitProps }),
    value: { _id, ...props },
    okText: i18n.t('admin.inputFieldModal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.cancel'),
    validationSchema: optionsValidationSchema,
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.duplicatedErrorMessage')] },
    width: '800px',
  });

const inputFieldTableColumns = ({ t, language, categoryListQuery }) => [
  {
    dataIndex: 'sort',
    width: 10,
    render: () => <DragButton />,
    key: 'sort',
  },
  {
    title: t('admin.inputFieldTableColumns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('admin.inputFieldTableColumns.type'),
    dataIndex: 'type',
    render: (e) => t(`common.InputField.${e}`),
    key: 'type',
    width: language === 'de' ? 120 : 110,
  },
  {
    title: t('admin.inputFieldTableColumns.actions'),
    dataIndex: '',
    key: 'actions',
    width: 101,
    render: ({ _id, ...props }) => {
      return (
        <>
          <Button ghost type="primary" icon={<EditOutlined />} onClick={() => editInputField({ _id, ...props })} />{' '}
          <Button
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteInputFieldWithConfirmation({ _id, categoryListQuery })}
          />
        </>
      );
    },
  },
];

const InputFieldsTab = ({ categoryId, parentId, parentType, inputFields, moveMutation, moveInCache }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const extraDropProps = {};
  const libraryContext = useLibraryContext();
  const { isLibrary } = libraryContext || {};
  const categoryListQuery = getCategoryListQuery(isLibrary);
  if (parentType === 'categoryItem') extraDropProps.itemId = parentId;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={() => addNewInputField({ categoryId, parentId, parentType, categoryListQuery })}
        type="primary"
      >
        {t('admin.addInputButton')}
      </Button>
      <br />
      <br />
      <div className={classes.wrapperTable}>
        <TableMemo
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyInputFieldListMessage')} /> }}
          rowKey={(record) => record?._id}
          pagination={false}
          dataSource={inputFields}
          columns={inputFieldTableColumns({ t, language, categoryListQuery })}
          onRow={(record, index) => ({
            index,
            record,
            move: moveInCache,
            extraDropProps,
            itemTypes: categoryId + ItemTypes.CATEGORY_INPUT,
            mutation: {
              move: moveMutation,
            },
          })}
          scroll={{
            x: 372,
          }}
          components={{
            body: {
              row: DraggableBodyRow,
              cell: Cell,
            },
          }}
        />
      </div>
    </>
  );
};
export default memo(InputFieldsTab);
