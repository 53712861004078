import gql from 'graphql-tag';
import userCompanyFragment from './userCompanyFragment';
import userContactFragment from './userContactFragment';

const userShoppingCartFragment = gql`
  fragment userShoppingCartFragment on ShoppingCart {
    _id
    categories {
      _id
      discountId
      inputFields {
        _id
        value
      }
      itemIds
      startOfService
    }
    contact
    createdAt
    documentTemplates {
      _id
    }
    feeType
    hiddenNote
    isSomethingChanged
    pdf
    showDiscounts
    showPrices
    valueMonthly
    valueOneOff
    valueYearly
    contacts {
      ...userContactFragment
    }
    company {
      ...userCompanyFragment
    }
    companyTypeId
  }
  ${userContactFragment}
  ${userCompanyFragment}
`;

export default userShoppingCartFragment;
