import gql from 'graphql-tag';

const userContactFragment = gql`
  fragment userContactFragment on Contact {
    _id
    salutation
    firstName
    lastName
    position
    email
    phoneNumber
    phoneNumberAlternative
  }
`;

export default userContactFragment;
