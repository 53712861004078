import { forwardRef } from 'react';
import { find, groupBy, map } from 'lodash';
import { useAdminCategoryInputFields } from 'graphql/hooks';
import { MentionContextProvider } from 'components/common/RichEditor/MentionContext';
import { BaseRichEditorField } from 'components/common/RichEditor/RichEditor';
import { RichEditorPlaceholders } from 'components/common/RichEditor/RichEditorPlaceholders';
import { useTranslation } from 'react-i18next';
import { withSingleLine } from 'components/common/RichEditor/plugins/withSingleLine';

const useDefaultFieldsHook = ({ categoryId, parentId, parentType }) => {
  return useAdminCategoryInputFields({ categoryId, parentId, parentType });
};

const defaultMentionContextValue = (fields) => {
  const renderMention = ({ element }) => {
    const { mentionType, _id } = element;
    if (mentionType === 'formula_function') return _id;
    if (mentionType === 'formula_reference') {
      const ff = find(fields, (f) => f._id === _id);
      if (!ff) return _id;
      return (
        // <Tooltip title={f.description}>
        <span>{ff.name}</span>
        // </Tooltip>
      );
    }
    return '?';
  };
  return { renderMention };
};

export const formulaMentions = [
  { name: 'round' },
  { name: 'StBVV_TabelleA' },
  { name: 'StBVV_TabelleB' },
  { name: 'StBVV_TabelleC' },
  { name: 'scale' },
];
const typeFields = {
  categoryItem: 'item',
  itemTotal: 'itemTotal',
  category: 'category',
  function: 'function',
  categoryMonthlyAdvance: 'categoryMonthlyAdvance',
  scale: 'scaleFunctionParameters',
};

const usePrepareSchemaHook = ([fields, loading], disabledFunctions) => {
  const { t } = useTranslation();
  if (loading) return [[], loading];
  const functionFields = formulaMentions
    .filter((e) => !disabledFunctions.includes(e.name))
    .map(({ name, disabled = false }) => {
      return {
        name,
        originalName: name,
        color: 'function',
        disabled,
        type: 'function',
        _id: name,
      };
    });
  const fields_ = [...fields.map((input) => ({ ...input, disabled: !!input?.disabled })), ...functionFields];
  const grouped = groupBy(fields_, 'type');
  const arrayGrouped = map(grouped, (value, key) => ({
    name: key,
    title: t(`admin.itemModal.inputs.pricingFormula.insertVariableMenu.subMenu.${typeFields[key]}`),
    fields: value.map((v) => ({
      ...v,
      title: v.originalName,
      onClick: ({ insertMention }) => {
        insertMention({ _id: v._id, mentionType: key === 'function' ? 'formula_function' : 'formula_reference' });
      },
    })),
  }));
  return [arrayGrouped, loading];
};
const emptyArr = [];
const PricingFormulaInput = (
  { hookProps, fieldsHook = useDefaultFieldsHook, disabledFunctions = emptyArr, ...props },
  ref,
) => {
  const [fields, loading] = fieldsHook(hookProps);
  const [schema] = usePrepareSchemaHook([fields, loading], disabledFunctions);
  if (loading) return null;
  const toolbarChildren = () => {
    return [<RichEditorPlaceholders schema={schema} />];
  };
  return (
    <>
      <MentionContextProvider value={defaultMentionContextValue(fields)}>
        <BaseRichEditorField
          allowedModifiers={emptyArr}
          // returnNullIf={invertFn}
          ref={ref}
          {...props}
          toolbarChildren={toolbarChildren}
          rootElement="div"
          withPlugins={withSingleLine}
        />
      </MentionContextProvider>
    </>
  );
};

export default forwardRef(PricingFormulaInput);
