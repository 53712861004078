import apollo from 'graphql/apollo';
import { createContactMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userContactListQuery } from 'graphql/queries';

const createContact = async ({
  salutation,
  firstName,
  lastName,
  email,
  position,
  phoneNumber,
  phoneNumberAlternative,
}) =>
  apollo.mutate({
    mutation: createContactMutation,
    variables: { contact: { salutation, firstName, lastName, position, email, phoneNumber, phoneNumberAlternative } },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: userContactListQuery });
      cache.writeQuery({
        query: userContactListQuery,
        data: {
          contacts: [...cachedData.contacts, result],
        },
      });
    },
  });
export default createContact;
