import DraggableBodyRow from 'components/admin/drag/DraggableBodyRow';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Collapse } from 'antd';
import { useRowContext } from 'contexts/RowContext';
import cn from 'classnames';
import HeaderCollapse from 'components/admin/components/HeaderCollapse/HeaderCollapse';
import classes from './Collapse.module.less';

const { Panel } = Collapse;

function PanelCollapse({
  newKeys,
  record,
  changeActiveKey,
  activeKey,
  component: Component,
  itemTypes,
  //
  collapseProps,
  extraPanelProps,
  panelProps,
  decorate = null,
  componentProps,
}) {
  const { _id: recordId } = record;
  const setIsActive = useCallback(
    (active) => {
      changeActiveKey({ recordId, active });
    },
    [recordId, changeActiveKey],
  );
  const { isDraggingInContainer } = useRowContext();

  let panelHeaderTitle = panelProps.header;
  if (typeof panelProps.header === 'function') {
    panelHeaderTitle = panelProps.header(recordId);
  }

  const setCollapseProps = { ...collapseProps };
  if (collapseProps?.defaultActiveKey) setCollapseProps.defaultActiveKey = collapseProps.defaultActiveKey(record);

  const setPanelProps = { ...panelProps };
  if (panelProps?.extra) setPanelProps.extra = panelProps.extra({ _id: recordId, ...extraPanelProps });

  let activeKeys;
  if (isDraggingInContainer === itemTypes) activeKeys = undefined;
  else activeKeys = activeKey ? record._id : undefined;

  return (
    <div style={{ position: 'relative ' }}>
      <Collapse
        {...setCollapseProps}
        activeKey={activeKeys}
        onChange={(keys) => {
          setIsActive(keys.length && keys[0]);
        }}
        className={cn('site-collapse-custom-collapse', collapseProps?.className)}
      >
        <Panel
          forceRender
          {...setPanelProps}
          l
          key={recordId}
          className={cn(classes.siteCollapseCustomPanel, 'xs-mb-20', panelProps?.className)}
          header={
            <HeaderCollapse name={panelHeaderTitle} isActive={isDraggingInContainer !== itemTypes && !!activeKey} />
          }
        >
          <Component {...componentProps} isNew={newKeys[record._id]} />
        </Panel>
      </Collapse>
      {decorate}
    </div>
  );
}

const PanelCollapseMemo = memo(PanelCollapse, equal);

function DraggableCollapse({
  component,
  data,
  moveInCache,
  moveMutation,
  componentProps,
  itemTypes,
  nameElementData,
  draggableProps = {},
  collapseProps,
  extraPanelProps,
  panelProps,
  decorate,
  newKeys = [],
}) {
  const [activeKeys, setActiveKeys] = useState({});

  const newKeysRef = useRef({});

  useEffect(() => {
    newKeys.forEach((e) => {
      if (typeof newKeysRef.current === 'object' && !(e in newKeysRef.current)) {
        newKeysRef.current[e] = true;
        setActiveKeys({ ...activeKeys, [e]: true });
      }
    });
  }, [activeKeys, newKeys]);

  const changeActiveKey = useCallback(({ recordId, active }) => {
    setActiveKeys((state) => {
      newKeysRef.current = { ...newKeysRef.current, [recordId]: false };
      return { ...state, [recordId]: active ? recordId : undefined };
    });
  }, []);

  if (!data?.length) return null;
  return (
    <>
      {data.map((record, index) => (
        <DraggableBodyRow
          key={record._id}
          record={record}
          index={index}
          itemTypes={itemTypes}
          move={moveInCache}
          mutation={moveMutation}
          {...draggableProps}
        >
          <PanelCollapseMemo
            newKeys={newKeysRef.current}
            changeActiveKey={changeActiveKey}
            activeKey={activeKeys[record._id]}
            record={record}
            component={component}
            itemTypes={itemTypes}
            //
            collapseProps={collapseProps}
            extraPanelProps={extraPanelProps}
            panelProps={panelProps}
            componentProps={{ ...componentProps, [nameElementData]: record }}
            decorate={decorate}
          />
        </DraggableBodyRow>
      ))}
    </>
  );
}

export default memo(DraggableCollapse, equal);
