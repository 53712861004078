import gql from 'graphql-tag';
import apollo from 'graphql/apollo';
import { adminStaticItemFragment } from 'graphql/fragments';

export const f = gql`
  fragment setCategoryStaticItem on Category {
    staticItems {
      ...adminStaticItemFragment
    }
  }
  ${adminStaticItemFragment}
`;
export const GET_TYPENAME_CATEGORY = gql`
  query getCategories {
    categories {
      __typename
    }
  }
`;
export default function updateCategoryStaticItemCache({ _id, staticItems }) {
  const { categories } = apollo.readQuery({
    query: GET_TYPENAME_CATEGORY,
  });
  if (!categories.length) return;

  const { __typename } = categories[0];

  apollo.writeFragment({
    id: `${__typename}___${_id}`,
    fragment: f,
    fragmentName: 'setCategoryStaticItem',
    data: { staticItems },
  });
}
