import gql from 'graphql-tag';

const m = gql`
  mutation moveCategoryStaticItem($_id: ID!, $offset: Int, $order: Int) {
    moveStaticItem(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default m;
