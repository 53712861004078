import { memo } from 'react';
import { Modal } from 'antd';
import routePaths from 'router/route-paths';
import ItemCatalogueConfiguration from 'pages/admin/ItemCatalogueConfiguration';
import { DisplayByRoute } from 'components/common/DisplayByRoute';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';

const ModalItemConfiguration = memo(({ path }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Modal
      visible
      onCancel={() => {
        switch (path) {
          case routePaths.itemCatalogueConfiguration:
            history.replace(routePaths.catalogueConfiguration, { prevLocation: location.pathname });
            break;
          case routePaths.commonLibraryItemConfiguration:
            history.replace(routePaths.commonLibraryConfiguration, { prevLocation: location.pathname });
            break;
          default:
        }
      }}
      width="80%"
      footer={null}
      style={{ top: 40 }}
    >
      <ItemCatalogueConfiguration />
    </Modal>
  );
}, equal);

function ItemEditModal() {
  const match = useRouteMatch([routePaths.itemCatalogueConfiguration, routePaths.commonLibraryItemConfiguration]);
  return (
    <DisplayByRoute
      component={() => <ModalItemConfiguration path={match?.path} />}
      path={[routePaths.commonLibraryItemConfiguration, routePaths.itemCatalogueConfiguration]}
    />
  );
}

export default memo(ItemEditModal, equal);
