import { useCallback, useMemo, useState } from 'react';
import { Button, Card, Dropdown, Menu, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import PageContainer from 'components/layout/PageContainer';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import SHOPPING_CART_ENTRIES_TABLE_QUERY from 'graphql/queries/shoppingCartEntriesTable';
import { useQuery } from '@apollo/react-hooks';
import { formatDateTime } from 'utils/date';
import { deleteShoppingCartWithConfirmation } from 'graphql/methods';
import { TableMemo } from 'memo';
import usePagination from 'utils/usePagination';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';

const updateShoppingCart = ({ _id, history }) => {
  history.push(`${routePaths.shoppingCartRevision}/${_id}`);
};

const menu = (row) => (
  <Menu>
    {row.documentTemplates.map((t) => {
      return (
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href={`/files/shopping-cart?id=${row._id}&t=${t._id}`}>
            {t.name}
          </a>
        </Menu.Item>
      );
    })}
  </Menu>
);

export const useShoppingCartsPaginated = ({ ...queryParams }) => {
  const variables = queryParams;
  const { data: response, loading, error } = useQuery(SHOPPING_CART_ENTRIES_TABLE_QUERY, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    const shoppingCarts = response?.shoppingCartEntries?.shoppingCarts;
    const count = response?.shoppingCartEntries?.count;
    const sc = Array.isArray(shoppingCarts)
      ? shoppingCarts.map((row) => ({
          key: row._id,
          ...row,
          createdAt: new Date(row.createdAt),
        }))
      : [];
    return { data: sc, count, loading, error };
  }, [loading, error, response]);
};

export default function ShoppingCartEntriesPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const { otherQueryData, error, query, setQuery, ...paginationProps } = usePagination({
    queryHook: useShoppingCartsPaginated,
  });

  const columns = useMemo(
    () => [
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => formatDateTime(date),
        sorter: true,
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.companyIdentifier'),
        dataIndex: 'company.identifer',
        key: 'companyIdentifier',
        render: (_, data) => data?.company?.identifer,
        sorter: true,
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.companyName'),
        dataIndex: 'company.name',
        key: 'companyName',
        render: (_, data) => data?.company?.name,
        sorter: true,
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.relatedContacts'),
        dataIndex: 'relatedContacts',
        key: 'contactsName',
        render: (_, data) => data?.contacts?.map((contact) => `${contact.firstName} ${contact.lastName}`).join(', '),
        sorter: true,
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.valueOneOff'),
        dataIndex: 'valueOneOff',
        key: 'valueOneOff',
        sorter: true,
        render: (v) => Calc.formatCurrency(v),
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.valueMonthly'),
        dataIndex: 'valueMonthly',
        key: 'valueMonthly',
        sorter: true,
        render: (v) => Calc.formatCurrency(v),
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.valueYearly'),
        dataIndex: 'valueYearly',
        key: 'valueYearly',
        sorter: true,
        render: (v) => Calc.formatCurrency(v),
      },
      {
        title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.actions'),
        key: 'actions',
        fixed: 'right',
        render: (text, row) => (
          <>
            <Dropdown disabled={!row?.documentTemplates?.length} overlay={menu(row)} placement="bottomRight">
              <Button icon={<FilePdfOutlined />} />
            </Dropdown>{' '}
            <Button
              icon={<EditOutlined />}
              ghost
              type="primary"
              onClick={() => updateShoppingCart({ history, _id: row._id })}
            />{' '}
            <Button
              type="danger"
              ghost
              icon={<DeleteOutlined />}
              onClick={() => deleteShoppingCartWithConfirmation({ _id: row._id })}
            />
          </>
        ),
      },
    ],
    [history, t],
  );

  const onChangeSearch = useMemo(() => debounce(setQuery, 300), [setQuery]);
  const [stateQuery, setStateQuery] = useState(query);
  const updateQuery = useCallback(
    (e) => {
      const { value } = e.target;
      setStateQuery(value);
      onChangeSearch(value);
    },
    [onChangeSearch],
  );
  return (
    <PageContainer title={t('ShoppingCartEntriesPage.title')}>
      <Card>
        <Card title={t('ShoppingCartEntriesPage.searchTitle')}>
          <Input
            placeholder={t('ShoppingCartEntriesPage.search')}
            value={stateQuery}
            onChange={updateQuery}
            suffix={<SearchOutlined />}
          />
        </Card>
        <QueryErrorAlert error={error} />
        <TableMemo
          columns={columns}
          loading={paginationProps.loading && !paginationProps.dataSource}
          {...paginationProps}
          scroll={{ x: 992 }}
        />
      </Card>
    </PageContainer>
  );
}
