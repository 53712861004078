import gql from 'graphql-tag';

const adminPdfBodyPrimaryFragment = gql`
  fragment adminPdfBodyPrimaryFragment on PdfBodyPrimary {
    fontFamily
    color
    background
    marginBottom
    marginTop
  }
`;

export default adminPdfBodyPrimaryFragment;
