import { format } from 'date-fns';

const formats = {
  DATE: 'dd.MM.yyyy',
  DATE_TIME: 'dd.MM.yyyy HH:mm',
  TIME: 'dd.MM.yyyy HH:mm',
};

export const formatDate = (date) => {
  return format(date, formats.DATE);
};

export const formatTime = (date) => {
  return format(date, formats.TIME);
};

export const formatDateTime = (date) => {
  return format(date, formats.DATE_TIME);
};
