import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import EmptyBox from 'components/common/EmptyBox';
import apollo from 'graphql/apollo';
import { userContactFragment } from 'graphql/fragments';
import updateContact from 'graphql/methods/admin/contact/updateContact';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import inputConfirmModal from 'utils/inputConfirmModal';
import * as Yup from 'yup';
import { PhoneController } from 'components/common/PhoneInputFormik';
import confirmModal from 'utils/confirmModal';
import salutations from 'constants/salutations';
import { ContactForm, NumberPhoneAlternativeFormikInput, NumberPhoneBusinessFormikInput } from '../components/Inputs';
import { contactFormSchema } from '../components/schema';
import classes from './ContactTable..module.less';

export const editContactFormSchema = () => {
  return Yup.object().shape({
    ...contactFormSchema(),
  });
};

const editContact = ({ contact, onChange }) =>
  inputConfirmModal({
    formContent: () => (
      <>
        <ContactForm />
        <NumberPhoneBusinessFormikInput typeContactData="contact" />
        <NumberPhoneAlternativeFormikInput typeContactData="contact" />
      </>
    ),
    fields: [],
    onSubmit: async (modifier) => {
      const { data } = await updateContact({ _id: contact?._id, ...modifier });
      const currentContact = grabFirstGQLDataResult(data);
      onChange(currentContact);
    },
    value: {
      email: contact?.email || '',
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
      phoneNumber: contact?.phoneNumber || '49-',
      phoneNumberAlternative: contact?.phoneNumberAlternative || '49-',
      position: contact?.position || '',
      salutation: contact?.salutation || salutations.Mr,
    },
    headerText: i18n.t('user.ShoppingCart.ContactData.modal.titles.editContact'),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: editContactFormSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['email', i18n.t('user.ShoppingCart.ContactData.contact.duplicatedErrorMessage.email')],
    },
  });

const contactTableColumns = ({ t, onDelete, onEdit }) => [
  {
    key: 'actions',
    width: 32,
    render: ({ _id }) => {
      return (
        <>
          <Button
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => {
              confirmModal({
                cancelText: i18n.t('common.cancel'),
                okText: i18n.t('common.ok'),
                okType: 'danger',
                onOk: () => onDelete(_id),
                title: i18n.t('user.ShoppingCart.ContactData.modal.titles.deleteContact'),
              });
            }}
          />
          <Button
            type="danger"
            ghost
            icon={<EditOutlined />}
            onClick={() => {
              onEdit(_id);
            }}
          />
        </>
      );
    },
  },
  {
    title: t('user.ShoppingCart.ContactData.contact.table.name'),
    render: ({ salutation, firstName, lastName }) =>
      `${t(`user.ShoppingCart.ContactData.contact.inputs.salutation.options.${salutation}`)} ${firstName} ${lastName}`,
  },
  {
    title: t('user.ShoppingCart.ContactData.contact.table.position'),
    dataIndex: 'position',
    key: 'position',
  },
  {
    title: t('user.ShoppingCart.ContactData.contact.table.email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: t('user.ShoppingCart.ContactData.contact.table.phones.title'),
    render: ({ phoneNumber, phoneNumberAlternative }) => (
      <>
        {PhoneController.parse(phoneNumber)?.phone ? (
          <div>{`${t('user.ShoppingCart.ContactData.contact.table.phones.phoneNumber')}: ${phoneNumber}`}</div>
        ) : null}
        {PhoneController.parse(phoneNumberAlternative)?.phone ? (
          <div>{`${t(
            'user.ShoppingCart.ContactData.contact.table.phones.phoneNumberAlternative',
          )}: ${phoneNumberAlternative}`}</div>
        ) : null}
      </>
    ),
  },
];

const ContactTable = ({ contacts = [], onDelete, onChange }) => {
  const { t } = useTranslation();

  const onEdit = useCallback(
    (idContact) => {
      const contact = apollo.readFragment({ id: `Contact___${idContact}`, fragment: userContactFragment });
      editContact({ contact, onChange });
    },
    [onChange],
  );
  return (
    <TableMemo
      bordered
      className={classes.table}
      locale={{ emptyText: <EmptyBox label={t('user.ShoppingCart.ContactData.contact.empty')} /> }}
      rowKey={(record) => record?._id}
      dataSource={contacts}
      scroll={{
        x: 600,
      }}
      pagination={false}
      columns={contactTableColumns({ t, onDelete, onEdit })}
    />
  );
};
export default ContactTable;
