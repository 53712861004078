import { Collapse } from 'antd';
import HeaderCollapse from 'components/admin/components/HeaderCollapse/HeaderCollapse';
import { AiOutlineRight } from 'react-icons/ai';
import { GrDrag } from 'react-icons/gr';
import { colors } from 'styles/colors';
import classes from './CollapseDrag.module.less';

const { Panel } = Collapse;
const CollapseDrag = ({ label, style }) => {
  return (
    <div className={classes.layer} style={style}>
      <Collapse
        defaultActiveKey={undefined}
        expandIcon={() => (
          <div className={classes.expandIcon}>
            <GrDrag size={14} className={classes.drag} />
            <AiOutlineRight size={14} color={colors.expandArrow} />
          </div>
        )}
        expandIconPosition="left"
      >
        <Panel forceRender header={<HeaderCollapse name={label} isActive={false} />} />
      </Collapse>
    </div>
  );
};

export default CollapseDrag;
