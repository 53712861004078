/* eslint-disable no-shadow */
import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import equal from 'fast-deep-equal/es6/react';
import { filter } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import classes from './RichEditorPlaceholders.module.less';

export const useInsertRichEditor = () => {
  const editor = useSlate();

  const insertNode = (node) => {
    Transforms.insertNodes(editor, node);
    Transforms.move(editor);
  };
  const insertMention = ({ text, ...props }) => {
    const mention = { type: 'mention', character: text, children: [{ text: '' }], ...props };
    insertNode(mention);
  };
  return { insertMention, editor, insertNode };
};
const Delimiter = () => <div className={classes.delimiter} />;

// schema: { title: String, fields: { disabled: Boolean, name: String, onClick: ({ insertMention, insertNode, editor }) => void }}
const CustomMenu = ({ schema, insertMention, insertNode, editor }) => {
  return (
    <Menu selectedKeys={undefined}>
      {schema.map(({ title, fields }) => {
        const fieldsFiltered = filter(fields, (f) => !f.disabled);
        if (!fieldsFiltered.length) return null;
        return (
          <>
            <SubMenu selectedKeys={undefined} title={title}>
              {fieldsFiltered.map(({ title, onClick }) => (
                <>
                  <Menu.Item active onClick={() => onClick({ insertMention, insertNode, editor })}>
                    <div className={classes.itemMenu}>{title}</div>
                  </Menu.Item>
                  <Delimiter />
                </>
              ))}
            </SubMenu>
            <Delimiter />
          </>
        );
      })}
    </Menu>
  );
};

const RichEditorPlaceholdersMemo = ({ schema = [] }) => {
  const { insertMention, editor, insertNode } = useInsertRichEditor();
  const { t } = useTranslation();

  return (
    <>
      <Dropdown
        overlay={<CustomMenu schema={schema} insertMention={insertMention} insertNode={insertNode} editor={editor} />}
      >
        <div className={`${classes.placeholder} ant-btn`}>
          <PlusOutlined />
          <span>{t('admin.itemModal.inputs.pricingFormula.insertVariableMenu.title')}</span>
          <i className="ant-menu-submenu-arrow" />
        </div>
      </Dropdown>
    </>

    /* <Button
      onMouseDown={(event) => {
        event.preventDefault();
        insertMention({ text: '123 123' });
      }}
    >
      123
    </Button> */
  );
};
export const RichEditorPlaceholders = memo(RichEditorPlaceholdersMemo, equal);
