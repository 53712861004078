/*
  A hook to fetch current user
  Add extra props to the currentUser fragment when you need it
*/
import gql from 'graphql-tag';
import useAuth from './useAuth';
import { CURRENT_USER_FRAGMENT } from './useCurrentUser';

const VERIFY_EMAIL = gql`
  mutation verifyEmail($email: String!, $otp: String!) {
    verifyEmail(email: $email, otp: $otp) {
      token
      refreshToken
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useVerifyEmail = () => {
  const [verifyEmail] = useAuth(VERIFY_EMAIL);
  return verifyEmail;
};

export default useVerifyEmail;
