import gql from 'graphql-tag';

const adminPdfTableOfContentFragment = gql`
  fragment adminPdfTableOfContentFragment on PdfTableOfContent {
    fontFamily
    color
    fontSize
  }
`;

export default adminPdfTableOfContentFragment;
