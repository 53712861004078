import { useEffect, useState } from 'react';
import cn from 'classnames';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { clamp } from 'lodash';
import classes from './ImageZoom.module.less';

function SettingZoom({ changeZoom, changeWidth }) {
  return (
    <div className={classes.setting}>
      <Button
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          changeZoom(true);
          changeWidth(true);
        }}
      >
        <PlusOutlined />
      </Button>
      <Button
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          changeZoom(false);
          changeWidth(false);
        }}
      >
        <MinusOutlined />
      </Button>
    </div>
  );
}
function ImageZoom({
  src,
  alt,
  initialWidth,
  zoomed,
  openZoom,
  closeZoom,
  increaseZoom = 10,
  maxWidth = 250,
  minWidth = 30,
}) {
  const [width, setWidth] = useState(initialWidth);
  const [isIncreaseZoom, setIsIncreaseZoom] = useState(true);
  const changeWidth = (_isIncreaseZoom) => {
    const newWidth = width + (_isIncreaseZoom ? increaseZoom : -increaseZoom);
    return setWidth(clamp(newWidth, minWidth, maxWidth));
  };

  useEffect(() => {
    setWidth(initialWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomed]);

  if (zoomed)
    return (
      <div aria-hidden="true" onClick={closeZoom} className={classes.container}>
        <div
          className={cn(
            classes.wrapper,
            width <= 100 && classes.centerImage,
            isIncreaseZoom ? 'cursor-zoom-in' : 'cursor-zoom-out',
          )}
        >
          <SettingZoom
            changeWidth={changeWidth}
            changeZoom={(value) => {
              changeWidth(value);
              setIsIncreaseZoom(value);
            }}
          />
          <img
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              changeWidth(isIncreaseZoom);
            }}
            style={{ width: `${width}%` }}
            src={src}
            alt={alt}
          />
        </div>
      </div>
    );
  return <img aria-hidden="true" onClick={openZoom} style={{ width: `${width}%` }} src={src} alt={alt} />;
}
export default ImageZoom;
