import i18n from 'i18n';
import * as Yup from 'yup';
import { PhoneInputValidationSchema } from 'components/common/PhoneInputFormik';

const textSchemaRequire = Yup.string()
  .required()
  .min(1)
  .nullable();
const numberSchemaRequire = Yup.number()
  .transform((value) => (Number.isNaN(value) ? undefined : value))
  .required()
  .nullable();

export const companyFormSchema = ({ type }) => ({
  type: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.type.label')),
  name: textSchemaRequire.label(i18n.t(`user.ShoppingCart.ContactData.company.inputs.name.${type}.label`)),
  street: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.street.label')),
  houseNumber: numberSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.houseNumber.label')),
  cityCode: numberSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.cityCode.label')),
  city: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.city.label')),
  country: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.country.label')),
});

export const companyFormTabSchema = ({ values }) => {
  return Yup.object().shape({
    ...(values?.tab === '1' && {
      company: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.company.inputs.company.label')),
    }),
    ...(values?.tab === '2' && companyFormSchema({ type: values?.type })),
  });
};

export const contactFormSchema = () => ({
  salutation: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.salutation.label')),
  firstName: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.firstName.label')),
  lastName: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.lastName.label')),
  email: textSchemaRequire.email().label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.email.label')),
  phoneNumber: PhoneInputValidationSchema().label(
    i18n.t('user.ShoppingCart.ContactData.contact.inputs.phoneNumber.label'),
  ),
  phoneNumberAlternative: PhoneInputValidationSchema().label(
    i18n.t('user.ShoppingCart.ContactData.contact.inputs.phoneNumberAlternative.label'),
  ),
});

export const contactFormTabSchema = ({ values }) => {
  return Yup.object().shape({
    ...(values?.tab === '1' && {
      contact: textSchemaRequire.label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.contact.label')),
    }),
    ...(values?.tab === '2' && contactFormSchema()),
  });
};
