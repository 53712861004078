import gql from 'graphql-tag';
import userContactFragment from './userContactFragment';

export const userCompanyAddressFragment = gql`
  fragment userCompanyAddressFragment on CompanyAddress {
    street
    houseNumber
    cityCode
    city
    country
  }
`;

const userCompanyFragment = gql`
  fragment userCompanyFragment on Company {
    _id
    type
    name
    identifer
    contacts {
      ...userContactFragment
    }
    address {
      ...userCompanyAddressFragment
    }
    phoneNumber
  }
  ${userCompanyAddressFragment}
  ${userContactFragment}
`;

export default userCompanyFragment;
