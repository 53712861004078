import gql from 'graphql-tag';

const adminStaticItemFragment = gql`
  fragment adminStaticItemFragment on StaticItem {
    _id
    name
    value
    order
  }
`;

export default adminStaticItemFragment;
