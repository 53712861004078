import gql from 'graphql-tag';
import { adminDocumentTemplateByIdFragment } from '../fragments';

const ADMIN_DOCUMENT_TEMPLATE_BY_ID_QUERY = gql`
  query adminDocumentTemplateById($documentTemplateId: ID!) {
    documentTemplateById(documentTemplateId: $documentTemplateId) {
      ...adminDocumentTemplateByIdFragment
    }
  }
  ${adminDocumentTemplateByIdFragment}
`;

export default ADMIN_DOCUMENT_TEMPLATE_BY_ID_QUERY;
