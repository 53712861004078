// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1sMvXCtdzUvCC0Tndjy4nA {\n  display: flex;\n}\n._1sMvXCtdzUvCC0Tndjy4nA > :first-child {\n  width: fit-content;\n}\n._1sMvXCtdzUvCC0Tndjy4nA .QPyUnmRXRYQscyGAGAdAz {\n  margin-top: 16px;\n  flex: 1;\n}\n", "",{"version":3,"sources":["webpack://src/components/auth/ResetPasswordForm/ResetPasswordForm.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAGI,kBAAA;AAEJ;AALA;EAMI,gBAAA;EACA,OAAA;AAEJ","sourcesContent":[".container {\n  display: flex;\n  > :first-child {\n    width: fit-content;\n  }\n  .resetPassword {\n    margin-top: 16px;\n    flex: 1;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1sMvXCtdzUvCC0Tndjy4nA",
	"resetPassword": "QPyUnmRXRYQscyGAGAdAz"
};
export default ___CSS_LOADER_EXPORT___;
