import { useState } from 'react';
import { Button, Card, Skeleton, Row, Col, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { useTranslation } from 'react-i18next';
import EmptyBox from 'components/common/EmptyBox';
// import settings from 'config/settings';
import { EditOutlined, PlusOutlined, /* ImportOutlined, */ DeleteOutlined, ImportOutlined } from '@ant-design/icons';
import PageContainer from 'components/layout/PageContainer';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import { formatDateTime } from 'utils/date';
import { deleteDocumentTemplateWithConfirmation, createDocumentTemplate } from 'graphql/methods';
import TextClamp from 'react-string-clamp';
import { useLibraryContext } from 'contexts/LibraryContext';
import { ImportDocumentFromLibrary } from 'components/admin/DocumentTemplate/modals';
import { useDocumentTemplateList } from 'graphql/hooks';

const skeletons = [...new Array(6)].map((_, i) => i + 1);

export default function DocumentTemplateListPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLibrary } = useLibraryContext();
  const { error, loading, data: documentTemplates } = useDocumentTemplateList(isLibrary);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onClickImportButton = () => setIsModalVisible(true);
  return (
    <PageContainer
      title={t(`admin.DocumentTemplateListPage.${isLibrary ? 'titleCommonLibrary' : 'title'}`)}
      rightProps={{ style: { flex: 0.5 } }}
      right={
        <div className="ant-modal-confirm-btns">
          {isLibrary ? null : (
            <Button icon={<ImportOutlined />} onClick={onClickImportButton}>
              {t('admin.DocumentTemplateListPage.importFromLibraryButton')}
            </Button>
          )}
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              createDocumentTemplate({
                isLibrary,
                onSuccess: (documentTemplate) => {
                  history.push(
                    routePatterns[
                      isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
                    ].stringify({ id: documentTemplate._id }),
                  );
                },
              });
            }}
            type="primary"
          >
            {t('admin.DocumentTemplateListPage.addDocumentTemplateButton')}
          </Button>
        </div>
      }
    >
      <div className="xs-mt-20">
        <QueryErrorAlert error={error} />
        {!loading && !documentTemplates.length ? (
          <Card>
            <EmptyBox label={t('admin.DocumentTemplateListPage.emptyListMessage')} />
          </Card>
        ) : null}
        {loading && !documentTemplates.length ? (
          <DocumentTemplateCardListWrapper>
            {skeletons.map((k) => (
              <DocumentTemplateCardWrapper key={k}>
                <Skeleton title loading={loading} active />
              </DocumentTemplateCardWrapper>
            ))}
          </DocumentTemplateCardListWrapper>
        ) : null}
        <DocumentTemplateCardListWrapper>
          {documentTemplates.map((documentTemplate) => (
            <DocumentTemplateCardWrapper
              link={routePatterns[
                isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
              ].stringify({ id: documentTemplate._id })}
            >
              <DocumentTemplateCardContent documentTemplate={documentTemplate} isLibrary={isLibrary} />
            </DocumentTemplateCardWrapper>
          ))}
        </DocumentTemplateCardListWrapper>
        {/* <TableMemo columns={columns} {...paginationProps} /> */}
      </div>
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          <ImportDocumentFromLibrary visible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
}

function DocumentTemplateCardContent({ documentTemplate, isLibrary }) {
  const { t } = useTranslation();
  return (
    <>
      <h2>{documentTemplate.name}</h2>
      <TextClamp text={documentTemplate.description} lines={4} />
      <div className="xs-mb-20">
        {documentTemplate.lastUsedDocumentNr
          ? t('admin.DocumentTemplateListPage.lastUsedDocumentNr', { n: documentTemplate.lastUsedDocumentNr })
          : null}
        <br />
        <Typography.Text type="secondary">
          {t('admin.DocumentTemplateListPage.nrOfDocumentsCreated', { count: documentTemplate.nrOfDocumentsCreated })}
          <br />
          {t('admin.DocumentTemplateListPage.createdAt', { date: formatDateTime(documentTemplate.createdAt) })}
        </Typography.Text>
      </div>
      <Button icon={<EditOutlined />} ghost type="primary" />{' '}
      <Button
        type="danger"
        ghost
        icon={<DeleteOutlined />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          deleteDocumentTemplateWithConfirmation({ _id: documentTemplate._id, isLibrary });
        }}
      />
    </>
  );
}

const DocumentTemplateCardListWrapper = ({ children }) => <Row gutter={[10, 10]}>{children}</Row>;
const DocumentTemplateCardWrapper = ({ children, link }) => {
  return (
    <Col xs={24} md={8}>
      {link ? (
        <Link to={link}>
          <Card>{children}</Card>
        </Link>
      ) : (
        <Card>{children}</Card>
      )}
    </Col>
  );
};
