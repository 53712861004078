import { Formik as DefaultFormik } from 'formik';
import RefreshI18nErrorsFormik from './RefreshI18nErrorsFormik';

export const I18nFormik = ({ children, ...props }) => {
  const isFunction = typeof children === 'function';
  return (
    <DefaultFormik {...props}>
      {(...formikChildrenProps) => (
        <>
          <RefreshI18nErrorsFormik />
          {isFunction ? children(...formikChildrenProps) : children}
        </>
      )}
    </DefaultFormik>
  );
};
export default I18nFormik;
