import apollo from 'graphql/apollo';
import { updateThemeMutation } from 'graphql/mutations';

const updateTheme = async ({
  pdf: {
    main,
    title,
    h1,
    h2,
    h3,
    textBlock,
    testimonial,
    testimonialSmallText,
    table,
    tableHeaderPrimary,
    tableHeaderSecondary,
    tableContent,
    tableFooter,
    tableLeftColumn,
    bodyPrimary,
    bodyPrimaryFooter,
    tableOfContent,
  },
}) => {
  return apollo.mutate({
    mutation: updateThemeMutation,
    variables: {
      theme: {
        pdf: {
          main,
          title,
          h1,
          h2,
          h3,
          textBlock,
          testimonial,
          testimonialSmallText,
          table,
          tableHeaderPrimary,
          tableHeaderSecondary,
          tableContent,
          tableFooter,
          tableLeftColumn,
          bodyPrimary,
          bodyPrimaryFooter,
          tableOfContent,
        },
      },
    },
    refetchQueries: ['adminMyTheme'],
  });
};

export default updateTheme;
