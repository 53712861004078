import { Skeleton } from 'antd';
import FormItem from 'components/common/FormItem';
import I18nFormik from 'components/common/I18nFormik';
import PhoneNumber from 'components/common/PhoneInputFormik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { Form } from 'formik-antd';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { ContactForm } from 'pages/user/ShoppingCart/ContactData/components/Inputs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { updateProfile } from 'graphql/methods';
import toast from 'utils/toast';
import i18n from 'i18n';
import * as Yup from 'yup';
import ChangeEmail from './ChangeEmail';

const skeletons = [...new Array(1)].map((_, i) => i + 1);

const mainSchema = () => ({
  firstName: Yup.string()
    .required()
    .label(i18n.t('user.Setting.AccountSetting.tabs.main.fields.firstName')),
  lastName: Yup.string()
    .required()
    .label(i18n.t('user.Setting.AccountSetting.tabs.main.fields.lastName')),
  position: Yup.string()
    .required()
    .label(i18n.t('user.Setting.AccountSetting.tabs.main.fields.position')),
});

export const updateUserInformation = () => Yup.object().shape(mainSchema());

function MainForm() {
  const { t } = useTranslation();
  const [me, loading] = useCurrentUser();
  const user = useMemo(() => ({ ...me?.profile, email: me?.email }), [me]);

  const initialValues = useMemo(
    () => ({
      salutation: user?.salutation || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      position: user?.position || '',
      phone: user?.phone || '49-',
    }),
    [user],
  );

  if (!me && loading) return skeletons.map((k) => <Skeleton title loading active key={k} />);

  return (
    <>
      <ChangeEmail />
      <I18nFormik
        initialValues={initialValues}
        validationSchema={updateUserInformation}
        onSubmit={({ email, ...modifier }) => {
          const success = updateProfile(modifier);
          if (success) toast.successDefault();
        }}
      >
        <Form layout="vertical" style={{ marginTop: '16px' }}>
          <ContactForm isAccountForm />
          <FormItem name="phone" label={t('user.Setting.AccountSetting.tabs.main.fields.phone')}>
            <PhoneNumber name="phone" />
          </FormItem>
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </>
  );
}

export default MainForm;
