import apollo from 'graphql/apollo';
import { importCategories } from 'graphql/mutations';

const importCategoriesFromLibrary = async (categories, categoryListQuery) =>
  apollo.mutate({
    mutation: importCategories,
    variables: { input: categories },
    refetchQueries: [{ query: categoryListQuery }],
  });

export default importCategoriesFromLibrary;
