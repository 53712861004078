// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1UEy_CgmaTB3Gt5BwY_S4v {\n  min-height: 100vh !important;\n  position: relative;\n}\n._1fzaR8UmBQ98FgCXS5Dw1q {\n  display: flex;\n}\n._9ooSgliXBTS7inSaj5NVY {\n  position: absolute;\n  padding-left: 20px;\n  display: flex;\n  align-items: center;\n}\n._9ooSgliXBTS7inSaj5NVY > div {\n  margin: 0;\n  padding: 0;\n}\n._9ooSgliXBTS7inSaj5NVY > :first-child {\n  margin: -5px 10px -10px -10px;\n  padding: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/components/layouts/AppLayout.module.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,kBAAA;AADF;AAGA;EACE,aAAA;AADF;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AADF;AAHA;EAMI,SAAA;EACA,UAAA;AAAJ;AAPA;EAUI,6BAAA;EACA,aAAA;AAAJ","sourcesContent":["@import 'styles/variables.less';\n\n.layout {\n  min-height: 100vh !important;\n  position: relative;\n}\n.content {\n  display: flex;\n}\n.leftHeaderMenu {\n  position: absolute;\n  padding-left: 20px;\n  display: flex;\n  align-items: center;\n  > div {\n    margin: 0;\n    padding: 0;\n  }\n  > :first-child {\n    margin: -5px 10px -10px -10px;\n    padding: 10px;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "_1UEy_CgmaTB3Gt5BwY_S4v",
	"content": "_1fzaR8UmBQ98FgCXS5Dw1q",
	"leftHeaderMenu": "_9ooSgliXBTS7inSaj5NVY"
};
export default ___CSS_LOADER_EXPORT___;
