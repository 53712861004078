import { Tooltip } from 'antd';
import { Fragment } from 'react';
import { useFocused, useSelected } from 'slate-react';
import { useMentionContext } from '../MentionContext';

export const withMentions = (editor) => {
  const { isInline, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

export const MentionElement = ({ attributes, children, element }) => {
  const mentionContext = useMentionContext();
  const selected = useSelected();
  const focused = useFocused();
  if (!mentionContext) {
    console.error('no mention context');
    return null;
  }
  const { renderMention, getMentionStyle, getMentionComponent } = mentionContext;
  const MentionComponent = getMentionComponent?.({ element, attributes, selected, focused }) || 'span';
  const mentionStyle = getMentionStyle?.({ element, attributes, selected, focused });
  const Container = element?.tooltip ? Tooltip : Fragment;
  return (
    <Container {...(element?.tooltip && { title: element.tooltip })}>
      <MentionComponent
        {...attributes}
        contentEditable={false}
        style={{
          padding: '3px 3px 2px',
          margin: '0 1px',
          verticalAlign: 'baseline',
          display: 'inline-block',
          borderRadius: '4px',
          backgroundColor: '#eee',
          fontSize: '0.9em',
          boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
          ...mentionStyle,
        }}
      >
        {renderMention({ element, attributes, selected, focused })}
        {children}
      </MentionComponent>
    </Container>
  );
};
