import apollo from 'graphql/apollo';
import { resetThemeMutation } from 'graphql/mutations';

const resetTheme = async () => {
  return apollo.mutate({
    mutation: resetThemeMutation,
    refetchQueries: ['adminMyTheme'],
  });
};

export default resetTheme;
