import { filter, find, map } from 'lodash';
import { Calc, Cart } from '@JavaScriptSuperstars/kanzleipilot-shared';
import i18n from 'i18n';

export const getDiscountForCategory = ({ category, values }) =>
  values[`${category._id}_discount`] && find(category.discounts, { _id: values[`${category._id}_discount`] });

export const calcItem = ({ items, values, category, item, calcCategoryTotalById, allCategoryIds }) => {
  const appliedDiscount = getDiscountForCategory({ category, values });
  let price;
  const variablesFromInputFields = Calc.inputFieldsToVariables(allInputFieldsInCategories([category]));
  try {
    // price = Calc.calcWithDiscount({
    const variables = { ...values, ...variablesFromInputFields };
    //   expression: formula,
    //   discount: appliedDiscount,
    //   minPrice,
    // });  let value = 0;
    let value = 0;

    let discountedValue = 0;
    value = Calc.calcItem({ items, item, variables, calcCategoryTotalById, allCategoryIds });
    discountedValue = Calc.applyDiscountToValue({ value, discount: appliedDiscount });

    price = { value, discountedValue };
  } catch (e) {
    price = null;
    console.debug(item.name, e);
  }
  return price;
};

export const formikToResponse = ({ categories, values }) => {
  const discounts = categories.map((c) => c.discounts).flat();
  const items = categories.map((c) => c.items).flat();
  const inputFields = allInputFieldsInCategories(categories);
  const cart = categories
    .filter((c) => c.items?.length)
    .map((c) => ({
      _id: c._id,
      discountId: values[`${c._id}_discount`] || null,
      itemIds: c.items.filter((item) => values[item._id]).map((i) => i._id),
      inputFields: filter(inputFields, { categoryId: c._id })
        .map(({ _id }) => ({ _id, value: values[_id] }))
        .filter(({ value }) => value !== undefined),
      startOfService: values[`${c._id}_startOfService`],
    }));
  return { cart, inputFields, items, discounts, categories };
};
export const responseToFormik = ({ cart }) => {
  const values = {};
  const {
    categories,
    company,
    companyTypeId,
    contact,
    contacts,
    feeType,
    isSomethingChanged,
    hiddenNote,
    pdf,
    showDiscounts,
    showPrices,
    documentTemplates,
  } = cart;
  values.contacts = contacts || undefined;
  values.company = company || undefined;
  values.companyTypeId = companyTypeId || undefined;
  values.contactEmail = contact || undefined;
  values.feeType = feeType || undefined;
  values.hiddenNote = hiddenNote || undefined;
  values.isSomethingChanged = isSomethingChanged || undefined;
  values.documentTemplates = map(documentTemplates, (t) => t._id).filter(Boolean);
  values.pdf = pdf || undefined;
  values.showDiscounts = showDiscounts || undefined;
  values.showPrices = showPrices || undefined;

  categories.forEach((category) => {
    category.discountId && (values[`${category._id}_discount`] = category.discountId);
    category.startOfService && (values[`${category._id}_startOfService`] = category.startOfService);
    category.inputFields.forEach((inputField) => {
      values[inputField._id] = inputField.value;
    });
    category.itemIds.forEach((itemId) => {
      values[itemId] = true;
    });
  });
  return values;
};

export const errorWithTouched = ({ error, formikRef, fieldName = 'pricingFormula' }) => {
  formikRef.current.setFieldTouched(fieldName, true, false);
  return error;
};

export const shouldPricePreviewBeVisibleForCurrentFeeType = ({ item, feeType }) => {
  if (Cart.isCurrentFeeTypeStandard(feeType)) return [true];
  const should = item.paymentInterval === 'oneOff';
  return [should, !should && i18n.t('user.ShoppingCart.Category.Item.fixedMonthlyFeeHint')];
};
export const allInputFieldsInCategories = (categories) => {
  return categories
    .map((c) => [c.inputFields, c.items.map((i) => i.inputFields)])
    .flat()
    .flat()
    .flat()
    .filter(Boolean);
};
