import gql from 'graphql-tag';

const adminContactFragment = gql`
  fragment adminContactFragment on Contact {
    _id
    salutation
    firstName
    lastName
    position
    email
    phoneNumber
    phoneNumberAlternative
  }
`;

export default adminContactFragment;
