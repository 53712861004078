import apollo from 'graphql/apollo';
import { updateInputFieldMutation } from 'graphql/mutations';

const updateInputField = async ({ _id, name, type, options }) =>
  apollo.mutate({
    mutation: updateInputFieldMutation,
    variables: { _id, modifier: { name, type, options: options?.map(({ __typename, ...o }) => o) } },
    refetchQueries: ['adminCategoryList'],
  });

export default updateInputField;
