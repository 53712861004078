import { Form, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { Card } from 'antd';
import { useDocumentTemplateList } from 'graphql/hooks';
import DocumentTypesTableCard from './DocumentTypesTable';

const { Option } = Select;

const ShoppingCartPdfTypesSelect = ({ buttonState, isSubmitting, currentSubmitTypeRef }) => {
  const { t } = useTranslation();
  const list = useDocumentTemplateList();
  const children =
    list?.data?.map(({ name, _id }) => (
      <Option key={_id} value={_id}>
        {name}
      </Option>
    )) || null;
  return (
    <Card title={t('user.ShoppingCart.DocumentTypes.label')}>
      <Form layout="vertical">
        <FormItem
          className="hide-form-item-colon margin-bottom-0"
          name="documentTemplates"
          label={t('user.ShoppingCart.DocumentTypes.typesSelectLabel')}
        >
          <Select
            name="documentTemplates"
            mode="multiple"
            allowClear
            placeholder={t('user.ShoppingCart.DocumentTypes.typesSelectLabel')}
          >
            {children}
          </Select>
        </FormItem>
      </Form>
      <br />
      <DocumentTypesTableCard
        buttonState={buttonState}
        isSubmitting={isSubmitting}
        currentSubmitTypeRef={currentSubmitTypeRef}
      />
    </Card>
  );
};
export default ShoppingCartPdfTypesSelect;
