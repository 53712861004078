/* eslint-disable react/no-children-prop */
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import AuthLayout from 'components/layouts/AuthLayout';
import AppLayout from 'components/layouts/AppLayout';
import ROLES from 'constants/roles';
import CatalogueConfiguration from 'pages/admin/CatalogueConfiguration';
import DocumentTemplateList from 'pages/admin/DocumentTemplateListContainer';
import CommonLibraryDocumentTemplateList from 'pages/admin/CommonLibraryDocumentTemplateListContainer';
import DocumentTemplate from 'pages/admin/DocumentTemplate';
import CommonLibraryDocumentTemplate from 'pages/admin/CommonLibraryDocumentTemplate';
import ShoppingCartEntries from 'pages/admin/ShoppingCartEntries';
import AccountSetting from 'pages/user/AccountSetting/AccountSetting';
import CommonLibraryConfiguration from 'pages/admin/CommonLibraryConfiguration';
import NotFound from 'pages/NotFound';
import ScrollToTop from 'components/common/ScrollToTop';
import ShoppingCartRevision from 'pages/user/ShoppingCartRevision';
import CorporateIdentitySetting from 'pages/admin/CorporateIdentitySetting/CorporateIdentitySetting';
import CompanySetting from 'pages/admin/CompanySetting/CompanySetting';
import routePaths from './route-paths';
import { authRoutes } from './AuthRouter';
import ShoppingCart from '../pages/user/ShoppingCart';

const routes = [
  {
    path: routePaths.catalogueConfiguration,
    component: CatalogueConfiguration,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    exact: true,
    path: routePaths.companySetting,
    component: CompanySetting,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    exact: true,
    path: routePaths.commonLibraryDocumentTemplateList,
    component: CommonLibraryDocumentTemplateList,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    exact: true,
    path: routePaths.documentTemplateList,
    component: DocumentTemplateList,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    exact: true,
    path: routePaths.documentTemplateConfiguration,
    component: DocumentTemplate,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    exact: true,
    path: routePaths.commonLibraryDocumentTemplateConfiguration,
    component: CommonLibraryDocumentTemplate,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    exact: true,
    path: routePaths.corporateIdentitySetting,
    component: CorporateIdentitySetting,
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    path: routePaths.commonLibraryConfiguration,
    component: CommonLibraryConfiguration,
    roles: [ROLES.SUPER_ADMIN],
  },
  // {
  //   exact: true,
  //   path: routePaths.itemCatalogueConfiguration,
  //   component: ItemCatalogueConfiguration,
  //   roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  // },
  {
    exact: true,
    path: routePaths.shoppingCartEntries,
    component: ShoppingCartEntries,
    // roles: [ROLES.ADMIN, ROLES.USER],
  },
  {
    path: routePaths.shoppingCart,
    component: ShoppingCart,
    // roles: [ROLES.USER],
  },
  {
    path: routePaths.accountSetting,
    component: AccountSetting,
    // roles: [ROLES.USER],
  },
  {
    exact: true,
    path: routePaths.shoppingCartRevision,
    routerPath: routePaths.routerShoppingCartRevision,
    component: ShoppingCartRevision,
    // roles: [ROLES.USER],
  },
];

function CustomScrollToTop() {
  const location = useLocation();
  const isPrevItemEditPath = matchPath(location.state?.prevLocation, routePaths.itemCatalogueConfiguration);
  const isItemEditPath = matchPath(location.pathname, routePaths.itemCatalogueConfiguration);

  if (isPrevItemEditPath || isItemEditPath) return null;
  return <ScrollToTop />;
}

const AppRouter = () => {
  const [me] = useCurrentUser();
  const role = me?.role;
  const filteredRoutes = routes.filter((r) => (r.roles ? r.roles.includes(role) : true));
  return (
    <Switch>
      {/* {!emailVerified && (
        <Route path={routePaths.activateAccount} exact>
          <ActivateAccount />
        </Route>
      )} */}
      {/* {!emailVerified && <Redirect to={routePaths.activateAccount} />} */}
      <Route path="/secure/:path?">
        <AppLayout>
          <CustomScrollToTop />
          <Switch>
            {filteredRoutes.map(({ path, routerPath, component, ...rest }) => (
              <Route key={path} path={routerPath || path} component={component} {...rest} />
            ))}
            {role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN ? (
              <Route render={() => <Redirect from={routePaths.home} to={routePaths.shoppingCartEntries} />} />
            ) : null}
            {role === ROLES.USER ? (
              <Route render={() => <Redirect from={routePaths.home} to={routePaths.shoppingCartEntries} />} />
            ) : null}
            <Route path="*" component={NotFound} />
          </Switch>
        </AppLayout>
      </Route>
      <Route path="/" render={() => <Redirect from="/" to="/secure" />} exact />
      <Route>
        <AuthLayout>
          <Switch>
            {authRoutes.map(({ path, component, ...rest }) => (
              <Route key={path} path={path} component={component} exact {...rest} />
            ))}
            <Route path="*" component={NotFound} />
          </Switch>
        </AuthLayout>
      </Route>
    </Switch>
  );
};

export default AppRouter;
