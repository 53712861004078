import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { CURRENT_USER_QUERY } from 'hooks/auth/useCurrentUser';
import RefreshTokenService from 'utils/RefreshTokenService';

export const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export const afterLogout = (client) => {
  RefreshTokenService.clearRefreshToken();
  client.writeQuery({ query: CURRENT_USER_QUERY, data: { me: null } });
  client.resetStore().catch(() => {});
};

const useLogout = () => {
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const logout = useCallback(async () => {
    await logoutMutation();
    afterLogout(client);
  }, [client, logoutMutation]);

  return logout;
};

export default useLogout;
