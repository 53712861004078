import gql from 'graphql-tag';
import { adminDiscountFragment } from 'graphql/fragments';

const UPDATE_DISCOUNT_MUTATION = gql`
  mutation updateCategoryDiscount($_id: ID!, $modifier: DiscountInput!) {
    updateCategoryDiscount(_id: $_id, modifier: $modifier) {
      ...adminDiscountFragment
    }
  }
  ${adminDiscountFragment}
`;

export default UPDATE_DISCOUNT_MUTATION;
