import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormItem as OriginalFormItem } from 'formik-antd';

const FormItem = ({ containerProps, label, tooltip, wideTooltip, ...props }) => {
  if (tooltip) {
    // eslint-disable-next-line no-param-reassign
    label = (
      <>
        {label}{' '}
        <Tooltip title={tooltip} overlayStyle={wideTooltip ? { maxWidth: '500px' } : undefined}>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    );
  }
  const { name } = props;
  return (
    <div fieldname={name} {...containerProps}>
      <OriginalFormItem {...props} label={label} />
    </div>
  );
};
export default FormItem;
