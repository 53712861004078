import * as Yup from 'yup';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { getAdminCategoryInputFields } from 'graphql/hooks/useAdminCategoryInputFields';
import { find, uniqBy } from 'lodash';
import { errorWithTouched } from 'components/user/utils';
import i18n from 'i18n';

export const textsSchema = () => ({
  name: Yup.string()
    .label(i18n.t('admin.categoryNameInput.label'))
    .required()
    .min(1),
});
export const priceSchema = ({ formikRef, categoryId, parentId, parentType }) => {
  const schemaObject = {
    paymentInterval: Yup.string()
      .label(i18n.t('admin.itemModal.inputs.paymentInterval.label'))
      .required(),
    pricingFormula: Yup.string()
      .label(i18n.t('admin.itemModal.inputs.pricingFormula.label'))
      .required()
      .min(1)
      .test('invalid', i18n.t('admin.itemModal.inputs.pricingFormula.errors.invalid'), function validate(value) {
        const { path, createError } = this;
        const recursiveError = () =>
          errorWithTouched({ formikRef, error: createError({ path, message: i18n.t('common.Item.recursive') }) });
        const scaleIsRequiredError = () =>
          errorWithTouched({ formikRef, error: createError({ path, message: i18n.t('common.Item.scaleError') }) });
        try {
          const fields = getAdminCategoryInputFields({ categoryId, parentId, parentType });
          const isIncludesRecursiveField = find(fields, (field) => field.disabled && value?.includes(field._id));
          if (isIncludesRecursiveField) return recursiveError();
          const currentFormula = Mentions.getItemFormulaWithScales({
            pricingFormula: value,
            scales: formikRef?.current?.values?.scalesEnabled && formikRef.current.values.scales,
          });
          Mentions.isValidFormula(currentFormula);
        } catch (e) {
          console.log(value, e);
          if (e.message?.includes('circular')) return recursiveError();
          if (e.message?.includes('@scale is empty')) return scaleIsRequiredError();
          return errorWithTouched({ formikRef, error: createError({ path }) });
        }
        return true;
      }),
    minPrice: Yup.number()
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? undefined : value))
      .label(i18n.t('admin.itemModal.inputs.minPrice.label'))
      .required()
      .min(0),
    ...(formikRef?.current?.values?.scalesEnabled && {
      scales: Yup.array()
        .label(i18n.t('admin.itemModal.inputs.scalesList.label'))
        .required()
        .min(2, i18n.t('admin.itemModal.inputs.scales.atLeastTwoError'))
        .test('invalid', i18n.t('admin.itemModal.inputs.scales.duplicatedError'), function test(arr) {
          const { path, createError } = this;
          if (uniqBy(arr, 'value').length !== arr.length)
            return errorWithTouched({ formikRef, error: createError({ path }), fieldName: 'scales' });
          return true;
        })
        .nullable(),
      scaleTitle: Yup.string()
        .label(i18n.t('admin.itemModal.inputs.scales.inputs.scaleTitle.label'))
        .required()
        .min(1)
        .nullable(),
      scalePricingFormulaTitle: Yup.string()
        .label(i18n.t('admin.itemModal.inputs.scales.inputs.pricingFormulaTitle.label'))
        .required()
        .min(1)
        .nullable(),
    }),
  };
  // console.log(schemaObject);
  return schemaObject;
};
