import { useQuery } from '@apollo/react-hooks';
import { userShoppingCartQuery } from 'graphql/queries';

const useUserShoppingCart = ({ _id, fetchPolicy = 'network-only' }) => {
  const { data, loading } = useQuery(userShoppingCartQuery, { variables: { _id }, fetchPolicy });
  if (loading) return [null, true];
  return [data?.userShoppingCart, loading];
};

export default useUserShoppingCart;
