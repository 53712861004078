/* eslint-disable no-nested-ternary */
import { Alert, Badge, Card, Collapse, Divider, Modal } from 'antd';
import { Checkbox, Form, Select } from 'formik-antd';
import { Fragment, memo, useRef, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { filter, find, map, sortBy, intersection, pickBy } from 'lodash';
import FormItem from 'components/common/FormItem';
import InputDate from 'components/common/InputDate';
import equal from 'fast-deep-equal/es6/react';
import { FormItemMemo, TableMemo } from 'memo';
import { hasCategoryStartOfService, isItemRecursive } from 'pages/user/ShoppingCart/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Calc, RichText, Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { InputNumber } from 'components/common/InputNumber';
import classes from './CategoryInCart.module.less';
import { CategoryContext, useCategoriesContext, useCategoryContext } from './context';
import {
  allInputFieldsInCategories,
  calcItem,
  getDiscountForCategory,
  shouldPricePreviewBeVisibleForCurrentFeeType,
} from './utils';
import { CategoryTotalTable, useCalcCategoryTotalById } from './TotalTable';
import { CategoryPanelHeaderMemo, ItemPanelHeaderMemo } from './CollapsedHeaders';

const { Option } = Select;
const { Panel } = Collapse;

const StartOfService = () => {
  const { t } = useTranslation();
  const category = useCategoryContext();
  const display = hasCategoryStartOfService(category);
  const name = `${category._id}_startOfService`;
  return display ? (
    <FormItem name={name} label={t('user.ShoppingCart.Category.startOfService')}>
      <InputDate name={name} picker="month" />
    </FormItem>
  ) : null;
};

const showInfoTextModal = ({ infoText, name }) => {
  Modal.info({
    title: `${name}`,
    maskClosable: true,
    content: <div className="checkmarks">{RichText.renderRichText(infoText)}</div>,
    onOk() {},
    width: 800,
  });
};

const InputFields = ({ inputFields: inputFieldsFromProps }) => {
  const { inputFields, _id } = useCategoryContext();

  const e = (inputFieldsFromProps || inputFields.filter((field) => field.parentId === _id)).map((field) => {
    return (
      <FormItem key={field._id} name={field._id} label={field.name}>
        {field.type === 'combo' ? (
          <Select name={field._id} allowClear>
            {field.options?.map((o) => (
              <Select.Option key={o._id} value={o._id}>
                {o.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <InputNumber name={field._id} />
        )}
      </FormItem>
    );
  });
  return (
    <div>
      <div className={classes.inputFields}>{e}</div>
    </div>
  );
};
const MemoInputFields = memo(InputFields);

const RequiredFieldsAlert = ({ relatedInputNames, fieldError, relItems }) => {
  const { t } = useTranslation();
  return (
    <Alert
      type="error"
      message={
        <div>
          <div>
            {relItems.map(({ isSelected, item, isValid }) => (
              <>
                {/* {JSON.stringify({ isSelected, isValid })} */}
                {isSelected
                  ? isValid
                    ? null
                    : t(`user.ShoppingCart.Category.Item.referenceError.referenceIsSelectedButInvalid.`, {
                        itemName: item.name,
                      })
                  : isValid
                  ? t(`user.ShoppingCart.Category.Item.referenceError.referenceNotSelectedButValid`, {
                      itemName: item.name,
                    })
                  : t(`user.ShoppingCart.Category.Item.referenceError.referenceNotSelectedAndInvalid`, {
                      itemName: item.name,
                    })}
              </>
            ))}{' '}
            {/* This field is lorem ipsum {relatedItem.name || null}. Please select it and fix errors. */}
          </div>
          <div> {fieldError || null}</div>
          {relatedInputNames?.length ? (
            <>
              {t('user.ShoppingCart.Category.Item.requiredFieldsAlertText')}
              <ul>
                {relatedInputNames.map((inputName) => (
                  <li key={inputName}>{inputName}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      }
    />
  );
};
const getInfoItemTableColumns = ({ scalesRef, titlesScaleTable }) => [
  {
    title: titlesScaleTable.value,
    dataIndex: 'value',
    key: 'value',
    width: 150,
    render: (v) => (v === null ? `>${scalesRef.current[scalesRef.current.length - 2].value}` : v),
  },
  {
    title: titlesScaleTable.pricingFormula,
    dataIndex: 'pricingFormula',
    render: (v) => Mentions.removeBrackets(Mentions.richTextToFormula(v)),
    key: 'pricingFormula',
  },
];
const Item = ({
  scales = [],
  relatedInputNames,
  isChecked,
  _id,
  name,
  infoText,
  pricingFormulaExtended,
  paymentInterval,
  price,
  showPrices,
  minPrice,
  t,
  inputFields,
  fieldError,
  relItems,
  isValid,
  currentFeeTypeMessage,
  discount,
  scalePricingFormulaTitle,
  scaleTitle,
  debugMode,
  subTitle,
}) => {
  const titlesScaleTable = {
    value: scaleTitle,
    pricingFormula: scalePricingFormulaTitle,
  };
  const sortedScales = sortBy(scales, 'value');
  const scalesRef = useRef();
  scalesRef.current = sortedScales;
  const infoTextExists = useMemo(() => !RichText.isRichTextEmpty(infoText), [infoText]);

  return (
    <div>
      <div className={classes.item}>
        <div className={classes.itemFieldContainer}>
          <FormItemMemo className="margin-bottom-0" key={_id} name={_id}>
            <Checkbox name={_id}>{name}</Checkbox>
            {infoTextExists ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  showInfoTextModal({ infoText, name });
                }}
              >
                <InfoCircleOutlined style={{ fontSize: '16px' }} />
              </a>
            ) : null}
            <ItemPanelHeaderMemo subTitle={subTitle} />
            <div className={classes.inputFieldsItem}>
              {inputFields?.length && isChecked ? <MemoInputFields inputFields={inputFields} /> : null}
            </div>
            {isChecked && !isValid && (
              <RequiredFieldsAlert fieldError={fieldError} relatedInputNames={relatedInputNames} relItems={relItems} />
            )}
            {debugMode ? (
              <div className={classes.itemText}>
                <div>
                  {t('user.ShoppingCart.Category.Item.pricingFormula', { formula: pricingFormulaExtended?.formatted })}
                </div>
                <div>{t('user.ShoppingCart.Category.Item.paymentInterval', { paymentInterval })}</div>
                {minPrice ? <div>{t('user.ShoppingCart.Category.Item.minPrice', { minPrice })}</div> : null}
              </div>
            ) : null}
          </FormItemMemo>
        </div>
        {((price && showPrices) || currentFeeTypeMessage) && (
          <Card size="small" className={classes.itemPricePreview}>
            <div>
              {currentFeeTypeMessage || null}
              {price && showPrices
                ? (() => {
                    const priceTranslation = Calc.priceInfoAsText({
                      finalPrice: price.discountedValue,
                      priceBeforeDiscount: price.value,
                      paymentInterval,
                      discount,
                    });
                    return (
                      <Trans
                        i18nKey={`sharedPackage.${priceTranslation.code}`}
                        components={{ del: <del /> }}
                        values={{
                          ...priceTranslation.variables,
                          newlineOrWhitespace: '<br/>',
                          paymentInterval: t(
                            `common.Item.paymentIntervalValue.${priceTranslation.variables.paymentInterval}`,
                            {
                              defaultValue: '',
                            },
                          ),
                        }}
                      />
                    );
                  })()
                : null}
            </div>
          </Card>
        )}
      </div>
      {debugMode && sortedScales.length ? (
        <div className={classes.scalesTable}>
          <TableMemo
            columns={getInfoItemTableColumns({ scalesRef, titlesScaleTable })}
            dataSource={sortedScales}
            bordered
            pagination={false}
          />
        </div>
      ) : null}
    </div>
  );
};
const MemoItem = memo(Item, equal);

const getItemValidation = ({ item, values, errors, category }) => {
  const { _id, recursiveFormulaInfo } = item;
  const rootFields = recursiveFormulaInfo.moreInfo[item._id]?.ids;
  const allInputFieldsInCategory = allInputFieldsInCategories([category]);
  const relatedInputs = filter(rootFields, (inputId) => errors[inputId])
    .map((inputId) => find(allInputFieldsInCategory, { _id: inputId }))
    .filter(Boolean);
  const relatedItems = recursiveFormulaInfo.usedIdsInFormula
    .map((itemId) => find(category.items, { _id: itemId }))
    .filter(Boolean);

  const relatedInputNames = map(relatedInputs, (i) => i.name);
  const fieldError = errors[_id];

  const allRelItems = isItemRecursive(item)
    ? []
    : // eslint-disable-next-line no-shadow
      relatedItems.map((item) => ({
        isSelected: values[item._id],
        item,
        isValid: getItemValidation({ item, values, errors, category })?.isValid,
      }));
  const relItems = allRelItems.filter(({ isSelected, isValid }) => !isSelected || !isValid);

  const isValid = !(relatedInputNames?.length || fieldError || relItems?.length);
  return {
    relatedInputNames,
    fieldError,
    relItems,
    isValid,
  };
};
const ItemContainer = (item) => {
  const {
    _id,
    name,
    infoText,
    pricingFormulaExtended,
    paymentInterval,
    inputFields,
    minPrice,
    scales,
    scaleTitle,
    scalePricingFormulaTitle,
    subTitle,
  } = item;
  const { values, errors } = useFormikContext();
  const category = useCategoryContext();
  const categories = useCategoriesContext();
  const { items } = category;
  const discount = getDiscountForCategory({ category, values });
  const { t } = useTranslation();
  const isChecked = !!values[_id];
  const { relatedInputNames, fieldError, relItems, isValid } = getItemValidation({ item, values, errors, category });
  const calcCategoryTotalById = useCalcCategoryTotalById();

  const [pricePreviewVisibleForCurrentFeeType, currentFeeTypeMessage] = isChecked
    ? shouldPricePreviewBeVisibleForCurrentFeeType({
        item,
        feeType: values.feeType,
      })
    : [false, ''];
  const price =
    isChecked &&
    isValid &&
    values.showPrices &&
    pricePreviewVisibleForCurrentFeeType &&
    calcItem({ values, category, item, items, calcCategoryTotalById, allCategoryIds: categories.map((c) => c._id) });

  return (
    <MemoItem
      scales={scales}
      fieldError={fieldError}
      relItems={relItems}
      relatedInputNames={relatedInputNames}
      isChecked={isChecked}
      isValid={isValid}
      _id={_id}
      name={name}
      infoText={infoText}
      pricingFormulaExtended={pricingFormulaExtended}
      paymentInterval={paymentInterval}
      price={price}
      currentFeeTypeMessage={currentFeeTypeMessage}
      showPrices={values.showPrices}
      t={t}
      inputFields={inputFields}
      discount={discount}
      minPrice={minPrice}
      debugMode={values.debugMode}
      scaleTitle={scaleTitle}
      scalePricingFormulaTitle={scalePricingFormulaTitle}
      subTitle={subTitle}
    />
  );
};
const ItemContainerMemo = memo(ItemContainer);

const Items = () => {
  const category = useCategoryContext();
  const { items } = category;
  const { values } = useFormikContext();
  const discount = getDiscountForCategory({ category, values });
  const itemsArray = items.map((item, index) => {
    return (
      <Fragment key={item._id}>
        <ItemContainerMemo {...item} discount={discount} />
        {index === items.length - 1 ? null : <Divider className={classes.divider} />}
      </Fragment>
    );
  });
  return <div className={classes.items}>{itemsArray}</div>;
};
const MemoItems = memo(Items);
const Discount = () => {
  const { _id, discounts } = useCategoryContext();
  const { t } = useTranslation();
  const name = `${_id}_discount`;
  return (
    <FormItem label={t('user.ShoppingCart.Category.Discount.label')} className={classes.discount} name={name}>
      <Select name={name} className={classes.discountSelect} allowClear>
        {discounts.map((discount) => (
          <Option key={discount._id} value={discount._id}>
            {discount.name}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};
const join = (elements, divider = <Divider className={classes.divider} />) => {
  return (
    <div>
      {elements?.length
        ? elements
            .map((element) => <span>{element}</span>)
            .reduce(
              (prev, curr, index) => (
                <>
                  {prev}
                  {index === 0 ? null : divider}
                  {curr}
                </>
              ),
              [],
            )
        : null}
    </div>
  );
};
const BadgeSelectedItemsMemo = memo(BadgeSelectedItems, equal);
function BadgeSelectedItems({ category, items }) {
  const length = useMemo(
    () =>
      intersection(
        Object.keys(items),
        category.items.map((e) => e._id),
      ).length,
    [category.items, items],
  );
  if (!length) return null;
  return (
    <div className={classes.badgeCategory}>
      <Badge count={length} showZero />
    </div>
  );
}

const CategoryInCart = ({ category, showPrices, t, className }) => {
  const { _id, name, isNew, inputFields, discounts, items, subTitle } = category;
  const displayStartOfService = hasCategoryStartOfService(category);
  const { values } = useFormikContext();
  const isOpen = category?.items?.find((item) => values[item._id]) || isNew;

  const activeFields = useMemo(() => {
    return pickBy(values, (value) => typeof value === 'boolean' && value);
  }, [values]);

  return (
    <CategoryContext.Provider value={category}>
      <Collapse defaultActiveKey={isOpen ? [_id] : null} expandIconPosition="left" className={className}>
        <Panel
          forceRender
          header={<CategoryPanelHeaderMemo title={name} subTitle={subTitle} />}
          key={_id}
          extra={<BadgeSelectedItemsMemo category={category} items={activeFields} />}
        >
          <Form layout="vertical">
            {displayStartOfService && <StartOfService />}
            {join(
              [
                inputFields.length && <MemoInputFields />,
                items?.length && <MemoItems />,
                values?.showDiscounts && discounts?.length && <Discount />,
              ].filter(Boolean),
            )}
            {!!showPrices && (
              <Collapse expandIconPosition="left" className={classes.categoryTotalWrapper} ghost>
                <Panel forceRender header={t('user.ShoppingCart.Category.Total.label')}>
                  <CategoryTotalTable />
                </Panel>
              </Collapse>
            )}
          </Form>
        </Panel>
      </Collapse>
    </CategoryContext.Provider>
  );
};

const CategoryInCartMemo = memo(CategoryInCart);
const CategoryInCartContainer = ({ category, className }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  return <CategoryInCartMemo category={category} showPrices={values.showPrices} t={t} className={className} />;
};
const CategoryInCartContainerMemo = memo(CategoryInCartContainer);
export default CategoryInCartContainerMemo;
