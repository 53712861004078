import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { getName } from 'i18n-iso-countries';
import { BsPencil, BsArrowRepeat } from 'react-icons/bs';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { memo, useCallback } from 'react';
import * as Yup from 'yup';
import { updateCompany } from 'graphql/methods';
import { grabFirstGQLDataResult } from 'utils/helpers';
import equal from 'fast-deep-equal/es6/react';
import classes from './CompanyCard.module.less';
import { CompanyForm, companyToString } from '../components/Inputs';
import { companyFormSchema } from '../components/schema';

export const editCompanyFormSchema = ({ values }) => {
  return Yup.object().shape({
    ...companyFormSchema(values),
  });
};
const CompanyFormMemo = memo(CompanyForm, equal);

const editCompany = ({ company, onChange }) =>
  inputConfirmModal({
    formContent: () => <CompanyFormMemo />,
    fields: [],
    onSubmit: async (modifier) => {
      const { data } = await updateCompany({ _id: company?._id, ...modifier });
      const currentContact = grabFirstGQLDataResult(data);
      onChange(currentContact);
    },
    value: {
      type: company.type || 'company',
      name: company.name || '',
      identifer: company.identifer || '',
      street: company.address.street || '',
      houseNumber: company.address.houseNumber || '',
      cityCode: company.address.cityCode || '',
      city: company.address.city || '',
      country: company.address.country || 'DE',
    },
    headerText: i18n.t('user.ShoppingCart.ContactData.modal.titles.editCompany'),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: editCompanyFormSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['name', i18n.t('user.ShoppingCart.ContactData.company.duplicatedErrorMessage.name')],
    },
  });

function CompanyCard({ company, onOpenModal, onChange }) {
  const { t } = useTranslation();
  const onEdit = useCallback(() => {
    editCompany({ company, onChange });
  }, [company, onChange]);
  if (!company) return null;
  return (
    <>
      <Card
        title={
          <div className={classes.title}>
            <h3>{companyToString(company)}</h3>
            {company.identifer ? (
              <div>
                {t('user.ShoppingCart.ContactData.company.card.identifer')}: {company.identifer}
              </div>
            ) : null}
          </div>
        }
        className={classes.cardCompany}
      >
        <div>
          <span>{company.address.street}</span> <span>{company.address.houseNumber}</span>
        </div>
        <div>
          <span>{company.address.cityCode}</span> <span>{company.address.city}</span>
        </div>
        <div>
          <span>{getName(company.address.country, i18n.language, { select: 'official' })}</span>
        </div>
      </Card>
      <div className={classes.cardCompanyFooter}>
        <Button onClick={onEdit} icon={<BsPencil size={20} />}>
          {t('user.ShoppingCart.ContactData.company.card.actions.edit')}
        </Button>
        <Button onClick={onOpenModal} icon={<BsArrowRepeat size={20} />}>
          {t('user.ShoppingCart.ContactData.company.card.actions.change')}
        </Button>
      </div>
    </>
  );
}
export default CompanyCard;
