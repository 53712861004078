import cn from 'classnames';
import { Link } from 'react-router-dom';
import kanzleiPilotLogoSrc from 'assets/images/KanzleiPilotLogo.svg';
import kanzleiPilotLogoCollapsedSrc from 'assets/images/KanzleiPilotLogoCollapse.svg';
import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import s from './AppHeader.module.less';

const Logo = memo(({ className, classNameWrapper, collapsed, onClick, to = null }) => {
  const img = (
    <img
      aria-hidden="true"
      onClick={onClick}
      className={cn(s.logo, className)}
      alt="logo"
      src={collapsed ? kanzleiPilotLogoCollapsedSrc : kanzleiPilotLogoSrc}
    />
  );
  return <div className={cn(classNameWrapper, s.logoWrapper)}>{to ? <Link to={to}>{img}</Link> : img}</div>;
}, equal);

export default memo(Logo, equal);
