import gql from 'graphql-tag';
import { adminDocumentTemplateListFragment } from '../fragments';

const ADMIN_DOCUMENT_TEMPLATE_LIST_QUERY = gql`
  query adminDocumentTemplateList($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      ...adminDocumentTemplateListFragment
    }
  }
  ${adminDocumentTemplateListFragment}
`;

export default ADMIN_DOCUMENT_TEMPLATE_LIST_QUERY;
