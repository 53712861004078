import { GrDrag } from 'react-icons/gr';
import { useRowContext } from 'contexts/RowContext';
import classes from './MoveButton.module.less';
import DraggableDecoration from './DraggableDecoration';

export const DragButton = ({ ...buttonProps }) => {
  const { drag } = useRowContext();
  return (
    <>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', left: -2, top: -7 }}>
          <div
            ref={(ref) => drag(ref)}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'move',
              padding: '10px 5px 10px 10px',
              marginTop: '-10px',
              marginLeft: '-10px',
            }}
          >
            <GrDrag {...buttonProps} type="primary" className={classes.drag} style={{ cursor: 'move' }} />
          </div>{' '}
        </div>
      </div>
      <DraggableDecoration />
    </>
  );
};

export default DragButton;
