/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { Input } from 'formik-antd';
import { Button, Select } from 'antd';
import { useCountries } from 'utils/locale';
import 'antd/dist/antd.css';
import { useFormikField } from 'hooks/common/useFormikField';
import i18n from 'i18n';
import PhoneNumber from 'components/common/PhoneInputFormik';
import salutations from 'constants/salutations';
import classes from './Inputs.module.less';
import TypeFormikSwitcher from './Switcher';

const { Option } = Select;
const defaultAntdFilterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const CompanyForm = () => {
  const { t } = useTranslation();
  const typesKeys = [
    { _id: 'company', label: t('user.ShoppingCart.ContactData.company.inputs.type.company') },
    { _id: 'individual', label: t('user.ShoppingCart.ContactData.company.inputs.type.individual') },
  ];
  return (
    <>
      <TypeFormikSwitcher allKeys={typesKeys} />
      <CompanyNameFormikInput />
      <CompanyIdentifierFormikInput />
      <div className={classes.group}>
        <StreetFormikInput containerProps={{ className: 'flex-3' }} />
        <HouseNumberFormikInput containerProps={{ className: 'flex-1' }} />
      </div>
      <div className={classes.group}>
        <CityCodeFormikInput containerProps={{ className: 'flex-1' }} />
        <CityFormikInput containerProps={{ className: 'flex-3' }} />
      </div>
      <CountryFormikDropdownInput />
    </>
  );
};
export const ContactForm = ({ isAccountForm }) => {
  const typeContactData = 'contact';
  return (
    <>
      <div className={classes.group}>
        <SalutationFormikInput typeContactData={typeContactData} />
        <FirstNameFormikInput containerProps={{ className: 'flex-1' }} typeContactData={typeContactData} />
        <LastNameFormikInput containerProps={{ className: 'flex-1' }} typeContactData={typeContactData} />
      </div>
      <PositionFormikInput typeContactData={typeContactData} />
      {isAccountForm ? null : <EmailFormikInput typeContactData={typeContactData} />}
    </>
  );
};
export const CompanyNameFormikInput = () => {
  const name = 'name';
  const { t } = useTranslation();
  const { value: type } = useFormikField('type');
  const { value, onChange } = useFormikField(name);

  return (
    <FormItem name={name} label={t(`user.ShoppingCart.ContactData.company.inputs.${name}.${type}.label`)}>
      <Input
        name={name}
        placeholder={t(`user.ShoppingCart.ContactData.company.inputs.${name}.${type}.placeholder`)}
        value={value}
        onChange={onChange}
      />
    </FormItem>
  );
};
export const CityCodeFormikInput = ({ containerProps }) => <InputFormik name="cityCode" containerProps={containerProps} />; // prettier-ignore
export const CityFormikInput = ({ containerProps }) => <InputFormik name="city" containerProps={containerProps} />;
export const CompanyIdentifierFormikInput = () => <InputFormik name="identifer" />;
export const EmailFormikInput = ({ typeContactData }) => <InputFormik name="email" typeContactData={typeContactData} />;
export const PositionFormikInput = ({ typeContactData }) => <InputFormik name="position" typeContactData={typeContactData} />; // prettier-ignore
export const FirstNameFormikInput = ({ typeContactData, containerProps }) => <InputFormik name="firstName" typeContactData={typeContactData} containerProps={containerProps} />; // prettier-ignore
export const HouseNumberFormikInput = ({ containerProps }) => <InputFormik name="houseNumber" containerProps={containerProps} />; // prettier-ignore
export const LastNameFormikInput = ({ typeContactData, containerProps }) => <InputFormik name="lastName" typeContactData={typeContactData} containerProps={containerProps} />; // prettier-ignore
export const StreetFormikInput = ({ containerProps }) => <InputFormik name="street" containerProps={containerProps} />;
export const NumberPhoneAlternativeFormikInput = ({ typeContactData }) => <InputFormik name="phoneNumberAlternative" Component={PhoneNumber} typeContactData={typeContactData} />; // prettier-ignore
export const NumberPhoneBusinessFormikInput = ({ typeContactData }) => <InputFormik name="phoneNumber" Component={PhoneNumber} typeContactData={typeContactData} />; // prettier-ignore

function InputFormik({ name, containerProps, Component = Input, typeContactData = 'company', componentProps }) {
  const { t } = useTranslation();
  const { value, onChange } = useFormikField(name);
  return (
    <FormItem
      name={name}
      label={t(`user.ShoppingCart.ContactData.${typeContactData}.inputs.${name}.label`)}
      containerProps={containerProps}
    >
      <Component
        name={name}
        placeholder={t(`user.ShoppingCart.ContactData.${typeContactData}.inputs.${name}.placeholder`)}
        value={value}
        onChange={onChange}
        {...componentProps}
      />
    </FormItem>
  );
}
export default InputFormik;

export function SalutationFormikInput({ typeContactData }) {
  const { t } = useTranslation();
  const mapSalutations = Object.values(salutations)
    .map((e) => ({
      _id: e,
      label: t(`user.ShoppingCart.ContactData.contact.inputs.salutation.options.${e}`),
    }))
    .filter(({ label }) => label);
  return (
    <InputFormik
      name="salutation"
      typeContactData={typeContactData}
      Component={FieldSearchDropdown}
      componentProps={{
        notFoundContent: <div>No salutations</div>,
        options: mapSalutations,
        placeholder: t(`user.ShoppingCart.ContactData.${typeContactData}.inputs.salutation.placeholder`),
      }}
    />
  );
}

export function CountryFormikDropdownInput() {
  const countries = useCountries();
  const { t } = useTranslation();

  return (
    <InputFormik
      name="country"
      Component={FieldSearchDropdown}
      componentProps={{
        options: countries,
        notFoundContent: <div>No countries</div>,
        placeholder: t('user.ShoppingCart.ContactData.company.inputs.country.placeholder'),
      }}
    />
  );
}

export function FieldSearchDropdown({ value, placeholder, onChange, options, notFoundContent, loading = false }) {
  return (
    <Select
      loading={loading}
      notFoundContent={notFoundContent}
      showSearch
      value={value}
      style={{ width: '100%' }}
      placeholder={placeholder}
      onSelect={onChange}
      filterOption={defaultAntdFilterOption}
    >
      {options.map((e) => (
        <Option value={e._id} key={e._id}>
          {e.label}
        </Option>
      ))}
    </Select>
  );
}

const notFoundContent = ({ name, namePlural, changeTab }) => () => (
  <div className={classes.emptyOption}>
    <div>No {namePlural}</div>
    <Button type="primary" onClick={() => changeTab('2')}>
      Create a {name}
    </Button>
  </div>
);

export const contactToString = (contact) => (contact ? `${contact.lastName} ${contact.firstName}` : '');
export const companyToString = (company) =>
  company
    ? `${company.name} (${i18n.t(`user.ShoppingCart.ContactData.company.inputs.type.${company.type?.toLowerCase()}`)})`
    : '';

export function ContactExistingFormikInput({ contacts = [], loading, changeTab }) {
  const { t } = useTranslation();
  const name = 'contact';
  return (
    <InputFormik
      name={name}
      typeContactData={name}
      Component={ExistingSearchDropdown}
      componentProps={{
        loading,
        notFoundContent: notFoundContent({ changeTab, name, namePlural: 'contacts' }),
        itemRender: (props) => contactToString(props),
        options: contacts,
        placeholder: t(`user.ShoppingCart.ContactData.contact.inputs.${name}.placeholder`),
      }}
    />
  );
}

export function CompanyExistingFormikInput({ companies = [], loading, changeTab, placeholder }) {
  const name = 'company';
  return (
    <InputFormik
      name={name}
      typeContactData={name}
      Component={ExistingSearchDropdown}
      componentProps={{
        loading,
        notFoundContent: notFoundContent({ changeTab, name, namePlural: 'companies' }),
        itemRender: (props) => companyToString(props),
        options: companies,
        placeholder,
      }}
    />
  );
}
export function ExistingSearchDropdown({
  value,
  placeholder,
  options,
  onChange,
  itemRender,
  notFoundContent,
  loading,
}) {
  return (
    <Select
      loading={loading}
      disabled={loading}
      value={value}
      notFoundContent={notFoundContent?.()}
      showSearch
      onSelect={onChange}
      filterOption={defaultAntdFilterOption}
      placeholder={placeholder}
    >
      {loading
        ? null
        : options.map((option) => (
            <Option value={option._id} key={option._id}>
              {itemRender(option)}
            </Option>
          ))}
    </Select>
  );
}
