/*
  In most cases you need routePaths default export
  But if the route includes a variable, you may need to interpolate this value into template. To do this, you can import routePatterns

  Usage:

  import { routePatterns } from 'router/route-paths';
  const path = routePatterns.userProfile.stringify({ id: params.id }))

  Where "userProfile" is a key in routePaths with a variable like this { userProfile: '/user/:id' }
*/
import UrlPattern from 'url-pattern';
import ROLES from 'constants/roles';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { pickBy, includes } from 'lodash';

const routePaths = {
  home: '/secure',
  catalogueConfiguration: '/secure/admin/catalog',
  itemCatalogueConfiguration: '/secure/admin/catalog/item/:id',
  commonLibraryConfiguration: '/secure/admin/common-library',
  documentTemplateList: '/secure/admin/document-templates',
  commonLibraryDocumentTemplateList: '/secure/admin/common-library-document-templates',
  documentTemplateConfiguration: '/secure/admin/document-templates/:id',
  commonLibraryDocumentTemplateConfiguration: '/secure/admin/common-library-document-templates/:id',
  commonLibraryItemConfiguration: '/secure/admin/common-library/item/:id',
  shoppingCartEntries: '/secure/shopping-cart-entries',
  shoppingCart: '/secure/shopping-cart',
  login: '/login',
  resetPassword: '/reset-password',
  shoppingCartRevision: '/secure/shopping-cart-revision',
  routerShoppingCartRevision: '/secure/shopping-cart-revision/:id',

  corporateIdentitySetting: '/secure/admin/setting/corporate-identity',
  companySetting: '/secure/admin/setting/company',
  accountSetting: '/secure/setting/account',
};
export const useIsUserRole = () => {
  const [me] = useCurrentUser();
  const role = me?.role;
  return role === ROLES.USER;
};

export const useGetRoutePathsNavBar = () => {
  const hideRoutePaths = [routePaths.login, routePaths.resetPassword];
  const hideRoutePathsForUser = [routePaths.shoppingCart, routePaths.routerShoppingCartRevision];

  const isUser = useIsUserRole();
  if (isUser) {
    return Object.values(pickBy(routePaths, (value) => !includes(hideRoutePathsForUser, value)));
  }
  return Object.values(pickBy(routePaths, (value) => !includes(hideRoutePaths, value)));
};

export const routePatterns = Object.entries(routePaths).reduce(
  (p, [key, pattern]) => ({ ...p, [key]: new UrlPattern(pattern) }),
  {},
);

export default routePaths;
