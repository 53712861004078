import gql from 'graphql-tag';

const MOVE_INPUT_FIELD_MUTATION = gql`
  mutation moveCategoryInputField($_id: ID!, $offset: Int, $order: Int) {
    moveCategoryInput(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default MOVE_INPUT_FIELD_MUTATION;
