import apollo from 'graphql/apollo';
import { updateTenantCompanyMutation } from 'graphql/mutations';
import { adminTenantCompany } from 'graphql/queries';

const updateCategoryItem = async ({ companyName, street, streetNr, cityCode, city, phoneNr, country, websiteUrl }) =>
  apollo.mutate({
    mutation: updateTenantCompanyMutation,
    variables: {
      company: {
        companyName,
        street,
        streetNr,
        cityCode,
        city,
        phoneNr,
        country,
        websiteUrl,
      },
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: adminTenantCompany,
        data: {
          getTenantCompany: data?.updateTenantCompany,
        },
      });
    },
  });
export default updateCategoryItem;
