import { allInputFieldsInCategories } from 'components/user/utils';
import apollo from 'graphql/apollo';
import { deleteInputFieldMutation } from 'graphql/mutations';
import i18n from 'i18n';
import { find } from 'lodash';
import confirmModal from 'utils/confirmModal';
import { clientDeleteInputField, toastGraphQLError } from 'utils/helpers';

const deleteInputField = async ({ _id, categoryListQuery }) =>
  apollo.mutate({
    mutation: deleteInputFieldMutation,
    refetchQueries: ['ItemCatalogueConfiguration'],
    variables: { _id },
    update: (cache) => {
      const cachedData = cache.readQuery({ query: categoryListQuery });
      const { categories } = cachedData;
      const category = categories.find(({ inputFields }) => find(inputFields, { _id }));
      if (!category) return;
      const newInputFields = clientDeleteInputField({ array: allInputFieldsInCategories([category]), _id });
      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: cachedData.categories.map((c) =>
            find(c.inputFields, { _id }) ? { ...c, inputFields: newInputFields } : c,
          ),
        },
      });
    },
  });
export const deleteInputFieldWithConfirmation = ({ _id, categoryListQuery }) =>
  confirmModal({
    okText: i18n.t('admin.deleteInputConfirmation.ok'),
    cancelText: i18n.t('admin.deleteInputConfirmation.cancel'),
    okType: 'danger',
    onOk: () => deleteInputField({ _id, categoryListQuery }).catch(toastGraphQLError),
    title: i18n.t('admin.deleteInputConfirmation.title'),
  });
export default deleteInputField;
