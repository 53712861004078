// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._18jHwcW0DgFRSqcQ3bFEnW .ant-collapse-content-box {\n  background-color: #fafafa;\n}\n._18jHwcW0DgFRSqcQ3bFEnW > :first-child {\n  display: flex;\n  flex: 1;\n  overflow: hidden;\n}\n._18jHwcW0DgFRSqcQ3bFEnW > :first-child .ant-collapse-extra {\n  margin-left: 10px;\n  align-self: flex-end;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/drag/Collapse/Collapse.module.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AAFA;EAKI,aAAA;EACA,OAAA;EACA,gBAAA;AAAJ;AAPA;EASM,iBAAA;EACA,oBAAA;AACN","sourcesContent":[".siteCollapseCustomPanel {\n  :global(.ant-collapse-content-box) {\n    background-color: #fafafa;\n  }\n  > :first-child {\n    display: flex;\n    flex: 1;\n    overflow: hidden;\n    :global(.ant-collapse-extra) {\n      margin-left: 10px;\n      align-self: flex-end;\n    }\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteCollapseCustomPanel": "_18jHwcW0DgFRSqcQ3bFEnW"
};
export default ___CSS_LOADER_EXPORT___;
