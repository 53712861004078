import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import moveCategoryItemCache from 'graphql/cache/moveCategoryItem';
import { createCategoryItem, deleteCategoryItemWithConfirmation, moveCategoryItem } from 'graphql/methods';
import { TableMemo } from 'memo';
import { useHistory } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import ItemTypes from 'constants/ItemTypes';
import { clientShift } from 'utils/helpers';
import EmptyBox from 'components/common/EmptyBox';
import inputConfirmModal from 'utils/inputConfirmModal';
import { getCategoryListQuery } from 'graphql/queries';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { useLibraryContext } from 'contexts/LibraryContext';
import DragButton from './drag/DragButton';
import DraggableBodyRow from './drag/DraggableBodyRow';
import classes from './CollapsibleCategoryConfiguration.module.less';
import { NameFormikInput } from './itemModal/components';
import { textsSchema } from './itemModal/schema';
import Cell from './table/Cell';

const itemForm = () =>
  function ItemForm() {
    return (
      <div>
        <Form layout="vertical">
          <NameFormikInput />
        </Form>
      </div>
    );
  };
const FieldDiv = ({ categoryId }) => [{ component: itemForm({ categoryId }), label: '' }];

const addNewCategoryItem = ({ categoryId, categoryListQuery, editItem }) =>
  inputConfirmModal({
    fields: FieldDiv({ categoryId }),
    onSubmit: (modifier) =>
      createCategoryItem({
        categoryId,
        categoryListQuery,
        modifier,
      }).then(
        ({
          data: {
            createCategoryItem: { _id },
          },
        }) => {
          editItem(_id);
        },
      ),
    value: {
      benefits: '',
      infoText: null,
      minPrice: 0,
      name: '',
      notesToEmployee: '',
      paymentInterval: 'oneOff',
      pleaseNote: '',
      pricingFormula: '[{"type":"div","children":[{"text":"0"}]}]',
      scalePricingFormulaTitle: '',
      scaleTitle: '',
      scales: [],
      scalesEnabled: false,
    },
    errorResolver: { Duplicated: ['name', i18n.t('admin.itemModal.duplicatedErrorMessage')] },
    headerText: i18n.t('admin.itemModal.addTitle'),
    okText: i18n.t('admin.itemModal.ok'),
    cancelText: i18n.t('admin.itemModal.cancel'),
    categoryId,
    validationSchema: () =>
      Yup.object().shape({
        ...textsSchema({}),
        //   ...priceSchema({ categoryId, parentId: categoryId, parentType: 'category', formikRef }),
      }),
    forceMultiField: true,
    width: '600px',
  });

const categoryItemsTableColumns = ({ editItem, categoryListQuery }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragButton />,
    key: 'sort',
  },
  {
    className: classes.nameColumn,
    title: i18n.t('admin.itemTable.columns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    className: classes.pricingFormulaColumn,
    title: i18n.t('admin.itemTable.columns.pricingFormula'),
    render: (e) => e?.pricingFormulaExtended?.formatted || null,
    key: 'pricingFormula',
  },
  {
    className: classes.paymentIntervalColumn,
    title: i18n.t('admin.itemTable.columns.paymentInterval'),
    render: (e) => i18n.t(`common.Item.paymentIntervalValue.${e.paymentInterval}`),
    key: 'paymentInterval',
  },
  {
    className: classes.actionsColumn,
    title: i18n.t('admin.itemTable.columns.actions'),
    dataIndex: '',
    key: 'actions',
    render: ({ _id }) => {
      return (
        <>
          <Button ghost type="primary" icon={<EditOutlined />} onClick={() => editItem(_id)} />{' '}
          <Button
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteCategoryItemWithConfirmation({ _id, categoryListQuery })}
          />
        </>
      );
    },
  },
];

const ItemsContainer = ({ _id, items }) => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const categoryListQuery = getCategoryListQuery(isLibrary);
  const history = useHistory();
  const editItem = useCallback(
    (itemId) => {
      let path = routePatterns.itemCatalogueConfiguration.stringify({ id: itemId });
      if (isLibrary) path = routePatterns.commonLibraryItemConfiguration.stringify({ id: itemId });
      history.push(path);
    },
    [history, isLibrary],
  );
  const columns = useMemo(() => categoryItemsTableColumns({ editItem, categoryListQuery }), [
    categoryListQuery,
    editItem,
  ]);
  const move = useCallback(
    (dragIndex, hoverIndex, itemId) => {
      const newItems = clientShift({ array: items, _id: itemId, shift: hoverIndex - dragIndex });
      moveCategoryItemCache({ _id, items: newItems });
    },
    [_id, items],
  );
  return (
    <div className={classes.itemsContainer}>
      <h4>{t('admin.itemTable.title')}</h4>
      <div className={classes.wrapperTable}>
        <TableMemo
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyItemListMessage')} /> }}
          rowKey={(record) => record?._id}
          components={{
            body: {
              row: DraggableBodyRow,
              cell: Cell,
            },
          }}
          onRow={(record, index) => ({
            index,
            record,
            move,
            itemTypes: _id + ItemTypes.CATEGORY_ITEM,
            mutation: {
              move: moveCategoryItem(categoryListQuery),
            },
            onClick: (e) => {
              if (e.target && e.target.nextSibling && e.target.getAttribute('class') === 'ant-table-cell')
                editItem(record._id);
            },
          })}
          pagination={false}
          dataSource={items}
          columns={columns}
          scroll={{
            x: 640,
          }}
          className="pointer-not-last-cell"
        />
      </div>
      <br />

      <Button
        icon={<PlusOutlined />}
        onClick={() => addNewCategoryItem({ categoryId: _id, categoryListQuery, editItem })}
        type="primary"
      >
        {t('admin.addItemButton')}
      </Button>
    </div>
  );
};
export default memo(ItemsContainer);
