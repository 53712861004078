import gql from 'graphql-tag';

const adminPdfBodyPrimaryFooterFragment = gql`
  fragment adminPdfBodyPrimaryFooterFragment on PdfBodyPrimaryFooter {
    fontFamily
    color
  }
`;

export default adminPdfBodyPrimaryFooterFragment;
