/* eslint-disable react/no-danger */
import { Collapse } from 'antd';
import EmptyBox from 'components/common/EmptyBox';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import classes from './CollapsibleCommonCategory.module.less';
import HeaderCollapse from './components/HeaderCollapse/HeaderCollapse';

const { Panel } = Collapse;

const columns = [
  {
    title: i18n.t('admin.itemTable.columns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: i18n.t('admin.itemTable.columns.pricingFormula'),
    render: (e) => e?.pricingFormulaExtended?.formatted || null,
    key: 'pricingFormula',
  },
  {
    title: i18n.t('admin.itemTable.columns.paymentInterval'),
    render: (e) => i18n.t(`common.Item.paymentIntervalValue.${e.paymentInterval}`),
    key: 'paymentInterval',
  },
];

const ItemsContainer = ({ items }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.itemsContainer}>
      <TableMemo
        locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyItemListMessage')} /> }}
        rowKey={(record) => record?._id}
        pagination={false}
        dataSource={items}
        columns={columns}
        className="pointer-not-last-cell"
      />
    </div>
  );
};

function CollapsibleCommonCategory({ category }) {
  const { _id, name, isNew, libraryDescription } = category;

  return (
    <Collapse
      defaultActiveKey={isNew ? [_id] : undefined}
      className={`site-collapse-custom-collapse ${classes.collapse}`}
    >
      <Panel forceRender header={<HeaderCollapse name={name} />} key={_id} className={classes.siteCollapseCustomPanel}>
        {libraryDescription ? <p className="checkmarks">{RichText.renderRichText(libraryDescription)}</p> : null}
        <ItemsContainer _id={_id} items={category.items} />
      </Panel>
    </Collapse>
  );
}

export default memo(CollapsibleCommonCategory);
