// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3bdp7Tv6enfaiJYa-P4gt6 {\n  width: calc(90vw - 48px);\n  height: calc(100vh - 50px - 24px - 24px - 53px - 24px - 20px - 10px);\n  padding: 24px 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/user/document/htmlModal/htmlModal.module.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,oEAAA;EACA,eAAA;AACF","sourcesContent":[".modal {\n  width: calc(90vw - 48px);\n  height: calc(100vh - 50px - 24px - 24px - 53px - 24px - 20px - 10px);\n  padding: 24px 0;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "_3bdp7Tv6enfaiJYa-P4gt6"
};
export default ___CSS_LOADER_EXPORT___;
