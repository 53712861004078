// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3I5RNyz3WswPUuqardVLt1 {\n  background-color: white;\n  margin: 0 !important;\n}\n._3I5RNyz3WswPUuqardVLt1 .ant-tabs-nav-wrap {\n  background-color: #fafafa;\n}\n._3I5RNyz3WswPUuqardVLt1 .ant-tabs-content-holder {\n  padding: 0 16px;\n}\n._3I5RNyz3WswPUuqardVLt1 .ant-tabs-tab {\n  border: none !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/ShoppingCart/ContactData/ContactData.module.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,oBAAA;AACF;AAHA;EAII,yBAAA;AAEJ;AANA;EAOI,eAAA;AAEJ;AATA;EAUI,uBAAA;AAEJ","sourcesContent":[".tabs {\n  background-color: white;\n  margin: 0 !important;\n  :global(.ant-tabs-nav-wrap) {\n    background-color: #fafafa;\n  }\n  :global(.ant-tabs-content-holder) {\n    padding: 0 16px;\n  }\n  :global(.ant-tabs-tab) {\n    border: none !important;\n  }\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "_3I5RNyz3WswPUuqardVLt1"
};
export default ___CSS_LOADER_EXPORT___;
