import gql from 'graphql-tag';
import userDiscountFragment from './userDiscountFragment';
import userInputFieldFragment from './userInputFieldFragment';
import userItemFragment from './userItemFragment';

const userCategoryListFragment = gql`
  fragment userCategoryListFragment on Category {
    _id
    subTitle
    introForDocument
    name
    order
    companyTypeIds
    inputFields {
      ...userInputFieldFragment
    }
    discounts {
      ...userDiscountFragment
    }
    items {
      ...userItemFragment
    }
  }
  ${userInputFieldFragment}
  ${userDiscountFragment}
  ${userItemFragment}
`;

export default userCategoryListFragment;
