import gql from 'graphql-tag';

const createShoppingCart = gql`
  mutation createShoppingCart($shoppingCart: ShoppingCartInput!) {
    createShoppingCart(shoppingCart: $shoppingCart) {
      _id
    }
  }
`;

export default createShoppingCart;
