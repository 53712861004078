import gql from 'graphql-tag';
import { adminContactFragment } from 'graphql/fragments';

const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact($_id: ID!, $modifier: UpdateContactInput) {
    updateContact(_id: $_id, modifier: $modifier) {
      ...adminContactFragment
    }
  }
  ${adminContactFragment}
`;

export default UPDATE_CONTACT_MUTATION;
