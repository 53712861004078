import apollo from 'graphql/apollo';
import { createHtmlPreviewMutation } from 'graphql/mutations';

const createHtmlPreview = async ({ shoppingCart, currentDocumentId }) =>
  (
    await apollo.mutate({
      mutation: createHtmlPreviewMutation,
      variables: { shoppingCart, currentDocumentId },
    })
  )?.data.generateDocumentHTMLPreview;
export default createHtmlPreview;
