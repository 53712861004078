import { useQuery } from '@apollo/react-hooks';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { adminCategoryInputFieldsQuery } from 'graphql/queries';
import i18n from 'i18n';
import apolloClient from 'graphql/apollo';
import { orderBy } from 'lodash';

// USED ONLY FOR FORMULA CALCULATION
const getPrefix = () => {
  return {
    category: i18n.t('common.Formula.categoryType'),
    categoryItem: i18n.t('common.Formula.itemType'),
    itemTotal: i18n.t('common.Formula.itemTotal'),
    categoryMonthlyAdvance: ({ categoryName }) => i18n.t('common.Formula.categoryMonthlyAdvance', { categoryName }),
  };
};
export const sortPriorities = {
  categoryItem: 1,
  category: 2,
  itemTotal: 3,
  categoryMonthlyAdvance: 4,
};
export const sortedFieldsByType = ({ fields, parentId }) => {
  const withSortPriorities = fields.map((s) => ({
    ...s,
    sortPriority: parentId === s.parentId ? 0 : sortPriorities[s.type] || 100,
  }));
  return orderBy(withSortPriorities, ['sortPriority', 'name']);
};
const getInputTranslation = () => i18n.t('common.InputField.name');
const getFieldsFromData = ({ data, parentId, categoryId }) => {
  const prefix = getPrefix();
  const all = [...(data?.categoryInputs || []), ...(data?.categoryItems || []), ...(data?.categories || [])];
  const fields = all
    .map((input) => {
      const name = Mentions.nameToFormulaCase(input.name);
      if (input.parentType === 'categoryItem' && input.parentId !== parentId) return null;
      return {
        _id: input._id,
        originalName: input.name,
        autoCompleteName: name,
        description: `(Value of "${input.name}" in category "${input?.category?.name}")`,
        parentId: input.parentId,
        ...(input.__typename === 'CategoryInputField' && {
          color: input.parentType === 'category' ? 'orange' : 'geekblue',
          prefix: prefix[input.parentType],
          type: input.parentType,
          name: [input.parentType === 'category' ? prefix[input.parentType] : null, input?.parent?.name, name]
            .filter(Boolean)
            .join('.'),
          description: `(${getInputTranslation()} "${input.name}" in ${prefix[input.parentType]} "${
            input?.parent?.name
          }")`,
        }),
        ...(input.__typename === 'CategoryItem' && {
          color: 'magenta',
          prefix: prefix.itemTotal,
          type: 'itemTotal',
          name: `${name}.${prefix.itemTotal}`,
          disabled:
            [parentId, categoryId].includes(input._id) ||
            input.recursiveFormulaInfo?.usedItemIdsInFormula?.find((i) => [parentId, categoryId].includes(i)),
        }),
        ...(input.__typename === 'Category' && {
          color: 'green',
          prefix: prefix.categoryMonthlyAdvance({ categoryName: name }),
          type: 'categoryMonthlyAdvance',
          name: prefix.categoryMonthlyAdvance({ categoryName: name }),
          disabled: input.items.find(
            (someInput) =>
              [parentId, categoryId].includes(someInput._id) ||
              someInput.recursiveFormulaInfo?.usedItemIdsInFormula?.find((i) => [parentId, categoryId].includes(i)),
          ),
        }),
      };
    })
    .filter(Boolean);
  const sortedFieldsByPriority = sortedFieldsByType({ fields, parentId });
  return sortedFieldsByPriority;
};
export const getAdminCategoryInputFields = ({ categoryId, parentId }) => {
  const data = apolloClient.readQuery({
    query: adminCategoryInputFieldsQuery,
    variables: { categoryId, parentId },
  });
  const fields = getFieldsFromData({ data, parentId, categoryId });
  return fields;
};
const useAdminCategoryInputFields = ({ categoryId, parentId, fetchPolicy = 'network-only' }) => {
  const { data, loading } = useQuery(adminCategoryInputFieldsQuery, {
    variables: { categoryId, parentId },
    fetchPolicy,
  });
  if (loading) return [null, true];
  const fields = getFieldsFromData({ data, parentId, categoryId });
  return [fields, loading];
};

export default useAdminCategoryInputFields;
