import { Form } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import * as Yup from 'yup';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { textsSchema } from 'components/admin/itemModal/schema';
import {
  NameFormikInput,
  InfoTextFormikInput,
  BenefitsFormikInput,
  PleaseNoteFormikInput,
  NotesToEmployeeFormikInput,
  SubTitleFormikInput,
} from 'components/admin/itemModal/components';
import I18nFormik from 'components/common/I18nFormik';

export const textsValidationSchema = () => Yup.object().shape(textsSchema());

const TextsTab = ({ initialValues, onSubmit }) => {
  return (
    <I18nFormik
      initialValues={initialValues}
      validationSchema={textsValidationSchema}
      onSubmit={(values, formik) => onSubmit(values, formik).catch(formik.setErrors)}
      enableReinitialize
    >
      <Form layout="vertical">
        <div className="margin-16 margin-top-0">
          <AlertFromFormik />
          <NameFormikInput />
          <SubTitleFormikInput />
          <InfoTextFormikInput />
          <BenefitsFormikInput />
          <PleaseNoteFormikInput />
          <NotesToEmployeeFormikInput />
          <SaveChangesButton initialValues={initialValues} />
        </div>
      </Form>
    </I18nFormik>
  );
};

export default TextsTab;
