import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';

const CategoryPanelHeader = ({ title, subTitle }) => {
  return (
    <>
      {title}
      <div className="sub-title">{subTitle}</div>
    </>
  );
};
const ItemPanelHeader = ({ subTitle }) => {
  return <div className="sub-title">{subTitle}</div>;
};

export const CategoryPanelHeaderMemo = memo(CategoryPanelHeader, equal);
export const ItemPanelHeaderMemo = memo(ItemPanelHeader, equal);
