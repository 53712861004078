/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import isEmpty from 'lodash/isEmpty';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { print } from 'graphql';
import { afterLogout, LOGOUT_MUTATION } from 'hooks/auth/useLogout';
import get from 'lodash/get';
import i18n from 'i18n';
import { CURRENT_USER_QUERY } from 'hooks/auth/useCurrentUser';
import RefreshTokenService from '../utils/RefreshTokenService';
import settings from '../config/settings';
import introspectionQueryResultData from './fragment-types.json';
import { formatQueryByErrorPositions } from './utils';

const fragmentMatcher = isEmpty(introspectionQueryResultData)
  ? undefined
  : new IntrospectionFragmentMatcher({ introspectionQueryResultData });

const link = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'token',
    isTokenValidOrUndefined: () => {
      return RefreshTokenService.isTokenValidOrUndefined();
    },
    fetchAccessToken: () => {
      console.log('fetching new access token');
      return RefreshTokenService.fetchNewAccessToken();
    },
    handleFetch: () => console.log('refreshed a token with apollo TokenRefreshLink'),
    handleResponse: () => (response) => {
      return response;
    },
    handleError: () => {
      window.setTimeout(() => {
        window?.currentHistory?.push('/login');
        window.setTimeout(async () => {
          try {
            await apollo.mutate({
              mutation: LOGOUT_MUTATION,
            });
          } catch (e) {
            console.log('Error while logout', e);
          }
          afterLogout(apollo);
        });
      });
    },
  }),
  setContext((_, context) => {
    context.headers = context.headers || {};
    context.headers['location-href'] = window?.location?.href;
    context.headers.locale = i18n.language;
    // const token = TokenManager.getToken();
    // if (token) {
    //   const authHeaders = {};
    //   return { headers: { ...headers, ...authHeaders } };
    // }
    return context;
  }),
  onError(({ graphQLErrors, networkError, operation }) => {
    // const code = response?.errors[0]?.extensions?.code;
    // if (code === 'UNAUTHENTICATED') {
    //   console.log('received unauthenticated error');
    //   window.location.replace(window.location.origin);
    // }
    const logError = (error, { errorType }) => {
      const { message, locations, path, extensions, stack } = error;
      if (extensions?.exception) extensions.exception.logged = true;
      const lExtensions = JSON.stringify({
        ...extensions,
        ...(extensions?.exception && {
          exception: { ...extensions.exception, ...(extensions.exception?.stacktrace && { stacktrace: '(below)' }) },
        }),
      });
      const lStacktrace =
        get(extensions, 'exception.stacktrace', []).reduce(
          (p, c, index) => (p || '').concat(index !== 0 ? '\n' : '', c),
          '',
        ) ||
        stack ||
        ''; // array to string
      const lVariables = JSON.stringify(operation.variables);
      const lQuery = formatQueryByErrorPositions({ queryString: print(operation.query), errorPositions: locations });
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lQuery && `Query:\n${lQuery}`,
          lVariables && `Variables: ${lVariables}`,
          lExtensions && `Extensions: ${lExtensions}`,
          lStacktrace && `Stacktrace: ${lStacktrace}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const doNotLogOnClient = get(error, 'extensions.exception.doNotLogOnClient');
        if (!doNotLogOnClient) logError(error, { errorType: 'GraphQL error' });
      });
    }

    if (!graphQLErrors && networkError) logError(networkError, { errorType: 'Network error' });
  }),
  onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      const tryTranslateMessage = (error) => {
        const message = `BackendErrors.${error.message}`;
        const translated = i18n.t(message, error?.extensions?.exception?.i18nVariables);
        if (translated !== message) {
          // eslint-disable-next-line no-param-reassign
          error.originalMessage = error.message;
          // eslint-disable-next-line no-param-reassign
          error.message = translated;
        }
      };
      graphQLErrors.forEach(tryTranslateMessage);
    }
  }),
  new HttpLink({
    uri: settings.graphqlServerUrl,
    credentials: 'include',
  }),
]);

const cacheIndexBlacklist = [];

const apollo = new ApolloClient({
  // request: operation => {
  //   const token = TokenManager.getToken();
  //   console.log(token);
  //   if (token) {
  //     const headers = { Authorization: `Bearer ${token}` };
  //     operation.setContext({ headers });
  //   }
  // },
  link,
  resolvers: {
    Category: {
      isNew: (category) => {
        return !!category.isNew;
      },
    },
  },
  cache: new InMemoryCache({
    fragmentMatcher,
    freezeResults: true, // new
    dataIdFromObject: ({ _id, __typename }) => {
      if (cacheIndexBlacklist.includes(__typename)) return null;
      return _id ? `${__typename}___${_id}` : null;
    },
    // typePolicies: {
    //   Category: {
    //     fields: {
    //       isNew: {
    //         read(category) {
    //           return !!category.isNew;
    //         },
    //       },
    //     },
    //   },
    // },
  }),
  assumeImmutableResults: true, // new
});

setInterval(async () => {
  if (!RefreshTokenService.isTokenValidOrUndefined()) {
    await apollo.query({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'network-only',
    });
  }
}, 3000);

export default apollo;
