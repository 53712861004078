import apollo from 'graphql/apollo';
import { importDocumentTemplates } from 'graphql/mutations';
import ADMIN_DOCUMENT_TEMPLATE_LIST_QUERY from 'graphql/queries/adminDocumentTemplateList';

const importDocumentTemplatesFromLibrary = async (documentTemplates) =>
  apollo.mutate({
    mutation: importDocumentTemplates,
    variables: { input: documentTemplates },
    refetchQueries: [{ query: ADMIN_DOCUMENT_TEMPLATE_LIST_QUERY, variables: { isLibrary: false } }],
  });

export default importDocumentTemplatesFromLibrary;
