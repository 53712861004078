import gql from 'graphql-tag';

export const adminCompanyAddressFragment = gql`
  fragment adminCompanyAddressFragment on CompanyAddress {
    street
    houseNumber
    cityCode
    city
    country
  }
`;

const adminCompanyFragment = gql`
  fragment adminCompanyFragment on Company {
    _id
    type
    name
    identifer
    email
    address {
      ...adminCompanyAddressFragment
    }
    phoneNumber
    website
  }
  ${adminCompanyAddressFragment}
`;

export default adminCompanyFragment;
