import gql from 'graphql-tag';

const adminDiscountFragment = gql`
  fragment adminDiscountFragment on Discount {
    _id
    name
    value
    order
    type
  }
`;

export default adminDiscountFragment;
