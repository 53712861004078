import { useEffect } from 'react';
import moment from 'moment';
import { ApolloProvider } from '@apollo/react-hooks';
import { ConfirmAlertContainer } from '@uplab/react-confirm-alert';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_GB';
import { setLocale } from 'yup';
import { de as deLocale, en as enLocale } from 'utils/yupLocale';
import { SidebarContextProvider } from './contexts/SidebarContext';
import RootRouter from './router/RootRouter';
import startup from './startup';
import apolloClient from './graphql/apollo';
import 'normalize.css';
import './styles/index.less';
import './i18n';
import './testing';

startup();

const antdTranslations = {
  de,
  en,
};
const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    moment.locale(i18n.language);
    switch (i18n.language) {
      case 'en':
        setLocale(enLocale);
        break;
      case 'de':
        setLocale(deLocale);
        break;
      default:
    }
  }, [i18n.language]);

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={antdTranslations[i18n.language]}>
        <SidebarContextProvider>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <RootRouter />
          </DndProvider>
        </SidebarContextProvider>
      </ConfigProvider>

      <ConfirmAlertContainer />
    </ApolloProvider>
  );
};

export default App;
