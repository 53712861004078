import gql from 'graphql-tag';
import { adminCategoryListFragment } from '../fragments';

const ADMIN_CATEGORY_LIST_QUERY = gql`
  query adminCategoryList {
    categories {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default ADMIN_CATEGORY_LIST_QUERY;
