// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yPf0nuLwG3GBQjTwZnqqe {\n  margin-top: 5px !important;\n  margin-bottom: 5px !important;\n  position: relative;\n}\n.yPf0nuLwG3GBQjTwZnqqe ._15J0D4nXhlo_h7KZK9207H {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 30px;\n  height: 30px;\n  border: 1px solid black;\n  cursor: pointer;\n  outline: none;\n  border-image: initial;\n}\n.yPf0nuLwG3GBQjTwZnqqe ._15J0D4nXhlo_h7KZK9207H svg {\n  fill: #e43f3f;\n}\n._2d2aRMFb2kTI5jTNTr_Ej5 .ant-collapse {\n  border: 1px solid #e43f3f !important;\n  border-bottom: 0;\n}\n._2d2aRMFb2kTI5jTNTr_Ej5 .ant-collapse .ant-collapse-item {\n  border-bottom: 0;\n}\n._3p1qsIKWGEmnG41_nhSV-A {\n  margin-top: 5px;\n}\n", "",{"version":3,"sources":["webpack://src/components/admin/ImportCategoryPresetsModal.module.less"],"names":[],"mappings":"AAEA;EACE,0BAAA;EACA,6BAAA;EACA,kBAAA;AADF;AAFA;EAKI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EACA,qBAAA;AAAJ;AAhBA;EAkBM,aAAA;AACN;AAGA;EAEI,oCAAA;EACA,gBAAA;AAFJ;AADA;EAKM,gBAAA;AADN;AAKA;EACE,eAAA;AAHF","sourcesContent":["@import 'styles/variables.less';\n\n.category {\n  margin-top: 5px !important;\n  margin-bottom: 5px !important;\n  position: relative;\n  .addCategoryBtn {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 30px;\n    height: 30px;\n    border: 1px solid black;\n    cursor: pointer;\n    outline: none;\n    border-image: initial;\n    svg {\n      fill: @primary-color;\n    }\n  }\n}\n.duplicatedCategory {\n  :global(.ant-collapse) {\n    border: 1px solid @primary-color !important;\n    border-bottom: 0;\n    :global(.ant-collapse-item) {\n      border-bottom: 0;\n    }\n  }\n}\n.errorWrapper {\n  margin-top: 5px;\n}\n\n@primary-color: #e43f3f;@font-size-base: 16px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category": "yPf0nuLwG3GBQjTwZnqqe",
	"addCategoryBtn": "_15J0D4nXhlo_h7KZK9207H",
	"duplicatedCategory": "_2d2aRMFb2kTI5jTNTr_Ej5",
	"errorWrapper": "_3p1qsIKWGEmnG41_nhSV-A"
};
export default ___CSS_LOADER_EXPORT___;
