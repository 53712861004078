import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { castArray, find, map } from 'lodash';
import { TableMemo } from 'memo';
import { useMemo } from 'react';
import { useDocumentTemplateList } from 'graphql/hooks';
import { Buttons } from './Buttons';

const DocumentTypeRowButtons = ({ _id, buttonState, currentSubmitTypeRef, isSubmitting }) => {
  const { t } = useTranslation();
  const rowButtonsSchema = useMemo(
    () => [
      // { type: 'email', label: t('user.ShoppingCart.Buttons.email'), submitProps: { documentId: _id } },
      { type: 'html', label: t('user.ShoppingCart.Buttons.html'), submitProps: { documentId: _id } },
    ],
    [_id, t],
  );

  return (
    <Buttons
      currentSubmitTypeRef={currentSubmitTypeRef}
      isSubmitting={isSubmitting}
      buttonState={buttonState}
      onlyButtons
      schema={rowButtonsSchema}
    />
  );
};
const totalPreviewColumns = ({ t, buttonState, currentSubmitTypeRef }) => [
  {
    title: t('user.ShoppingCart.DocumentTypesTable.nameHeader'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('user.ShoppingCart.DocumentTypesTable.actionsHeader'),
    key: 'actions',
    render: (text, row) => (
      <DocumentTypeRowButtons _id={row?._id} buttonState={buttonState} currentSubmitTypeRef={currentSubmitTypeRef} />
    ),
  },
];

const DocumentTypesTable = ({ templates, buttonState, currentSubmitTypeRef, isSubmitting }) => {
  const { t } = useTranslation();
  return (
    <TableMemo
      bordered
      pagination={false}
      dataSource={templates}
      columns={totalPreviewColumns({ t, buttonState, currentSubmitTypeRef, isSubmitting })}
    />
  );
};

const DocumentTypesTableCard = ({ buttonState, currentSubmitTypeRef, isSubmitting }) => {
  const { values } = useFormikContext();
  const { documentTemplates } = values || {};
  const templatesArray = castArray(documentTemplates).filter(Boolean);
  const list = useDocumentTemplateList();
  if (!templatesArray.length) return null;
  const templates = map(templatesArray, (_id) => find(list?.data, (e) => e._id === _id));

  return (
    <>
      <DocumentTypesTable
        templates={templates}
        buttonState={buttonState}
        currentSubmitTypeRef={currentSubmitTypeRef}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default DocumentTypesTableCard;
