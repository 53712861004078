// import { formatDateTime } from 'utils/date';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import EmptyBox from 'components/common/EmptyBox';
import ItemTypes from 'constants/ItemTypes';
import DocumentBlockDragLayer from 'components/admin/drag/DragLayer/Document/DocumentBlockDragLayer';
import DraggableCollapse from 'components/admin/drag/Collapse/Collapse';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import { find } from 'lodash';
import CustomExpandIcon from 'components/admin/components/CustomExpandIcon/CustomExpandIcon';
import DraggableDecoration from 'components/admin/drag/DraggableDecoration';
import { useFunctionToRefCB } from 'memo';
import BlockController from './BlockController';
import AddBlockModal from './AddBlockModal';
import classes from './DocumentLayoutBlock.module.less';
import DocumentLayoutBlockContent from './DocumentLayoutBlockContent';

export default function DocumentLayout({ documentTemplate }) {
  const { t } = useTranslation();
  const { blocks, _id } = documentTemplate;
  // const initialValues = getFormInitialValues(documentTemplate);
  const [isAddBlockModalVisible, setIsAddBlockModalVisible] = useState(false);
  const [newBlockKeys, setNewBlockKeys] = useState([]);
  const move = (dragIndex, hoverIndex, blockId) => {
    const newBlocks = BlockController.reorderBlockById({ blocks, blockId, shift: hoverIndex - dragIndex });
    BlockController.persistBlocksInCache({ blocks: newBlocks, documentTemplateId: _id });
  };
  const mutation = () => {
    BlockController.persistBlocks({ _id, blocks });
  };
  const getDocumentTemplate = useFunctionToRefCB(() => documentTemplate);
  return (
    <Card
      className={classes.container}
      title={t('admin.DocumentTemplateConfigurationPage.layoutSection.title')}
      extra={
        <Button icon={<PlusOutlined />} type="primary" onClick={() => setIsAddBlockModalVisible(true)}>
          {t('admin.DocumentTemplateConfigurationPage.layoutSection.addBlockButton')}
        </Button>
      }
    >
      <DocumentBlockDragLayer />

      {Array.isArray(blocks) && blocks.length ? (
        <DraggableCollapse
          newKeys={newBlockKeys}
          component={(props) => <DocumentLayoutBlockContent getDocumentTemplate={getDocumentTemplate} {...props} />}
          componentProps={getDocumentTemplate}
          moveInCache={move}
          moveMutation={mutation}
          data={blocks}
          itemTypes={ItemTypes.DOCUMENT_BLOCK}
          nameElementData="block"
          draggableProps={{ isConnectedPreview: false }}
          collapseProps={{
            expandIcon: (props) => <CustomExpandIcon {...props} />,
          }}
          extraPanelProps={{ documentTemplate }}
          panelProps={{
            // eslint-disable-next-line no-shadow
            extra: ({ _id: blockId, documentTemplate }) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  confirmModal({
                    cancelText: t('admin.DocumentTemplateConfigurationPage.deleteBlockConfirmation.cancel'),
                    okText: t('admin.DocumentTemplateConfigurationPage.deleteBlockConfirmation.ok'),
                    okType: 'danger',
                    onOk: () => {
                      const newBlocks = BlockController.deleteBlockById({ blocks: documentTemplate.blocks, blockId });
                      BlockController.persistBlocks({ _id: documentTemplate._id, blocks: newBlocks })
                        .then(toast.successDefault)
                        .catch(toastGraphQLError);
                    },
                    title: t('admin.DocumentTemplateConfigurationPage.deleteBlockConfirmation.title'),
                  });
                }}
                type="danger"
                ghost
                size="small"
                icon={<DeleteOutlined />}
              />
            ),
            forceRender: true,
            header: (id) => find(blocks, { _id: id })?.descriptor?.displayName,
          }}
          decorate={<DraggableDecoration height="49px" />}
        />
      ) : (
        <EmptyBox label={t('admin.DocumentTemplateConfigurationPage.layoutSection.emptyBlockList')} />
      )}
      <AddBlockModal
        isVisible={isAddBlockModalVisible}
        changeActiveKey={({ blockId }) => {
          setNewBlockKeys([...newBlockKeys, blockId]);
        }}
        close={useCallback(() => setIsAddBlockModalVisible(false), [])}
        getDocumentTemplate={getDocumentTemplate}
      />
    </Card>
  );
}
